import { props___page___hasToast } from "../../../interfaces/interface.toasts"

//	P R O P S

export interface props___SbSyncConnectorPage extends props___page___hasToast {

}

//	S T A T E

export interface state___SbSyncConnectorPage {

	dealer___props___connector: any | null,

	GUI___page___is_loading: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbSyncConnectorPage___defaults : state___SbSyncConnectorPage = {

	dealer___props___connector: null,

	GUI___page___is_loading: true,

}