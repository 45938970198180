import React from "react";

//	S T Y L E

import "./sb-settings-general.scss";

//	S E R V I C E S

import { service_RestApiService } from "../../../services/service-api";

//	T Y P E S

import { type___api___response } from "../../../types/types.api-response";
import { type___dealer_setting___property } from "../../../types/types.types";
import { type___toast___controller } from "../../../types/types.toasts";

//	S T A T E S

import { props___SbSettingsGlobalPage, state___SbSettingsGlobalPage, state___SbSettingsGlobalPage___defaults } from "./sb-settings-general.state";

//	C L A S S

export default class SbSettingsGlobalPage extends React.Component<props___SbSettingsGlobalPage, state___SbSettingsGlobalPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbSettingsGlobalPage,
	)
	{
		super(_PROPS);
		this.state = state___SbSettingsGlobalPage___defaults;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___settings = async () => {
		const ___dealer_settings___props: type___api___response = await this._API.dealers___read___single();
		this.setState({
			
			dealer_setting___vehicles___visible_after_sale: ___dealer_settings___props.data['dealer_setting___vehicles___visible_after_sale'],
			dealer_setting___vehicles___visible_after_sale___duration: ___dealer_settings___props.data['dealer_setting___vehicles___visible_after_sale___duration'],
			dealer_setting___vehicles___visible_after_sale___price: ___dealer_settings___props.data['dealer_setting___vehicles___visible_after_sale___price'],

			dealer_setting___vehicle_sync___default_visible: ___dealer_settings___props.data['dealer_setting___vehicle_sync___default_visible'],

		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___select___tab = async (___e: state___SbSettingsGlobalPage['selected___settings_general___tab']) => {
		this.setState({
			GUI___page___is_loading___section: true,
			selected___settings_general___tab: ___e,
		}, async () => {
			await this.read_props___settings();
		});
	}

	handle___submit___settings = async () => {

		let ___new_settings: type___dealer_setting___property[] = []

		switch (this.state.selected___settings_general___tab) {
			case 'sales':
				___new_settings = [
					{ key: 'dealer_setting___vehicles___visible_after_sale', value: this.state.dealer_setting___vehicles___visible_after_sale },
					{ key: 'dealer_setting___vehicles___visible_after_sale___duration', value: this.state.dealer_setting___vehicles___visible_after_sale___duration },
					{ key: 'dealer_setting___vehicles___visible_after_sale___price', value: this.state.dealer_setting___vehicles___visible_after_sale___price },
				];
			break;













		}

		const ___setting_submitted: type___api___response = await this._API.dealers___change_settings(___new_settings);
		if (___setting_submitted['response'] === 'success')
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'success',
				toast___text: <>Impostazioni aggiornate</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
			await this.read_props___settings();
		}
		else
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'danger',
				toast___text: <>Errore nell'aggiornamento delle impostazioni...</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}

	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		await this.read_props___settings();
	}

//#endregion

//#region 																							R E N D E R

	render()
	{
		return <>
			<div className="page---container is-full-width">
				<section>
					<div className="settings-overview---flexy">
						<div>
							<div className="page---top-bar page---top-bar---is-sidebar">
								<section>
									<input type="radio" id="d-ts---csec---sales" name="d-ts---cb" value="gallery" checked={ this.state.selected___settings_general___tab === 'sales' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
									<label htmlFor="d-ts---csec---sales" className="is-card">
										<img src="/assets/ui/icons/icon-sale.svg" />
										<span>Vendite</span>
									</label>



								</section>
							</div>
						</div>

						<div className="is-card settins-container---container-flex">
							{(() => {
								switch (this.state.selected___settings_general___tab) {
									case 'sales': return <>
										<div>
											<h2 className="is-title" style={{paddingLeft:'15pt',textTransform:'uppercase'}}>Visibilità Annunci</h2>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Visibilità degli annunci dopo la vendita effettuata</p>
												<select value={ this.state.dealer_setting___vehicles___visible_after_sale ?? '' } className="input---nb-outlined" onChange={(___e: any)=>{this.setState({ dealer_setting___vehicles___visible_after_sale: ___e.target.value })}}>
													<option value="visible">Visibili</option>
													<option value="hidden">Nascosti</option>
												</select>
											</section>
											{(this.state.dealer_setting___vehicles___visible_after_sale === 'visible') ? <>
												<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
													<p>Giorni di visibilità degli annunci dopo la vendita effettuata</p>
													<input value={ this.state.dealer_setting___vehicles___visible_after_sale___duration ?? '' } className="input---nb-outlined" onInput={(___e: any)=>{this.setState({ dealer_setting___vehicles___visible_after_sale___duration: ___e.target.value })}}/>
												</section>
												<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
													<p>Rendi visibile il prezzo della vendita</p>
													<select value={ this.state.dealer_setting___vehicles___visible_after_sale___price ?? '' } className="input---nb-outlined" onChange={(___e: any)=>{this.setState({ dealer_setting___vehicles___visible_after_sale___price: ___e.target.value })}}>
														<option value="visible">Si</option>
														<option value="hidden">No</option>
													</select>
												</section>
											</> : <></>}
											<br />
											<br />
											<br />
											<div className="page-container---bottom-bar---buttons is-centered">
												<button type="button" className="is-card is-submit" onClick={ this.handle___submit___settings }>
													<i className="fas fa-check"></i>
													<span>Salva Impostazioni</span>
												</button>
											</div>
										</div>
									</>; break;
									



















								}
							})()}
						</div>

					</div>
				</section>
			</div>
		</>;
	}

//#endregion

}




