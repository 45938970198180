import { lib_names } from '../libs/lib.names';

//	E X P O R T

export class service_RestAuthService
{

//#region 																							D E C L A R A T I O N S

	private _HEADERS: any = { 'Content-Type': 'application/json' };

//#endregion

//#region 																							R E T U R N   S E R V E R   R E S P O N S E

	private async return_server_response(___target: string, ___to_post: any | null) : Promise<any>
	{
		const ___s_route: string = lib_names.endpoints.server_api + 'auth/' + ___target;
		const ___s_response: Response = await fetch(___s_route, { method: 'POST', headers: this._HEADERS, body: JSON.stringify(___to_post) });
		if (___s_response.ok) { const ___server_response: any = await ___s_response.json(); return ___server_response; }
		else { try { const ___error_details: any = await ___s_response.json(); return ___error_details.message; } catch (___e: any) { return ___e; }}
	}

//#endregion

//#region 																							C H E C K   I F   I S   L O G G E D

	async auth___checklogged(___ltk: string | null = null) : Promise<any>
	{
		const ___to_post___token: string | null = (___ltk !== null) ? ___ltk.toString() : localStorage.getItem(lib_names.storage.user___logintoken) ?? null;
		const ___to_post: any = { 'token': ___to_post___token };
		return await this.return_server_response('checklogged', ___to_post);
	}

//#endregion

//#region 																							L O G I N

	async auth___login(___username: string, ___password: string) : Promise<any>
	{
		const ___to_post: any = { 'username': ___username, 'password': ___password };
		return await this.return_server_response('login', ___to_post);
	}

//#endregion

//#region 																							L O G O U T

	sign___logout() : void
	{
		localStorage.removeItem(lib_names.storage.user___logintoken);
		window.location.href = window.location.href;
	}

//#endregion

}