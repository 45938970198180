import React from 'react';

//	S T Y L E S

import './md-document-uploader.scss';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___document___type } from '../../types/types.types';

//	S T A T E S

import { props___MdDocumentUploaderModal, state___MdDocumentUploaderModal, state___MdDocumentUploaderModal___default } from './md-document-uploader.state';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../components/com-spinner/com-spinner';

//	C L A S S

export default class MdDocumentUploaderModal extends React.Component<props___MdDocumentUploaderModal, state___MdDocumentUploaderModal>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdDocumentUploaderModal,
	) {
		super(props);
		this.state = state___MdDocumentUploaderModal___default
	}

//#endregion

//#region 																							S U B M I T 

	submit___document = () => {
		
		this.setState({
			GUI___modal___is_uploading: true,
		}, async () => {

			const ___field___title: string = (this.state.field___file___title.length > 0) ? this.state.field___file___title : this.state.field___file___name;
			const ___field___name: string = this.state.field___file___name;
			const ___field___target: type___document___type = this.props.target;
			const ___field___target_param: string = this.props.target_param;
			const ___field___file: string = this.state.field___file;
			const ___field___file_size: number = this.state.field___file___size;
	
			const ___document___submitted: type___api___response = await this._API.documents___submit(___field___title, ___field___file, ___field___name, ___field___file_size, ___field___target, ___field___target_param);
			if (___document___submitted['response'] === 'success') { this.props.event___onDismiss(true); }
			else { this.props.event___onDismiss(false); }

			this.setState({
				GUI___modal___is_uploading: false,
			});

		});

	}

//#endregion

//#region 																							H A N D L E R S

	handle___file___select = (___e: React.ChangeEvent<HTMLInputElement>) => {
		let ___file___size_error: boolean = false;
		if (___e.target.files) {
			const ___files_array: File[] = Array.from(___e.target.files);
			const ___f___single: File = ___files_array[0];
			if (___f___single.size <= 5 * 1024 * 1024) {
				const ___f___single___reader: any = new FileReader();
				___f___single___reader.onloadend = () => {
					this.setState({
						field___file___name: ___f___single.name,
						field___file: ___f___single___reader.result as string,
						field___file___size: ___f___single.size,
					});
				};
				___f___single___reader.readAsDataURL(___f___single);
			}
			else
			{
				___file___size_error = true;
				console.error('File is too large. Maximum size is 5MB.');
			}
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{

	}

//#endregion

//#region 																							R E N D E R

	render()
	{
		return (<>
			<div className="document-uploader---opacizer" onClick={() => { this.props.event___onDismiss(null) }}></div>
			<div className="document-uploader---container is-card">
				{(this.state.GUI___modal___is_uploading === false) ? <>

					<h3>Carica Documento</h3>

					<br />

					<span className="input---nb-label is-required">titolo del documento</span>
					<section className="input---nb-outlined---flexy">
						<input type="text" value={ this.state.field___file___title } className="input---nb-outlined" placeholder="Titolo del documento" onInput={(___e: any) => { this.setState({ field___file___title: ___e.target.value })}}/>
					</section>

					<span className="input---nb-label is-required">file del documento</span>
					<section className="input---nb-outlined---file">
						<label htmlFor="input-file---document">
							<i className="fas fa-upload"></i>
							<span>{(this.state.field___file___name.length > 0) ? this.state.field___file___name : 'carica documento' }</span>
							<input type="file" id="input-file---document" onChange={ this.handle___file___select }/>
							<span></span>
						</label>
					</section>

					<br />
					<br />

					<div className="page-container---bottom-bar---buttons is-centered">
						<button type="button" className="is-card no-shadow" onClick={() => { this.props.event___onDismiss(null); }}>
							<i className="fas fa-times"></i>
							<span>Chiudi</span>
						</button>
						{(this.state.field___file.length > 0) ? <>
							<button type="button" className="is-card is-submit no-shadow" onClick={ this.submit___document }>
								<i className="fas fa-upload"></i>
								<span className="is-full-width">Carica Documento</span>
							</button>
						</> : <>
							<button type="button" className="is-card is-disabled no-shadow">
								<i className="fas fa-upload"></i>
								<span className="is-full-width">Seleziona un file</span>
							</button>
						</>}
					</div>

				</> : <>
					<ComSpinnerComponent/>
				</>}
			</div>
		</>);
	}

//#endregion

}