import React from 'react';

//	S T Y L E

import './com-results-more.scss';

//	T Y P E S

import { props___ComResultsMoreComponent } from './com-results-more.state';

//	C O M P O N E N T

import ComSpinnerComponent from '../com-spinner/com-spinner';

//	E X P O R T

export default class ComResultsMoreComponent extends React.Component<props___ComResultsMoreComponent, {}>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComResultsMoreComponent,
	)
	{
		super(props);
	}

//#endregion

//#region 																							R E T U R N

	render(): React.ReactNode
	{
		return <>
			<div className="results---load-more---container">
				{(() => {
					switch (this.props.is_loading as boolean) {
						case true: return <>
							<section style={{transform:'scale(0.45)',opacity:'0.25'}}>
								<ComSpinnerComponent has_margin={ false }/>
							</section>
						</>; break;
						case false: return <>
							<section>
								<span onClick={() => { this.props.event___onClick(); }}>
									{(this.props.text) ? <>{ this.props.text }</> : <>carica altro...</>}
								</span>
							</section>
						</>; break;
					}
				})()}
			</div>
		</>;
	}

//#endregion

}