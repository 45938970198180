import React from 'react';

//	S T Y L E

import './sb-sync-connector.scss';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';
import { type___toast___controller } from '../../../types/types.toasts';

//	L I B S

import { funcs_clipboard } from '../../../funcs/funcs.clipboard';

//	S T A T E

import { props___SbSyncConnectorPage, state___SbSyncConnectorPage, state___SbSyncConnectorPage___defaults } from './sb-sync-connector.state';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';

//	C L A S S

export default class SbSyncConnectorPage extends React.Component<props___SbSyncConnectorPage, state___SbSyncConnectorPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___clipboard: funcs_clipboard = new funcs_clipboard();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbSyncConnectorPage
	) {
		super(_PROPS);
		this.state = state___SbSyncConnectorPage___defaults;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___dealer = async () => {
		const ___dealer___props___connector: type___api___response = await this._API.dealers___read___connector_credentials();
		this.setState({
			dealer___props___connector: ___dealer___props___connector.data,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___input___copy = async (___text: string) => {

		const ___is_copied: boolean = await this.funcs___clipboard.clipboard___copy_text(___text);

		if (___is_copied === true)
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'success',
				toast___text: <>Hai copiato "<b>{ ___text }</b>"</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}
		else
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'danger',
				toast___text: <>Errore generico nel copiare</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}

	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		this.setState({
			GUI___page___is_loading: true,
		}, async () => {
			await this.read_props___dealer();
		});
	}

//#endregion 

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return <>
			{(this.state.dealer___props___connector !== null) ? <>

				<div className="page---container is-full-width">
					<section>
						<div className="is-card dealer---ch-connector---container">
							<section>
								<img src="/assets/logo.horizontal.svg"/>
								<br />
								<br />
								<br />
								<h2 className="input---nb-title">Credenziali Api per il plugin CarsHub Connector</h2>
								<span className="input---nb-label">API user</span>
								<section className="input---nb-outlined---flexy">
									<input type="text" value={ this.state.dealer___props___connector['dealer_api_user'] } disabled={true}/>
									<div className="page-container---bottom-bar---buttons" style={{ width: '200pt' }}>
										<button type="button" className="is-card no-shadow" onClick={() => { this.handle___input___copy(this.state.dealer___props___connector['dealer_api_user']) }}>
											<i className="far fa-copy"></i><span className="is-full-width">Copia User</span>
										</button>
									</div>
								</section>
								<span className="input---nb-label">API Token</span>
								<section className="input---nb-outlined---flexy">
									<input type="text" value={ this.state.dealer___props___connector['dealer_api_token'] } disabled={true}/>
									<div className="page-container---bottom-bar---buttons" style={{ width: '200pt' }}>
										<button type="button" className="is-card no-shadow" onClick={() => { this.handle___input___copy(this.state.dealer___props___connector['dealer_api_token']) }}>
											<i className="far fa-copy"></i><span className="is-full-width">Copia Token</span>
										</button>
									</div>
								</section>
								<br />
								<br />
								<br />
							</section>
						</div>
					</section>
				</div>
			</> : <><ComSpinnerComponent/></>}
		</>;
	}

//#endregion

}