import React from 'react';

//	S T Y L E

import './sb-calendar.scss';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';

//	F U N C S

import { funcs_numbers } from '../../funcs/funcs.numbers';

//	S T A T E

import { props___SbCalendarPage, state___SbCalendarPage, state___SbCalendarPage___defaults } from './sb-calendar.state';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComResultsEmptyComponent from '../../components/com-results-empty/com-results-empty';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';
import ComCalendarEventComponent from '../../components/com-calendar-event/com-calendar-event';

//	C L A S S

export default class SbCalendarPage extends React.Component<props___SbCalendarPage, state___SbCalendarPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService()

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbCalendarPage
	) {
		super(_PROPS);
		this.state = state___SbCalendarPage___defaults;
	}

//#endregion

//#region																							R E A D   P R O P S

	read_props___for_month = async () => {
		const ___date___month: number = this.state.date___today.getMonth() + 1;
		const ___date___year: number = this.state.date___today.getFullYear();
		const ___props_for_month___founds: type___api___response = await this._API.calendar___read___from_month(___date___month, ___date___year);
		this.setState({
			list___events_for___month: ___props_for_month___founds.data,
			GUI___section___is_loading___month: false
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___calendar___change_month = (___index: 'actual' | 'previous' | 'next') => {
		this.setState({
			list___events_for___month: [],
			GUI___section___is_loading___month: true,
		}, () => {
			
			switch (___index) {
				case 'actual': this.state.date___today.setMonth(this.state.date___today.getMonth()); break;
				case 'previous': this.state.date___today.setMonth(this.state.date___today.getMonth() - 1); break;
				case 'next': this.state.date___today.setMonth(this.state.date___today.getMonth() + 1); break;
			}

			const ___date_current___year: number = this.state.date___today.getFullYear();
			const ___date_current___month: number = this.state.date___today.getMonth();
			const ___date_current___days_in_month = new Date(___date_current___year, (___date_current___month + 1), 0).getDate();
	
			let ___date___days___temp: any[] = [];
	
			const ___date_current___day_first: Date = new Date(___date_current___year, ___date_current___month, 1);
			const ___date_current___day_first___of_week: number = ___date_current___day_first.getDay();
			const ___date_current___day_first___not_sunday: number = (___date_current___day_first___of_week - 1 + 7) % 7;
			for (let ___i = 0; ___i < ___date_current___day_first___not_sunday; ___i++) { ___date___days___temp.push({ day_valid: false }); }
	
			___date___days___temp = ___date___days___temp.concat(Array.from({ length: ___date_current___days_in_month }, (_, ___i) => {
				const ___date___selected_day: Date = new Date(___date_current___year, ___date_current___month, ___i + 1);
				const ___date___selected_day___name: string = ___date___selected_day.toLocaleDateString('en-US', { weekday: 'short' });
				const ___date___selected_day___name___date: string = ___date___selected_day.getFullYear().toString() + (___date___selected_day.getMonth() + 1).toString() + ___date___selected_day.getDate().toString();
				const ___date___selected_day___return: any = {
					day_valid: true,
					day_date: ___date___selected_day___name___date,
					day_year: ___date___selected_day.getFullYear(),
					day_month: (___date___selected_day.getMonth() + 1),
					day_number: ___date___selected_day.getDate(),
					day_name_short: ___date___selected_day___name
				}
				return ___date___selected_day___return as any;
			}));
	
			const ___date_current___day_last = ___date___days___temp.length;
			const ___date_current___day_last___count = (7 - (___date_current___day_last % 7)) % 7;
			for (let i = 0; i < ___date_current___day_last___count; i++) { ___date___days___temp.push({}); }
	
			this.setState({
				date___days: ___date___days___temp,
				GUI___section___is_loading___month: false
			}, async () => {
				await this.read_props___for_month();
			});

		});
	}

	handle___calendar___select_day = (___day: any) => {
		this.setState({
			selected___day: ___day,
			list___events_for___day: [],
			GUI___section___is_loading___day: true,
		}, () => {
			this.setState({
				list___events_for___day: this.state.list___events_for___month[___day['day_number']]['element_list'],
				GUI___section___is_loading___day: false,
			});
		})
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		this.handle___calendar___change_month('actual');
	}

//#endregion 

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="page---container is-full-width is-flexy">
				<section>

					<div className="calendar---container is-card">
						<section>
							<button className="is-card no-shadow" onClick={() => this.handle___calendar___change_month('previous')}>
								<i className="fas fa-chevron-left"></i>
							</button>
							<div>
								<h6>
									{(() => {
										switch ((this.state.date___today.getMonth() + 1)) {
											case 1: return (<span>Gennaio</span>); break;
											case 2: return (<span>Febbraio</span>); break;
											case 3: return (<span>Marzo</span>); break;
											case 4: return (<span>Aprile</span>); break;
											case 5: return (<span>Maggio</span>); break;
											case 6: return (<span>Giugno</span>); break;
											case 7: return (<span>Luglio</span>); break;
											case 8: return (<span>Agosto</span>); break;
											case 9: return (<span>Settembre</span>); break;
											case 10: return (<span>Ottobre</span>); break;
											case 11: return (<span>Novembre</span>); break;
											case 12: return (<span>Dicembre</span>); break;
										}
									})()}
									<span>{this.state.date___today.getFullYear()}</span>
								</h6>
							</div>
							<button className="is-card no-shadow" onClick={() => this.handle___calendar___change_month('next')}>
								<i className="fas fa-chevron-right"></i>
							</button>
						</section>
						{(this.state.GUI___section___is_loading___month === false) ? <>
							<section>
								<div className={(this.state.selected___day && this.state.selected___day['day_name_short'] == 'Mon') ? ' is-day-header---selected ' : ' ' }>lun</div>
								<div className={(this.state.selected___day && this.state.selected___day['day_name_short'] == 'Tue') ? ' is-day-header---selected ' : ' ' }>mar</div>
								<div className={(this.state.selected___day && this.state.selected___day['day_name_short'] == 'Wed') ? ' is-day-header---selected ' : ' ' }>mer</div>
								<div className={(this.state.selected___day && this.state.selected___day['day_name_short'] == 'Thu') ? ' is-day-header---selected ' : ' ' }>gio</div>
								<div className={(this.state.selected___day && this.state.selected___day['day_name_short'] == 'Fri') ? ' is-day-header---selected ' : ' ' }>ven</div>
								<div className={(this.state.selected___day && this.state.selected___day['day_name_short'] == 'Sat') ? ' is-day-header---selected ' : ' ' }>sab</div>
								<div className={(this.state.selected___day && this.state.selected___day['day_name_short'] == 'Sun') ? ' is-day-header---selected ' : ' ' }>dom</div>
								{this.state.date___days.map((___day, ___i) => <>
									{(___day.day_valid === true) ? <>
										<div key={ ___i } className={((this.state.selected___day === ___day) ? 'day---is-selected ' : '') + ' is-card no-shadow ' + ((this.state.date___today.getDate().toString() === ___day['day_number'].toString()) ? 'day---is-today ' : '')} onClick={() => { this.handle___calendar___select_day(___day); }}>
											{(this.state.date___today.getDate().toString() === ___day['day_number'].toString()) ? <><span>oggi</span></> : <></>}
											<h6>{ ___day['day_number'] }</h6>
											{(this.state.list___events_for___month && this.state.list___events_for___month.length > 0 && this.state.list___events_for___month[parseInt(___day['day_number']) - 1]) ? <>
												<section>
													{(this.state.list___events_for___month[parseInt(___day['day_number']) - 1]['element_type_activities'] === true) ? <>
														{(this.state.list___view_element___activity === true) ? <>
															<i className="fas fa-calendar-day"></i>
														</> : <><span className="fa-calendar-day"></span></>}
													</> : <></>}
													{(this.state.list___events_for___month[parseInt(___day['day_number']) - 1]['element_type_leads'] === true) ? <>
														{(this.state.list___view_element___lead === true) ? <>
															<i className="fas fa-envelope-open-text"></i>
														</> : <><span className="fa-envelope-open-text"></span></>}
													</> : <></>}
													{(this.state.list___events_for___month[parseInt(___day['day_number']) - 1]['element_type_sales'] === true) ? <>
														{(this.state.list___view_element___sale === true) ? <>
															<i className="fas fa-money-bill-wave"></i>
														</> : <><span className="fa-money-bill-wave"></span></>}
													</> : <></>}
													{(this.state.list___events_for___month[parseInt(___day['day_number']) - 1]['element_type_vehicles_cars'] === true) ? <>
														{(this.state.list___view_element___vehicle === true) ? <>
															<i className="fas fa-car"></i>
														</> : <><span className="fa-car"></span></>}
													</> : <></>}
													{(this.state.list___events_for___month[parseInt(___day['day_number']) - 1]['element_type_vehicles_motorcycle'] === true) ? <>
														{(this.state.list___view_element___vehicle === true) ? <>
															<i className="fas fa-motorcycle"></i>
														</> : <><span className="fa-motorcycle"></span></>}
													</> : <></>}
												</section>
											</> : <></>}
										</div>
									</> : <>
										<div className="is-not-a-day is-card no-shadow"></div>
									</>}
								</>)}
								{(this.state.date___days.length < 40) ? <>
									{(Array(7).fill(null)).map((_: any) => <>
										<div className="is-not-a-day is-card no-shadow"></div>
									</>)}
								</> : <></>}
							</section>
						</> : <>
							<ComSpinnerComponent />
						</>}
					</div>

					<div className="calendar-event-list---container">
						
						<div className=" is-card">
							{(this.state.selected___day) ? <>
								<h6>
									<span>Agenda eventi del eventi del giorno </span>
									<span>{ this.funcs___numbers.pad___twoDigits(this.state.selected___day['day_number']) + '/' + this.funcs___numbers.pad___twoDigits(this.state.selected___day['day_month']) + '/' + this.state.selected___day['day_year'].toString() }</span>
								</h6>
								{(this.state.GUI___section___is_loading___day === false) ? <>
									{(this.state.list___events_for___day.length > 0) ? <>
										<div className="events-list---container">
											<section>
												{this.state.list___events_for___day.map((___c_ele: any, ___i: number) => { return <>
													{(
														(___c_ele['element_type'] === 'activity' && this.state.list___view_element___activity === true) ||
														(___c_ele['element_type'] === 'lead' && this.state.list___view_element___lead === true) ||
														(___c_ele['element_type'] === 'sale' && this.state.list___view_element___sale === true) ||
														(___c_ele['element_type'] === 'vehicle_car' && this.state.list___view_element___vehicle === true) ||
														(___c_ele['element_type'] === 'vehicle_motorcyle' && this.state.list___view_element___vehicle === true)
													) ? <>
														<ComCalendarEventComponent key={ ___i } element___props={ ___c_ele }/>
													</> : <></>}
												</>; })}
											</section>
										</div>
									</> : <>
										<ComResultsEmptyComponent text="nessuna attività disponibile in questa giornata"/>
									</>}
								</> : <>
									<ComSpinnerComponent/>
								</>}
							</> : <>
								<h6>
									<span>Agenda</span>
								</h6>
								<ComResultsEmptyComponent text="seleziona un giorno dal calendario per visualizzare gli eventi"/>
							</>}
						</div>
						<div className="is-card event-list-viewer-toggler---container">
							<input type="checkbox" id="viewable---cb---activities" checked={ this.state.list___view_element___activity } onChange={() => { this.setState({ list___view_element___activity: !this.state.list___view_element___activity }); }}/>
							<label htmlFor="viewable---cb---activities" data-title="Visualizza/Nascondi Attività">
								<i className="fas fa-calendar-day"></i>
								<span>Attività</span>
							</label>
							<input type="checkbox" id="viewable---cb---leads" checked={ this.state.list___view_element___lead } onChange={() => { this.setState({ list___view_element___lead: !this.state.list___view_element___lead }); }}/>
							<label htmlFor="viewable---cb---leads" data-title="Visualizza/Nascondi Leads">
								<i className="fas fa-envelope-open-text"></i>
								<span>Leads</span>
							</label>
							<input type="checkbox" id="viewable---cb---states" checked={ this.state.list___view_element___sale } onChange={() => { this.setState({ list___view_element___sale: !this.state.list___view_element___sale }); }}/>
							<label htmlFor="viewable---cb---states" data-title="Visualizza/Nascondi Vendite">
								<i className="fas fa-money-bill-wave"></i>
								<span>Vendite</span>
							</label>
							<input type="checkbox" id="viewable---cb---vehicles" checked={ this.state.list___view_element___vehicle } onChange={() => { this.setState({ list___view_element___vehicle: !this.state.list___view_element___vehicle }); }}/>
							<label htmlFor="viewable---cb---vehicles" data-title="Visualizza/Nascondi Veicoli">
								<i className="fas fa-car"></i>
								<span>Veicoli</span>
							</label>
						</div>
					</div>

				</section>
			</div>
		</>;
	}

//#endregion

}