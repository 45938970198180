//	P R O P S

export interface props___ComVehicleGalleryEditComponent {

	gallery_pictures: any[]

	event___onUpdate: (_: string[]) => void

}

//	S T A T E

export interface state___ComVehicleGalleryEditComponent {

	field___gallery_pictures: any[]
	action___item___is_dragging: boolean

}

//	S T A T E   D E F A U L T S

export const state___ComVehicleGalleryEditComponent___defaults: state___ComVehicleGalleryEditComponent = {

	field___gallery_pictures: [],
	action___item___is_dragging: false

}