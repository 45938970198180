import { props___page___hasAlert } from "../../../interfaces/interface.alerts"
import { props___page___hasToast } from "../../../interfaces/interface.toasts"
import { type___user___role } from "../../../types/types.types"

//	P R O P S

export interface props___SbSettingsUsersPage extends props___page___hasAlert, props___page___hasToast {

}

//	S T A T E

export interface state___SbSettingsUsersPage {
	
	dealer___props: any | null, 

	user___list: any[],

	user___single___id: string | 'new' | null,
	user___single___is_me: boolean,
	user___single___field___role: type___user___role,
	user___single___field___username: string,
	user___single___field___name: string,
	user___single___field___surname: string,
	user___single___field___registry___cf: string,
	user___single___field___contact___mail: string,
	user___single___field___contact___phone: string,
	user___single___field___address___street: string,
	user___single___field___address___street_n: string,
	user___single___field___address___city: string,
	user___single___field___address___prov: string,
	user___single___field___address___zip: string,
	user___single___field___address___country: string,

	user___single___can_submit: boolean,

	GUI___page___is_loading___list: boolean,
	GUI___page___is_loading___user: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbSettingsUsersPage___defaults : state___SbSettingsUsersPage = {

	dealer___props: null, 

	user___list: [],

	user___single___id: null,
	user___single___is_me: false,
	user___single___field___role: 'member',
	user___single___field___username: '',
	user___single___field___name: '',
	user___single___field___surname: '',
	user___single___field___registry___cf: '',
	user___single___field___contact___mail: '',
	user___single___field___contact___phone: '',
	user___single___field___address___street: '',
	user___single___field___address___street_n: '',
	user___single___field___address___city: '',
	user___single___field___address___prov: '',
	user___single___field___address___zip: '',
	user___single___field___address___country: '',

	user___single___can_submit: false,

	GUI___page___is_loading___list: true,
	GUI___page___is_loading___user: false,

}