//	E X P O R T   L I B

import { type___request___order } from "../types/types.types";

export const lib_defaults = {
	types: {
		type___request___order: { param: 'createdAt', direction: -1 } as type___request___order
		







	}
}