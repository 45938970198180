import React from "react";
import { Link } from "react-router-dom";

//	S T Y L E

import "./com-calendar-event.scss";

//	T Y P E S

import { type___activity___type, type___lead___interest___to, type___lead___source, type___lead___status } from "../../types/types.types";
import { type___api___response } from "../../types/types.api-response";

//	L I B S

import { lib_names } from "../../libs/lib.names";
import { lib_properties } from "../../libs/lib.properties";

//	F U N C S

import { funcs_datetime } from "../../funcs/funcs.datetime";
import { funcs_numbers } from "../../funcs/funcs.numbers";

//	S T A T E

import { props___ComCalendarEventComponent, state___ComCalendarEventComponent, state___ComCalendarEventComponent___default } from "./com-calendar-event.state";

//	S E R V I C E S

import { service_RestApiService } from "../../services/service-api";

//	C O M P O N E N T S

import ComSpinnerComponent from "../com-spinner/com-spinner";

//	C L A S S

export default class ComCalendarEventComponent extends React.Component<props___ComCalendarEventComponent, state___ComCalendarEventComponent>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly lib___properties: lib_properties = new lib_properties();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComCalendarEventComponent
	) {
		super(props);
		this.state = state___ComCalendarEventComponent___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___activity = async () => {
		const ___activity___props: type___api___response = await this._API.activities___read___single(this.props.element___props['element_id']);
		this.setState({
			elment_props___activity: ___activity___props.data,
			GUI___element___is_loading: false
		});
	}

	read_props___lead = async () => {
		const ___lead___props: type___api___response = await this._API.leads___read__single(this.props.element___props['element_id']);
		const ___lead___props___vehicle: type___api___response = (___lead___props.response === 'success') ? await this._API.vehicles___read___single(___lead___props.data['lead_vehicle']) : { response: 'error', data: null};
		this.setState({
			elment_props___lead: ___lead___props.data,
			elment_props___vehicle: ___lead___props___vehicle.data,
			GUI___element___is_loading: false
		});
	}

	read_props___vehicle = async () => {
		const ___vehicle___props: type___api___response = await this._API.vehicles___read___single(this.props.element___props['element_id']);
		this.setState({
			elment_props___vehicle: ___vehicle___props.data,
			GUI___element___is_loading: false
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		this.setState({
			GUI___element___is_loading: true
		}, async () => {
			switch (this.props.element___props['element_type'])
			{
				case 'activity':
					await this.read_props___activity();
					break;
				case 'birthday': 
					console.log('birthday');
					break;
				case 'lead':
					await this.read_props___lead();
					break;
				case 'sale':
				case 'vehicle_car':
				case 'vehicle_motorcyle':
					await this.read_props___vehicle();
					break;
			}
		});
	}

//#endregion

//#region 																							R E N D E R

	render(): React.ReactNode
	{

		let ___element___link_to_url: string = '';
		let ___element___class: string = '';

		if (this.state.GUI___element___is_loading === false) {
			switch (this.props.element___props['element_type']) {
				case 'activity':
					___element___link_to_url = '/leads/view/' + this.state.elment_props___activity['activity_lead'];
					___element___class = ' event---is-activity ';
					break;
				case 'lead': 
					___element___link_to_url = '/leads/view/' + this.props.element___props['element_id'];
					___element___class = ' event---is-lead ';
					break;
				case 'sale':
					___element___link_to_url = '/vehicles/view/' + this.props.element___props['element_id'];
					___element___class = ' event---is-sale ';
					break;
				case 'vehicle_car':
					___element___link_to_url = '/vehicles/view/' + this.props.element___props['element_id'];
					___element___class = ' event---is-vehicle-car ';
					break;
				case 'vehicle_motorcyle':
					___element___link_to_url = '/vehicles/view/' + this.props.element___props['element_id'];
					___element___class = ' event---is-vehicle-motorcycle ';
					break;
			}
		}

		return <>
			<Link className={ 'com-calendar-event---container ' + ___element___class } to={ ___element___link_to_url }>
				<div>
					<h3>{ this.funcs___datetime.datetime___get___time(this.props.element___props['element_datetime']) }</h3>
				</div>
				<div>
					{(() => {
						switch (this.props.element___props['element_type']) {
							case 'activity': return <>
								<h4>Attività</h4>
								{(this.state.GUI___element___is_loading === false) ? <>
									<div>
										<p>
											{(() => {
												switch (this.state.elment_props___activity['activity_type'] as type___activity___type) {
													case 'call': return <><span>Chiamata con il cliente</span></>; break;
													case 'appointment': return <><span>Appuntamento con il cliente</span></>; break;
													case 'test_drive': return <><span>Test Drive Prenotato</span></>; break;
													case 'lead_status_change': return <>
														<span>
															<>Aggiornamento Lead</>
															{(() => {
																switch (this.state.elment_props___activity['activity_notes'] as type___lead___status) {
																	case 'working': return <> - <span>In lavorazione</span></>; break;
																	case 'ready': return <> - <span>In Consegna</span></>; break;
																	case 'sold': return <> - <span>Trattativa Conclusa (Venduto)</span></>; break;
																	case 'archived': return <> - <span>Archiviato</span></>; break;
																}
															})()}
														</span>
													</>; break;
													case 'sale': return <><span><>Vendita Effettuata!</></span></>; break;
												}
											})()}
										</p>
									</div>
								</> : <>
									<ComSpinnerComponent has_margin={ false }/>
								</>}
							</>; break;
							case 'lead': return <>
								<h4>Nuovo Lead</h4>
								{(this.state.GUI___element___is_loading === false) ? <>
									<p>
										{(this.state.elment_props___lead['lead_partner']['partner_type'] === 'p') ? <>
											<b>{ this.state.elment_props___lead['lead_partner']['partner_name'] + ' ' + this.state.elment_props___lead['lead_partner']['partner_surname'] }</b>
										</> : <>
											<b>{ this.state.elment_props___lead['lead_partner']['partner_name_business'] }</b>
										</>}
										<span> è interessato a </span>
										<b>
											{(() => {
												switch (this.state.elment_props___lead['lead_partner_interest'] as type___lead___interest___to) {
													case 'brands': return <>
														{(this.state.elment_props___lead['lead_vehicles_brands'].length > 0) ? <>
															{ this.state.elment_props___lead['lead_vehicles_brands'].map((___b: any, ___b_i: number) => {
																const ___b___comma: string = (___b_i < (this.state.elment_props___lead['lead_vehicles_brands'].length - 1)) ? ', ' : ' ';
																const ___b___conjuntion: string = (___b_i === (this.state.elment_props___lead['lead_vehicles_brands'].length - 2)) ? ' e ' : ___b___comma;
																return <><span key={ ___b_i }>{ ___b['vehicle_brand_name'] }</span>{ ___b___conjuntion }</>;
															})}
														</> : <></> }
													</>; break;
													case 'models': return <>
														{(this.state.elment_props___lead['lead_vehicles_models'].length > 0) ? <>
															{this.state.elment_props___lead['lead_vehicles_models'].map((___m: any, ___m_i: number) => {
																const ___m___comma: string = (___m_i < (this.state.elment_props___lead['lead_vehicles_models'].length - 1)) ? ', ' : ' ';
																const ___m___conjuntion: string = (___m_i === (this.state.elment_props___lead['lead_vehicles_models'].length - 2)) ? ' e ' : ___m___comma;
																return <><span key={ ___m_i }>{ ___m['car_brand'] + ' ' + ___m['car_model'] }</span>{ ___m___conjuntion }</>;
															})}
														</> : <></> }
													</>; break;
													case 'stock_vehicle': return <>
														{(this.state.elment_props___lead['lead_vehicle'] !== null) ? <>
															{(this.state.elment_props___lead['lead_vehicle'] !== null && this.state.elment_props___vehicle) ? <>
																{ this.state.elment_props___vehicle['vehicle_name'] }
															</> : <>
																{ this.state.elment_props___lead['lead_vehicle_name'] }
															</>}
														</> : <>...</>}
													</>; break;
													case 'custom': return <>
														{(this.state.elment_props___lead['lead_vehicle_requested']) ? <>
																<span>{ this.state.elment_props___lead['lead_vehicle_requested'] }</span>
														</> : <></> }
													</>; break;
												}
											})()}
										</b>
										{(this.state.elment_props___lead['lead_marketing_source'] !== 'other' && this.state.elment_props___lead['lead_marketing_source'] !== '') ? <>
											<span> ed ha contatto il nostro concessionario tramite </span>
											<b><span>{ this.lib___properties.get___property('lead_marketing_source', this.state.elment_props___lead['lead_marketing_source'])}</span></b>
										</> : <></>}
									</p>
								</> : <>
									<ComSpinnerComponent has_margin={ false }/>
								</>}
							</>; break;
							case 'sale': return <>
								{(this.state.GUI___element___is_loading === false) ? <>
									<h4>Vendita effettuata</h4>
									<div>
										{(this.state.elment_props___vehicle['vehicle_gallery_pictures'].length > 0) ? <>
											<img src={ this.state.elment_props___vehicle['vehicle_gallery_pictures'][0] }/>
										</> : <>
											<img src={ lib_names.media.placeholders.vehicle.generic }/>
										</>}
										<p>
											<span>E' stato venduto il veicolo </span>
											<b>{ this.state.elment_props___vehicle['vehicle_name'] }</b>
											<span> al prezzo di </span>
											<b>{ this.funcs___numbers.format___price(this.state.elment_props___vehicle['vehicle_accounting___price_sell'], ' €') }</b>
										</p>
									</div>
								</> : <>
									<ComSpinnerComponent has_margin={ false }/>
								</>}
							</>; break;
							case 'vehicle':
							case 'vehicle_car':
							case 'vehicle_motorcycle': return <>
								{(() => {
									switch (this.props.element___props['element_type']) {
										case 'vehicle_car': return <h4>Inserito Annuncio Auto</h4>; break;
										case 'vehicle_motorcycle': return <h4>Inserito Annuncio Moto</h4>; break;
										default: return <h4>Inserito Annuncio</h4>; break;
									}
								})()}
								{(this.state.GUI___element___is_loading === false) ? <>
									<div>
										{(this.state.elment_props___vehicle['vehicle_gallery_pictures'].length > 0) ? <>
											<img src={ lib_names.cdn.endpoint + this.state.elment_props___vehicle['vehicle_gallery_pictures'][0] }/>
										</> : <>
											<img src={ lib_names.media.placeholders.vehicle.generic }/>
										</>}
										<p>
											<span>E' stato inserito l'annuncio per </span>
											<b>{ this.state.elment_props___vehicle['vehicle_name'] }</b>
											<span> al prezzo di </span>
											<b>{ this.funcs___numbers.format___price(this.state.elment_props___vehicle['vehicle_accounting___price_sell'], ' €') }</b>
											<span> iva { this.state.elment_props___vehicle['vehicle_accounting___price_sell_vat'] }% inc.</span>
										</p>
									</div>
								</> : <>
									<ComSpinnerComponent has_margin={ false }/>
								</>}
							</>; break;
						}
					})()}
				</div>
				<div>
					<i className="fas fa-chevron-right"></i>
				</div>
			</Link>
		</>;
	}

//#endregion

}