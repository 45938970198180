import React from 'react';

//	S T Y L E

import './al-alert-controller.scss';

//	L I B S

import { funcs_text_extendend } from '../funcs/funcs.text.extended';

//	I N T E R F A C E S

import { props___AlAlertController, state___AlAlertController, state___AlAlertController___default } from './al-alert-controller.state';

//	E X P O R T

export default class AlertController extends React.Component<props___AlAlertController, state___AlAlertController>
{

//#region 																							D E C L A R A T I O N S

	private readonly funcs___text_extendend: funcs_text_extendend = new funcs_text_extendend();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___AlAlertController,
	)
	{
		super(props);
		this.state = state___AlAlertController___default;
	}

//#endregion

//#region 																							W R A P P E R S

	componentDidMount(): void
	{
		const ___wrapped___alert_message: React.ReactNode = this.funcs___text_extendend.text_extendend___wrap___as___ReactNode(this.props.alert___message);
		this.setState({
			wrapped___alert_message: ___wrapped___alert_message
		});
	}

//#endregion

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return (<>
			<div className="al-alert-controller---opacizer" onClick={ this.props.event___onDidDismiss }></div>
			<div className="al-alert-controller---container">
				<div className="al-alert-controller---title">
					<h6>
						<i className="fas fa-exclamation-triangle"></i>
						<span>{ this.props.alert___title }</span>
					</h6>
				</div>
				<div className="al-alert-controller---message">
					{ this.state.wrapped___alert_message }
				</div>
				<br />
				<div className="page-container---bottom-bar---buttons">
					{(this.props.alert___buttons.length > 0) ? <>
						{ this.props.alert___buttons.map((___a_b: any, ___a___idx: number) => <>
							<button key={ ___a___idx } className="no-shadow" onClick={ ___a_b['alert___button___action'] }>
								<i className={ 'fas ' + (___a_b['alert___button___icon'] ?? ' fa-circle')}></i>
								<span>{ ___a_b['alert___button___text'] }</span>
							</button>
						</>)}
					</> : <>
						<button className="no-shadow" onClick={ this.props.event___onDidDismiss }>
							<i className="fas fa-check"></i>
							<span>Ok</span>
						</button>
					</> }
				</div>
			</div>
		</>);
	}

//#endregion

}