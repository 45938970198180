//	P R O P S

export interface props___ComSlideShowComponent {
	images: string[],
}

//	S T A T E

export interface state___ComSlideShowComponent {

	image___index: number,











}

//	S T A T E   D E F A U L T

export const state___ComSlideShowComponent___default: state___ComSlideShowComponent = {


	image___index: 0,











}



