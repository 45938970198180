import React from "react";

//	S T Y L E

import "./sb-sync-vehicles.scss";

//	T Y P E S

import { type___api___response } from "../../../types/types.api-response";
import { type___toast___controller } from "../../../types/types.toasts";
import { type___vehicle___visibility } from "../../../types/types.types";

//	S E R V I C E S

import { service_RestApiService } from "../../../services/service-api";

//	S T A T E S

import { props___SbSyncVehiclesPage, state___SbSyncVehiclesPage, state___SbSyncVehiclesPage___defaults } from "./sb-sync-vehicles.state";

//	C O M P O N E N T S

import ComButtonCopierComponent from "../../../components/com-button-copier/com-button-copier";
import ComResultsEmptyComponent from "../../../components/com-results-empty/com-results-empty";
import ComSpinnerComponent from "../../../components/com-spinner/com-spinner";

//	C L A S S

export default class SbSyncVehiclesPage extends React.Component<props___SbSyncVehiclesPage, state___SbSyncVehiclesPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbSyncVehiclesPage,
	)
	{
		super(_PROPS);
		this.state = state___SbSyncVehiclesPage___defaults;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___dealer = async () => {
		const ___dealer___props: type___api___response = await this._API.dealers___read___single();
		const ___dealer___props___importers: any = await this._API.dealers___read___importer_credentials___vehicles();
		this.setState({
			dealer___props: ___dealer___props.data,
			dealer___props___importers: ___dealer___props___importers,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___select___tab = async (___e: state___SbSyncVehiclesPage['selected___import___tab']) => {
		this.setState({
			selected___import___tab: ___e,
		});
	}

	handle___dealer_settings___select_vehicle_default_visibility = async (___e: any) => {
		const ___udpated_vehicle___new_state: type___vehicle___visibility = ___e.target.value as type___vehicle___visibility;
		const ___udpated_vehicle: type___api___response = await this._API.dealers___change_settings([{ key: 'dealer_setting___vehicle_sync___default_visible', value: ___udpated_vehicle___new_state }]);
		if (___udpated_vehicle['response'] === 'success')
		{
			let ___udpated_vehicle___new_state____text: string = '';
			switch (___udpated_vehicle___new_state) {
				case 'visible': ___udpated_vehicle___new_state____text = 'Visibile pubblicamente'; break;
				case 'hidden': ___udpated_vehicle___new_state____text = 'Nascosto'; break;
			}
			const ___toast_props: type___toast___controller = {
				toast___color: 'success',
				toast___text: <>Hai modificato il globalmente la visibilità di default dei veicoli importati con "<b>{ ___udpated_vehicle___new_state____text }</b>"</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
			await this.read_props___dealer();
		}
		else
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'danger',
				toast___text: <>Errore generico nella modifica della visibilità di default dei veicoli importati</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		await this.read_props___dealer();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="page---container is-full-width">
				<section>
					<div className="impoter---container---flexy">

						<div>
							<div className="page---top-bar page---top-bar---is-sidebar page---top-bar---is-sidebar---vehicles-import">
								<section>
									<input type="radio" id="d-ts---csec---subito_it" name="d-ts---cb" value="subito_it" checked={ this.state.selected___import___tab === 'multigestionale_motori' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
									<label htmlFor="d-ts---csec---subito_it" className="is-card">
										<img src="/assets/third/third.multigestionale-motori.png" />
									</label>
								</section>
							</div>
						</div>
						{(() => {
							switch (this.state.selected___import___tab) {
								case 'multigestionale_motori': return <>
									<div className="is-card import-from---container">
										<div className="import-from---container---logo">
											<img src="/assets/third/third.multigestionale-motori.png" />
										</div>
										<h2 className="input---nb-title">
											Importazione automatica da Multi Gestionale Motori
										</h2>
										<span className="input---nb-label" style={{lineHeight:'14pt'}}>
											Con lo script di importazione da <a target="_blank" href="https://motori.multigestionale.com/" style={{textDecoration:'underine'}}>MultiGestionale Motori </a> 
											importerai tutti i veicoli attualmente pubblicati nel tuo account collegato al tuo profilo CarsHub tramite chiavi api escludendo quelli già importati 
											e senza sovrascrivere quelli attualmente presenti
										</span>
										<span className="input---nb-label" style={{lineHeight:'14pt'}}>
											A causa di alcune limitazioni della piattaforma API di MultiGestionale Motori non è possibile importare alcune specifiche del veicolo, che dovranno 
											essere poi impostate manualmente
										</span>
										<br />
										<br />
										{(this.state.dealer___props !== null && this.state.dealer___props___importers !== null) ? <>
											<div className="import-from---container---credentials">
												<div>
													<section className="input---nb-outlined---flexy">
														<p>User</p>
														<input type="text" value={ this.state.dealer___props___importers['dealer_sync___multigestionale_motori___user'] } disabled={ true }/>
														<ComButtonCopierComponent to_copy={ this.state.dealer___props___importers['dealer_sync___multigestionale_motori___user'] as string } toast___setContent={ this.props.toast___setContent } toast___setShowing={ this.props.toast___setShowing }/>
													</section>
													<span></span>
													<section className="input---nb-outlined---flexy">
														<p>Token</p>
														<input type="text" value={ this.state.dealer___props___importers['dealer_sync___multigestionale_motori___token'] } disabled={ true }/>
														<ComButtonCopierComponent to_copy={ this.state.dealer___props___importers['dealer_sync___multigestionale_motori___token'] as string } toast___setContent={ this.props.toast___setContent } toast___setShowing={ this.props.toast___setShowing }/>
													</section>
													<span></span>
													<section className="input---nb-outlined---flexy">
														<p>Importati</p>
														<select value={ this.state.dealer___props['dealer_setting___vehicle_sync___default_visible'] as type___vehicle___visibility } onChange={(___e: any) => { this.handle___dealer_settings___select_vehicle_default_visibility(___e); }}>
															<option value={ 'visible' }>Visibile pubblicamente</option>
															<option value={ 'hidden' }>Nascosto</option>
														</select>
													</section>
												</div>
											</div>
										</> : <>
											<ComSpinnerComponent></ComSpinnerComponent>
										</>}
									</div>
								</>; break;
								case '':
								default: return <>
									<div className="is-card import-from---welcome---container">
										<ComResultsEmptyComponent text="Decidi cosa importare"/>
									</div>
								</>; break;
							}
						})()}
					</div>
				</section>
			</div>
		</>;
	}

//#endregion

}




