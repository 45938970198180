import React from 'react';
import { NavLink, Link } from 'react-router-dom';

//	S T Y L E

import './com-nav.scss';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	S T A T E S

import { props___ComNavComponent, state___ComNavComponent, state___ComNavComponent___default } from './com-nav.state';

//	E X P O R T

export default class ComNavComponent extends React.Component<props___ComNavComponent, state___ComNavComponent>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComNavComponent,
	)
	{
		super(props);
		this.state = state___ComNavComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	handler___navNavLink___click = () => {
		document.getElementById('nav---sidemenu---toggler')?.click();
	}

//#endregion

//#region 																							R E N D E R

	render(): React.ReactNode
	{

		return <>
			<input type="checkbox" id="nav---sidemenu---toggler" className="nav---sidemenu---toggler"/>
			<div className="nav---topbar">
				<Link to="dashboard" className="nav---topbar---logo">
					{(this.props.me___props___dealer['dealer_logo_horizontal']) ? <>
						<img src={ lib_names.cdn.endpoint + this.props.me___props___dealer['dealer_logo_horizontal'] }/>
					</> : <>
						<img src="/assets/logo.horizontal.svg"/>
					</>}
				</Link>
				<section>
					<NavLink to="/dashboard" className={({isActive}) => ((isActive) ? ' nav-top-button---active ' : ' ')}>
						<span>Scrivania</span>
						{(this.props.notification___leads && this.props.notification___leads > 0) ? <>
							<div>{ this.props.notification___leads }</div>
						</> : <>
							<i className="fas fa-th-large"></i>
						</>}
					</NavLink>
					<span>•</span>
					<NavLink to="/calendar" className={({isActive}) => ((isActive) ? ' nav-top-button---active ' : ' ')}>
						<span>Calendario</span>
						<i className="far fa-calendar-alt"></i>
					</NavLink>
					<span>•</span>
					<NavLink to="/vehicles/list" className={({isActive}) => ((isActive) ? ' nav-top-button---active ' : ' ')}>
						<span>Veicoli</span>
						<i className="fas fa-car"></i>
					</NavLink>
					<span>•</span>
					<NavLink to="/partners/list" className={({isActive}) => ((isActive) ? ' nav-top-button---active ' : ' ')}>
						<span>Clienti</span>
						<i className="fas fa-users"></i>
					</NavLink>
					<span>•</span>
					<label htmlFor="nav---sidemenu---toggler" className="nav---sidemenu---hamburger">
						<section>
							<span>menu</span>
							<span>chiudi</span>
							<span>
								<span></span>
								<span></span>
								<span></span>
							</span>
						</section>
					</label>
				</section>
			</div>
			<label htmlFor="nav---sidemenu---toggler" className="nav---sidemenu---opacizer">[close]</label>
			<label htmlFor="nav---sidemenu---toggler" className="nav---sidemenu---opacizer---white">[close]</label>
			<div className="nav---sidemenu">
				
				<div>
					<NavLink to="/dashboard" onClick={ this.handler___navNavLink___click }>
						<img src="/assets/ui/icons/icon-lead-new.svg"/>
						<span>Dashboard</span>
						<i className="fas fa-chevron-right"></i>
					</NavLink>
					<section>
						<NavLink to="/dashboard" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
							<span></span>
							<span>Home</span>
							<i className="fas fa-home"></i>
						</NavLink>
						<NavLink to="/calendar" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
							<span></span>
							<span>Calendario</span>
							<i className="fas fa-calendar-alt"></i>
						</NavLink>
						{(this.props.dealer___licence['licence_active___analytics'] === true && (this.props.me___props['user_role'] === 'admin' || this.props.me___props['user_role'] === 'support')) ? <>
							<NavLink to="/analytics" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
								<span></span>
								<span>Statistiche</span>
								<i className="fas fa-chart-pie"></i>
							</NavLink>
						</> : <></>}
					</section>
				</div>
	
				<div>
					<NavLink to="/vehicles/list" onClick={ this.handler___navNavLink___click }>
						<img src="/assets/ui/icons/icon-car.svg"/>
						<span>Veicoli</span>
						<i className="fas fa-chevron-right"></i>
					</NavLink>
					<section>
						<NavLink to="/vehicles/create" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
							<span></span>
							<span>Aggiungi Nuovo</span>
							<i className="fas fa-plus"></i>
						</NavLink>
						<NavLink to="/vehicles/list" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
							<span></span>
							<span>Lista Veicoli</span>
							<i className="fas fa-list"></i>
						</NavLink>
					</section>
				</div>
	
				<div>
					<NavLink to="/partners/list" onClick={ this.handler___navNavLink___click }>
						<img src="/assets/ui/icons/icon-partners.svg"/>
						<span>Clienti e Fornitori</span>
						<i className="fas fa-chevron-right"></i>
					</NavLink>
					<section>
						<NavLink to="/partners/create" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
							<span></span>
							<span>Aggiungi Nuovo</span>
							<i className="fas fa-user-plus"></i>
						</NavLink>
						<NavLink to="/partners/list" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
							<span></span>
							<span>Management Clienti e Fornitori</span>
							<i className="fas fa-users"></i>
						</NavLink>
						<NavLink to="/leads/list" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
							<span></span>
							<span>Management Leads</span>
							<i className="fas fa-comments"></i>
						</NavLink>
					</section>
				</div>

				<div>
					<NavLink to="/settings/profile" onClick={ this.handler___navNavLink___click }>
						<img src="/assets/ui/icons/icon-users.svg"/>
						<span>Utenti e Profilo</span>
						<i className="fas fa-chevron-right"></i>
					</NavLink>
					<section>
						<NavLink to="/settings/profile" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
							<span></span>
							<span>Profilo Personale</span>
							<i className="fas fa-user-circle"></i>
						</NavLink>
						<NavLink to="/settings/dealer" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
							<span></span>
							<span>Profilo Concessionario</span>
							<i className="fas fa-store"></i>
						</NavLink>
						{(this.props.dealer___licence['licence_active___multiusers'] === true && (this.props.me___props['user_role'] === 'admin' || this.props.me___props['user_role'] === 'support')) ? <>
							<NavLink to="/settings/users" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
								<span></span>
								<span>Gestione Utenti</span>
								<i className="fas fa-users-cog"></i>
							</NavLink>
						</> : <></>}
						<div className="nav-element---is-logout" onClick={() => { this.handler___navNavLink___click(); this.props.event_performLogOut(); }}>
							<span></span>
							<span>Disconnetti il tuo Account</span>
							<i className="fas fa-power-off"></i>
						</div>
					</section>
				</div>

				{((this.props.me___props['user_role'] === 'admin' || this.props.me___props['user_role'] === 'support') && (this.props.dealer___licence['licence_active___sync___leads'] === true || this.props.dealer___licence['licence_active___sync___vehicles'] === true || this.props.dealer___licence['licence_active___sync___catalog'])) ? <>
					<div>
						<NavLink to="" onClick={ this.handler___navNavLink___click }>
							<img src="/assets/ui/icons/icon-sync.svg"/>
							<span>Sync</span>
							<i className="fas fa-chevron-right"></i>
						</NavLink>
						<section>
							{(this.props.dealer___licence['licence_active___sync___vehicles'] === true) ? <>
								<NavLink to="/sync/vehicles" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
									<span></span>
									<span>Veicoli - API e credenziali Piattaforme</span>
									<i className="fas fa-sync-alt"></i>
								</NavLink>
							</> : <></>}
							{(this.props.dealer___licence['licence_active___sync___catalog'] === true) ? <>
								<NavLink to="/sync/catalog" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
									<span></span>
									<span>Veicoli - Cataloghi Connessi</span>
									<i className="fas fa-sync-alt"></i>
								</NavLink>
							</> : <></>}
							{(this.props.dealer___licence['licence_active___plugin___CarsHubConnector'] === true) ? <>
								<NavLink to="/sync/connector" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
									<span></span>
									<span>CarsHub™ - Connector Plugin</span>
									<i className="fas fa-sync-alt"></i>
								</NavLink>
							</> : <></>}
						</section>
					</div>
				</> : <></>}

				<div>
					<NavLink to="" onClick={ this.handler___navNavLink___click }>
						<img src="/assets/ui/icons/icon-settings.svg"/>
						<span>Impostazioni</span>
						<i className="fas fa-chevron-right"></i>
					</NavLink>
					<section>
						<NavLink to="/settings/general" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
							<span></span>
							<span>Impostazioni Globali</span>
							<i className="fas fa-cog"></i>
						</NavLink>
						{(this.props.dealer___licence['licence_active___sync___leads'] === true) ? <>
							<NavLink to="/settings/imaps" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
								<span></span>
								<span>Impostazioni Account IMAPs</span>
								<i className="fas fa-sync-alt"></i>
							</NavLink>
						</> : <></>}
						{(this.props.dealer___licence['licence_active___contracts'] === true && (this.props.me___props['user_role'] === 'admin' || this.props.me___props['user_role'] === 'support')) ? <>
							<NavLink to="/settings/contracts" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
								<span></span>
								<span>Editor Contratti</span>
								<i className="fas fa-file-signature"></i>
							</NavLink>
						</> : <></>}









						




						



						{/*
							<NavLink to="/standalone/download" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
								<span></span>
								<span>Scarica App</span>
								<i className="fas fa-download"></i>
							</NavLink>
						*/}
					</section>
				</div>

				<div>
					<NavLink to="" onClick={ this.handler___navNavLink___click }>
						<img src="/assets/ui/icons/icon-support.svg"/>
						<span>Info e Assistenza</span>
						<i className="fas fa-chevron-right"></i>
					</NavLink>
					<section>
						<NavLink to="/info/licence" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
							<span></span>
							<span>Licenza e Abbonamento</span>
							<i className="fas fa-file-invoice"></i>
						</NavLink>
						<NavLink to="/info/eula" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
							<span></span>
							<span>Termini e Condizioni</span>
							<i className="fas fa-balance-scale"></i>
						</NavLink>
						<NavLink to="/info/support" className={({isActive}) => ((isActive) ? ' nav-sidemenu-button---active ' : ' ')} onClick={ this.handler___navNavLink___click }>
							<span></span>
							<span>Assistenza e Supporto</span>
							<i className="fas fa-headset"></i>
						</NavLink>
					</section>
				</div>

			</div>
		</>;

	}

//#endregion

}