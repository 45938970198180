//	L I B S

import { type___api___response } from '../types/types.api-response';

//	T Y P E S

import { service_RestApiService } from './service-api';

//	E X P O R T

export class service_LeadsSync
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							S Y N C

	syncService = async () : Promise<number> => {
		
		const ___leads_imported: type___api___response = await this._API.sync_leads___sync();
		
		if (___leads_imported.response)
		{
			const ___leads___count: type___api___response = await this._API.leads___count();
			return (___leads___count.response === 'success') ? ___leads___count.data['leads_count_new'] ?? 0 : 0;
		}

		return 0;

	}

//#endregion

}