import { props___page___hasAlert } from "../../../interfaces/interface.alerts"
import { props___page___hasLicence } from "../../../interfaces/interface.licence"
import { props___page___hasToast } from "../../../interfaces/interface.toasts"

//	P R O P S

export interface props___SbPartnersViewPage extends props___page___hasAlert, props___page___hasToast, props___page___hasLicence
{
	params: any
}

//	S T A T E

export interface state___SbPartnersViewPage {

	selected___partner_view___tab: 'registry' | 'leads' | 'activities' | 'sales' | 'documents',

	partner___id: string,
	partner___props: any | null,

	partner___activities___list: any[],
	partner___leads___list: any[],
	partner___sales___list: any[],

	partner___documents: any[],
	partner___documents___is_uploading: boolean,

	GUI___page___is_loading: boolean,
	GUI___page___is_loading___section: boolean, 

}

//	S T A T E   D E F A U L T S

export const state___SbPartnersViewPage___defaults: state___SbPartnersViewPage = {

	selected___partner_view___tab: 'registry',

	partner___id: '',
	partner___props: null,

	partner___activities___list: [],
	partner___leads___list: [],
	partner___sales___list: [],

	partner___documents: [],
	partner___documents___is_uploading: false,

	GUI___page___is_loading: true,
	GUI___page___is_loading___section: true,

}