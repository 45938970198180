import { type___toast___controller } from "../types/types.toasts";

//	I N T E R F A C E

export interface props___TsToastController extends type___toast___controller {

	event_onDismiss: any,

}

//	S T A T E

export interface state___TsToastController {

	toast___content: React.ReactNode

	GUI___toast___isDismissing: boolean,

}

//	S T A T E   D E F A U L T

export const state___TsToastController___default : state___TsToastController = {

	toast___content: <></>,

	GUI___toast___isDismissing: false

}