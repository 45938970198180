import React from 'react';

//	S T Y L E

import './sb-info-eula.scss'

//	C O M P O N E N T S

import ComMarkdownComponent from '../../../components/com-markdown/com-markdown';

//	E X P O R T

export default class SbInfoEulaPage extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return <>
			<div className="page---container is-card eula---container is-card">
				<section>
					<img src="/assets/logo.squared.svg"/>
					<ComMarkdownComponent file_path="/assets/docs/eula.it-it.md" />
				</section>
			</div>
		</>;
	}

//#endregion

}