import { props___page___hasAlert } from "../../interfaces/interface.alerts"
import { props___page___hasToast } from "../../interfaces/interface.toasts"
import { type___lead___status } from "../../types/types.types"

//	P R O P S

export interface props___SbCalendarPage extends props___page___hasAlert, props___page___hasToast {

}

//	S T A T E

export interface state___SbCalendarPage {

	selected___day : any | null,
	
	date___today : Date,
	date___days : any[],

	list___events_for___month: any[],
	list___events_for___day: any[],

	list___view_element___activity: boolean,
	list___view_element___lead: boolean,
	list___view_element___sale: boolean,
	list___view_element___vehicle: boolean,

	GUI___section___is_loading___month: boolean,
	GUI___section___is_loading___day: boolean,

	GUI___page___is_loading: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbCalendarPage___defaults : state___SbCalendarPage = {

	selected___day : null,

	date___today : new Date,
	date___days : [],

	list___events_for___month: [],
	list___events_for___day: [],

	list___view_element___activity: true,
	list___view_element___lead: true,
	list___view_element___sale: true,
	list___view_element___vehicle: true,

	GUI___section___is_loading___month: false,
	GUI___section___is_loading___day: false,

	GUI___page___is_loading: true,


}