import { props___page___hasAlert } from "../../../interfaces/interface.alerts";
import { props___page___hasToast } from "../../../interfaces/interface.toasts";
import { lib_defaults } from "../../../libs/lib.defaults";
import { type___request___order } from "../../../types/types.types";

//	P R O P S

export interface props___SbPartnersListPage extends props___page___hasAlert, props___page___hasToast {

}

//	S T A T E

export interface state___SbPartnersListPage {

	search___param___partner: string,
	search___order: type___request___order,
	
	partners___list: any[],
	partners___list___is_loading: boolean,

	GUI___page___is_loading: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbPartnersListPage___default: state___SbPartnersListPage = {

	search___param___partner: '',
	search___order: lib_defaults.types.type___request___order,

	partners___list: [],
	partners___list___is_loading: true,

	GUI___page___is_loading: true,

}