import React from 'react';

//	S T Y L E

import './ts-toast-controller.scss';

//	F U N C S

import { funcs_text_extendend } from '../funcs/funcs.text.extended';

//	T Y P E S

import { props___TsToastController, state___TsToastController, state___TsToastController___default } from './ts-toast-controller.state';

//	C L A S S

export default class TsToastController extends React.Component<props___TsToastController, state___TsToastController>
{

//#region 																							D E C L A R A T I O N S

	private readonly funcs___text_extendend: funcs_text_extendend = new funcs_text_extendend();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___TsToastController
	) {
		super(_PROPS);
		this.state = state___TsToastController___default;
	}

//#endregion

//#region 																							L I F E C Y C L E

	initializeToastController = () =>
	{
		const ___toast___content: React.ReactNode = this.funcs___text_extendend.text_extendend___wrap___as___ReactNode(this._PROPS.toast___text);
		const ___toast___duration: number = (this._PROPS.toast___duration) ? this._PROPS.toast___duration : 3000;
		this.setState({
			toast___content: ___toast___content,
		}, () => {
			setTimeout(() => {
				if (this.state.GUI___toast___isDismissing === false) { this.dismissToastController(); }
			}, ___toast___duration);
		});
	}

	dismissToastController = () =>
	{
		this.setState({
			GUI___toast___isDismissing: true
		}, () => {
			setTimeout(() => {
				this._PROPS.event_onDismiss(false);
			}, 500);
		});
	}

	componentDidMount(): void {
		this.initializeToastController();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>
			<div className={ 'ch-toast---container is-card ' + ('is-color-' + this._PROPS.toast___color as string) + ((this.state.GUI___toast___isDismissing === true) ? ' is-dismissing' : ' ' ) }>
				<section>
					<p className="toast---inner">{ this.state.toast___content }</p>
				</section>
				<section>
					<i className="fas fa-chevron-left" onClick={ this.dismissToastController }></i>
				</section>
			</div>
		</>	;

	}

//#endregion

}