import { props___page___hasAlert } from "../../interfaces/interface.alerts";
import { props___page___hasToast } from "../../interfaces/interface.toasts";
import { type___lead___status } from "../../types/types.types";

//	P R O P S

export interface props___ComLeadActivityComponent {
	
	activity___props: any,
	
	



}

//	S T A T E

export interface state___ComLeadActivityComponent {
	

	



}

//	S T A T E   D E F A U L T S


export const state___ComLeadActivityComponent___default: state___ComLeadActivityComponent = {
	
	




}