import React from "react";
import { Link } from "react-router-dom";

//	S T Y L E

import "./sb-partners-edit.scss";

//	S T A T E S

import { props___SbPartnersEditPage, state___SbPartnersEditPage, state___SbPartnersEditPage___defaults } from "./sb-partners-edit.state";

//	T Y P E S

import { type___alert___controller } from "../../../types/types.alerts";
import { type___api___response } from "../../../types/types.api-response";
import { type___partner___address, type___partner___address___born, type___partner___einvoice___type, type___partner___type } from "../../../types/types.types";

//	S E R V I C E S

import { service_RestApiService } from "../../../services/service-api";

//	C O M P O N E N T S

import ComSpinnerComponent from "../../../components/com-spinner/com-spinner";
import ComPartnerComponent from "../../../components/com-partner/com-partner";

//	C L A S S

export default class SbPartnersEditPage extends React.Component<props___SbPartnersEditPage, state___SbPartnersEditPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

	readonly _PARAMS: any;
	readonly _NAVIGATE: any

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbPartnersEditPage,
	) {
		super(_PROPS);
		this.state = state___SbPartnersEditPage___defaults;
		this._PARAMS = this.props.params;
		this._NAVIGATE = this.props.navigate;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___partner_single = async () => {
		this.setState({
			GUI___page___is_loading: true
		}, async () => {
			if (this.state.field___partner___id !== null)
			{
				const ___partner___found: type___api___response = await this._API.partners___read___single(this.state.field___partner___id);
				if (___partner___found.response === 'success')
				{
					this.setState({
						
						field___partner___type: ___partner___found.data['partner_type'],
						
						field___partner___is___customer: ___partner___found.data['partner_is_customer'] as boolean,
						field___partner___is___supplier: ___partner___found.data['partner_is_seller'] as boolean,
						
						field___partner___name___business: ___partner___found.data['partner_name_business'],
						field___partner___name: ___partner___found.data['partner_name'],
						field___partner___surname: ___partner___found.data['partner_surname'],
						
						field___partner___email: ___partner___found.data['partner_email'],
						field___partner___email_pec: ___partner___found.data['partner_email_pec'],
						
						field___partner___phone: ___partner___found.data['partner_phone'],
						field___partner___phone_mobile: ___partner___found.data['partner_phone_mobile'],
						field___partner___phone_fax: ___partner___found.data['partner_phone_fax'],

						field___partner___born_date: ___partner___found.data['partner_born_date'],
						field___partner___born_city: ___partner___found.data['partner_born_address']['city'],
						field___partner___born_country: ___partner___found.data['partner_born_address']['country'],

						field___partner___registry___cf: ___partner___found.data['partner_registry_cf'],
						field___partner___registry___vat: ___partner___found.data['partner_registry_piva'],
						
						field___partner___e_invoice___type: ___partner___found.data['partner_einvoice_type'],
						field___partner___e_invoice___sdi: ___partner___found.data['partner_einvoice_sdi'],
						
						field___partner___address___street: (___partner___found.data['partner_address'] as type___partner___address)['street'],
						field___partner___address___street___n: (___partner___found.data['partner_address'] as type___partner___address)['street_n'],
						field___partner___address___city: (___partner___found.data['partner_address'] as type___partner___address)['city'],
						field___partner___address___zip: (___partner___found.data['partner_address'] as type___partner___address)['zip'],
						field___partner___address___prov: (___partner___found.data['partner_address'] as type___partner___address)['prov'],
						field___partner___address___country: (___partner___found.data['partner_address'] as type___partner___address)['country'],
						
						field___partner___address_business___street: (___partner___found.data['partner_address_business']) ? (___partner___found.data['partner_address_business'] as type___partner___address)['street'] : '',
						field___partner___address_business___street___n: (___partner___found.data['partner_address_business']) ? (___partner___found.data['partner_address_business'] as type___partner___address)['street_n'] : '',
						field___partner___address_business___city: (___partner___found.data['partner_address_business']) ? (___partner___found.data['partner_address_business'] as type___partner___address)['city'] : '',
						field___partner___address_business___zip: (___partner___found.data['partner_address_business']) ? (___partner___found.data['partner_address_business'] as type___partner___address)['zip'] : '',
						field___partner___address_business___prov: (___partner___found.data['partner_address_business']) ? (___partner___found.data['partner_address_business'] as type___partner___address)['prov'] : '',
						field___partner___address_business___country: (___partner___found.data['partner_address_business']) ? (___partner___found.data['partner_address_business'] as type___partner___address)['country'] : '',

						field___partner___additional___notes: ___partner___found.data['partner_notes'],
						field___partner___additional___website: ___partner___found.data['partner_website'],

					});
				}
				else 
				{
					const ___alert_props : type___alert___controller = {
						alert___title: "Errore",
						alert___message : <>Impossibile caricare questo cliente/fornitore...</>,
						alert___buttons : [
							{
								alert___button___icon: 'fa-chevron-left',
								alert___button___text: 'Torna Indietro',
								alert___button___action : () => {
									this._PROPS.alert___setShowing(false);
									if (window.history.length > 1) { window.history.back(); }
									else { window.location.href = '/partners/list'; }
								}
							}
						]
					}
					this._PROPS.alert___setAlert(___alert_props);
					this._PROPS.alert___setShowing(true);
				}
			}
		});
	}

	read_props___partners_suggested = async (___search_target: 'name_business' | 'email' | 'phone' | 'piva' | 'cf', ___search_param: string) => {
		if (this.state.field___partner___id === null)
		{
			if (___search_param.length > 0)
			{
				const ___partner_suggestions___target: string = 'search:' + ___search_target as string;
				const ___partner_suggestions___results: type___api___response = await this._API.partners___read___multi(___partner_suggestions___target, ___search_param, 0, { param: 'partner_name', direction: 1 });
				this.setState({
					suggested_partners___list: ___partner_suggestions___results.data
				});
			}
			else
			{
				this.setState({
					suggested_partners___list: []
				});
			}
		}	
	}

//#endregion

//#region 																							A L E R T S

	alert___invalid__fields = () => {
		const ___alert_props: type___alert___controller = {
			alert___title: "Attenzione",
			alert___message: <>C'è qualcosa che non va, per favore ricontrolla i campi</>,
			alert___buttons: [
				{
					alert___button___text: 'Ok',
					alert___button___action: () => {
						this._PROPS.alert___setShowing(false);
					}
				},
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

//#endregion

//#region 																							S U B M I T

	submit___partner___check_if_can(): boolean
	{
		//perchè checkato a ogni onInput? 

	//	if (this.state.field___partner___name.length > 2 &&
	//		this.state.field___partner___surname.length > 2 &&
	//		lib_names.regex.sign_email.test(this.state.field___partner___email) &&
	//		lib_names.regex.phone_number.test(this.state.field___partner___phone)) {
	//		return true;
	//	}
	//	else
	//		return false;
		
		return true;


	}

	async submit___partner()
	{

		const ___partner___type: type___partner___type | '' = (this.state.field___partner___type !== '') ? this.state.field___partner___type : 'p';
		const ___partner___born_address: type___partner___address___born = { city: this.state.field___partner___born_city, prov: this.state.field___partner___born_country, country: this.state.field___partner___born_country };
		const ___partner___contacts___emails: [string, string] = [this.state.field___partner___email, this.state.field___partner___email_pec];
		const ___partner___contacts___phones: [string, string, string] = [this.state.field___partner___phone, this.state.field___partner___phone_mobile, this.state.field___partner___phone_fax];
		const ___partner___address: type___partner___address = { street: this.state.field___partner___address___street, street_n: this.state.field___partner___address___street___n, city: this.state.field___partner___address___city, prov: this.state.field___partner___address___prov, zip: this.state.field___partner___address___zip, country: this.state.field___partner___address___country };
		const ___partner___address___business: type___partner___address = { street: this.state.field___partner___address_business___street, street_n: this.state.field___partner___address_business___street___n, city: this.state.field___partner___address_business___city, prov: this.state.field___partner___address_business___prov, zip: this.state.field___partner___address_business___zip, country: this.state.field___partner___address_business___country };

		const can___submit: boolean = this.submit___partner___check_if_can();

		if (can___submit)
		{
			const ___partner___to_submit: type___api___response = await this._API.partners___submit(this.state.field___partner___id, ___partner___type, this.state.field___partner___is___customer, this.state.field___partner___is___supplier, this.state.field___partner___name, this.state.field___partner___name___business, this.state.field___partner___surname, ___partner___contacts___emails, ___partner___contacts___phones, this.state.field___partner___born_date, ___partner___born_address, this.state.field___partner___registry___cf, this.state.field___partner___registry___vat, this.state.field___partner___e_invoice___type, this.state.field___partner___e_invoice___sdi, ___partner___address, ___partner___address___business, this.state.field___partner___additional___notes, this.state.field___partner___additional___website);
			
			console.log('___partner___to_submit',___partner___to_submit);
			
			this._NAVIGATE('/partners/list');





		}
		else
		{
			this.alert___invalid__fields()
		}

	}

//#endregion

//#region 																							H A N D L E R S

	handle___select___partner_suggested___load = () => {
		this.setState({
			field___partner___id: (!this._PARAMS['id'] || this._PARAMS['id'] === undefined) ? null : this._PARAMS['id'],
			GUI___page___is_loading: true
		}, async () => {
			console.log('ID', this.state.field___partner___id);
			await this.read_props___partner_single()
				.then(() => {
					this.setState({
						GUI___page___is_loading: false
					}, () => {
						window.location.href = window.location.href;
					});
				});
		});
	}

	handle___reset = () => {
		this.setState(state___SbPartnersEditPage___defaults);
	}

	handle___select___partner___type = (___e: any) => {
		this.setState({
			field___partner___type: ___e.target.value,
			field___partner___name___business: ''
		});
	}

	handle___select___partner___e_invoice___type = (___e: any) => {
		this.setState({
			field___partner___e_invoice___type: ___e.target.value,
			field___partner___name___business: ''
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount(): Promise<void>
	{
		this.setState({
			field___partner___id: (!this._PARAMS['id'] || this._PARAMS['id'] === undefined) ? null : this._PARAMS['id'],
			GUI___page___is_loading: true
		}, async () => {
			console.log('ID', this.state.field___partner___id);
			await this.read_props___partner_single()
				.then(() => {
					this.setState({ GUI___page___is_loading: false });
				});
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="page---container is-full-width is-flexy">
				<section>
					{(this.state.GUI___page___is_loading !== null) ? <>
						<div className="is-card" style={{overflow:'scroll'}}>
							<h3 className="is-title">Scheda Anagrafica</h3>
							<section className="is-content">

								<span className="input---nb-label">Tipologia Persona</span>
								<section className="input---nb-outlined---flexy">
									<select value={this.state.field___partner___type ?? ''} className={'input---nb-outlined'} onChange={this.handle___select___partner___type}>
										<option value={''} disabled={true}>Seleziona tipologia persona</option>
										<option value={'p'}>Privato (Persona Fisica)</option>
										<option value={'b'}>Azienda (Persona Giuridica)</option>
									</select>
								</section>
								<br />

								{(this.state.field___partner___type === 'b') ? <>
									<span className="input---nb-label">Ragione Sociale</span>
									<section className="input---nb-outlined---flexy">
										<input type="text" value={this.state.field___partner___name___business} className={'input---nb-outlined'} placeholder="nome azienda" onInput={(___e: any) => { this.setState({ field___partner___name___business: ___e.target.value }, () => { this.submit___partner___check_if_can() }); }} />
									</section>
								</> : <></>}

								<span className="input---nb-label is-required">
									<span>Nome</span>
									{(this.state.field___partner___type === 'b') ? ' Rapp. Legale' : <></>}	
								</span>
								<section className="input---nb-outlined---flexy">
									<input type="text" value={this.state.field___partner___name} className={'input---nb-outlined'} placeholder="nome" onInput={(___e: any) => { this.setState({ field___partner___name: ___e.target.value }); }} />
								</section>
								<span className="input---nb-label is-required">
									<span>Cognome</span>
									{(this.state.field___partner___type === 'b') ? ' Rapp. Legale' : <></>}	
								</span>
								<section className="input---nb-outlined---flexy">
									<input type="text" value={this.state.field___partner___surname} className={'input---nb-outlined'} placeholder="surname" onInput={(___e: any) => { this.setState({ field___partner___surname: ___e.target.value }); }} />
								</section>

								<br />

								<span className="input---nb-label is-required">Email</span>
								<section className="input---nb-outlined---flexy">
									<input type="email" value={this.state.field___partner___email} className={'input---nb-outlined'} placeholder="persona@email.com" onInput={(___e: any) => { this.setState({ field___partner___email: ___e.target.value }, async () => { await this.read_props___partners_suggested('email', this.state.field___partner___email); }); }} />
								</section>

								<span className="input---nb-label is-required">Telefono Cellulare</span>
								<section className="input---nb-outlined---flexy">
									<input type="tel" value={this.state.field___partner___phone_mobile} className={'input---nb-outlined'} placeholder="+39 333 333 3334" onInput={(___e: any) => { this.setState({ field___partner___phone_mobile: ___e.target.value }, async () => { this.submit___partner___check_if_can(); await this.read_props___partners_suggested('phone', this.state.field___partner___phone_mobile); }); }}/>
								</section>

							</section>

							<br />

							{(this.state.suggested_partners___list.length > 0) ? <>
								<h3 className="is-title has-icon">
									<span>Suggerimento</span>
									<i className="fas fa-info-circle"></i>
								</h3>
								<section className="is-content">
									<Link to={ '/partners/edit/' + this.state.suggested_partners___list[0]['partner_id'] } onClick={ this.handle___select___partner_suggested___load }>
										<ComPartnerComponent partner___props={ this.state.suggested_partners___list[0] }/>
									</Link>
								</section>
							</> : <></>}

						</div>
						<div>
							<div style={{display:'none'}}></div>
							<div className="is-card" style={{height:'calc(100% - 20pt)',overflow:'scroll'}}>
								<h3 className="is-title">Informazioni aggiuntive</h3>
								<section className="is-content">
									
									<section className="input---nb-outlined---flexy---cb">
										<p>Questa anagrafica è parte dell'elenco</p>
										<label htmlFor="cb---partner---partner">
											<input type="checkbox" id="cb---partner---partner" checked={ this.state.field___partner___is___customer } onChange={(___e: any) => { this.setState({ field___partner___is___customer: ___e.target.checked })}}/>
											<p>Clienti</p>
										</label>
										<label htmlFor="cb---partner---supplier">
											<input type="checkbox" id="cb---partner---supplier" checked={ this.state.field___partner___is___supplier } onChange={(___e: any) => { this.setState({ field___partner___is___supplier: ___e.target.checked })}}/>
											<p>Fornitori</p>
										</label>
									</section>
									
									<br />

									{(this.state.field___partner___type === 'b') ? <>
										<span className="input---nb-label">Nascita Rapp. Legale</span>
									</> : <>
										<span className="input---nb-label">Nascita</span>
									</>}
									<section className="input---nb-outlined---flexy">
										<p>il</p>
										<input type="date" value={this.state.field___partner___born_date ?? undefined} className={'input---nb-outlined'} placeholder="Città" onInput={(___e: any) => { this.setState({ field___partner___born_date: ___e.target.value }); }} />
									</section>
									<section className="input---nb-outlined---flexy" style={{paddingTop:'10pt'}}>
										<p>a</p>
										<input type="text" value={this.state.field___partner___born_city} className={'input---nb-outlined'} placeholder="Città" onInput={(___e: any) => { this.setState({ field___partner___born_city: ___e.target.value }); }} />
										<input type="text" value={this.state.field___partner___born_country} className={'input---nb-outlined'} placeholder="Prov. (o Stato estero)" onInput={(___e: any) => { this.setState({ field___partner___born_country: ___e.target.value }); }} />
									</section>

									<br />

									{(this.state.field___partner___type === 'b') ? <>
										<span className="input---nb-label">Indirizzo Rapp. Legale</span>
										<section className="input---nb-outlined---flexy">
											<input type="text" value={this.state.field___partner___address___street} className={'input---nb-outlined'} placeholder="via" onInput={(___e: any) => { this.setState({ field___partner___address___street: ___e.target.value }); }} />
											<input type="text" value={this.state.field___partner___address___street___n} className={'input---nb-outlined'} placeholder="n°" onInput={(___e: any) => { this.setState({ field___partner___address___street___n: ___e.target.value }); }} style={{width:'100pt'}}/>
										</section>
										<section className="input---nb-outlined---flexy" style={{paddingTop:'10pt'}}>
											<input type="text" value={this.state.field___partner___address___city} className={'input---nb-outlined'} placeholder="Città" onInput={(___e: any) => { this.setState({ field___partner___address___city: ___e.target.value }); }} />
											<input type="text" value={this.state.field___partner___address___prov} className={'input---nb-outlined'} placeholder="Prov" onInput={(___e: any) => { this.setState({ field___partner___address___prov: ___e.target.value }); }} style={{width:'100pt'}}/>
											<input type="text" value={this.state.field___partner___address___zip} className={'input---nb-outlined'} placeholder="Zip/CAP" onInput={(___e: any) => { this.setState({ field___partner___address___zip: ___e.target.value }); }} />
											<input type="text" value={this.state.field___partner___address___country} className={'input---nb-outlined'} placeholder="Paese" onInput={(___e: any) => { this.setState({ field___partner___address___country: ___e.target.value }); }} style={{width:'100pt'}} />
										</section>
										<br />
									</> : <></>}

									<br />
									
									{(this.state.field___partner___type === 'b') ? <>
										<span className="input---nb-label">Codice Fiscale / Partita Iva</span>
									</> : <>
										<span className="input---nb-label">Codice Fiscale</span>
									</>} 
									<section className="input---nb-outlined---flexy">
										<input type="text" value={this.state.field___partner___registry___cf} className={'input---nb-outlined'} placeholder="Codice Fiscale" onInput={(___e: any) => { this.setState({ field___partner___registry___cf: ___e.target.value }, async () => { await this.read_props___partners_suggested('cf', this.state.field___partner___registry___cf); }); }} />
										{(this.state.field___partner___type === 'b') ? <>
											<input type="text" value={this.state.field___partner___registry___vat} className={'input---nb-outlined'} placeholder="Partita Iva" onInput={(___e: any) => { this.setState({ field___partner___registry___vat: ___e.target.value }, async () => { await this.read_props___partners_suggested('piva', this.state.field___partner___registry___vat); }); }} />
										</> : <></>} 
									</section>

									<br />

									<span className="input---nb-label">Telefono</span>
									<section className="input---nb-outlined---flexy">
										<input type="tel" value={this.state.field___partner___phone} className={'input---nb-outlined'} placeholder="+39 0000 000000" onInput={(___e: any) => { this.setState({ field___partner___phone: ___e.target.value }, async () => { await this.read_props___partners_suggested('phone', this.state.field___partner___email); }); }} />
									</section>

									<br />

									<span className="input---nb-label">Email (PEC)</span>
									<section className="input---nb-outlined---flexy">
										<input type="email" value={this.state.field___partner___email_pec} className={'input---nb-outlined'} placeholder="persona@pec.com" onInput={(___e: any) => { this.setState({ field___partner___email_pec: ___e.target.value }, async () => { await this.read_props___partners_suggested('email', this.state.field___partner___email_pec); }); }} />
									</section>
									<span className="input---nb-label">Fax</span>
									<section className="input---nb-outlined---flexy">
										<input type="tel" value={this.state.field___partner___phone_fax} className={'input---nb-outlined'} placeholder="+39 0000 000000" onInput={(___e: any) => { this.setState({ field___partner___phone_fax: ___e.target.value }, () => { this.submit___partner___check_if_can() }); }} />
									</section>

									<br />

									<span className="input---nb-label">Dati fatturazine elettronica</span>
									<section className="input---nb-outlined---flexy">
										<select value={ this.state.field___partner___e_invoice___type } onChange={ this.handle___select___partner___e_invoice___type }>
											<option value={''}>Nessuna fatturazine elettronica</option>
											<option value={'pec'}>via PEC</option>
											{(this.state.field___partner___type === 'b') ? <>
												<option value={'sdi'}>via Codice Univoco</option>
											</> : <></>}
										</select>
										{(() => {
											switch (this.state.field___partner___e_invoice___type as type___partner___einvoice___type) {
												case 'pec': return <>
													<input type="email" value={this.state.field___partner___email_pec} className={'input---nb-outlined'} placeholder="persona@pec.com" onInput={(___e: any) => { this.setState({ field___partner___email_pec: ___e.target.value }); }} />
												</>; break;
												case 'sdi': return <>
													<input type="text" value={this.state.field___partner___e_invoice___sdi} className={'input---nb-outlined'} placeholder="Codice Univoco" onInput={(___e: any) => { this.setState({ field___partner___e_invoice___sdi: ___e.target.value }); }} />
												</>; break;
											}
										})()}
									</section>

									<br />

									{(this.state.field___partner___type === 'b') ? <>
										<span className="input---nb-label">Indirizzo Aziendale</span>
										<section className="input---nb-outlined---flexy">
											<input type="text" value={this.state.field___partner___address_business___street} className={'input---nb-outlined'} placeholder="via" onInput={(___e: any) => { this.setState({ field___partner___address_business___street: ___e.target.value }); }} />
											<input type="text" value={this.state.field___partner___address_business___street___n} className={'input---nb-outlined'} placeholder="n°" onInput={(___e: any) => { this.setState({ field___partner___address_business___street___n: ___e.target.value }); }} style={{width:'100pt'}}/>
										</section>
										<section className="input---nb-outlined---flexy" style={{paddingTop:'10pt'}}>
											<input type="text" value={this.state.field___partner___address_business___city} className={'input---nb-outlined'} placeholder="Città" onInput={(___e: any) => { this.setState({ field___partner___address_business___city: ___e.target.value }); }} />
											<input type="text" value={this.state.field___partner___address_business___prov} className={'input---nb-outlined'} placeholder="Prov" onInput={(___e: any) => { this.setState({ field___partner___address_business___prov: ___e.target.value }); }} style={{width:'100pt'}}/>
											<input type="text" value={this.state.field___partner___address_business___zip} className={'input---nb-outlined'} placeholder="Zip/CAP" onInput={(___e: any) => { this.setState({ field___partner___address_business___zip: ___e.target.value }); }} />
											<input type="text" value={this.state.field___partner___address_business___country} className={'input---nb-outlined'} placeholder="Paese" onInput={(___e: any) => { this.setState({ field___partner___address_business___country: ___e.target.value }); }} style={{width:'100pt'}}/>
										</section>
										<br />
									</> : <>
										<span className="input---nb-label">Indirizzo</span>
										<section className="input---nb-outlined---flexy">
											<input type="text" value={this.state.field___partner___address___street} className={'input---nb-outlined'} placeholder="via" onInput={(___e: any) => { this.setState({ field___partner___address___street: ___e.target.value }); }} />
											<input type="text" value={this.state.field___partner___address___street___n} className={'input---nb-outlined'} placeholder="n°" onInput={(___e: any) => { this.setState({ field___partner___address___street___n: ___e.target.value }); }} style={{width:'100pt'}}/>
										</section>
										<section className="input---nb-outlined---flexy" style={{paddingTop:'10pt'}}>
											<input type="text" value={this.state.field___partner___address___city} className={'input---nb-outlined'} placeholder="Città" onInput={(___e: any) => { this.setState({ field___partner___address___city: ___e.target.value }); }} />
											<input type="text" value={this.state.field___partner___address___prov} className={'input---nb-outlined'} placeholder="Prov" onInput={(___e: any) => { this.setState({ field___partner___address___prov: ___e.target.value }); }} style={{width:'100pt'}}/>
											<input type="text" value={this.state.field___partner___address___zip} className={'input---nb-outlined'} placeholder="Zip/CAP" onInput={(___e: any) => { this.setState({ field___partner___address___zip: ___e.target.value }); }} />
											<input type="text" value={this.state.field___partner___address___country} className={'input---nb-outlined'} placeholder="Paese" onInput={(___e: any) => { this.setState({ field___partner___address___country: ___e.target.value }); }} style={{width:'100pt'}} />
										</section>
									</>}

									<br />

									<span className="input---nb-label">Note sul cliente</span>
									<textarea value={ this.state.field___partner___additional___notes } onInput={(___e: any)=>{this.setState({field___partner___additional___notes:___e.target.value},()=>{/*this.submit___lead___check_if_can()*/})}} className={'input---nb-outlined is-textarea'} style={{height:'100pt'}} placeholder="note sul cliente"></textarea>

									<br />

									<span className="input---nb-label">sito web</span>
									<section className="input---nb-outlined---flexy">
										<input type="text" value={this.state.field___partner___additional___website} className={'input---nb-outlined'} placeholder="www.sitoweb.com" onInput={(___e: any) => { this.setState({ field___partner___additional___website: ___e.target.value }); }} />
									</section>

									<br />
									<br />
									<br />

								</section>
							</div>

							<div className="page-container---bottom-bar---buttons">
								<button type="button" className="is-card" onClick={ this.handle___reset }>
									<i className="fas fa-times"></i>
									<span>Reset</span>
								</button>
								<button type="button" className="is-card is-submit" onClick={() => this.submit___partner()}>
									<i className="fas fa-check"></i>
									<span>Salva Anagrafica</span>
								</button>
							</div>
						</div>
					</> : <><ComSpinnerComponent/></>
					}
				</section>
			</div>
		</>
	}

	//#endregion

}