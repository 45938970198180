import { props___page___hasAlert } from "../../../interfaces/interface.alerts"
import { props___page___hasToast } from "../../../interfaces/interface.toasts"

//	P R O P S

export interface props___SbSyncCatalogPage extends props___page___hasAlert, props___page___hasToast
{

}

//	S T A T E

export interface state___SbSyncCatalogPage {

	selected___catalog___tab: '' | 'meta',

	catalog_url___meta: string,

	dealer___props___importers: any | null,

}

//	S T A T E   D E F A U L T S

export const state___SbSyncCatalogPage___defaults: state___SbSyncCatalogPage = {

	selected___catalog___tab: 'meta',

	catalog_url___meta: '',

	dealer___props___importers: null,

}