//	E X P O R T   L I B

export const lib_names = {
	cdn: {
		endpoint: 'https://deliver.carshub.media/',
	},
	media: {
		aspect_ratios: {
			product_image_horizontal: 4 / 3,
		},
		placeholders: {
			vehicle: {
				generic: 'https://pub-0d0b8b25ea914ae8a2928abe81b69ed9.r2.dev/placeholder-vehicle-generic.png',
				car: 'https://pub-0d0b8b25ea914ae8a2928abe81b69ed9.r2.dev/placeholder-vehicle-generic.png',
				motorcycle: 'https://pub-0d0b8b25ea914ae8a2928abe81b69ed9.r2.dev/placeholder-vehicle-generic.png'
			}
		}
	},
	regex: {
		sign_username: /^[a-zA-Z0-9_]{5,12}$/,
		sign_email: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z_.-]{2,}$/i,
		sign_password: /^(.{6,16})$/,
		text_url: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
		text_hashtag: /^#\w+/,
		text_usertag: /^@\w+/,
		text_whitespace: /\s+/
	},
	endpoints: {
		catalogs: {
			meta: 'https://external.carshub.app/external/v1/social/facebook-catalog/',






		},
		exporter: {
			calendar: '',
			leads: '',
			partners: 'https://exporter.carshub.app/exporter/partners/',
			vehicles: 'https://exporter.carshub.app/exporter/vehicles/',
		},
		server_api: 'https://server.carshub.app/',
	//	server_api: 'http://localhost:3000/',
	},
	storage: {
		user___logintoken: '126cbcfdedsdsd47ec45qwdqwde22eb75e040af0ca9065e0',
		user___cookies_accepted: '339a04a3qsdsdsdwdqwd1fb8517b23450cdc9fb66257cf',
	},
	sync: {
		interval: 900000
	},
	text_editor: {
		snippet: {
			signature___mark: '{{make_signature}}',
			signature___element: '<br><br><br><br><br><div class="contract---signature"><span>Firma dell\'Acquirente</span></div><br><br><br><br><br>',
		},
		placeholders: {
			x: '×××××'
		}
	}
}