import React from "react";

//	T Y P E S

import { type___vehicle___condition } from "../../types/types.types";

//	L I B S

import { lib_properties } from "../../libs/lib.properties";
import { lib_names } from "../../libs/lib.names";
import { lib_vehicle_attributes } from "../../libs/lib.attributes";

//	F U N C S

import { funcs_numbers } from "../../funcs/funcs.numbers";
import { funcs_text } from "../../funcs/funcs.text";
import { funcs_text_extendend } from "../../funcs/funcs.text.extended";

//	S T A T E   -   P R O P S

import { props___ComContractBodyComponent } from "./com-contract-body.state";

//	C L A S S

export default class ComContractBodyComponent extends React.Component<props___ComContractBodyComponent, {}>
{

//#region 																							R E N D E R

	private readonly lib___properties: lib_properties = new lib_properties();
	private readonly lib___vehicle_attributes: lib_vehicle_attributes = new lib_vehicle_attributes();

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();
	private readonly funcs___text: funcs_text = new funcs_text();
	private readonly funcs___text_extended: funcs_text_extendend = new funcs_text_extendend();

//#endregion

//#region 																							P R O C E S S O R S

	process___condition = (___condition: type___vehicle___condition) : string => {
		switch (___condition)
		{
			case 'pristine': return 'PERFETTO'; break;
			case 'normal': return 'NORMALE'; break;
			case 'poor': return 'MEDIOCRE'; break;
			case 'broken': return 'NON EFFICIENTE'; break;
			case 'none': return 'ASSENTE'; break;
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		const ___optional___01: number = this.funcs___numbers.return___numeric_value(this.props.contract___fields[64]);
		const ___optional___02: number = this.funcs___numbers.return___numeric_value(this.props.contract___fields[66]);
		const ___optional___03: number = this.funcs___numbers.return___numeric_value(this.props.contract___fields[68]);
		const ___optional___04: number = this.funcs___numbers.return___numeric_value(this.props.contract___fields[70]);
		const ___optional___05: number = this.funcs___numbers.return___numeric_value(this.props.contract___fields[72]);
		const ___optional___06: number = this.funcs___numbers.return___numeric_value(this.props.contract___fields[74]);
		const ___optional___07: number = this.funcs___numbers.return___numeric_value(this.props.contract___fields[76]);
		const ___optional___08: number = this.funcs___numbers.return___numeric_value(this.props.contract___fields[78]);
		const ___optional___09: number = this.funcs___numbers.return___numeric_value(this.props.contract___fields[80]);
		const ___optional___total: number = ___optional___01 + ___optional___02 + ___optional___03 + ___optional___04 + ___optional___05 + ___optional___06 + ___optional___07 + ___optional___08 + ___optional___09;

		const ___total_price___vehicle: number = this.funcs___numbers.return___numeric_value(this.props.contract___fields[22]);
		const ___total_price___optionals: number = ___optional___total;

		const ___total_price___exchange: number = this.funcs___numbers.return___numeric_value(this.props.contract___fields[44]);
		const ___total_price___exchange___minus: number = this.funcs___numbers.return___numeric_value(this.props.contract___fields[49]) + this.funcs___numbers.return___numeric_value(this.props.contract___fields[52]) + this.funcs___numbers.return___numeric_value(this.props.contract___fields[55]) + this.funcs___numbers.return___numeric_value(this.props.contract___fields[58]) + this.funcs___numbers.return___numeric_value(this.props.contract___fields[61]);
		const ___total_price___exchange___result: number = ___total_price___exchange - ___total_price___exchange___minus;

		const ___total_price___total___no_exchange: number = ___total_price___vehicle + ___total_price___optionals;
		const ___total_price___total: number = ___total_price___total___no_exchange - ___total_price___exchange___result;

		return <>
			<section>

				<img src={ lib_names.cdn.endpoint + this.props.dealer___props['dealer_logo'] } style={{transform:'scale(0.5)'}}/>

				<br />

				<h2>Contratto di acquisto veicolo</h2>

				<br />

				<section>
					<p>
						contratto redatto dall'agente di vendita 
						<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[0], null) }</span>
						per conto di
						<span>{ this.funcs___text.text___return_empty(this.props.dealer___props['dealer_registry_name'], null) }</span>
						con sede legale nella città di
						<span>{ this.funcs___text.text___return_empty(this.props.dealer___props['dealer_address']['city'], null) + ' (' + this.props.dealer___props['dealer_address']['prov'] + ')'}</span>
						cap
						<span>{ this.funcs___text.text___return_empty(this.props.dealer___props['dealer_address']['zip'], null) }</span>
						in
						<span>{ this.funcs___text.text___return_empty(this.props.dealer___props['dealer_address']['street'], null) }</span>
						n°
						<span>{ this.funcs___text.text___return_empty(this.props.dealer___props['dealer_address']['street_n'], null) }</span>
						avente come P.Iva 
						<span>{ this.funcs___text.text___return_empty(this.props.dealer___props['dealer_registry_piva'], null) }</span>
					</p>

					<p>
						io sottoscritto
						<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[1], null) }</span>
						nato a
						<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[2], null) }</span>
						il
						<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[3], null) }</span>
						e residente nella città di
						<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[4], null) }</span>
						cap
						<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[5], null) }</span>
						in
						<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[6], null) }</span>
						n°
						<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[7], null) }</span>
						avente come C.F.
						<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[8], null) }</span>
						{(this.props.contract___for_business === true) ? <>
							in qualità di
							<span>amministratore</span>
							dell'azienda
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[9], null) }</span>
							con sede legale nella città di
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[10], null) }</span>
							cap
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[11], null) }</span>
							in
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[12], null) }</span>
							n°
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[13], null) }</span>
							avende come p. iva
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[14], null) }</span>
							e come metodi di contatto identificati come: il telefono
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[15], null) }</span>
							, la mail
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[16], null) }</span>
							e la pec
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[17], null) }</span>
						</> : <></>}
					</p>
				</section>

				{(this.props.contract___fields[18].trim() === 'true') ? <>
					<br />
					<section className="print---contract-preview---print-preview---md" dangerouslySetInnerHTML={{ __html: this.props.textfile___contract____c_commitment }}/>
					<br />
				</> : <>
					<br />
				</>}

				<section>
					<p>
						L'autovettura (marca e modello)
						<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[19], null) }</span>
						{(this.props.contract___fields[20] && this.props.contract___fields[20].length > 0) ? <>
							identificata mediante numero di telaio
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[20], null) }</span>
						</> : <></>}
						{(this.props.contract___fields[21] && this.props.contract___fields[21].length > 0) ? <>
							e numero di targa
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[21], null) }</span>
						</> : <></>}
						al prezzo totale di
						<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[22], ' €', 'price') }</span>
						con iva 
						{(this.props.contract___fields[84] && this.props.contract___fields[84].trim() === 'true') ? <>
							<span style={{paddingRight:'10pt'}}>{ this.funcs___text.text___return_empty(this.props.contract___fields[23], ' %') }</span>
						</> : <></>}
						<span style={{paddingLeft:'0pt'}}>{ this.lib___properties.get___property('vehicle_vat_type', this.props.contract___fields[24]) }</span>
						, la cui consegna verrà effettuata entro non oltre il 
						<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[62], null) }</span>
					</p>
				</section>

				<br />

				<div className="contract---signature contract---signature---last">
					<p><span>{ this.props.contract___fields[81] }</span>, il <span>{ this.props.contract___fields[82] }</span></p>
					<section><span>Timbro Concessionario / Firma del Venditore</span><span>Firma dell'Acquirente</span></section>
				</div>

				{/*              p a g e   b r e a k              */}

				<h2>Specifiche Veicolo</h2>

				<br />
				<br />

				<section>
					<p>
						L'autovettura (marca e modello)
						<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[19], null) }</span>
						{(this.props.contract___fields[20] && this.props.contract___fields[20].length > 0) ? <>
							identificata mediante numero di telaio
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[20], null) }</span>
						</> : <></>}
						{(this.props.contract___fields[21] && this.props.contract___fields[21].length > 0) ? <>
							e numero di targa
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[21], null) }</span>
						</> : <></>}
						al prezzo totale di
						<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[22], ' €', 'price') }</span>
						con iva
						{(this.props.contract___fields[84].trim() === 'true') ? <>
							<span style={{paddingRight:'10pt'}}>{ this.funcs___text.text___return_empty(this.props.contract___fields[23], ' %') }</span>
						</> : <></>}
						<span style={{paddingLeft:'0pt'}}>{ this.lib___properties.get___property('vehicle_vat_type', this.props.contract___fields[24]) }</span>
						viene venduta con i seguenti optional, servizi aggiuntivi, dotazioni e caratteristiche:
					</p>
				</section>

				<br />

				<section>
					<div className="print---contract-preview---print-preview---table column-count-2">
						{(this.props.vehicle___props['vehicle_conditions___registration_month'] && this.props.vehicle___props['vehicle_conditions___registration_year']) ? <>
							<div><div><span>-</span><span>Prima immatricolazione</span></div><div><span>{ this.props.vehicle___props['vehicle_conditions___registration_month'] + ' / ' + this.props.vehicle___props['vehicle_conditions___registration_year'] }</span></div></div>
						</> : <></>}
						<div><div><span>-</span><span>Km</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.vehicle___props['vehicle_conditions___km'], null, 'mileage') } km</span></div></div>
						{(this.props.vehicle___brand) ? <><div><div><span>-</span><span>Marca</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.vehicle___brand['vehicle_brand_name'], null) }</span></div></div></> : <></>}
						{(this.props.vehicle___model) ? <><div><div><span>-</span><span>Modello</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.vehicle___model['vehicle_model_name'], null) }</span></div></div></> : <></>}
						{(this.props.vehicle___props['vehicle_version']) ? <><div><div><span>-</span><span>Versione</span></div><div><span>{this.funcs___text.text___return_empty(this.props.vehicle___props['vehicle_version'], null) }</span></div></div></> : <></>}
						{(this.props.vehicle___props['vehicle_variant']) ? <><div><div><span>-</span><span>Variante</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.vehicle___props['vehicle_variant'], null) }</span></div></div></> : <></>}
						{(this.props.vehicle___props['vehicle_specs___engine_fuel_type']) ? <><div><div><span>-</span><span>Carburante</span></div><div><span>{ this.funcs___text.text___return_empty(this.lib___vehicle_attributes.get___vehicle_attribute___name___property('engine_fuel_type', this.props.vehicle___props['vehicle_specs___engine_fuel_type']), null) }</span></div></div></> : <></>}
						{(this.props.vehicle___props['vehicle_specs___engine_name']) ? <><div><div><span>-</span><span>Motore</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.vehicle___props['vehicle_specs___engine_name'], null) }</span></div></div></> : <></>}
						{(this.props.vehicle___props['vehicle_specs___engine_cylinders_number']) ? <><div><div><span>-</span><span>Specifiche motore</span></div><div><span>{ this.funcs___text.text___return_empty(this.lib___vehicle_attributes.get___vehicle_attribute___name___property('engine_cylinder_displacement', this.props.vehicle___props['vehicle_specs___engine_cylinders_displacement']), null) + ' - ' + this.props.vehicle___props['vehicle_specs___engine_cylinders_number'] + ' cilindri' }</span></div></div></> : <></>}
						{(this.props.vehicle___props['vehicle_specs___engine_capacity']) ? <><div><div><span>-</span><span>Cilindrata</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.vehicle___props['vehicle_specs___engine_capacity'], ' cc') }</span></div></div></> : <></>}
						{(this.props.vehicle___props['vehicle_specs___engine_power_kw']) ? <><div><div><span>-</span><span>Potenza</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.vehicle___props['vehicle_specs___engine_power_kw'], ' kw (' + this.funcs___numbers.convert___power(this.props.vehicle___props['vehicle_specs___engine_power_kw'], 'hp') + ' cv)') }</span></div></div></> : <></>}
						{(this.props.vehicle___props['vehicle_specs___gearbox_type']) ? <><div><div><span>-</span><span>Cambio</span></div><div><span>{ this.funcs___text.text___return_empty(this.lib___vehicle_attributes.get___vehicle_attribute___name___property('gearbox_type', this.props.vehicle___props['vehicle_specs___gearbox_type']), null)}</span></div></div></> : <></>}
			{/*			{(this.props.vehicle___props['vehicle_specs___gearbox_type'] && this.props.vehicle___props['vehicle_specs___gearbox_gears']) ? <><div><div><span>-</span><span>Cambio</span></div><div><span>{ this.funcs___text.text___return_empty(this.lib___vehicle_attributes.get___vehicle_attribute___name___property('gearbox_type', this.props.vehicle___props['vehicle_specs___gearbox_type']), null) + ' - ' + this.props.vehicle___props['vehicle_specs___gearbox_gears'] + ' rapporti' }</span></div></div></> : <></>}		*/}
						{(this.props.vehicle___props['vehicle_specs___transmission_drive']) ? <><div><div><span>-</span><span>Trazione</span></div><div><span>{ this.funcs___text.text___return_empty(this.lib___vehicle_attributes.get___vehicle_attribute___name___property('transmission_drive', this.props.vehicle___props['vehicle_specs___transmission_drive']), null) }</span></div></div></> : <></>}
						{(this.props.vehicle___props['vehicle_specs___brakes_front_type']) ? <><div><div><span>-</span><span>Freni Ant.</span></div><div><span>{ this.funcs___text.text___return_empty(this.lib___vehicle_attributes.get___vehicle_attribute___name___property('brakes_type', this.props.vehicle___props['vehicle_specs___brakes_front_type']), null) }</span></div></div></> : <></>}
						{(this.props.vehicle___props['vehicle_specs___brakes_rear_type']) ? <><div><div><span>-</span><span>Freni Post.</span></div><div><span>{ this.funcs___text.text___return_empty(this.lib___vehicle_attributes.get___vehicle_attribute___name___property('brakes_type', this.props.vehicle___props['vehicle_specs___brakes_rear_type']), null) }</span></div></div></> : <></>}
						{(this.props.vehicle___props['vehicle_config___body_type'] && this.props.vehicle___props['vehicle_config___body_doors_number']) ? <><div><div><span>-</span><span>Tipo Carrozzeria</span></div><div><span>{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('body_type', this.props.vehicle___props['vehicle_config___body_type']) + ' ' + this.props.vehicle___props['vehicle_config___body_doors_number'] + ' porte' }</span></div></div></> : <></>}
						{(this.props.vehicle___props['vehicle_config___paint_color'] && this.props.vehicle___props['vehicle_config___paint_type']) ? <><div><div><span>-</span><span>Vernice</span></div><div><span>{ this.funcs___text.text___return_empty(this.lib___vehicle_attributes.get___vehicle_attribute___name___property('body_paint_color', this.props.vehicle___props['vehicle_config___paint_color']), null) + ' ' + this.lib___vehicle_attributes.get___vehicle_attribute___name___property('body_paint_type', this.props.vehicle___props['vehicle_config___paint_type']) }</span></div></div></> : <></>}
						{(this.props.vehicle___props['vehicle_config___interior_material'] && this.props.vehicle___props['vehicle_config___interior_color'] && this.props.vehicle___props['vehicle_config___interior_details']) ? <><div><div><span>-</span><span>Interni</span></div><div><span>{ this.funcs___text.text___return_empty(this.lib___vehicle_attributes.get___vehicle_attribute___name___property('interior_material', this.props.vehicle___props['vehicle_config___interior_material']), null) + ' ' + this.lib___vehicle_attributes.get___vehicle_attribute___name___property('interior_colors', this.props.vehicle___props['vehicle_config___interior_color']) + ' / ' + this.lib___vehicle_attributes.get___vehicle_attribute___name___property('interior_details', this.props.vehicle___props['vehicle_config___interior_details']) }</span></div></div></> : <></>}
						{(this.props.vehicle___props['vehicle_config___wheel_material'] && this.props.vehicle___props['vehicle_config___wheel_tyres']) ? <><div><div><span>-</span><span>Cerchi e Gomme</span></div><div><span>{ this.funcs___text.text___return_empty(this.lib___vehicle_attributes.get___vehicle_attribute___name___property('wheel_material', this.props.vehicle___props['vehicle_config___wheel_material']), null) + ' - ' + this.props.vehicle___props['vehicle_config___wheel_tyres'] }</span></div></div></> : <></>}
					</div>
				</section>

				<br />

				{(this.props.contract___fields[25] === 'true') ? <>
					<section style={{textAlign:'center'}}>
						<p>e viene fornito nelle seguenti condizioni funzioanli, accettate al momento della firma</p>
					</section>
					<br />
					<section>
						<div className="print---contract-preview---print-preview---table column-count-2">
							<div><div></div><div>condizione</div></div>
							<div><div><span>-</span><span>Motore</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___engine']), null) }</span></div></div>
							<div><div><span>-</span><span>Sistema di alimentazione</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___power_supply']), null) }</span></div></div>
							<div><div><span>-</span><span>Scarico</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___exhaust']), null) }</span></div></div>
							<div><div><span>-</span><span>Emissioni</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___exhaust_emissions']), null) }</span></div></div>
							<div><div><span>-</span><span>Perdite di liquidi</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___leaks']), null) }</span></div></div>
							<div><div><span>-</span><span>Raffreddamento Motore</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___engine_cooling']), null) }</span></div></div>
							<div><div><span>-</span><span>Cambio</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___gearbox']), null) }</span></div></div>
							<div><div><span>-</span><span>Frizione</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___clutch']), null) }</span></div></div>
							<div><div><span>-</span><span>Freni</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___brakes']), null) }</span></div></div>
							<div><div><span>-</span><span>Sterzo</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___steering']), null) }</span></div></div>
							<div><div><span>-</span><span>Sospensioni</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___suspensions']), null) }</span></div></div>
							<div><div><span>-</span><span>Ruote e Gomme</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___wheels']), null) }</span></div></div>
							<div><div><span>-</span><span>Rumorosità</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___noise']), null) }</span></div></div>
							<div><div><span>-</span><span>Batteria</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___battery']), null) }</span></div></div>
							<div><div><span>-</span><span>Avviamento</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___engine_starter']), null) }</span></div></div>
							<div><div><span>-</span><span>Luci</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___lights']), null) }</span></div></div>
							<div><div><span>-</span><span>Batteria</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___battery']), null) }</span></div></div>
							<div><div><span>-</span><span>Vetri</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___windows']), null) }</span></div></div>
							<div><div><span>-</span><span>Tergicristalli</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___windows_wipers']), null) }</span></div></div>
							<div><div><span>-</span><span>Errori e Spie di Avaria</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___warnings']), null) }</span></div></div>
							<div><div><span>-</span><span>Aria condizionata</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___air_conditioning']), null) }</span></div></div>
							<div><div><span>-</span><span>Specchietti</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___mirrors']), null) }</span></div></div>
							<div><div><span>-</span><span>Cinture di Sicurezza</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___seatbelts']), null) }</span></div></div>
							<div><div><span>-</span><span>Interni (Tappezzeria)</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___interior']), null) }</span></div></div>
							<div><div><span>-</span><span>Sedili</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___interior_seats']), null) }</span></div></div>
							<div><div><span>-</span><span>Cruscotto</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___dashboard']), null) }</span></div></div>
						</div>
					</section>
					<br />
				</> : <>
					<br />
				</>}
				{(this.props.contract___fields[26] === 'true') ? <>
					<section style={{textAlign:'center'}}>
						<p> e delle seguenti condizioni estetiche </p>
					</section>
					<br />
					<section>
						<div style={{display:'flex',gap:'25pt',paddingLeft:'25pt'}}>
							<img src="/assets/ui/images/car-body-conditions.png" style={{display:'block',width:'4cm',height:'165'}}/>
							<div className="print---contract-preview---print-preview---table column-count-2-small">
								<div></div>
								<div><div><span>-</span><span>1</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][0]), null) }</span></div></div>
								<div><div><span>-</span><span>2</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][1]), null) }</span></div></div>
								<div><div><span>-</span><span>3</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][2]), null) }</span></div></div>
								<div><div><span>-</span><span>4</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][3]), null) }</span></div></div>
								<div><div><span>-</span><span>5</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][4]), null) }</span></div></div>
								<div><div><span>-</span><span>6</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][5]), null) }</span></div></div>
								<div><div><span>-</span><span>7</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][6]), null) }</span></div></div>
								<div><div><span>-</span><span>8</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][7]), null) }</span></div></div>
								<div><div><span>-</span><span>9</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][8]), null) }</span></div></div>
								<div><div><span>-</span><span>10</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][9]), null) }</span></div></div>
								<div><div><span>-</span><span>11</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][10]), null) }</span></div></div>
							</div>
							<div className="print---contract-preview---print-preview---table column-count-2-small">
								<div></div>
								<div><div><span>-</span><span>12</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][11]), null) }</span></div></div>
								<div><div><span>-</span><span>13</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][12]), null) }</span></div></div>
								<div><div><span>-</span><span>14</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][13]), null) }</span></div></div>
								<div><div><span>-</span><span>15</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][14]), null) }</span></div></div>
								<div><div><span>-</span><span>16</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][15]), null) }</span></div></div>
								<div><div><span>-</span><span>17</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][16]), null) }</span></div></div>
								<div><div><span>-</span><span>18</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][17]), null) }</span></div></div>
								<div><div><span>-</span><span>19</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][18]), null) }</span></div></div>
								<div><div><span>-</span><span>20</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][19]), null) }</span></div></div>
								<div><div><span>-</span><span>21</span></div><div><span>{ this.funcs___text.text___return_empty(this.process___condition(this.props.vehicle___props['vehicle_efficiency___body'][20]), null) }</span></div></div>
								<div><div></div></div>
							</div>
						</div>
					</section>
					<br />
				</> : <>
					<br />
				</>}

				{(
					this.props.contract___fields[63] || this.props.contract___fields[63].length > 0 || this.props.contract___fields[64] || this.props.contract___fields[64].length > 0 ||
					this.props.contract___fields[65] || this.props.contract___fields[65].length > 0 || this.props.contract___fields[66] || this.props.contract___fields[66].length > 0 ||
					this.props.contract___fields[67] || this.props.contract___fields[67].length > 0 || this.props.contract___fields[68] || this.props.contract___fields[68].length > 0 ||
					this.props.contract___fields[69] || this.props.contract___fields[69].length > 0 || this.props.contract___fields[70] || this.props.contract___fields[70].length > 0 ||
					this.props.contract___fields[71] || this.props.contract___fields[71].length > 0 || this.props.contract___fields[72] || this.props.contract___fields[72].length > 0 ||
					this.props.contract___fields[73] || this.props.contract___fields[73].length > 0 || this.props.contract___fields[74] || this.props.contract___fields[74].length > 0 ||
					this.props.contract___fields[75] || this.props.contract___fields[75].length > 0 || this.props.contract___fields[76] || this.props.contract___fields[76].length > 0 ||
					this.props.contract___fields[77] || this.props.contract___fields[77].length > 0 || this.props.contract___fields[78] || this.props.contract___fields[78].length > 0 ||
					this.props.contract___fields[79] || this.props.contract___fields[79].length > 0 || this.props.contract___fields[80] || this.props.contract___fields[80].length > 0
				) ? <>

					<section style={{textAlign:'center'}}>
						<p> e delle corredato dai seguenti servizi/optional aggiuntivi </p>
					</section>

					<br />

					<div className="print---contract-preview---print-preview---table column-count-2-optionals">
						<div></div>
						{(this.props.contract___fields[63] || this.props.contract___fields[63].length > 0 || this.props.contract___fields[64] || this.props.contract___fields[64].length > 0) ? <><div><div><span>1</span><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[63], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[64], ' €', 'price') }</span></div></div></> : <></>}
						{(this.props.contract___fields[65] || this.props.contract___fields[65].length > 0 || this.props.contract___fields[66] || this.props.contract___fields[66].length > 0) ? <><div><div><span>2</span><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[65], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[66], ' €', 'price') }</span></div></div></> : <></>}
						{(this.props.contract___fields[67] || this.props.contract___fields[67].length > 0 || this.props.contract___fields[68] || this.props.contract___fields[68].length > 0) ? <><div><div><span>3</span><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[67], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[68], ' €', 'price') }</span></div></div></> : <></>}
						{(this.props.contract___fields[69] || this.props.contract___fields[69].length > 0 || this.props.contract___fields[70] || this.props.contract___fields[70].length > 0) ? <><div><div><span>4</span><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[69], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[70], ' €', 'price') }</span></div></div></> : <></>}
						{(this.props.contract___fields[71] || this.props.contract___fields[71].length > 0 || this.props.contract___fields[72] || this.props.contract___fields[72].length > 0) ? <><div><div><span>5</span><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[71], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[72], ' €', 'price') }</span></div></div></> : <></>}
						{(this.props.contract___fields[73] || this.props.contract___fields[73].length > 0 || this.props.contract___fields[74] || this.props.contract___fields[74].length > 0) ? <><div><div><span>6</span><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[73], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[74], ' €', 'price') }</span></div></div></> : <></>}
						{(this.props.contract___fields[75] || this.props.contract___fields[75].length > 0 || this.props.contract___fields[76] || this.props.contract___fields[76].length > 0) ? <><div><div><span>7</span><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[75], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[76], ' €', 'price') }</span></div></div></> : <></>}
						{(this.props.contract___fields[77] || this.props.contract___fields[77].length > 0 || this.props.contract___fields[78] || this.props.contract___fields[78].length > 0) ? <><div><div><span>8</span><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[77], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[78], ' €', 'price') }</span></div></div></> : <></>}
						{(this.props.contract___fields[79] || this.props.contract___fields[79].length > 0 || this.props.contract___fields[80] || this.props.contract___fields[80].length > 0) ? <><div><div><span>9</span><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[79], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[80], ' €', 'price') }</span></div></div></> : <></>}
						<div style={{height:'5pt'}}></div>
						<div><div style={{textAlign:'right'}}><span></span><span style={{textAlign:'right'}}>per un totale dei servizi/optionals di </span></div><div><span>{ this.funcs___text.text___return_empty(___optional___total, ' €', 'price') }</span></div></div>
						<div><div style={{textAlign:'right'}}><span></span><span style={{textAlign:'right'}}>per un totale complessivo di </span></div><div><span>{ this.funcs___text.text___return_empty(___optional___total + ___total_price___vehicle, ' €', 'price') }</span></div></div>
					</div>
					<br />
				</> : <></>}

				<div className="contract---signature contract---signature---last">
					<p><span>{ this.props.contract___fields[81] }</span>, il <span>{ this.props.contract___fields[82] }</span></p>
					<section><span>Timbro Concessionario / Firma del Venditore</span><span>Firma dell'Acquirente</span></section>
				</div>

				{/*              p a g e   b r e a k              */}

				{(this.props.contract___fields[40].trim() === 'true') ? <>
				
					<h2>Permuta</h2>

					<br />
					<br />

					<section>
						<p>
							La permuta avviene con l'autovettura (marca e modello)
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[41], null) }</span>
							{(this.props.contract___fields[42] && this.props.contract___fields[42].length > 0) ? <>
								identificata mediante numero di telaio
								<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[42], null) }</span>
							</> : <></>}
							{(this.props.contract___fields[43] && this.props.contract___fields[43].length > 0) ? <>
								e numero di targa
								<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[43], null) }</span>
							</> : <></>}	
							al prezzo totale di
							<span>{ this.funcs___text.text___return_empty(this.props.contract___fields[44], ' €', 'price') }</span>
							{(___total_price___exchange___minus > 0) ? <>
								a cui viene sottratto il valore di <span>{ this.funcs___text.text___return_empty(___total_price___exchange___minus, ' €' + 'price') }</span> 
								delle condizioni (danneggiamenti) alla tabella seguente per un totale di <span>{ this.funcs___text.text___return_empty(___total_price___exchange___result, ' €' + 'price') }</span>
							</> : <></>}
							con iva 
							{(this.props.contract___fields[84].trim() === 'true') ? <>
								<span style={{paddingRight:'10pt'}}>{ this.funcs___text.text___return_empty(this.props.contract___fields[45], ' %') }</span>
							</> : <></>}
							<span style={{paddingLeft:'0pt'}}>{ this.lib___properties.get___property('vehicle_vat_type', this.props.contract___fields[46]) }</span>
						</p>
					</section>

					<br />

					{(
						this.props.contract___fields[47] || this.props.contract___fields[47].length > 0 || this.props.contract___fields[48] || this.props.contract___fields[48].length > 0 || this.props.contract___fields[49] || this.props.contract___fields[49].length > 0 || 
						this.props.contract___fields[50] || this.props.contract___fields[50].length > 0 || this.props.contract___fields[51] || this.props.contract___fields[51].length > 0 || this.props.contract___fields[52] || this.props.contract___fields[52].length > 0 || 
						this.props.contract___fields[53] || this.props.contract___fields[53].length > 0 || this.props.contract___fields[54] || this.props.contract___fields[54].length > 0 || this.props.contract___fields[55] || this.props.contract___fields[55].length > 0 || 
						this.props.contract___fields[56] || this.props.contract___fields[56].length > 0 || this.props.contract___fields[57] || this.props.contract___fields[57].length > 0 || this.props.contract___fields[58] || this.props.contract___fields[58].length > 0 || 
						this.props.contract___fields[59] || this.props.contract___fields[59].length > 0 || this.props.contract___fields[60] || this.props.contract___fields[60].length > 0 || this.props.contract___fields[61] || this.props.contract___fields[61].length > 0
					) ? <>

						<section style={{textAlign:'center'}}>
							<p> con il seguente prospetto dei danni ed il loro valore di mercato </p>
						</section>

						<br />

						<div className="print---contract-preview---print-preview---table column-count-3-conditions">
							{(this.props.contract___fields[47] || this.props.contract___fields[47].length > 0 || this.props.contract___fields[48] || this.props.contract___fields[48].length > 0 || this.props.contract___fields[49] || this.props.contract___fields[49].length > 0) ? <><div><div><span style={{width:'200pt'}}>{ this.funcs___text.text___return_empty(this.props.contract___fields[47], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[48], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[49], ' €', 'price') }</span></div></div></> : <></>}
							{(this.props.contract___fields[50] || this.props.contract___fields[50].length > 0 || this.props.contract___fields[51] || this.props.contract___fields[51].length > 0 || this.props.contract___fields[52] || this.props.contract___fields[52].length > 0) ? <><div><div><span style={{width:'200pt'}}>{ this.funcs___text.text___return_empty(this.props.contract___fields[50], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[51], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[52], ' €', 'price') }</span></div></div></> : <></>}
							{(this.props.contract___fields[53] || this.props.contract___fields[53].length > 0 || this.props.contract___fields[54] || this.props.contract___fields[54].length > 0 || this.props.contract___fields[55] || this.props.contract___fields[55].length > 0) ? <><div><div><span style={{width:'200pt'}}>{ this.funcs___text.text___return_empty(this.props.contract___fields[53], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[54], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[55], ' €', 'price') }</span></div></div></> : <></>}
							{(this.props.contract___fields[56] || this.props.contract___fields[56].length > 0 || this.props.contract___fields[57] || this.props.contract___fields[57].length > 0 || this.props.contract___fields[58] || this.props.contract___fields[58].length > 0) ? <><div><div><span style={{width:'200pt'}}>{ this.funcs___text.text___return_empty(this.props.contract___fields[56], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[57], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[58], ' €', 'price') }</span></div></div></> : <></>}
							{(this.props.contract___fields[59] || this.props.contract___fields[59].length > 0 || this.props.contract___fields[60] || this.props.contract___fields[60].length > 0 || this.props.contract___fields[61] || this.props.contract___fields[61].length > 0) ? <><div><div><span style={{width:'200pt'}}>{ this.funcs___text.text___return_empty(this.props.contract___fields[59], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[60], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[61], ' €', 'price') }</span></div></div></> : <></>}
						</div>

						<br />

					</> : <></>}

					<div className="contract---signature contract---signature---last">
						<p><span>{ this.props.contract___fields[81] }</span>, il <span>{ this.props.contract___fields[82] }</span></p>
						<section><span>Timbro Concessionario / Firma del Venditore</span><span>Firma dell'Acquirente</span></section>
					</div>

				</> : <></>}

				{/*              p a g e   b r e a k              */}

				<h2>Importi e Pagamento</h2>

				<br />
				<br />

				<section>
					<p style={{textAlign:'center'}}>Il totale da corrispondersi è definito in:</p>
				</section>

				<div className="print---contract-preview---print-preview---table column-count-2-optionals">
					<div><div><span>+</span><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[19], null) }</span></div><div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[22], ' €', 'price') }</span></div></div>
					<div><div><span>+</span><span>Totale Optionals e Dotazioni Aggiuntive</span></div><div><span>{ this.funcs___text.text___return_empty(___optional___total.toString(), ' €', 'price') }</span></div></div>
					<div><div style={{textAlign:'right'}}><span></span><span style={{textAlign:'right',fontWeight:'bold'}}>per un totale di</span></div><div><span>{ this.funcs___text.text___return_empty(___total_price___total___no_exchange.toString(), ' €', 'price') }</span></div></div>
				</div>

				{(this.props.contract___fields[40].toLowerCase() === 'true') ? <>
					<section>
						<p style={{textAlign:'center'}}>da cui viene sottratto il valore della permuta</p>
					</section>
					<div className="print---contract-preview---print-preview---table column-count-2-optionals">
						<div><div><span>+</span><span>Totale da corrispondere</span></div><div><span>{ this.funcs___text.text___return_empty(___total_price___total___no_exchange.toString(), ' €', 'price') }</span></div></div>
						<div><div><span>-</span><span>Valore della permuta</span></div><div><span>{ this.funcs___text.text___return_empty(___total_price___exchange___result, ' €', 'price') }</span></div></div>
						<div><div style={{textAlign:'right'}}><span></span><span style={{textAlign:'right',fontWeight:'bold'}}>per un totale di</span></div><div><span>{ this.funcs___text.text___return_empty(___total_price___total.toString(), ' €', 'price') }</span></div></div>
					</div>
				</> : <></>}

				<section>
					<p style={{textAlign:'center'}}>Mi impegno a corrispondere il prezzo complessivo nel seguente modo:</p>
				</section>

				<br />

				<section>
					<div className="print---contract-preview---print-preview---table column-count-3">
						<div>
							<div></div>
							<div>tipologia</div>
							<div>importo</div>
						</div>
						<div>
							<div><span>a</span><span>A titolo di deposito cauzionale</span></div>
							<div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[29], null) }</span></div>
							<div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[28], ' €', 'price') }</span></div>
						</div>
						<div>
							<div><span>b</span><span>Importo Totale Finanziamento / Leasing</span></div>
							<div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[31], null) }</span></div>
							<div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[30], ' €', 'price') }</span></div>
						</div>
						{(this.props.contract___fields[30].length > 0 || this.props.contract___fields[31].length > 0) ? <>
							<div style={{justifyContent:'flex-start'}}>
								<div style={{paddingLeft:'50pt'}}><span>b.1</span><span>Numero Rate</span></div>
								<div style={{marginLeft:'-50pt'}}><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[32], null) }</span></div>
							</div>
							<div style={{justifyContent:'space-between'}}>
								<div style={{paddingLeft:'50pt'}}><span>b.2</span><span>Importo Rata</span></div>
								<div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[33], ' € cad.', 'price') }</span></div>
							</div>
							<div style={{justifyContent:'space-between'}}>
								<div style={{paddingLeft:'50pt'}}><span>b.3</span><span>Riscatto o MaxiRata finale</span></div>
								<div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[34], ' €', 'price') }</span></div>
							</div>
						</> : <></>}
						<div>
							<div><span>c</span><span>Importo da effettuarsi prima della consegna</span></div>
							<div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[36], null) }</span></div>
							<div><span>{ this.funcs___text.text___return_empty(this.props.contract___fields[35], ' €', 'price') }</span></div>
						</div>
						{(this.props.contract___fields[40] === 'true') ? <>
							<div>
								<div><span>d</span><span>Valore della permuta effettuata</span></div>
								<div><span></span></div>
								<div><span>{ this.funcs___text.text___return_empty(___total_price___exchange___result, ' €', 'price') }</span></div>
							</div>
						</> : <></>}
					</div>
				</section>

				<br />

				<div className="contract---signature contract---signature---last">
					<p><span>{ this.props.contract___fields[81] }</span>, il <span>{ this.props.contract___fields[82] }</span></p>
					<section><span>Timbro Concessionario / Firma del Venditore</span><span>Firma dell'Acquirente</span></section>
				</div>

				{/*              p a g e   b r e a k              */}

				{(this.props.contract___fields[27].trim() === 'true') ? <>
					<br />
					<br />
					<section className="print---contract-preview---print-preview---md" dangerouslySetInnerHTML={{ __html: this.props.textfile___contract____c_sell }}/>
					<br />
					<br />
					<div className="contract---signature contract---signature---last">
						<p><span>{ this.props.contract___fields[81] }</span>, il <span>{ this.props.contract___fields[82] }</span></p>
						<section><span>Timbro Concessionario / Firma del Venditore</span><span>Firma dell'Acquirente</span></section>
					</div>
				</> : <>
					<br />
					<br />
				</>}

				{(this.props.contract___fields[37].trim() === 'true') ? <>
					<br />
					<br />
					<section className="print---contract-preview---print-preview---md" dangerouslySetInnerHTML={{ __html: this.props.textfile___contract____c_payments }}/>
				</> : <></>}

				{(this.props.contract___fields[38].trim() === 'true') ? <>
					<br />
					<br />
					<section className="print---contract-preview---print-preview---md" dangerouslySetInnerHTML={{ __html: this.props.textfile___contract____c_payments_leasing }}/>
				</> : <></>}

				{(this.props.contract___fields[39].trim() === 'true') ? <>
					<br />
					<br />
					<section className="print---contract-preview---print-preview---md" dangerouslySetInnerHTML={{ __html: this.props.textfile___contract____c_payments_financing }}/>
				</> : <></>}

			</section>
		</>;

	}

//#endregion

}