import React from "react";
import { Link } from "react-router-dom";

//	S T Y L E

import "./sb-leads-view.scss"

//	T Y P E S

import { type___activity___type, type___lead___interest___to, type___lead___source, type___lead___status, type___request___contract_additionals, type___vehicle___price_vat_type } from "../../../types/types.types";
import { type___alert___controller } from "../../../types/types.alerts";
import { type___api___response } from "../../../types/types.api-response";
import { type___params__url } from "../../../types/types.params";
import { type___toast___controller } from "../../../types/types.toasts";

//	L I B S

import { lib_names } from "../../../libs/lib.names";
import { lib_selectables } from "../../../libs/lib.selectables";

//	F U N C S

import { funcs_datetime } from "../../../funcs/funcs.datetime";
import { funcs_numbers } from "../../../funcs/funcs.numbers";
import { funcs_text } from "../../../funcs/funcs.text";

//	S E R V I C E S

import { service_RestApiService } from "../../../services/service-api";

//	S T A T E S

import { props___SbLeadsViewPage, state___SbLeadsViewPage, state___SbLeadsViewPage___defaults } from "./sb-leads-view.state";

//	C O M P O N E N T S

import ComLeadActivityComponent from "../../../components/com-lead-activity/com-lead-activity";
import ComPartnerComponent from "../../../components/com-partner/com-partner";
import ComResultsEmptyComponent from "../../../components/com-results-empty/com-results-empty";
import ComSpinnerComponent from "../../../components/com-spinner/com-spinner";
import ComVehicleComponent from "../../../components/com-vehicle/com-vehicle";

//	M O D A L S

import MdContractPreviewModal from "../../../modals/md-contract-preview/md-contract-preview";

//	C L A S S

export default class SbLeadsViewPage extends React.Component<props___SbLeadsViewPage, state___SbLeadsViewPage>
{

//#region 																							D E C L A R A T I O N S

	readonly _URL_PARAMS: type___params__url;
//	readonly _URL_NAVIGATE: NavigateFunction = useNavigate();

	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly lib___selectables: lib_selectables = new lib_selectables();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();
	private readonly funcs___text: funcs_text = new funcs_text();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbLeadsViewPage,
	)
	{
		super(_PROPS);
		this.state = state___SbLeadsViewPage___defaults;
		this._URL_PARAMS = this.props.params
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___me = async () => {
		const ___me___props: type___api___response = await this._API.users___read___single('me');
		this.setState({
			me___props: ___me___props.data
		});
	}

	read_props___lead = async () => {
		const ___lead___props: type___api___response = await this._API.leads___read__single(this.state.lead___id);
		if (___lead___props.response === 'success')
		{
			const ___partner___props: type___api___response = await this._API.partners___read___single(___lead___props.data['lead_partner']['partner_id']);
			if (___lead___props.data['lead_vehicle'] !== null) { await this.read_props___lead___selected_vehicle(___lead___props.data['lead_vehicle']); }
			this.setState({
				lead___id: ___lead___props.data['lead_id'],
				lead___props: ___lead___props.data,
				partner___props: ___partner___props.data,
				contract___generated: (___lead___props.data['lead_contract_generated']) ? ___lead___props.data['lead_contract_generated'] : this.state.contract___generated,
				GUI___page___is_loading: true
			}, async () => {
				switch (this.state.selected___lead_view___tab)
				{
					case 'overview': await this.read_props___lead___activities(); break;
					case 'matches': await this.read_props___lead___matches(); break;
					case 'contract': this.handle___contract___generate(false); break;
					case 'sell': this.handle___contract___generate(false); break;
				}
				this.setState({
					GUI___page___is_loading: false,
				});
			});
		}
		else
		{
			const ___alert_props : type___alert___controller = {
				alert___title: "Errore",
				alert___message : <>Impossibile caricare questo Lead...</>,
				alert___buttons : [
					{
						alert___button___icon: 'fa-chevron-left',
						alert___button___text: 'Torna Indietro',
						alert___button___action : () => {
							this._PROPS.alert___setShowing(false);
							if (window.history.length > 1) { window.history.back(); }
							else { window.location.href = '/leads/list'; }
						}
					}
				]
			}
			this._PROPS.alert___setAlert(___alert_props);
			this._PROPS.alert___setShowing(true);
		}
	}

	read_props___lead___activities = async (___is_reset: boolean = false) => {
		this.setState({
			lead___activities: (___is_reset === true) ? [] : this.state.lead___activities,
			GUI___page___is_loading___section: true
		}, async () => {
			const ___lead___activities: type___api___response = await this._API.activities___read___multi('lead', this.state.lead___id, this.state.lead___activities.length, 'standard');
			this.setState({
				GUI___page___is_loading___section: false,
				lead___activities: ___lead___activities.data,
			});
		});
	}

	read_props___lead___matches = async (___matches_target: 'lead_search' | 'lead_matches' = 'lead_matches') => {
		this.setState({
			lead___matches_vehicles: [],
			GUI___page___is_loading___section: true
		}, async () => {
			const ___lead___matches_vehicles___target: string = ___matches_target;
			const ___lead___matches_vehicles___param: string = (___matches_target === 'lead_matches') ? this.state.lead___id : this.state.lead___matches_vehicles___search_param.trim();
			const ___lead___matches_vehicles: type___api___response = await this._API.vehicles___read___multi(___lead___matches_vehicles___target, ___lead___matches_vehicles___param, this.state.lead___matches_vehicles.length, { param: 'createdAt', direction: -1 });
			this.setState({
				GUI___page___is_loading___section: false,
				lead___matches_vehicles: ___lead___matches_vehicles.data,
			});
		});
	}

	read_props___lead___selected_vehicle = async (___vehicle_id: string | null = null) => {
		const ___lead___selected_vehicle: type___api___response = await this._API.vehicles___read___single(___vehicle_id ?? this.state.lead___props['lead_vehicle']);
		this.setState({
			vehicle___props: ___lead___selected_vehicle.data,
		});
	}

//#endregion

//#region 																							S U B M I T

	submit___activity___check_if_can() : boolean
	{

		let ___error_toast___should_display: boolean = false;
		let ___error_toast___text: string = 'Impossibile salvare, mancano i seguenti campi: ';

	//	if (
	//		(this.state.field___partner___binding_loaded === true && this.state.field___partner___binding_id !== null) ||
	//		(this.state.field___partner___type.length > 0 && this.state.field___partner___name.length > 0 && this.state.field___partner___surname.length > 0)
	//	) { this.setState({can_submit___section___partner: true}); }

	//	if (this.state.can_submit___section___partner === true && this.state.can_submit___section___lead === true) { ___error_toast___should_display = true; }

		return !(___error_toast___should_display);

	}

	async submit___activity()
	{
		const ___activity_submitted: type___api___response = await this._API.activities___submit(null, this.state.lead___id, this.state.field___new_activity___type, this.state.field___new_activity___datetime___from!, this.state.field___new_activity___datetime___to!, this.state.field___new_activity___note);
		if (___activity_submitted['response'] === 'success')
		{
			this.setState({
				field___new_activity___type: 'appointment',
				field___new_activity___datetime___from: this.funcs___datetime.datetime___format___input_datetime_local(new Date()),
				field___new_activity___datetime___to: this.funcs___datetime.datetime___format___input_datetime_local(new Date()),
				field___new_activity___note: ''
			}, async () => {
				this.handle___activity___create_new___cancel();
				await this.read_props___lead();
				const ___toast_props: type___toast___controller = {
					toast___color: 'danger',
					toast___text: <>Nuova attività creata</>
				}
				this._PROPS.toast___setContent(___toast_props);
				this._PROPS.toast___setShowing(true);
			});
		}
		else
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'danger',
				toast___text: <>Impossibile aggiungere attività...</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}
	}

//#endregion

//#region 																							H A N D L E R S

	handle___select___tab = async (___e: state___SbLeadsViewPage['selected___lead_view___tab']) => {
		this.setState({
			selected___lead_view___tab: ___e,
			lead___activities: [],
			lead___matches_vehicles: [],
		}, async () => {
			switch (___e)
			{
				case 'overview': await this.read_props___lead___activities(); break;
				case 'matches': await this.read_props___lead___matches(); break;
				case 'contract': this.handle___contract___generate(false); break;
				case 'sell': this.handle___contract___generate(false); break;
			}
		});
	}

	handle___select___lead_state = async (___new_state: type___lead___status, ___new_state___additional: type___request___contract_additionals | null = null) => {
		let ___new_status___text: string = '';
		switch (___new_state) {
			case 'new': ___new_status___text = 'Nuovo'; break;
			case 'working': ___new_status___text = 'In lavorazione'; break;
			case 'ready': ___new_status___text = 'In Consegna'; break;
			case 'sold': ___new_status___text = 'Trattative Concluse (Venduto)'; break;
			case 'archived': ___new_status___text = 'Archiviato'; break;
		}
		let ___new_status___text___message_body: React.ReactNode = <>Stai modificando il lead con "<b>{ ___new_status___text }</b>", sei sicuro?</>;
		if (___new_state === 'sold') {
			___new_status___text___message_body = <>Stai modificando il lead con "<b>{ ___new_status___text }</b>", sei sicuro? <br/><br/> Questa azione influirà sulle statistiche e sul contratto qualora abilitati <br/><br/><b>NON SARA' POSSIBILE PIU' MODIFICARE IL LEAD O IL VEICOLO SUCCESSIVAMENTE ALLA VENDITA</b></>
		}
		if (___new_state === 'working' && this.state.lead___props['lead_status'] === 'sold') {
			___new_status___text___message_body = <>Stai <b>ANNULLANDO LA VENDITA</b> modificando il lead con "<b>{ ___new_status___text }</b>", sei sicuro? <br/><br/> Questa azione influirà sulle statistiche e sul contratto qualora abilitati</>
		}
		const ___alert_props : type___alert___controller = {
			alert___title: "Attenzione",
			alert___message : ___new_status___text___message_body,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si',
					alert___button___action: async (___partner_id : string) => {
						const ___new_state___processed: type___lead___status | 'unsold' = (___new_state === 'working' && this.state.lead___props['lead_status'] === 'sold') ? 'unsold' : ___new_state;
						const ___new_lead_status_updated: type___api___response = await this._API.leads___update___status(this.state.lead___props['lead_id'], ___new_state___processed, ___new_state___additional);
						await this.read_props___lead();
						await this.handle___select___tab('overview');
						if (___new_lead_status_updated['response'] === 'success')
						{
							this._PROPS.alert___setShowing(false);
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Hai modificato lo stato di questo lead in "<b>{ ___new_status___text }</b>"</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore generico nel modificare lo stato del Lead</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

	handle___activity___contact___via_email = async () => {
		
		const ___partner___email: string = this.state.partner___props['partner_email'];

	//	const ___email___subject: string = 'Ti stiamo contattando per il tuo interesse verso "' + this.;
	//	const ___email___subject___encoded: string = encodeURIComponent(___email___subject);

	//	const ___email___body: string = '';
	//	const ___email___body___encoded: string = encodeURIComponent(___email___body);

		const ___email___intent: string = 'mailto:' + ___partner___email; //	+ '?subject=' + ___email___subject___encoded + '&body=' + ___email___body___encoded
		window.location.href = ___email___intent;

	}

	handle___activity___create_new = () => {
		this.setState({
			field___new_activity___datetime___from: this.funcs___datetime.datetime___format___input_datetime_local(new Date()),
			field___new_activity___datetime___to: this.funcs___datetime.datetime___format___input_datetime_local(new Date()),
			GUI___lead___create___new_activity: true,
		});
	}

	handle___activity___create_new___cancel = () => {
		this.setState({
			field___new_activity___datetime___from: this.funcs___datetime.datetime___format___input_datetime_local(new Date()),
			field___new_activity___datetime___to: this.funcs___datetime.datetime___format___input_datetime_local(new Date()),
			GUI___lead___create___new_activity: false,
		});
	}

	handle___lead___match_vehicle___search = (___e: any) => {
		this.setState({
			lead___matches_vehicles___search_param: ___e.target.value
		}, async () => {
			await this.read_props___lead___matches('lead_search');
		})
	}

	handle___lead___match_vehicle = async (___vehicle: any) => {
		const ___alert_props : type___alert___controller = {
			alert___title: "Attenzione",
			alert___message : <>Vuoi abbinare il veicolo "<b>{ ___vehicle['vehicle_name'] }</b>" a questo Lead?</>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si',
					alert___button___action: async () => {
						const ___matched_vehicle: type___api___response = await this._API.leads___match___vehicle(this.state.lead___props['lead_id'], ___vehicle['vehicle_id']);
						if (___matched_vehicle['response'] === 'success')
						{
							await this.read_props___lead();
							await this.handle___select___tab('overview');
							this._PROPS.alert___setShowing(false);
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Hai abbinato il veicolo "<b>{ ___vehicle['vehicle_name'] }</b>" a questo Lead</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore generico nell'abbinamento del Lead</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

	handle___lead___match_vehicle___remove = async () => {
		const ___alert_props : type___alert___controller = {
			alert___title: "Attenzione",
			alert___message : <>Vuoi rimuovere l'abbinamento con il veicolo per questo Lead?</>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si',
					alert___button___action: async () => {
						const ___matched_remove: type___api___response = await this._API.leads___match___vehicle(this.state.lead___props['lead_id'], null);
						if (___matched_remove['response'] === 'success')
						{
							await this.read_props___lead();
							await this.handle___select___tab('overview');
							this._PROPS.alert___setShowing(false);
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Hai abbinato rimosso l'abbinamento di un'auto con questo Lead</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore generico nel rimuovere l'abbinamento del Lead</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

	handle___lead___match_vehicle___remove_and_go = async () => {
		await this._API.leads___match___vehicle(this.state.lead___props['lead_id'], null);
		await this.read_props___lead();
		await this.handle___select___tab('matches');
	}

	handle___lead___delete = async () => {
		const ___alert_props : type___alert___controller = {
			alert___title: "Conferma eliminazione",
			alert___message : <><>Vuoi davvero <b>ELIMINARE</b> questo Lead?</><br/><br/><b style={{textDecoration:'underline'}}>AZIONE IRREVERSIBILE</b></>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si, elimina',
					alert___button___action: async () => {
						const ___lead___delete: type___api___response = await this._API.leads___delete(this.state.lead___id);
						if (___lead___delete['response'] === 'success')
						{
							const ___lead___deleted___url: string = '/leads/list';
							window.location.href = ___lead___deleted___url;
							this._PROPS.alert___setShowing(false);
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Hai rimosso il Lead in questione</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore generico nel rimuovere il Lead</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

	handle___contract___generate = (___has_toast: boolean = false) => {
	
		let ___temp___contract_fields: string[] = [];

		___temp___contract_fields[0] = this.state.me___props['user_name'] + ' ' + this.state.me___props['user_surname'];
		___temp___contract_fields[1] = this.state.partner___props['partner_name'] + ' ' + this.state.partner___props['partner_surname'];
		___temp___contract_fields[2] = this.state.partner___props['partner_born_address']['city'] + ' (' + this.state.partner___props['partner_born_address']['prov'] + ')';
		___temp___contract_fields[3] = this.funcs___datetime.datetime___get___date(this.state.partner___props['partner_born_date']);
		___temp___contract_fields[4] =this.state.partner___props['partner_address']['city'] + ' (' + this.state.partner___props['partner_address']['prov'] + ')';
		___temp___contract_fields[5] =this.state.partner___props['partner_address']['zip'];
		___temp___contract_fields[6] =this.state.partner___props['partner_address']['street'];
		___temp___contract_fields[7] =this.state.partner___props['partner_address']['street_n'];
		___temp___contract_fields[8] =this.state.partner___props['partner_registry_cf'];
		___temp___contract_fields[9] =this.state.partner___props['partner_name_business'];
		___temp___contract_fields[10] = this.state.partner___props['partner_address_business']['city'] + ' (' + this.state.partner___props['partner_address_business']['prov'] + ')';
		___temp___contract_fields[11] = this.state.partner___props['partner_address_business']['zip'];
		___temp___contract_fields[12] = this.state.partner___props['partner_address_business']['street'];
		___temp___contract_fields[13] = this.state.partner___props['partner_address_business']['street_n'];
		___temp___contract_fields[14] = this.state.partner___props['partner_registry_piva'];
		___temp___contract_fields[15] = this.state.partner___props['partner_phone'];
		___temp___contract_fields[16] = this.state.partner___props['partner_email'];
		___temp___contract_fields[17] = this.state.partner___props['partner_email_pec'];
		___temp___contract_fields[18] = 'true';		//		contratto impegno
		___temp___contract_fields[19] = this.state.vehicle___props['vehicle_name'];
		___temp___contract_fields[20] = this.state.vehicle___props['vehicle_registration___vin'];
		___temp___contract_fields[21] = this.state.vehicle___props['vehicle_registration___plate'];
		___temp___contract_fields[22] = this.state.vehicle___props['vehicle_accounting___price_sell'];
		___temp___contract_fields[23] = this.state.vehicle___props['vehicle_accounting___price_sell_vat'];
		___temp___contract_fields[24] = this.state.vehicle___props['vehicle_accounting___price_sell_vat_type'];
		___temp___contract_fields[25] = 'true';		//		condizioni funzionali
		___temp___contract_fields[26] = 'false';	//		condizioni estetiche
		___temp___contract_fields[27] = 'true';		//		contratto condizioni generali vendita
		___temp___contract_fields[28] = '';			//		deposito cauzionale €
		___temp___contract_fields[29] = '';			//		deposito cauzionale cond
		___temp___contract_fields[30] = '';			//		leasing €
		___temp___contract_fields[31] = '';			//		leasing cond
		___temp___contract_fields[32] = '';			//		leasing rate
		___temp___contract_fields[33] = '';			//		leasing imp rata €
		___temp___contract_fields[34] = '';			//		maxirata €
		___temp___contract_fields[35] = '';			//		consegna prezzo €
		___temp___contract_fields[36] = '';			//		consegna cond
		___temp___contract_fields[37] = 'false';	//		contratto pagamento
		___temp___contract_fields[38] = 'false';	//		contratto leasing
		___temp___contract_fields[39] = 'false';	//		contratto finanziamento
		___temp___contract_fields[40] = (this.state.lead___props['lead_exchange'] === true) ? 'true' : 'false'
		___temp___contract_fields[41] = '';			//		permuta auto marca modello
		___temp___contract_fields[42] = '';			//		permuta telaio
		___temp___contract_fields[43] = '';			//		permuta targa
		___temp___contract_fields[44] = '';			//		permuta prezzo
		___temp___contract_fields[45] = '';			//		permuta iva
		___temp___contract_fields[46] = '';			//		permuta iva type
		___temp___contract_fields[47] = '';			//		permuta condizioni 1 titolo
		___temp___contract_fields[48] = '';			//		permuta condizioni 1 valore
		___temp___contract_fields[49] = '';			//		permuta condizioni 1 ammontare danno
		___temp___contract_fields[50] = '';			//		permuta condizioni 2 titolo
		___temp___contract_fields[51] = '';			//		permuta condizioni 2 valore
		___temp___contract_fields[52] = '';			//		permuta condizioni 2 ammontare danno
		___temp___contract_fields[53] = '';			//		permuta condizioni 3 titolo
		___temp___contract_fields[54] = '';			//		permuta condizioni 3 valore
		___temp___contract_fields[55] = '';			//		permuta condizioni 3 ammontare danno
		___temp___contract_fields[56] = '';			//		permuta condizioni 4 titolo
		___temp___contract_fields[57] = '';			//		permuta condizioni 4 valore
		___temp___contract_fields[58] = '';			//		permuta condizioni 4 ammontare danno
		___temp___contract_fields[59] = '';			//		permuta condizioni 5 titolo
		___temp___contract_fields[60] = '';			//		permuta condizioni 5 valore
		___temp___contract_fields[61] = '';			//		permuta condizioni 5 ammontare danno
		___temp___contract_fields[62] = '';			//		consegna data
		___temp___contract_fields[63] = '';			//		opt 1 titolo
		___temp___contract_fields[64] = '';			//		opt 1 price
		___temp___contract_fields[65] = '';			//		opt 2 titolo
		___temp___contract_fields[66] = '';			//		opt 2 price
		___temp___contract_fields[67] = '';			//		opt 3 titolo
		___temp___contract_fields[68] = '';			//		opt 3 price
		___temp___contract_fields[69] = '';			//		opt 4 titolo
		___temp___contract_fields[70] = '';			//		opt 4 price
		___temp___contract_fields[71] = '';			//		opt 5 titolo
		___temp___contract_fields[72] = '';			//		opt 5 price
		___temp___contract_fields[73] = '';			//		opt 6 titolo
		___temp___contract_fields[74] = '';			//		opt 6 price
		___temp___contract_fields[75] = '';			//		opt 7 titolo
		___temp___contract_fields[76] = '';			//		opt 7 price
		___temp___contract_fields[77] = '';			//		opt 8 titolo
		___temp___contract_fields[78] = '';			//		opt 8 price
		___temp___contract_fields[79] = '';			//		opt 9 titolo
		___temp___contract_fields[80] = '';			//		opt 9 price
		___temp___contract_fields[81] = '';			//		signature location
		___temp___contract_fields[82] =	this.funcs___datetime.datetime___get___date(new Date());			//		signature date
		___temp___contract_fields[83] = '';			//		permuta includi direttamente nello stock
		___temp___contract_fields[84] = '';			//		aliquote iva visibili

		if (this.state.lead___props['lead_contract_generated'] === true && this.state.lead___props['lead_contract_fields'].length > 0)
		{
			___temp___contract_fields[0] = this.state.lead___props['lead_contract_fields'][0] ?? this.state.contract___fields[0];
			___temp___contract_fields[1] = this.state.lead___props['lead_contract_fields'][1] ?? this.state.contract___fields[1];
			___temp___contract_fields[2] = this.state.lead___props['lead_contract_fields'][2] ?? this.state.contract___fields[2];
			___temp___contract_fields[3] = this.state.lead___props['lead_contract_fields'][3] ?? this.state.contract___fields[3];
			___temp___contract_fields[4] = this.state.lead___props['lead_contract_fields'][4] ?? this.state.contract___fields[4];
			___temp___contract_fields[5] = this.state.lead___props['lead_contract_fields'][5] ?? this.state.contract___fields[5];
			___temp___contract_fields[6] = this.state.lead___props['lead_contract_fields'][6] ?? this.state.contract___fields[6];
			___temp___contract_fields[7] = this.state.lead___props['lead_contract_fields'][7] ?? this.state.contract___fields[7];
			___temp___contract_fields[8] = this.state.lead___props['lead_contract_fields'][8] ?? this.state.contract___fields[8];
			___temp___contract_fields[9] = this.state.lead___props['lead_contract_fields'][9] ?? this.state.contract___fields[9];
			___temp___contract_fields[10] = this.state.lead___props['lead_contract_fields'][10] ?? this.state.contract___fields[10];
			___temp___contract_fields[11] = this.state.lead___props['lead_contract_fields'][11] ?? this.state.contract___fields[11];
			___temp___contract_fields[12] = this.state.lead___props['lead_contract_fields'][12] ?? this.state.contract___fields[12];
			___temp___contract_fields[13] = this.state.lead___props['lead_contract_fields'][13] ?? this.state.contract___fields[13];
			___temp___contract_fields[14] = this.state.lead___props['lead_contract_fields'][14] ?? this.state.contract___fields[14];
			___temp___contract_fields[15] = this.state.lead___props['lead_contract_fields'][15] ?? this.state.contract___fields[15];
			___temp___contract_fields[16] = this.state.lead___props['lead_contract_fields'][16] ?? this.state.contract___fields[16];
			___temp___contract_fields[17] = this.state.lead___props['lead_contract_fields'][17] ?? this.state.contract___fields[17];
			___temp___contract_fields[18] = this.state.lead___props['lead_contract_fields'][18] ?? this.state.contract___fields[18];
			___temp___contract_fields[19] = this.state.lead___props['lead_contract_fields'][19] ?? this.state.contract___fields[19];
			___temp___contract_fields[20] = this.state.lead___props['lead_contract_fields'][20] ?? this.state.contract___fields[20];
			___temp___contract_fields[21] = this.state.lead___props['lead_contract_fields'][21] ?? this.state.contract___fields[21];
			___temp___contract_fields[22] = this.state.lead___props['lead_contract_fields'][22] ?? this.state.contract___fields[22];
			___temp___contract_fields[23] = this.state.lead___props['lead_contract_fields'][23] ?? this.state.contract___fields[23];
			___temp___contract_fields[24] = this.state.lead___props['lead_contract_fields'][24] ?? this.state.contract___fields[24];
			___temp___contract_fields[25] = this.state.lead___props['lead_contract_fields'][25] ?? this.state.contract___fields[25];
			___temp___contract_fields[26] = this.state.lead___props['lead_contract_fields'][26] ?? this.state.contract___fields[26];
			___temp___contract_fields[27] = this.state.lead___props['lead_contract_fields'][27] ?? this.state.contract___fields[27];
			___temp___contract_fields[28] = this.state.lead___props['lead_contract_fields'][28] ?? this.state.contract___fields[28];
			___temp___contract_fields[29] = this.state.lead___props['lead_contract_fields'][29] ?? this.state.contract___fields[29];
			___temp___contract_fields[30] = this.state.lead___props['lead_contract_fields'][30] ?? this.state.contract___fields[30];
			___temp___contract_fields[31] = this.state.lead___props['lead_contract_fields'][31] ?? this.state.contract___fields[31];
			___temp___contract_fields[32] = this.state.lead___props['lead_contract_fields'][32] ?? this.state.contract___fields[32];
			___temp___contract_fields[33] = this.state.lead___props['lead_contract_fields'][33] ?? this.state.contract___fields[33];
			___temp___contract_fields[34] = this.state.lead___props['lead_contract_fields'][34] ?? this.state.contract___fields[34];
			___temp___contract_fields[35] = this.state.lead___props['lead_contract_fields'][35] ?? this.state.contract___fields[35];
			___temp___contract_fields[36] = this.state.lead___props['lead_contract_fields'][36] ?? this.state.contract___fields[36];
			___temp___contract_fields[37] = this.state.lead___props['lead_contract_fields'][37] ?? this.state.contract___fields[37];
			___temp___contract_fields[38] = this.state.lead___props['lead_contract_fields'][38] ?? this.state.contract___fields[38];
			___temp___contract_fields[39] = this.state.lead___props['lead_contract_fields'][39] ?? this.state.contract___fields[39];
			___temp___contract_fields[40] = this.state.lead___props['lead_contract_fields'][40] ?? this.state.contract___fields[40];
			___temp___contract_fields[41] = this.state.lead___props['lead_contract_fields'][41] ?? this.state.contract___fields[41];
			___temp___contract_fields[42] = this.state.lead___props['lead_contract_fields'][42] ?? this.state.contract___fields[42];
			___temp___contract_fields[43] = this.state.lead___props['lead_contract_fields'][43] ?? this.state.contract___fields[43];
			___temp___contract_fields[44] = this.state.lead___props['lead_contract_fields'][44] ?? this.state.contract___fields[44];
			___temp___contract_fields[45] = this.state.lead___props['lead_contract_fields'][45] ?? this.state.contract___fields[45];
			___temp___contract_fields[46] = this.state.lead___props['lead_contract_fields'][46] ?? this.state.contract___fields[46];
			___temp___contract_fields[47] = this.state.lead___props['lead_contract_fields'][47] ?? this.state.contract___fields[47];
			___temp___contract_fields[48] = this.state.lead___props['lead_contract_fields'][48] ?? this.state.contract___fields[48];
			___temp___contract_fields[49] = this.state.lead___props['lead_contract_fields'][49] ?? this.state.contract___fields[49];
			___temp___contract_fields[50] = this.state.lead___props['lead_contract_fields'][50] ?? this.state.contract___fields[50];
			___temp___contract_fields[51] = this.state.lead___props['lead_contract_fields'][51] ?? this.state.contract___fields[51];
			___temp___contract_fields[52] = this.state.lead___props['lead_contract_fields'][52] ?? this.state.contract___fields[52];
			___temp___contract_fields[53] = this.state.lead___props['lead_contract_fields'][53] ?? this.state.contract___fields[53];
			___temp___contract_fields[54] = this.state.lead___props['lead_contract_fields'][54] ?? this.state.contract___fields[54];
			___temp___contract_fields[55] = this.state.lead___props['lead_contract_fields'][55] ?? this.state.contract___fields[55];
			___temp___contract_fields[56] = this.state.lead___props['lead_contract_fields'][56] ?? this.state.contract___fields[56];
			___temp___contract_fields[57] = this.state.lead___props['lead_contract_fields'][57] ?? this.state.contract___fields[57];
			___temp___contract_fields[58] = this.state.lead___props['lead_contract_fields'][58] ?? this.state.contract___fields[58];
			___temp___contract_fields[59] = this.state.lead___props['lead_contract_fields'][59] ?? this.state.contract___fields[59];
			___temp___contract_fields[60] = this.state.lead___props['lead_contract_fields'][60] ?? this.state.contract___fields[60];
			___temp___contract_fields[61] = this.state.lead___props['lead_contract_fields'][61] ?? this.state.contract___fields[61];
			___temp___contract_fields[62] = this.state.lead___props['lead_contract_fields'][62] ?? this.state.contract___fields[62];
			___temp___contract_fields[63] = this.state.lead___props['lead_contract_fields'][63] ?? this.state.contract___fields[63];
			___temp___contract_fields[64] = this.state.lead___props['lead_contract_fields'][64] ?? this.state.contract___fields[64];
			___temp___contract_fields[65] = this.state.lead___props['lead_contract_fields'][65] ?? this.state.contract___fields[65];
			___temp___contract_fields[66] = this.state.lead___props['lead_contract_fields'][66] ?? this.state.contract___fields[66];
			___temp___contract_fields[67] = this.state.lead___props['lead_contract_fields'][67] ?? this.state.contract___fields[67];
			___temp___contract_fields[68] = this.state.lead___props['lead_contract_fields'][68] ?? this.state.contract___fields[68];
			___temp___contract_fields[69] = this.state.lead___props['lead_contract_fields'][69] ?? this.state.contract___fields[69];
			___temp___contract_fields[70] = this.state.lead___props['lead_contract_fields'][70] ?? this.state.contract___fields[70];
			___temp___contract_fields[71] = this.state.lead___props['lead_contract_fields'][71] ?? this.state.contract___fields[71];
			___temp___contract_fields[72] = this.state.lead___props['lead_contract_fields'][72] ?? this.state.contract___fields[72];
			___temp___contract_fields[73] = this.state.lead___props['lead_contract_fields'][73] ?? this.state.contract___fields[73];
			___temp___contract_fields[74] = this.state.lead___props['lead_contract_fields'][74] ?? this.state.contract___fields[74];
			___temp___contract_fields[75] = this.state.lead___props['lead_contract_fields'][75] ?? this.state.contract___fields[75];
			___temp___contract_fields[76] = this.state.lead___props['lead_contract_fields'][76] ?? this.state.contract___fields[76];
			___temp___contract_fields[77] = this.state.lead___props['lead_contract_fields'][77] ?? this.state.contract___fields[77];
			___temp___contract_fields[78] = this.state.lead___props['lead_contract_fields'][78] ?? this.state.contract___fields[78];
			___temp___contract_fields[79] = this.state.lead___props['lead_contract_fields'][79] ?? this.state.contract___fields[79];
			___temp___contract_fields[80] = this.state.lead___props['lead_contract_fields'][80] ?? this.state.contract___fields[80];
			___temp___contract_fields[81] = this.state.lead___props['lead_contract_fields'][81] ?? this.state.contract___fields[81];
			___temp___contract_fields[82] = this.state.lead___props['lead_contract_fields'][82] ?? this.state.contract___fields[82];
			___temp___contract_fields[83] = this.state.lead___props['lead_contract_fields'][83] ?? this.state.contract___fields[83];
			___temp___contract_fields[84] = this.state.lead___props['lead_contract_fields'][84] ?? this.state.contract___fields[84];
		}

		for (let ___cf___idx = 0; ___cf___idx < ___temp___contract_fields.length; ___cf___idx++)
		{
			___temp___contract_fields[___cf___idx] = (___temp___contract_fields[___cf___idx] !== null) ? ___temp___contract_fields[___cf___idx] : '';
		}

		this.setState({
			contract___fields: ___temp___contract_fields,
			contract___generated: true,
		}, () => {
			if (___has_toast === true)
			{
				const ___toast_props: type___toast___controller = {
					toast___color: 'success',
					toast___text: <>Contratto caricato con successo...</>
				}
				this._PROPS.toast___setContent(___toast_props);
				this._PROPS.toast___setShowing(true);
			}
		});

	}

	handle___contract___field_update = (___index_to_change: number, ___new_value: string) => {
		this.setState((prevState: state___SbLeadsViewPage) => ({
			contract___fields: prevState.contract___fields.map((___item: string, ___i: number) => ___i === ___index_to_change ? ___new_value ?? '' : ___item ?? ''),
		}));
	}

	handle___contract___preview = async (___have_to_save: boolean = false) => {
		if (___have_to_save === true)
		{
			await this.handle___contract___save();
		}
		this.setState({
			GUI___lead___contract___preview: true
		});
	}

	handle___contract___save = async (___is_toasted: boolean = true) => {
		const ___is_saved: type___api___response = await this._API.leads___submit___contract(this.state.lead___id, this.state.contract___fields);
		if (___is_saved['response'] === 'success')
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'success',
				toast___text: <>Bozza salvata con successo...</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}
		else
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'danger',
				toast___text: <>Errore nel salvataggio della bozza...</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		if (this._URL_PARAMS.id)
		{
			this.setState({
				GUI___page___is_loading: true,
				lead___id: this._URL_PARAMS.id
			}, async () => {
				await this.read_props___me();
				await this.read_props___lead()
					.then(() => {
						this.setState({
							GUI___page___is_loading: false,
							GUI___page___is_loading___section: false
						});

							//	begin testing --->

							//			this.setState({ GUI___lead___contract___preview: true });
							//			this.handle___select___tab('contract');
							//			this.handle___contract___preview();

							//	end testing 

				});
			});
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		const ___menu_disabled___item___overview: boolean = false;
		const ___menu_disabled___item___matches: boolean = (this.state.lead___props !== null) ? this.state.lead___props['lead_vehicle'] !== null || this.state.lead___props['lead_status'] === 'sold' : true;
		const ___menu_disabled___item___contract: boolean = (this.state.lead___props !== null) ? this.state.lead___props['lead_vehicle'] === null : true;
		const ___menu_disabled___item___sell: boolean = (this.state.lead___props !== null) ? this.state.lead___props['lead_vehicle'] === null : true;

		const ___optional___01: number = this.funcs___numbers.return___numeric_value(this.state.contract___fields[64]);
		const ___optional___02: number = this.funcs___numbers.return___numeric_value(this.state.contract___fields[66]);
		const ___optional___03: number = this.funcs___numbers.return___numeric_value(this.state.contract___fields[68]);
		const ___optional___04: number = this.funcs___numbers.return___numeric_value(this.state.contract___fields[70]);
		const ___optional___05: number = this.funcs___numbers.return___numeric_value(this.state.contract___fields[72]);
		const ___optional___06: number = this.funcs___numbers.return___numeric_value(this.state.contract___fields[74]);
		const ___optional___07: number = this.funcs___numbers.return___numeric_value(this.state.contract___fields[76]);
		const ___optional___08: number = this.funcs___numbers.return___numeric_value(this.state.contract___fields[78]);
		const ___optional___09: number = this.funcs___numbers.return___numeric_value(this.state.contract___fields[80]);
		const ___optional___total: number = ___optional___01 + ___optional___02 + ___optional___03 + ___optional___04 + ___optional___05 + ___optional___06 + ___optional___07 + ___optional___08 + ___optional___09;

		const ___total_price___vehicle: number = this.funcs___numbers.return___numeric_value(this.state.contract___fields[22]);
		const ___total_price___optionals: number = ___optional___total;
		const ___total_price___exchange: number = this.funcs___numbers.return___numeric_value(this.state.contract___fields[44]);
		const ___total_price___exchange___minus: number = this.funcs___numbers.return___numeric_value(this.state.contract___fields[49]) + this.funcs___numbers.return___numeric_value(this.state.contract___fields[52]) + this.funcs___numbers.return___numeric_value(this.state.contract___fields[55]) + this.funcs___numbers.return___numeric_value(this.state.contract___fields[58]) + this.funcs___numbers.return___numeric_value(this.state.contract___fields[61]);
		const ___total_price___total: number = ___total_price___vehicle + ___total_price___optionals - ___total_price___exchange;

		let ___financing_price___total: number = ___total_price___total;
		if (!(isNaN(parseFloat(this.state.contract___fields[28])))) { ___financing_price___total = ___financing_price___total - parseFloat(this.state.contract___fields[28]); }
		if (!(isNaN(parseFloat(this.state.contract___fields[30])))) { ___financing_price___total = ___financing_price___total - parseFloat(this.state.contract___fields[30]); }
		if (!(isNaN(parseFloat(this.state.contract___fields[35])))) { ___financing_price___total = ___financing_price___total - parseFloat(this.state.contract___fields[35]); }
		if (!(isNaN(parseFloat(this.state.contract___fields[49])))) { ___financing_price___total = ___financing_price___total - parseFloat(this.state.contract___fields[49]); }
		if (!(isNaN(parseFloat(this.state.contract___fields[52])))) { ___financing_price___total = ___financing_price___total - parseFloat(this.state.contract___fields[52]); }
		if (!(isNaN(parseFloat(this.state.contract___fields[55])))) { ___financing_price___total = ___financing_price___total - parseFloat(this.state.contract___fields[55]); }
		if (!(isNaN(parseFloat(this.state.contract___fields[58])))) { ___financing_price___total = ___financing_price___total - parseFloat(this.state.contract___fields[58]); }
		if (!(isNaN(parseFloat(this.state.contract___fields[61])))) { ___financing_price___total = ___financing_price___total - parseFloat(this.state.contract___fields[61]); }
		
		const ___temp___contract___selling___additionals: type___request___contract_additionals = {
			exchange: (this.state.contract___fields[40] === 'true') ? true : false,
			exchange_import: (this.state.contract___fields[40] === 'true' && this.state.contract___fields[83] === 'true') ? true : false,
			exchange_price: ___total_price___exchange - ___total_price___exchange___minus,
			sell_price_total: ___total_price___total,
		};

		return <>
			{(this.state.GUI___lead___contract___preview === true && this.state.partner___props !== null) ? <>
				<MdContractPreviewModal contract___for_business={ this.state.partner___props['partner_type'] === 'b' } contract___fields={ this.state.contract___fields } vehicle___props={ this.state.vehicle___props } event___onDismiss={() => { this.setState({ GUI___lead___contract___preview: false })}}/>
			</> : <></>}
			{(this.state.GUI___page___is_loading === false && this.state.lead___props !== null) ? <>
				<div className="page---container is-full-width is-flexy leads---left-bar---container ">
					<section>
						<div className="is-card">
							<h3 className="is-title has-icon"><span>Visualizzazione Lead</span></h3>
							<section className="is-content">
								<div className="input---nb-dropdown">
									{(() => {
										switch (this.state.lead___props['lead_status'] as type___lead___status) {
											case 'new': return <>
												<div>
													<span>Nuovo Lead</span>
													<i className="fas fa-angle-down"></i>
												</div>
											</>; break;
											case 'working': return <>
												<div>
													<span>In Lavorazione</span>
													<i className="fas fa-angle-down"></i>
												</div>
											</>; break;
											case 'ready': return <>
												<div>
													<span>In Consegna</span>
													<i className="fas fa-angle-down"></i>
												</div>
											</>; break;
											case 'sold': return <>
												<div className="lead---is-sold">
													<span>Vendita Effettuata</span>
													<i className="fas fa-angle-down"></i>
												</div>
											</>; break;
											case 'archived': return <>
												<div className="lead---is-archived">
													<span>Archiviato</span>
													<i className="fas fa-angle-down"></i>
												</div>
											</>; break;
										}
									})()}
									<div className="is-card">
										{(() => {
											switch (this.state.lead___props['lead_status'] as type___lead___status) {
												case 'new': return <>
													{(this.state.vehicle___props !== null && this.state.vehicle___props['vehicle_status_sold'] === false) ? <>
														<span onClick={async () => { await this.handle___select___lead_state('working'); }}>assegna - <span>In lavorazione</span></span>
														<span onClick={async () => { await this.handle___select___lead_state('ready'); }} >pronto - <span>In Consegna</span></span>
														{(this.state.lead___props['lead_vehicle'] !== null) ? <><span onClick={async () => { await this.handle___select___lead_state('sold'); }} style={{color:'var(--color-success'}}><span>Trattative Concluse (Venduto)</span></span></> : <></>}
													</> : <></>}
													<span onClick={async () => { await this.handle___select___lead_state('archived'); }} style={{color:'var(--color-danger'}}><span>Archivia</span></span>
												</>; break;
												case 'working': return <>
													{(this.state.vehicle___props !== null && this.state.vehicle___props['vehicle_status_sold'] === false) ? <>
														<span onClick={async () => { await this.handle___select___lead_state('ready'); }} >pronto - <span>In Consegna</span></span>
														{(this.state.lead___props['lead_vehicle'] !== null) ? <><span onClick={async () => { await this.handle___select___lead_state('sold'); }} style={{color:'var(--color-success'}}><span>Trattative Concluse (Venduto)</span></span></> : <></>}
														</> : <></>}
													<span onClick={async () => { await this.handle___select___lead_state('archived'); }} style={{color:'var(--color-danger'}}><span>Archivia</span></span>
												</>; break;
												case 'ready': return <>
													{(this.state.vehicle___props !== null && this.state.vehicle___props['vehicle_status_sold'] === false) ? <>
														<span onClick={async () => { await this.handle___select___lead_state('working'); }}>assegna - <span>In lavorazione</span></span>
														{(this.state.lead___props['lead_vehicle'] !== null) ? <><span onClick={async () => { await this.handle___select___lead_state('sold'); }} style={{color:'var(--color-success'}}><span>Trattative Concluse (Venduto)</span></span></> : <></>}
														</> : <></>}
													<span onClick={async () => { await this.handle___select___lead_state('archived'); }} style={{color:'var(--color-danger'}}><span>Archivia</span></span>
												</>; break;
												case 'sold': return <>
													<span onClick={async () => { await this.handle___select___lead_state('working'); }}><span style={{color:'var(--color-danger'}}>Annulla Vendita</span></span>
												</>; break;
												case 'archived': return <>
													<span onClick={async () => { await this.handle___select___lead_state('working'); }}><span style={{color:'var(--color-danger'}}>Annulla Archiviazione</span></span>
												</>; break;
											}
										})()}
									</div>
								</div>
								<br />
								<div className="leads-single---vehicle-gallery---preview">
									{(this.state.vehicle___props !== null && this.state.vehicle___props['vehicle_gallery_pictures'].length > 0) ? <>
										<section>
											<img src={ lib_names.cdn.endpoint + this.state.vehicle___props['vehicle_gallery_pictures'][0] }/>
											{(this.state.lead___props['lead_status'] !== 'sold' && this.state.vehicle___props['vehicle_status_sold'] === true) ? <>
												<span>Veicolo non disponibile</span>
											</> : <></>}
										</section>
										<section>
											{(this.state.vehicle___props['vehicle_gallery_pictures'][1]) ? <><img src={ lib_names.cdn.endpoint + this.state.vehicle___props['vehicle_gallery_pictures'][1] }/></> : <><img style={{opacity:'0'}} /></>}
											{(this.state.vehicle___props['vehicle_gallery_pictures'][2]) ? <><img src={ lib_names.cdn.endpoint + this.state.vehicle___props['vehicle_gallery_pictures'][2] }/></> : <><img style={{opacity:'0'}} /></>}
											{(this.state.vehicle___props['vehicle_gallery_pictures'][3]) ? <><img src={ lib_names.cdn.endpoint + this.state.vehicle___props['vehicle_gallery_pictures'][3] }/></> : <><img style={{opacity:'0'}} /></>}
											{(this.state.vehicle___props['vehicle_gallery_pictures'][4]) ? <><img src={ lib_names.cdn.endpoint + this.state.vehicle___props['vehicle_gallery_pictures'][4] }/></> : <><img style={{opacity:'0'}} /></>}
										</section>
									</> : <>
										<img src={ lib_names.media.placeholders.vehicle.generic }/>
									</>}
								</div>
								<br />
								{(this.state.lead___props !== null) ? <>
									<Link className="partner-single-hint---container" to={ '/partners/view/' + this.state.lead___props['lead_partner']['partner_id'] }>
										<ComPartnerComponent partner___props={ this.state.partner___props }/>
									</Link>
								</> : <></>}
								<br />
								<br />
								{(this.state.lead___props['lead_vehicle'] === null) ? <>
									<section className="lead---interests---container">
										{(this.state.lead___props['lead_vehicles_brands']) ? <>{ this.state.lead___props['lead_vehicles_brands'].map((___b: any) => { return <span>{ ___b['vehicle_brand_name'] }</span>; })}</> : <></> }
										{(this.state.lead___props['lead_vehicles_models']) ? <>{ this.state.lead___props['lead_vehicles_models'].map((___m: any) => { return <span>{ ___m['car_brand'] + ' ' + ___m['car_model'] }</span>; })}</> : <></> }
									</section>
								</> : <></>}
							</section>

							<br />

							{(this.state.lead___props['lead_status'] !== 'sold') ? <>
								<div className="page-container---bottom-bar---buttons">
									<button className="no-shadow is-cancel" onClick={ this.handle___lead___delete }>
										<i className="fas fa-trash"></i>
										<span>Elimina Lead</span>
									</button>
								</div>
							</> : <></>}

						</div>
						<div>
							<div className="page---top-bar">
								<section>
									<input type="radio" id="d-ts---csec---overview" name="d-ts---cb" value="overview" checked={ this.state.selected___lead_view___tab === 'overview' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }} disabled={ ___menu_disabled___item___overview }/>
									<label htmlFor="d-ts---csec---overview" className="is-card">
										<img src="/assets/ui/icons/icon-overview.svg" />
										<span>Panoramica</span>
									</label>
									<input type="radio" id="d-ts---csec---matches" name="d-ts---cb" value="matches" checked={ this.state.selected___lead_view___tab === 'matches' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }} disabled={ ___menu_disabled___item___matches }/>
									<label htmlFor="d-ts---csec---matches" className={ 'is-card ' + ((___menu_disabled___item___matches === true) ? ' is-disabled ' : ' ') } >
										<img src="/assets/ui/icons/icon-car-match.svg" />
										<span>Car Matches</span>
									</label>
									{(this.props.props___licence['licence_active___contracts'] === true) ? <>
										<input type="radio" id="d-ts---csec---contract" name="d-ts---cb" value="contract" checked={ this.state.selected___lead_view___tab === 'contract' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }} disabled={ ___menu_disabled___item___contract }/>
										<label htmlFor="d-ts---csec---contract" className={ 'is-card ' + ((___menu_disabled___item___contract === true) ? ' is-disabled ' : ' ') } >
											<img src="/assets/ui/icons/icon-contract.svg" />
											<span>Contratto</span>
										</label>
									</> : <></>}
									<input type="radio" id="d-ts---csec---sell" name="d-ts---cb" value="sell" checked={ this.state.selected___lead_view___tab === 'sell' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }} disabled={ ___menu_disabled___item___sell }/>
									<label htmlFor="d-ts---csec---sell" className={ 'is-card ' + ((___menu_disabled___item___sell === true) ? ' is-disabled ' : ' ') } >
										<img src="/assets/ui/icons/icon-sale.svg" />
										<span>Vendita</span>
									</label>
								</section>
							</div>
							{(this.state.partner___props !== null) ? <>
								{(() => {
									switch (this.state.selected___lead_view___tab) {
										case 'overview': return <>
											{(this.state.GUI___page___is_loading___section === false) ? <>
												<div className="is-card lead---overview---container">
													{(this.state.lead___props['lead_vehicle'] !== null && this.state.vehicle___props !== null) ? <>
														<h3 className="is-title">Auto scelta dal cliente</h3>
														<br />
														<div>
															<section className="is-content">
																{(this.state.vehicle___props !== null) ? <>
																	<section className="lead---chosen-car---container">
																		{(this.state.vehicle___props['vehicle_status_sold'] === true) ? <>
																			{(this.state.lead___props['lead_id'] === this.state.vehicle___props['vehicle_status_sold_lead']) ? <>
																				<div className="lead---chosen-car---container---warning warning---is-mega-success">
																					<span>Veicolo Venduto con questo Lead</span>
																					<span></span>
																				</div>
																			</> : <>
																				<div className="lead---chosen-car---container---warning warning---is-mega-danger">
																					<span>Veicolo già Venduto</span>
																					<Link to={ '/leads/view/' + this.state.vehicle___props['vehicle_status_sold_lead'] }>Visualizza</Link>
																				</div>
																			</>}
																		</> : <>
																			{(this.state.vehicle___props['vehicle_leads'] && this.state.vehicle___props['vehicle_leads'].length > 1) ? <>
																				{(this.state.vehicle___props['vehicle_leads'].length === 2) ? <>
																					<div className="lead---chosen-car---container---warning">
																						<span>Attenzione, veicolo abbinato ad un altro Lead</span>
																						{this.state.vehicle___props['vehicle_leads'].map((___l: any) => {
																							return <>
																								{(___l['_id'] !== this.state.lead___props['lead_id']) ? <>
																									<Link to={ '/leads/view/' + this.state.vehicle___props['vehicle_leads'][0]['_id'] }>Visualizza</Link>
																								</> : <></>}
																							</>
																						})}
																					</div>	
																				</> : <>
																					<div className="lead---chosen-car---container---warning">
																						<span>Attenzione</span>
																						<span>Veicolo abbinato ad altri Leads</span>
																					</div>
																				</>}
																			</> : <></>}
																		</>}
																		<ComVehicleComponent vehicle___props={ this.state.vehicle___props } option___description={ true } option___card={ false } option___buttons={[<>
																			{(this.state.lead___props['lead_status'] !== 'sold' && this.state.lead___props['lead_partner_interest'] !== 'stock_vehicle') ? <>
																				<div onClick={() => { this.handle___lead___match_vehicle___remove(); }}>
																					<span>Rimuovi abbinamento</span>
																					<i className="fas fa-unlink"></i>
																				</div>
																			</> : <></>}
																		</>]} option___redirect={ 'redirect' }/>
																	</section>
																</> : <>
																	<ComSpinnerComponent/>
																</>}
															</section>
														</div>
													</> : <>
														{(this.state.lead___props['lead_partner_interest'] === 'stock_vehicle' && !(this.state.vehicle___props)) ? <>
															<section className="lead-car-chosen---not-available">
																<h3 className="is-title">Scelta del cliente non disponibile</h3>
																<br />
																<div>
																	<section className="is-content">
																		<p>La scelta del cliente non è disponibile poiché il veicolo a cui era abbinato è stato eliminato</p>
																		<section>
																			<h6 onClick={() => { this.handle___lead___match_vehicle___remove_and_go(); }}>
																				<span>Vai al Car Match</span>
																				<i className="fas fa-chevron-right"></i>
																			</h6>
																		</section>
																	</section>
																</div>
															</section>
															<br />
														</> : <></>}
													</>}
													<h3 className="is-title">Informazioni</h3>
													<br />
													<div>
														<section className="input---nb-richtext">
															<span>Il cliente ha contattato il nosto punto vendita il giorno </span>
															<b>{ this.funcs___datetime.datetime___get___date(this.state.lead___props['lead_created_datetime']) }</b>
															<span> alle ore </span>
															<b>{ this.funcs___datetime.datetime___get___time(this.state.lead___props['lead_created_datetime']) }</b>
															{(this.state.lead___props['lead_marketing_source']) ? <>
																<span> e proviene da </span>
																<b>
																	{(() => {
																		switch (this.state.lead___props['lead_marketing_source'] as type___lead___source) {
																			case '': return <></>; break;
																			case 'partner_already': return <>un passato come Già Cliente</>; break;
																			case 'partner_wordofmouth': return <>Passa Parola</>; break;
																			case 'external_marketplace_autoscout24': return <>un annuncio trovato su AutoScout24</>; break;
																			case 'external_marketplace_subitoit': return <>un annuncio trovato su Subito.it</>; break;
																			case 'external_paper': return <>pubblicità mediante Volantini o simili (cartacei)</>; break;
																			case 'external_social_facebook': return <>inserzione Facebook</>; break;
																			case 'external_social_instagram': return <>inserzione Instagram</>; break;
																			case 'external_website': return <>visita al Sito Web</>; break;
																			case 'other': return <></>; break;
																		}
																	})()}
																</b>
															</> : <></>}
														</section>
														<section className="input---nb-richtext">
															{(() => {
																switch (this.state.lead___props['lead_partner_interest'] as type___lead___interest___to) {
																	case 'brands':
																	case 'models': return <>
																		<span>Cliente interessato ad un veicolo </span>
																		{(this.state.lead___props['lead_price_from'] || this.state.lead___props['lead_price_to']) ? <>
																			<span> con prezzo </span>
																			{(this.state.lead___props['lead_price_from']) ? <>
																				<span> a partire da </span>
																				<b>{ this.funcs___numbers.format___price(this.state.lead___props['lead_price_from']) }</b>
																			</> : <></>}
																			{(this.state.lead___props['lead_price_to']) ? <>
																				<span> fino a </span>
																				<b>{ this.funcs___numbers.format___price(this.state.lead___props['lead_price_to']) }</b>
																			</> : <></>}
																		</> : <></>}
																		{(this.state.lead___props['lead_year_from'] || this.state.lead___props['lead_year_to']) ? <>
																			<span> ed anno di produzione </span>
																			{(this.state.lead___props['lead_year_from']) ? <>
																				<span> a partire dal </span>
																				<b>{ this.state.lead___props['lead_year_from'] }</b>
																			</> : <></>}
																			{(this.state.lead___props['lead_year_to']) ? <>
																				<span> fino al </span>
																				<b>{ this.state.lead___props['lead_year_to'] }</b>
																			</> : <></>}
																		</> : <></>}
																		{(() => {
																			switch (this.state.lead___props['lead_partner_interest'] as type___lead___interest___to) {
																				case 'brands': return <>
																					<br />
																					<br />
																					<span>Brand di interesse </span>
																					{(this.state.lead___props['lead_vehicles_brands'].length > 0) ? <>
																						{this.state.lead___props['lead_vehicles_brands'].map((___b: any, ___b_i: number) => {
																							const ___b___separator: string = (this.state.lead___props['lead_vehicles_brands'].length > (___b_i + 1)) ? ' , ' : ' ';
																							return <><b>{ ___b['vehicle_brand_name'] }</b>{ ___b___separator }</>;
																						})}
																					</> : <></>}
																				</>; break;
																				case 'models': return <>
																					<br />
																					<br />
																					<span>Modelli di interesse </span>
																					{(this.state.lead___props['lead_vehicles_models'].length > 0) ? <>
																						{this.state.lead___props['lead_vehicles_models'].map((___m: any, ___m_i: number) => {
																							const ___m___separator: string = (this.state.lead___props['lead_vehicles_models'].length > (___m_i + 1)) ? ' , ' : ' ';
																							return <><b>{ ___m['car_brand'] + ' ' + ___m['car_model'] }</b>{ ___m___separator }</>;
																						})}
																					</> : <></>}
																				</>; break;
																			}
																		})()}
																	</>; break;
																	case 'stock_vehicle': return <>
																		<span>Il cliente è interessato al veicolo</span>
																		<b>{ (this.state.vehicle___props && this.state.vehicle___props['vehicle_name']) ? this.state.vehicle___props['vehicle_name'] : 'sconosciuto' }</b>
																	</>; break;	
																	case 'custom': return <>
																		<span>Il cliente è interessato a</span>
																		{(this.state.lead___props['lead_vehicle_requested'] && this.state.lead___props['lead_vehicle_requested'].length > 0) ? <>
																			<b>{ this.state.lead___props['lead_vehicle_requested'] }</b>
																		</> : <>
																			<b>{ this.state.lead___props['lead_notes'].toString().split('http')[0] }</b>
																		</>}
																	</>; break;
																}
															})()}
														</section>
													</div>
													<h3 className="is-title">Attività</h3>
													<br />
													<div>
														<section className="is-content">
															<section className="lead---activity---container">
																<ComLeadActivityComponent activity___props={{
																	activity_status: 'done',
																	activity_type: 'first_contact',
																	activity_datetime_from: this.state.lead___props['lead_created_datetime'], 
																	activity_datetime_to: null,
																	activity_user_name: null,
																	activity_notes: this.state.lead___props['lead_notes']
																}}/>
																{this.state.lead___activities.map((___a: any) => { return <>
																	<ComLeadActivityComponent activity___props={ ___a }/>
																</> })}
															</section>
															{(this.state.lead___props['lead_status'] as type___lead___status !== 'sold') ? <>
																{(() => {
																	switch (this.state.GUI___lead___create___new_activity) {
																		case true: return <>
																			<section className="new-activity---container">
																				<div>
																					<i className="fas fa-plus"></i>
																					<span>Nuova attività</span>
																				</div>
																				<div>
																					<br />
																					<section className="input---nb-outlined---flexy">
																						<select value={ this.state.field___new_activity___type as type___activity___type } onChange={(___e: any) => { this.setState({ field___new_activity___type: ___e['target']['value'] }, () => { this.submit___activity___check_if_can(); })}}>
																							<option value={'appointment'}>Appuntamento Generico</option>
																							<option value={'call'}>Chiamata o Videochiamata</option>
																							<option value={'test_drive'}>Test Drive</option>
																						</select>
																						<p>dalle</p>
																						<input type="datetime-local" value={ this.state.field___new_activity___datetime___from } style={{width:'50%'}} onInput={(___e: any) => { this.setState({ field___new_activity___datetime___from: this.funcs___datetime.datetime___format___input_datetime_local(new Date(___e.target.value)) }); }}/>
																						<p>alle</p>
																						<input type="datetime-local" value={ this.state.field___new_activity___datetime___to } style={{width:'50%'}} onInput={(___e: any) => { this.setState({ field___new_activity___datetime___to: this.funcs___datetime.datetime___format___input_datetime_local(new Date(___e.target.value)) }); }}/>
																					</section>
																					<br />
																					<textarea value={ this.state.field___new_activity___note } onInput={(___e: any)=>{this.setState({field___new_activity___note:___e.target.value})}} className={'input---nb-outlined is-textarea'} placeholder="Informazioni aggiuntive o note" style={{height:'100pt'}}></textarea>
																					<br />
																					<div className="page-container---bottom-bar---buttons">
																						<button type="button" className="is-card no-shadow" onClick={() => { this.handle___activity___create_new___cancel(); }}>
																							<i className="fas fa-times"></i>
																							<span>Annulla</span>
																						</button>
																						<button type="button" className="is-card is-submit no-shadow" onClick={() => { this.submit___activity(); }}>
																							<i className="fas fa-check"></i>
																							<span>Salva Attività</span>
																						</button>
																					</div>
																				</div>
																			</section>
																		</>; break;
																		case false: return <>
																			<br />
																			<br />
																			<br />
																			<br />
																			<br />
																			<div className="page-container---bottom-bar---buttons is-centered">
																				{(this.state.partner___props['partner_email'] && this.state.partner___props['partner_email'].length > 0) ? <>
																					<button type="button" className="is-card is-hovered no-shadow" onClick={() => { this.handle___activity___contact___via_email(); }}>
																						<i className="fas fa-envelope"></i>
																						<span>Contatta via Mail</span>
																					</button>
																				</> : <></>}
																				<button type="button" className="is-card is-hovered no-shadow" onClick={() => { this.handle___activity___create_new(); }}>
																					<i className="fas fa-plus"></i>
																					<span>Aggiungi nuova attività</span>
																				</button>
																			</div>
																			<br />
																			<br />
																			<br />
																		</>; break;
																	}
																})()}
															</> : <>
																<br />
																<br />
																<br />
															</>}
														</section>
													</div>
												</div>
											</> : <>
												<ComSpinnerComponent/>
											</>}
										</>; break;
										case 'matches': return <>
											<div className="leads---car-matches---container">
												<div className="page---top-bar" style={{paddingRight:'0'}}>
													<section></section>
													<section>
														<label className="page---top-bar---search-box is-card">
															<i className="fas fa-search"></i>
															<input type="text" placeholder="Cerca veicolo da abbinare..." value={ this.state.lead___matches_vehicles___search_param } onInput={ this.handle___lead___match_vehicle___search } />
														</label>
													</section>
												</div>
												{(this.state.GUI___page___is_loading___section === false) ? <>
													<div className="is-card">
														{(this.state.lead___matches_vehicles.length > 0) ? <>
															<div className="container---table container---table---lead-vehicles">
																<div>
																	<div></div>
																	<div>
																		<i className="fas fa-image" style={{opacity:'1'}}></i>
																	</div>
																	<div>
																		<span>Marca, Modello, Versione</span>
																		<span>•</span>
																	</div>
																	<div>
																		<span>Prezzo</span>
																		<span>•</span>
																	</div>
																	<div></div>
																</div>
																{this.state.lead___matches_vehicles.map((___v: any, ___i: number) => <>
																	<div>
																		<div>
																			<Link target="_blank" to={`/vehicles/view/${___v['vehicle_id']}`}><i className="fas fa-eye"></i></Link>
																		</div>
																		<div>
																			{(___v['vehicle_gallery_pictures'].length > 0) ? <>
																				<img src={ lib_names.cdn.endpoint + ___v['vehicle_gallery_pictures'][0] }/>
																			</>: <>
																				<img src={ lib_names.media.placeholders.vehicle.generic }/>
																			</>}
																		</div>
																		<div><b>{ ___v['vehicle_name'] }</b></div>
																		<div>{this.funcs___numbers.format___price(___v['vehicle_accounting___price_sell'])}</div>
																		<div>
																			<div className="is-card is-button is-submit no-shadow" onClick={() => { this.handle___lead___match_vehicle(___v); }}>
																				<i className="fas fa-link"></i>
																				<span>Abbina a questo Lead</span>
																			</div>
																		</div>
																	</div>
																</>)}
															</div>
														</> : <>
															{ <ComResultsEmptyComponent text="Nessun veicolo compatibile..."/> }
														</>}
													</div>
												</> : <>
													<ComSpinnerComponent/>
												</>}
											</div>
										</>; break;
										case 'contract': return <>
											{(this.state.GUI___page___is_loading___section === false) ? <>
												<div className="contract---container---generator is-card">
													{(this.state.lead___props['lead_status'] === 'sold') ? <>
														<br />
														<br />
														<br />
														<div className="page-container---bottom-bar---buttons is-centered">
															<button className="is-card is-button is-submit is-hovered no-shadow" onClick={() => { this.handle___contract___preview(false) }}>
																<i className="far fa-file-alt"></i>
																<span>Visualizza Contratto di Vendita e stampa</span>
															</button>
														</div>
														<br />
														<br />
														<br />
													</> : <>
														{(this.state.contract___generated === true && this.state.contract___fields.length > 0) ? <>
															<section className="contract---container---writer">

																<br />

																<h3>Contratto di Vendita</h3>
																
																<br />
																<br />

																<span>Luogo </span>
																<input style={{width:'150pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[81] } onInput={(___e: any) => { this.handle___contract___field_update(81, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>, il giorno (data) </span>
																<input style={{width:'100pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[82] } onInput={(___e: any) => { this.handle___contract___field_update(82, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>

																<br />
																<br />
																<br />

																<h3>Opzioni</h3>

																<br />
																<br />

																<div className="input---nb---text-page---table">
																	<div>
																		<div><span style={{display:'none'}}></span><span>Visualizza aliquote Iva</span></div>
																		<div style={{width:'400pt'}}><span> </span><select value={ this.state.contract___fields[84] } onChange={(___e: any) => { this.handle___contract___field_update(84, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}><option value="true">VISUALIZZA</option><option value="false">NASCONDI</option></select></div>
																	</div>
																</div>

																<br />
																<br />
																<br />
														
																<h5>Dati cliente</h5>
																
																<br />

																<span>il sottoscritto</span>
																<input style={{width:'300pt'}} type="text" placeholder="nome e cognome" value={ this.state.contract___fields[1] } onInput={(___e: any) => { this.handle___contract___field_update(1, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>nato a</span>
																<input style={{width:'300pt'}} type="text" placeholder="luogo di nascita" value={ this.state.contract___fields[2] } onInput={(___e: any) => { this.handle___contract___field_update(2, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>il</span>
																<input style={{width:'100pt'}} type="text" placeholder="data di nascita" value={ this.state.contract___fields[3] } onInput={(___e: any) => { this.handle___contract___field_update(3, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>e residente nella città di</span>
																<input style={{width:'300pt'}} type="text" placeholder="città" value={ this.state.contract___fields[4] } onInput={(___e: any) => { this.handle___contract___field_update(4, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>(cap</span>
																<input style={{width:'80pt'}} type="text" placeholder="cap / zip" value={ this.state.contract___fields[5] } onInput={(___e: any) => { this.handle___contract___field_update(5, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>) in </span>
																<input style={{width:'300pt'}} type="text" placeholder="via / piazza" value={ this.state.contract___fields[6] } onInput={(___e: any) => { this.handle___contract___field_update(6, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>n°</span>
																<input style={{width:'50pt'}} type="text" placeholder="n" value={ this.state.contract___fields[7] } onInput={(___e: any) => { this.handle___contract___field_update(7, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>avente come C.F.</span>
																<input style={{width:'150pt'}} type="text" placeholder="Codice fiscale" value={ this.state.contract___fields[8] } onInput={(___e: any) => { this.handle___contract___field_update(8, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>

																{(this.state.partner___props['partner_type'] === 'b') ? <>
																	<br />
																	<br />
																	<span>in qualità di <b>amministratore</b> della</span>
																	<input style={{width:'500pt'}} type="text" placeholder="ragione sociale" value={ this.state.contract___fields[9] } onInput={(___e: any) => { this.handle___contract___field_update(9, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																	<span>con sede legale nella città di</span>
																	<input style={{width:'300pt'}} type="text" placeholder="città" value={ this.state.contract___fields[10] } onInput={(___e: any) => { this.handle___contract___field_update(10, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																	<span>(cap </span>
																	<input style={{width:'80pt'}} type="text" placeholder="cap / zip" value={ this.state.contract___fields[11] } onInput={(___e: any) => { this.handle___contract___field_update(11, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																	<span>) in </span>
																	<input style={{width:'300pt'}} type="text" placeholder="via / piazza" value={ this.state.contract___fields[12] } onInput={(___e: any) => { this.handle___contract___field_update(12, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																	<span>n° </span>
																	<input style={{width:'50pt'}} type="text" placeholder="n°" value={ this.state.contract___fields[13] } onInput={(___e: any) => { this.handle___contract___field_update(13, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																	<span>avente come p.iva</span>
																	<input style={{width:'150pt'}} type="text" placeholder="p.iva / c.f." value={ this.state.contract___fields[14] } onInput={(___e: any) => { this.handle___contract___field_update(14, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																	<span>e come metodi di contatto il numero di telefono</span>
																	<input style={{width:'150pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[15] } onInput={(___e: any) => { this.handle___contract___field_update(15, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																	<span>la mail</span>
																	<input style={{width:'300pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[16] } onInput={(___e: any) => { this.handle___contract___field_update(16, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																	<span>e la pec</span>
																	<input style={{width:'300pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[17] } onInput={(___e: any) => { this.handle___contract___field_update(17, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																</> : <></>}

																<br />
																<br />
																<br />

																<h5>Impegno all'acquisto</h5>
																
																<br />

																<div className="input---nb---text-page---table">
																	<div>
																		<div><span style={{display:'none'}}></span><span>Includi impegno all'acquisto</span></div>
																		<div style={{width:'400pt'}}><span> </span><select value={ this.state.contract___fields[18] } onChange={(___e: any) => { this.handle___contract___field_update(18, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}><option value="true">INCLUDI</option><option value="false">NO</option></select></div>
																	</div>
																</div>
																
																<br />
																<br />

																<h5>Veicolo</h5>

																<br />

																<span>Il veicolo (marca e modello)</span>
																<input style={{width:'500pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[19] } onInput={(___e: any) => { this.handle___contract___field_update(19, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>identificata mediante numero di telaio</span>
																<input style={{width:'500pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[20] } onInput={(___e: any) => { this.handle___contract___field_update(20, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>e targa</span>
																<input style={{width:'150pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[21] } onInput={(___e: any) => { this.handle___contract___field_update(21, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>al prezzo totale di</span>
																<input style={{width:'100pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[22] } onInput={(___e: any) => { this.handle___contract___field_update(22, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>€ con iva</span>
																<input style={{width:'50pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[23] } onInput={(___e: any) => { this.handle___contract___field_update(23, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>% </span>
																<select value={ this.state.contract___fields[24] as type___vehicle___price_vat_type } onChange={(___e: any) => { this.handle___contract___field_update(24, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}>{ this.lib___selectables.selectable_options___vat_types() }</select>

																<br />
																<br />
																<br />

																<h5>Caratteristiche del veicolo</h5>

																<br />

																<div className="input---nb---text-page---table">
																	<div>
																		<div><span style={{display:'none'}}></span><span>Includi condizioni funzioali del veicolo</span></div>
																		<div style={{width:'400pt'}}><span> </span><select value={ this.state.contract___fields[25] } onChange={(___e: any) => { this.handle___contract___field_update(25, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}><option value="true">INCLUDI</option><option value="false">NO</option></select></div>
																	</div>
																	<div>
																		<div><span style={{display:'none'}}></span><span>Includi condizioni estetiche del veicolo</span></div>
																		<div style={{width:'400pt'}}><span> </span><select value={ this.state.contract___fields[26] } onChange={(___e: any) => { this.handle___contract___field_update(26, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}><option value="true">INCLUDI</option><option value="false">NO</option></select></div>
																	</div>
																</div>

																<br />
																<br />
																<br />

																<h5>Condizioni generali di vendita</h5>

																<br />

																<div className="input---nb---text-page---table">
																	<div>
																		<div><span style={{display:'none'}}></span><span>Includi condizioni generali di vendita</span></div>
																		<div style={{width:'400pt'}}><span> </span><select value={ this.state.contract___fields[27] } onChange={(___e: any) => { this.handle___contract___field_update(27, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}><option value="true">INCLUDI</option><option value="false">NO</option></select></div>
																	</div>
																</div>
																
																<br />
																<br />
																
																<h5>Permuta</h5>

																<br />
															
																<div className="input---nb---text-page---table">
																	<div>
																		<div><span>d</span><span>Cessione veicolo usato in permuta</span></div>
																		<div style={{width:'400pt'}}><span> </span><select value={ this.state.contract___fields[40] } onChange={(___e: any) => { this.handle___contract___field_update(40, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}><option value="true">SI</option><option value="false">NO</option></select></div>
																	</div>
																</div>

																{(this.state.contract___fields[40].toLowerCase() === 'true') ? <>

																	<br />
																	<br />

																	<span>La permuta avviene con l'autovettura (marca e modello)</span>
																	<input style={{width:'500pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[41] } onInput={(___e: any) => { this.handle___contract___field_update(41, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																	<span>identificata mediante numero di telaio</span>
																	<input style={{width:'300pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[42] } onInput={(___e: any) => { this.handle___contract___field_update(42, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																	<span>e targa</span>
																	<input style={{width:'100pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[43] } onInput={(___e: any) => { this.handle___contract___field_update(43, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																	<span>al prezzo totale di</span>
																	<input style={{width:'150pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[44] } onInput={(___e: any) => { this.handle___contract___field_update(44, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																	<span>€ con iva </span>
																	<input style={{width:'50pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[45] } onInput={(___e: any) => { this.handle___contract___field_update(45, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																	<span>% </span>
																	<select value={ this.state.contract___fields[46] as type___vehicle___price_vat_type } onChange={(___e: any) => { this.handle___contract___field_update(46, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}>{ this.lib___selectables.selectable_options___vat_types() }</select>

																	<br />
																	<br />
																	<br />

																	<div className="input---nb---text-page---table">
																		<div>
																			<div><span style={{display:'none'}}></span><span>Includi automaticamente il veicolo in permuta nello stock</span></div>
																			<div style={{width:'400pt'}}><span> </span><select value={ this.state.contract___fields[83] } onChange={(___e: any) => { this.handle___contract___field_update(83, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}><option value="true">INCLUDI IN STOCK</option><option value="false">NON INCLUDERE</option></select></div>
																		</div>
																	</div>
																	
																	<br />
																	<br />
																	<br />

																	<h5>Permuta - Condizioni del veicolo e ammontare danni *</h5>

																	<br />

																	<div className="input---nb---vehicle-status---table">
																		<div>
																			<div style={{width:'200pt'}}><span>1</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[47] } onInput={(___e: any) => { this.handle___contract___field_update(47, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																			<div style={{width:'100%'}}><span>|</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[48] } onInput={(___e: any) => { this.handle___contract___field_update(48, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																			<div><span>| -</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[49] } onInput={(___e: any) => { this.handle___contract___field_update(49, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/><span>€</span></div>
																		</div>
																		<div>
																			<div style={{width:'200pt'}}><span>2</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[50] } onInput={(___e: any) => { this.handle___contract___field_update(50, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																			<div style={{width:'100%'}}><span>|</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[51] } onInput={(___e: any) => { this.handle___contract___field_update(51, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																			<div><span>| -</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[52] } onInput={(___e: any) => { this.handle___contract___field_update(52, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/><span>€</span></div>
																		</div>
																		<div>
																			<div style={{width:'200pt'}}><span>3</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[53] } onInput={(___e: any) => { this.handle___contract___field_update(53, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																			<div style={{width:'100%'}}><span>|</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[54] } onInput={(___e: any) => { this.handle___contract___field_update(54, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																			<div><span>| -</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[55] } onInput={(___e: any) => { this.handle___contract___field_update(55, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/><span>€</span></div>
																		</div>
																		<div>
																			<div style={{width:'200pt'}}><span>4</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[56] } onInput={(___e: any) => { this.handle___contract___field_update(56, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																			<div style={{width:'100%'}}><span>|</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[57] } onInput={(___e: any) => { this.handle___contract___field_update(57, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																			<div><span>| -</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[58] } onInput={(___e: any) => { this.handle___contract___field_update(58, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/><span>€</span></div>
																		</div>
																		<div>
																			<div style={{width:'200pt'}}><span>5</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[59] } onInput={(___e: any) => { this.handle___contract___field_update(59, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																			<div style={{width:'100%'}}><span>|</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[60] } onInput={(___e: any) => { this.handle___contract___field_update(60, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																			<div><span>| -</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[61] } onInput={(___e: any) => { this.handle___contract___field_update(61, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/><span>€</span></div>
																		</div>
																	</div>

																	<br />

																	<span style={{fontWeight:'bold',opacity:'0.5'}}>* qualora venga inserito l'importo verrà detratto dal valore complessivo della permuta nella stampa del contratto</span>

																	<br />
																	<br />

																</> : <>
																	<br />
																</>}

																<h5>Consegna</h5>

																<br />

																<span>Consegna veicolo entro e non oltre il: </span>
																<input style={{width:'150pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[62] } onInput={(___e: any) => { this.handle___contract___field_update(62, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																
																<br />
																<br />
																<br />

																<h5>Servizi Opzionali alla consegna e/o garanzie</h5>

																<br />
																<br />

																<div className="input---nb---text-page---table">
																	<div>
																		<div style={{width:'100%'}}><span>1</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[63] } onInput={(___e: any) => { this.handle___contract___field_update(63, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		<div><span>|</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[64] } onInput={(___e: any) => { this.handle___contract___field_update(64, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/><span>€</span></div>
																	</div>
																	<div>
																		<div style={{width:'100%'}}><span>2</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[65] } onInput={(___e: any) => { this.handle___contract___field_update(65, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		<div><span>|</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[66] } onInput={(___e: any) => { this.handle___contract___field_update(66, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/><span>€</span></div>
																	</div>
																	<div>
																		<div style={{width:'100%'}}><span>3</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[67] } onInput={(___e: any) => { this.handle___contract___field_update(67, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		<div><span>|</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[68] } onInput={(___e: any) => { this.handle___contract___field_update(68, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/><span>€</span></div>
																	</div>
																	<div>
																		<div style={{width:'100%'}}><span>4</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[69] } onInput={(___e: any) => { this.handle___contract___field_update(69, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		<div><span>|</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[70] } onInput={(___e: any) => { this.handle___contract___field_update(70, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/><span>€</span></div>
																	</div>
																	<div>
																		<div style={{width:'100%'}}><span>5</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[71] } onInput={(___e: any) => { this.handle___contract___field_update(71, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		<div><span>|</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[72] } onInput={(___e: any) => { this.handle___contract___field_update(72, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/><span>€</span></div>
																	</div>
																	<div>
																		<div style={{width:'100%'}}><span>6</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[73] } onInput={(___e: any) => { this.handle___contract___field_update(73, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		<div><span>|</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[74] } onInput={(___e: any) => { this.handle___contract___field_update(74, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/><span>€</span></div>
																	</div>
																	<div>
																		<div style={{width:'100%'}}><span>7</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[75] } onInput={(___e: any) => { this.handle___contract___field_update(75, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		<div><span>|</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[76] } onInput={(___e: any) => { this.handle___contract___field_update(76, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/><span>€</span></div>
																	</div>
																	<div>
																		<div style={{width:'100%'}}><span>8</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[77] } onInput={(___e: any) => { this.handle___contract___field_update(77, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		<div><span>|</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[78] } onInput={(___e: any) => { this.handle___contract___field_update(78, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/><span>€</span></div>
																	</div>
																	<div>
																		<div style={{width:'100%'}}><span>9</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[79] } onInput={(___e: any) => { this.handle___contract___field_update(79, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		<div><span>|</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[80] } onInput={(___e: any) => { this.handle___contract___field_update(80, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/><span>€</span></div>
																	</div>
																	<br />
																	<div>
																		<div style={{width:'100%'}}><span></span><input type="text" value="Totale" disabled={ true }/></div>
																		<div><span>|</span><input type="text" value={ ___optional___total } disabled={ true }/><span>€</span></div>
																	</div>
																</div>

																<br />
																<br />

																<h5>Prezzo Totale</h5>

																<br />
																<br />

																<div className="input---nb---text-page---table">
																	<div>
																		<div style={{width:'100%'}}><span>+</span><input type="text" value="Prezzo veicolo" disabled={ true }/></div>
																		<div><span>|</span><input type="text" value={ this.state.contract___fields[22] } disabled={ true }/><span>€</span></div>
																	</div>
																	<div>
																		<div style={{width:'100%'}}><span>+</span><input type="text" value="Prezzo Optionals e dotazioni" disabled={ true }/></div>
																		<div><span>|</span><input type="text" value={ ___optional___total } disabled={ true }/><span>€</span></div>
																	</div>
																	{(this.state.contract___fields[40].toLowerCase() === 'true') ? <>
																		<div>
																			<div style={{width:'100%'}}><span>-</span><input type="text" value="Valore Permuta" disabled={ true }/></div>
																			<div><span>|</span><input type="text" value={ this.state.contract___fields[44] } disabled={ true }/><span>€</span></div>
																		</div>
																	</> : <></>}
																	<br />
																	<div>
																		<div style={{width:'100%'}}><span>=</span><input type="text" value="Totale" disabled={ true }/></div>
																		<div><span>|</span><input type="text" value={ ___total_price___total } disabled={ true }/><span>€</span></div>
																	</div>
																</div>

																<br />
																<br />

																<h5>Pagamento</h5>

																<br />
																<br />

																<div className="input---nb---text-page---table">
																	<div>
																		<div><span>a</span><span>Alla firma del presente ordine, a titolo di deposito cauzionale</span></div>
																		<div><span>€</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[28] } onInput={(___e: any) => { this.handle___contract___field_update(28, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		<div><span>|</span><input type="text" placeholder="tipologia" value={ this.state.contract___fields[29] } onInput={(___e: any) => { this.handle___contract___field_update(29, ___e.target.value) }}/></div>
																	</div>
																	<div>
																		<div><span>b</span><span>Importo Totale Finanziamento / Leasing</span></div>
																		<div><span>€</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[30] } onInput={(___e: any) => { this.handle___contract___field_update(30, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		<div><span>|</span><input type="text" placeholder="riferimento" value={ this.state.contract___fields[31] } onInput={(___e: any) => { this.handle___contract___field_update(31, ___e.target.value) }}/></div>
																	</div>
																	{(this.state.contract___fields[30].length > 0 || this.state.contract___fields[31].length > 0) ? <>
																		<div>
																			<div style={{paddingLeft:'50pt'}}><span>b.1</span><span>Numero Rate</span></div>
																			<div><span>n</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[32] } onInput={(___e: any) => { this.handle___contract___field_update(32, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		</div>
																		<div>
																			<div style={{paddingLeft:'50pt'}}><span>b.2</span><span>Importo Rata</span></div>
																			<div><span>€</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[33] } onInput={(___e: any) => { this.handle___contract___field_update(33, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		</div>
																		<div>
																			<div style={{paddingLeft:'50pt'}}><span>b.3</span><span>Riscatto o MaxiRata finale</span></div>
																			<div><span>€</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[34] } onInput={(___e: any) => { this.handle___contract___field_update(34, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		</div>
																	</> : <></>}
																	<div>
																		<div><span>c</span><span>Importo da effettuarsi prima della consegna</span></div>
																		<div><span>€</span><input type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[35] } onInput={(___e: any) => { this.handle___contract___field_update(35, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/></div>
																		<div><span>|</span><input type="text" placeholder="tipologia" value={ this.state.contract___fields[36] } onInput={(___e: any) => { this.handle___contract___field_update(36, ___e.target.value) }}/></div>
																	</div>
																	<br />
																	<div>
																		<div><span>=</span><span style={{opacity:'0.5',fontWeight:'bold',textTransform:'uppercase'}}>Totale residuo</span></div>
																		<div><span>€</span><input type="text" value={ ___financing_price___total } disabled={ true }/></div>
																		{(___financing_price___total !== 0) ? <>
																			<div><span></span><span style={{color:'var(--color-danger)',fontWeight:'bold'}}>ATTENZIONE</span></div>
																		</> : <>
																			<div><span></span><span style={{color:'var(--color-success)',fontWeight:'bold'}}>OK</span></div>
																		</>}
																	</div>
																</div>

																<br />
																<br />
																
																<h5>Contratto di pagamento e/o finanziamento</h5>

																<br />
																
																<div className="input---nb---text-page---table">
																	<div>
																		<div><span style={{display:'none'}}></span><span>Include contratto di pagamento</span></div>
																		<div style={{width:'400pt'}}><span> </span><select value={ this.state.contract___fields[37] } onChange={(___e: any) => { this.handle___contract___field_update(37, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}><option value="true">INCLUDI</option><option value="false">NO</option></select></div>
																	</div>
																	<div>
																		<div><span style={{display:'none'}}></span><span>Include contratto di leasing</span></div>
																		<div style={{width:'400pt'}}><span> </span><select value={ this.state.contract___fields[38] } onChange={(___e: any) => { this.handle___contract___field_update(38, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}><option value="true">INCLUDI</option><option value="false">NO</option></select></div>
																	</div>
																	<div>
																		<div><span style={{display:'none'}}></span><span>Include contratto di finanziamento</span></div>
																		<div style={{width:'400pt'}}><span> </span><select value={ this.state.contract___fields[39] } onChange={(___e: any) => { this.handle___contract___field_update(39, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}><option value="true">INCLUDI</option><option value="false">NO</option></select></div>
																	</div>
																</div>

																<br />
																<br />

															</section>
															
															<br />
															<br />
															<br />
															
															<div className="page-container---bottom-bar---buttons is-centered">
																<button className="is-card is-button is-submit is-hovered no-shadow" onClick={() => { this.handle___contract___save(true) }}>
																	<i className="fas fa-save"></i>
																	<span>Salva Bozza</span>
																</button>
																<button className="is-card is-button is-submit is-hovered no-shadow" onClick={() => { this.handle___contract___preview(true); }}>
																	<i className="far fa-file-alt"></i>
																	<span>Salva Contratto di Vendita e Stampa</span>
																</button>
															</div>
															
															<br />
															<br />
															<br />

														</> : <>

															<br />
															<br />
															<br />
															<br />
															<div className="page-container---bottom-bar---buttons is-centered">
																<button className="is-card is-button is-standard is-hovered no-shadow" onClick={() => { this.handle___contract___generate(true) }}>
																	<i className="fas fa-flask"></i>
																	<span>Genera Contratto di Vendita</span>
																</button>
															</div>
															<br />
															<br />
															<br />
															<br />
													
														</>}
													</>}
												</div>
											</> : <>
												<ComSpinnerComponent/>
											</>}
										</>; break;
										case 'sell': return <>
											{(this.state.GUI___page___is_loading___section === false) ? <>
												<div className="contract---container---generator is-card">
													<section className="contract---container---writer">

														<h3> Vendita</h3>
																
														<br />
														<br />
														
														{(this.state.lead___props['lead_status'] === 'sold') ? <>
															<span>Il veicolo (marca e modello)</span>
															<input style={{width:'500pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[19] }  disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>identificata mediante numero di telaio</span>
															<input style={{width:'500pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[20] } disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>e targa</span>
															<input style={{width:'150pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[21] } disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>è stata venduta al prezzo totale di</span>
															<input style={{width:'100pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[22] } disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>€ con iva</span>
															<input style={{width:'50pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[23] } disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>% </span>
															<select value={ this.state.contract___fields[24] as type___vehicle___price_vat_type } disabled={ this.state.lead___props['lead_status'] === 'sold'}>{ this.lib___selectables.selectable_options___vat_types() }</select>
															<br />
															<br />
															<span>verrà venduta al/alla sig.</span>
															<input style={{width:'300pt'}} type="text" placeholder="nome e cognome" value={ this.state.contract___fields[1] } onInput={(___e: any) => { this.handle___contract___field_update(1, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>nato a</span>
															<input style={{width:'300pt'}} type="text" placeholder="luogo di nascita" value={ this.state.contract___fields[2] } onInput={(___e: any) => { this.handle___contract___field_update(2, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>il</span>
															<input style={{width:'100pt'}} type="text" placeholder="data di nascita" value={ this.state.contract___fields[3] } onInput={(___e: any) => { this.handle___contract___field_update(3, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>e residente nella città di</span>
															<input style={{width:'300pt'}} type="text" placeholder="città" value={ this.state.contract___fields[4] } onInput={(___e: any) => { this.handle___contract___field_update(4, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>(cap</span>
															<input style={{width:'80pt'}} type="text" placeholder="cap / zip" value={ this.state.contract___fields[5] } onInput={(___e: any) => { this.handle___contract___field_update(5, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>) in </span>
															<input style={{width:'300pt'}} type="text" placeholder="via / piazza" value={ this.state.contract___fields[6] } onInput={(___e: any) => { this.handle___contract___field_update(6, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>n°</span>
															<input style={{width:'50pt'}} type="text" placeholder="n" value={ this.state.contract___fields[7] } onInput={(___e: any) => { this.handle___contract___field_update(7, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>avente come C.F.</span>
															<input style={{width:'250pt'}} type="text" placeholder="Codice fiscale" value={ this.state.contract___fields[8] } onInput={(___e: any) => { this.handle___contract___field_update(8, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<br />
															<br />
															{(this.state.partner___props['partner_type'] === 'b') ? <>
																<span>in qualità di <b>amministratore</b> della</span>
																<input style={{width:'500pt'}} type="text" placeholder="ragione sociale" value={ this.state.contract___fields[9] } onInput={(___e: any) => { this.handle___contract___field_update(9, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>con sede legale nella città di</span>
																<input style={{width:'300pt'}} type="text" placeholder="città" value={ this.state.contract___fields[10] } onInput={(___e: any) => { this.handle___contract___field_update(10, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>(cap </span>
																<input style={{width:'80pt'}} type="text" placeholder="cap / zip" value={ this.state.contract___fields[11] } onInput={(___e: any) => { this.handle___contract___field_update(11, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>) in </span>
																<input style={{width:'300pt'}} type="text" placeholder="via / piazza" value={ this.state.contract___fields[12] } onInput={(___e: any) => { this.handle___contract___field_update(12, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>n° </span>
																<input style={{width:'50pt'}} type="text" placeholder="n°" value={ this.state.contract___fields[13] } onInput={(___e: any) => { this.handle___contract___field_update(13, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>avente come p.iva</span>
																<input style={{width:'150pt'}} type="text" placeholder="p.iva / c.f." value={ this.state.contract___fields[14] } onInput={(___e: any) => { this.handle___contract___field_update(14, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>e come metodi di contatto il numero di telefono</span>
																<input style={{width:'150pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[15] } onInput={(___e: any) => { this.handle___contract___field_update(15, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>il fax</span>
																<input style={{width:'150pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[16] } onInput={(___e: any) => { this.handle___contract___field_update(16, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>la mail</span>
																<input style={{width:'300pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[17] } onInput={(___e: any) => { this.handle___contract___field_update(17, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>e la pec</span>
																<input style={{width:'300pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[18] } onInput={(___e: any) => { this.handle___contract___field_update(18, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<br />
																<br />
															</> : <></>}
															<span>in data</span>
															<input style={{width:'500pt'}} type="text" value={ this.funcs___datetime.datetime___get___date(this.state.vehicle___props['vehicle_status_sold_datetime']) } disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
														</> : <>
															<span>Il veicolo (marca e modello)</span>
															<input style={{width:'500pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[19] }  disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>identificata mediante numero di telaio</span>
															<input style={{width:'500pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[20] } disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>e targa</span>
															<input style={{width:'150pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[21] } disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>verrà venduta al prezzo totale di</span>
															<input style={{width:'100pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[22] } disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>€ con iva</span>
															<input style={{width:'50pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[23] } disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>% </span>
															<select value={ this.state.contract___fields[24] as type___vehicle___price_vat_type } disabled={ this.state.lead___props['lead_status'] === 'sold'}>{ this.lib___selectables.selectable_options___vat_types() }</select>
															<br />
															<br />
															<span>verrà venduta al/alla sig.</span>
															<input style={{width:'300pt'}} type="text" placeholder="nome e cognome" value={ this.state.contract___fields[1] } onInput={(___e: any) => { this.handle___contract___field_update(1, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>nato a</span>
															<input style={{width:'300pt'}} type="text" placeholder="luogo di nascita" value={ this.state.contract___fields[2] } onInput={(___e: any) => { this.handle___contract___field_update(2, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>il</span>
															<input style={{width:'100pt'}} type="text" placeholder="data di nascita" value={ this.state.contract___fields[3] } onInput={(___e: any) => { this.handle___contract___field_update(3, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>e residente nella città di</span>
															<input style={{width:'300pt'}} type="text" placeholder="città" value={ this.state.contract___fields[4] } onInput={(___e: any) => { this.handle___contract___field_update(4, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>(cap</span>
															<input style={{width:'80pt'}} type="text" placeholder="cap / zip" value={ this.state.contract___fields[5] } onInput={(___e: any) => { this.handle___contract___field_update(5, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>) in </span>
															<input style={{width:'300pt'}} type="text" placeholder="via / piazza" value={ this.state.contract___fields[6] } onInput={(___e: any) => { this.handle___contract___field_update(6, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>n°</span>
															<input style={{width:'50pt'}} type="text" placeholder="n" value={ this.state.contract___fields[7] } onInput={(___e: any) => { this.handle___contract___field_update(7, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<span>avente come C.F.</span>
															<input style={{width:'250pt'}} type="text" placeholder="Codice fiscale" value={ this.state.contract___fields[8] } onInput={(___e: any) => { this.handle___contract___field_update(8, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
															<br />
															<br />
															{(this.state.partner___props['partner_type'] === 'b') ? <>
																<span>in qualità di <b>amministratore</b> della</span>
																<input style={{width:'500pt'}} type="text" placeholder="ragione sociale" value={ this.state.contract___fields[9] } onInput={(___e: any) => { this.handle___contract___field_update(9, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>con sede legale nella città di</span>
																<input style={{width:'300pt'}} type="text" placeholder="città" value={ this.state.contract___fields[10] } onInput={(___e: any) => { this.handle___contract___field_update(10, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>(cap </span>
																<input style={{width:'80pt'}} type="text" placeholder="cap / zip" value={ this.state.contract___fields[11] } onInput={(___e: any) => { this.handle___contract___field_update(11, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>) in </span>
																<input style={{width:'300pt'}} type="text" placeholder="via / piazza" value={ this.state.contract___fields[12] } onInput={(___e: any) => { this.handle___contract___field_update(12, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>n° </span>
																<input style={{width:'50pt'}} type="text" placeholder="n°" value={ this.state.contract___fields[13] } onInput={(___e: any) => { this.handle___contract___field_update(13, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>avente come p.iva</span>
																<input style={{width:'150pt'}} type="text" placeholder="p.iva / c.f." value={ this.state.contract___fields[14] } onInput={(___e: any) => { this.handle___contract___field_update(14, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>e come metodi di contatto il numero di telefono</span>
																<input style={{width:'150pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[15] } onInput={(___e: any) => { this.handle___contract___field_update(15, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>la mail</span>
																<input style={{width:'300pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[16] } onInput={(___e: any) => { this.handle___contract___field_update(16, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<span>e la pec</span>
																<input style={{width:'300pt'}} type="text" placeholder={ this.funcs___text.text___empty_field } value={ this.state.contract___fields[17] } onInput={(___e: any) => { this.handle___contract___field_update(17, ___e.target.value) }} disabled={ this.state.lead___props['lead_status'] === 'sold'}/>
																<br />
																<br />
															</> : <></>}
															<br />
															<br />
															<br />
															<div className="page-container---bottom-bar---buttons is-centered">
																<button className="is-card is-button is-submit is-hovered no-shadow" onClick={() => { this.handle___contract___save(true) }}>
																	<i className="fas fa-save"></i>
																	<span>Salva Bozza</span>
																</button>
																<button className="is-card is-button is-submit is-hovered no-shadow" onClick={() => { this.handle___select___lead_state('sold', ___temp___contract___selling___additionals); }}>
																	<i className="far fa-file-alt"></i>
																	<span>Marca come venduta</span>
																</button>
															</div>
															<br />
															<br />
															<br />
														</>}
													</section>
												</div>
											</> : <>
												<ComSpinnerComponent/>
											</>}
										</>; break;
									}
								})()}
							</> : <>
								<ComSpinnerComponent/>
							</>}
						</div>
					</section>
				</div>
			</> : <>
				<ComSpinnerComponent/>
			</>}
		</>;
	}

//#endregion

}