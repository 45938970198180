import React from 'react'

//	S T Y L E

import './com-spinner.scss'

//	S T A T E

import { props___ComSpinnerComponent } from './com-spinner.state';

//	C L A S S

export default class ComSpinnerComponent extends React.Component<props___ComSpinnerComponent, {}>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComSpinnerComponent,
	)
	{
		super(props);
	}

//#endregion

//#region 																							R E N D E R

	render(): React.ReactNode
	{

//		return <>
//			<div className="loading---spinner---gif">
//				<img src="/assets/ui/spinner/loader.gif"/>
//			</div>
//		</>;

		return <>
			<div className="loading---spinner---squared" style={(this.props.has_margin === false) ? {} : { marginTop: '100pt' }}>
				<div>
					<div>1</div>
					<div>2</div>
					<div>3</div>
				</div>
			</div>
		</>;

	}

//#endregion

}