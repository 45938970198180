import React from 'react';

//	S T Y L E

import './com-slideshow-results.scss';

//	T Y P E S

import { props___ComSlideShowComponent, state___ComSlideShowComponent, state___ComSlideShowComponent___default } from './com-slideshow-results.state';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	C L A S S

export default class ComSlideShowComponent extends React.Component<props___ComSlideShowComponent, state___ComSlideShowComponent>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComSlideShowComponent,
	)
	{
		super(props);
		this.state = state___ComSlideShowComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	handle___slide___move = (___e: 'prev' | 'next') => {
		switch (___e)
		{
			case 'prev':
				const ___prev_index: number = (this.state.image___index === 0) ? (this.props.images.length - 1) : (this.state.image___index - 1);
				this.setState({ image___index: ___prev_index  });
				break;
			case 'next':
				const ___next_index: number = (this.state.image___index === (this.props.images.length - 1)) ? 0 : (this.state.image___index + 1);
				this.setState({ image___index: ___next_index  });
				break;
		}
	}

	handle___slide___go_to = (___e: number) => { this.setState({ image___index: ___e }); }

//#endregion

//#region 																							R E T U R N

	render(): React.ReactNode
	{
		return <>
			<div className="slideshow---container">
				<div>
					{(this.props.images.length > 0) ? <>
						<section><i className="fas fa-chevron-left is-card no-shadow" onClick={() => { this.handle___slide___move('prev'); }}></i></section>
						<img src={ lib_names.cdn.endpoint + this.props.images[this.state.image___index] } alt="{{image}}" />
						<section><i className="fas fa-chevron-right is-card no-shadow" onClick={() => { this.handle___slide___move('next'); }}></i></section>
					</> : <>
						<section></section>
						<img src={ lib_names.media.placeholders.vehicle.generic } alt="{{image}}" />
						<section></section>
					</>}
				</div>
				<div>
					{(this.props.images.length > 0) ? <>
						{this.props.images.map((___p: any, ___p___idx: number) => <>
							<div key={ ___p___idx } style={(___p___idx === this.state.image___index) ? {cursor:'default',filter:'grayscale(1)',opacity:'0.5'} : {}}onClick={() => { this.handle___slide___go_to(___p___idx); }}>
								<img src={ lib_names.cdn.endpoint + ___p }/>
							</div>
						</>)}
					</> : <>
						<div style={{cursor:'default',filter:'grayscale(1)',opacity:'0.5'}}>
							<img src={ lib_names.media.placeholders.vehicle.generic }/>
						</div>
					</>}
				</div>
			</div>
		</>;
	}

//#endregion

}