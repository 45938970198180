import React from 'react';

//	S T Y L E

import './sb-settings-imaps.scss';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';
import { type___dealer_imap___account } from '../../../types/types.types';

//	S T A T E

import { state___SbSettingsImapsPage, state___SbSettingsImapsPage___defaults } from './sb-settings-imaps.state';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComResultsEmptyComponent from '../../../components/com-results-empty/com-results-empty';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';

//	C L A S S

export default class SbSettingsImapsPage extends React.Component<{}, state___SbSettingsImapsPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService()

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: {}
	) {
		super(_PROPS);
		this.state = state___SbSettingsImapsPage___defaults;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___dealer_imaps___list = async (___search_param: string = '') => {
		const ___dealer_impas___list: type___api___response = await this._API.dealers_imaps___read___multi();
		this.setState({
			dealer_imaps___list: ___dealer_impas___list.data,
			GUI___page___is_loading___list: false
		});
	}
	
	read_props___dealer_imaps___single___account = async (___account___id: string | 'new' | null) => {
		
	}

//#endregion

//#region 																							S U B M I T

	submit___account_imap___check_if_can = () : boolean => {

		return true;

	}

	submit___account_imap = async () => {

		const ___imap_account___id: string | null = (this.state.field___account___id !== 'new') ? this.state.field___account___id : null;
		const ___imap_account___structure: type___dealer_imap___account = {
			imap_account_provider: this.state.field___account___provider,
			imap_account_email_name: this.state.field___account___name,
			imap_account_email: this.state.field___account___mail,
			imap_account_password: this.state.field___account___password,
		}
		

	//	testmail@prysmlab.com
	//	LeTettineDelle05!

		const ___can___submit: boolean = this.submit___account_imap___check_if_can();
		if (___can___submit)
		{
			const ___imap___connected: type___api___response = await this._API.dealers_imaps___submit(___imap_account___id, ___imap_account___structure);
	
			console.log('___imap___connected',___imap___connected)
	
	
	
	//		const ___usrr___account-imapname: string = (___account-imap___id !== null && ___account-imap___id !== 'new') ? this.state.dealer___props['dealer_account-imapname'] + '@' + this.state.account-imap___single___field___account-imapname : this.state.account-imap___single___field___account-imapname;
	//		const ___account-imap___is_created: type___response___is_created = await this._API.account-imaps___submit(___account-imap___id, this.state.account-imap___single___field___role, ___usrr___account-imapname, this.state.account-imap___single___field___name, this.state.account-imap___single___field___surname, this.state.account-imap___single___field___registry___cf, this.state.account-imap___single___field___contact___mail, this.state.account-imap___single___field___contact___phone, ___account-imap___address);
	//		console.log('___account-imap___is_created', ___account-imap___is_created);
	//		this.read_props___account-imaps___list();
		}
		else
		{
		//	this.alert___invalid__fields()
		}

	}

	submit___account_imap___delete = () => {



	}

//#endregion

//#region 																							H A N D L E R S

	handle___account_imap___show = (___account___id: string | 'new' | null) => {
		this.setState({
			field___account___id: ___account___id,
			GUI___page___is_loading___account: true,
		}, async () => {
			if (___account___id !== 'new' && ___account___id !== null)
				{
					const ___account_imap___single: type___api___response = await this._API.dealers_imaps___read___single(___account___id);
					this.setState({
						field___account___id: ___account___id,
						field___account___provider: ___account_imap___single.data['imap_account_provider'],
						field___account___name: ___account_imap___single.data['imap_account_email_name'],
						field___account___mail: ___account_imap___single.data['imap_account_email'],
						field___account___password: ___account_imap___single.data['imap_account_password'],
						field___account___password_repeat: ___account_imap___single.data['imap_account_password'],
						field___account___password_visible: false,
						GUI___page___is_loading___account: false
					});
				}
				else
				{
					this.setState({
						field___account___id: null,
						field___account___provider: 'generic',
						field___account___name: '',
						field___account___mail: '',
						field___account___password: '',
						field___account___password_repeat: '',
						field___account___password_visible: false,
						GUI___page___is_loading___account: (___account___id === null) ? false : true
					});
				}
		});
	}










//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		this.setState({
			GUI___page___is_loading___list: true,
			GUI___page___is_loading___account: true
		}, async () => {
			await this.read_props___dealer_imaps___list();
			this.handle___account_imap___show(null);
		});
	}

//#endregion 

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return <>
			<div className="page---container is-full-width is-flexy">
				<section>
					<div className="is-card settings-account-imap---list---container">
						<h3 className="is-title">Lista Account Imap</h3>
						{(this.state.GUI___page___is_loading___list === false) ? <>
							<section>
								<div className={(this.state.field___account___id === 'new') ? ' account-imap-is-selected ' : ''} onClick={() => { this.handle___account_imap___show('new') }}>
									<span>
										<span>Aggiungi Nuovo Account</span>
									</span>
									<i className="fas fa-plus"></i>
								</div>
							</section>
							<br />
							<section>
								{this.state.dealer_imaps___list.map((___a: any, ___a_i: number) => <>
									<div key={ ___a_i } className={(this.state.field___account___id === ___a['imap_account_id']) ? ' account-imap-is-selected ' : ''} onClick={() => { this.handle___account_imap___show(___a['imap_account_id']) }}>
										<span>
											<img src="/assets/ui/icons/icon-mail.svg"/>
											<div>
												<span>{ ___a['imap_account_email_name'] }</span>
												<span>{ ___a['imap_account_email'] }</span>
											</div>
										</span>
										<i className="fas fa-chevron-right"></i>
									</div>
								</>)}
							</section>
						</>: <>
							<ComSpinnerComponent/>
						</>}
					</div>
					<div>
						<div className="is-card settings-account-imap---account-imap---container">
							{(this.state.GUI___page___is_loading___account === false) ? <>
								{(this.state.field___account___id === null) ? <>
									<ComResultsEmptyComponent text="Nessun account selezionato"/>
								</> : <>

									<h2 className="is-title">Account IMAP</h2>

									<span className="input---nb-label is-required">nome dell'account</span>
									<section className="input---nb-outlined---flexy">
										<select value={ this.state.field___account___provider ?? 'generic' } onChange={(___e: any) => { this.setState({field___account___provider: ___e.target.value })}}>
											<option value={ 'generic' }>Indirizzo Mail Generico</option>
											<option disabled>-</option>
											<option value={ 'aruba_mail' }>Aruba Mail (e dominio personalizzato su Aruba)</option>
											<option value={ 'libero_mail' }>Libero.it (libero.it, iol.it, inwind.it, blu.it, giallo.it)</option>
										</select>
									</section>

									<br />

									<span className="input---nb-label is-required">nome dell'account</span>
									<section className="input---nb-outlined---flexy">
										<input type="text" value={ this.state.field___account___name } placeholder="Nome Account" onInput={(___e: any) => { this.setState({ field___account___name: ___e.target.value })}} autoComplete="off"/>
									</section>

									<br />

									<span className="input---nb-label is-required">indirizzo email</span>
									<section className="input---nb-outlined---flexy">
										<input type="text" value={ this.state.field___account___mail } placeholder="example@email.com" onInput={(___e: any) => { this.setState({ field___account___mail: ___e.target.value })}} autoComplete="off"/>
									</section>

									<br />

									<span className="input---nb-label is-required">password e ripeti password</span>
									<section className="input---nb-outlined---flexy">
										<input type={(this.state.field___account___password_visible === false) ? 'password' : 'text'} value={ this.state.field___account___password } placeholder="password123" onInput={(___e: any) => { this.setState({ field___account___password: ___e.target.value })}} autoComplete="off"/>
										<input type={(this.state.field___account___password_visible === false) ? 'password' : 'text'} value={ this.state.field___account___password_repeat } placeholder="password123" onInput={(___e: any) => { this.setState({ field___account___password_repeat: ___e.target.value })}} autoComplete="off"/>
										{(this.state.field___account___password_visible === false) ? <>
											<i className="fas fa-eye" style={{cursor:'pointer'}} onClick={() => { this.setState({ field___account___password_visible: true })}}></i>
										</> : <>
											<i className="fas fa-eye-slash" style={{cursor:'pointer'}} onClick={() => { this.setState({ field___account___password_visible: false })}}></i>
										</>}
									</section>

									<br />
									<br />

									<div className="page-container---bottom-bar---buttons is-spaced">
										{(this.state.field___account___id !== 'new') ? <>
											<button type="button" className="is-card is-cancel no-shadow">
												<i className="fas fa-times"></i>
												<span>Elimina Account</span>
											</button>
										</>: <>
											<section></section>
										</>}
										<section>
											<button type="button" className="is-card is-submit no-shadow" onClick={ this.submit___account_imap }>
												<i className="fas fa-check"></i>
												<span>Salva</span>
											</button>
										</section>
									</div>

								</>}
							</> : <>
								<ComSpinnerComponent/>
							</>}
						</div>
					</div>
				</section>
			</div>
		</>;
	}

//#endregion

}