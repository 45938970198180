import React from "react";
import { Link } from "react-router-dom";

//	S T Y L E

import "./sb-partners-list.scss"

//	L I B S

import { lib_names } from "../../../libs/lib.names";

//	T Y P E S

import { type___alert___controller } from "../../../types/types.alerts";
import { type___api___response } from "../../../types/types.api-response";
import { type___toast___controller } from "../../../types/types.toasts";
import { type___partner___from, type___request___exporter_mime, type___request___order } from "../../../types/types.types";

//	S T A T E S

import { props___SbPartnersListPage, state___SbPartnersListPage, state___SbPartnersListPage___default } from "./sb-partners-list.state";

//	S E R V I C E S

import { service_RestApiService } from "../../../services/service-api";
import { service_RestExporterService } from "../../../services/service-exporter";

//	C O M P O E N T S

import ComResultsMoreComponent from "../../../components/com-results-more/com-results-more";

//	C L A S S

export default class SbpartnersListPage extends React.Component<props___SbPartnersListPage, state___SbPartnersListPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _EXPORTER: service_RestExporterService = new service_RestExporterService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbPartnersListPage,
	) {
		super(_PROPS);
		this.state = state___SbPartnersListPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___partners_list = async (___is_reset: boolean = true) => {

		await new Promise<void>((resolve) => this.setState({
			partners___list: (___is_reset === true) ? [] : this.state.partners___list,
			partners___list___is_loading: true
		}, () => resolve()));

		const ___vehicles___founds: type___api___response = await this._API.partners___read___multi('all', this.state.search___param___partner, this.state.partners___list.length, this.state.search___order);
		switch (___is_reset) {
			case true: this.setState({ partners___list: ___vehicles___founds.data }); break;
			case false: this.setState({ partners___list: [...this.state.partners___list, ...___vehicles___founds.data]}); break;
		}

		await new Promise<void>((resolve) => this.setState({
			partners___list___is_loading: false
		}, () => resolve()));

	}

//#endregion

//#region 																							H A N D L E R S

	handle___partner___search = (___e: any) => {
		this.setState({
			search___param___partner: ___e.target.value
		}, async () => {
			await this.read_props___partners_list(true);
		});
	}

	handle___partners___sortBy = (___param: string) => {
		let ___new___direction: type___request___order['direction'];
		const ___old___param: string = this.state.search___order['param'];
		if (___old___param === ___param) {
			___new___direction = (this.state.search___order['direction'] === 1) ? -1 : 1;
		} else {
			___new___direction = 1;
		}
		this.setState({
			search___order: {
				param: ___param,
				direction: ___new___direction
			}
		}, async () => {
			await this.read_props___partners_list(true);
		});
	}

	handle___partner___delete = (___partner: any) => {
		const ___alert_props : type___alert___controller = {
			alert___title: "Conferma Eliminazione",		
			alert___message : <><>Vuoi davvero <b>ELIMINARE</b> { ___partner['partner_name'] + ___partner['partner_surname'] }?</><br/><br/><>Questa azione comporterà l'eliminazione dei Leads, delle vendite e di tutto il materiale relativo a questo cliente/fornitore</><br/><br/><b style={{textDecoration:'underline'}}>AZIONE IRREVERSIBILE</b></>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si, elimina',
					alert___button___action: async () => {
						const ___removed_vehicle: type___api___response = await this._API.partner___delete(___partner['partner_id']);
						if (___removed_vehicle['response'] === 'success')
						{
							await this.read_props___partners_list(true);
							this._PROPS.alert___setShowing(false);
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Eliminato { ___partner['partner_name'] + ___partner['partner_surname'] } dalle anagrafiche</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore generico nell'eliminazione dell'anagrafica</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

	handle___partner___download_list = async (___mime: type___request___exporter_mime) => {
		const ___is_downloaded___filename: string = 'elenco_partner';
		const ___is_downloaded: boolean = await this._EXPORTER.download(lib_names.endpoints.exporter.partners, ___is_downloaded___filename, ___mime);
		if (___is_downloaded === true)
		{
			this._PROPS.alert___setShowing(false);
			const ___toast_props: type___toast___controller = {
				toast___color: 'success',
				toast___text: <>Lista dei partner scaricata con successo</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}
		else
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'danger',
				toast___text: <>Errore generico...</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount(): Promise<void>
	{
		await this.read_props___partners_list();
	}

//#endregion

//#region 																							R E A D   P R O P S

	render()
	{
		return <>
			<div className="page---container is-full-width">
				<section>
					<div className="page---top-bar">
						<section>
							<Link to ={ '/partners/create' } className="is-card">
								<i className="fas fa-plus"></i>
								<span>Nuova Anagrafica</span>
							</Link>
							<button onClick={() => { this.handle___partner___download_list('csv') }} className="is-card">
								<img src="/assets/ui/icons/icon-spreadsheet.svg" />
								<span>Esporta .CSV</span>
							</button>
						</section>
						<section>
							<label className="page---top-bar---search-box is-card" style={{width:'250pt'}}>
								<i className="fas fa-search"></i>
								<input type="text" placeholder="Cerca anagrafica..." onInput={this.handle___partner___search} />
							</label>
						</section>
					</div>
					<div className="container---table container---table---partners is-card">
						<div>
							<div></div>
							<div>
								<span>Tipo</span>
								<span>•</span>
							</div>
							<div>
								<span>Nome e Cognome</span>
								{(this.state.search___order['param'] === 'partner_name') ? <>
									{(this.state.search___order['direction'] === 1) ? <>
										<i className="fas fa-sort-up" onClick={() => { this.handle___partners___sortBy('partner_name'); }}></i>
									</> : <>
										<i className="fas fa-sort-down" onClick={() => { this.handle___partners___sortBy('partner_name'); }}></i>
									</>}
								</> : <>
									<i className="fas fa-sort" onClick={() => { this.handle___partners___sortBy('partner_name'); }}></i>
								</>}
							</div>
							<div>
								<span>Email</span>
								<span>•</span>
							</div>
							<div>
								<span>Telefono</span>
								<span>•</span>
							</div>
							<div>
								<span>Provenienza</span>
								<span>•</span>
							</div>
						</div>
						{this.state.partners___list.map((___p: any, ___i: number) => (
							<div key={ ___i }>
								<div>
									<Link to={ '/partners/view/' + ___p['partner_id'] } data-title="Visualizza">
										<i className="fas fa-eye"></i>
									</Link>
									<Link to={ '/partners/edit/' + ___p['partner_id'] } data-title="Modifica">
										<i className="fas fa-edit"></i>
									</Link>
									<i className="fas fa-trash" onClick={() => { this.handle___partner___delete(___p) }} data-title="Elimina"></i>
								</div>
								<div style={{display:'flex',gap:'10pt'}}>
									{(___p['partner_is_customer'] === true) ? <b data-title="Cliente">CL</b> : <></>}
									{(___p['partner_is_customer'] === ___p['partner_is_seller'] === true ) ? <span>/</span> : <></>}
									{(___p['partner_is_seller'] === true) ? <b data-title="Fornitore">FORN</b> : <></>}
								</div>
								<div><b>{(___p['partner_type'] === 'b') ? <>{___p['partner_name_business']} - </> : <></>}{___p['partner_name'] + ' ' + ___p['partner_surname']}</b></div>
								<div>{(___p['partner_email']) ? <>{ ___p['partner_email'] }</> : <>-</>}</div>
								<div>{(___p['partner_phone']) ? <>{ ___p['partner_phone'] }</> : <>-</>}</div>
								<div>
									{(() => {
										switch (___p['partner_from'] as type___partner___from) {
											case "partner_already": return <>-</>; break;
											case "partner_wordofmouth": return <>-</>; break;
											case "external_marketplace_autoscout24": return <>AutoScout24</>; break;
											case "external_marketplace_subitoit": return <>Subito.it</>; break;
											case "external_paper": return <>-</>; break;
											case "external_social_facebook": return <>-</>; break;
											case "external_social_instagram": return <>-</>; break;
											case "external_website": return <>Sito Web</>; break;
											default: return <>-</>; break;
										}
									})()}
								</div>
							</div>
						))}
						<ComResultsMoreComponent is_loading={ this.state.partners___list___is_loading } event___onClick={() => { this.read_props___partners_list(false); }}/>
					</div>
				</section>
			</div>
		</>;
	}

//#endregion

}