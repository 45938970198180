//	P R O P S

export interface props___SbLoginPage {

	event_setMeIsLogged: any,
	event_setMeProps: any,

	toast___setContent: any,
	toast___setShowing: any,

}

//	S T A T E

export interface state___SbLoginPage {

	field___username: string, 
	field___password: string,
	field___eula_accepted: boolean,

	GUI___lead___eula___preview: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbLoginPage___default: state___SbLoginPage = {

	field___username: '', 
	field___password: '',
	field___eula_accepted: false,

	GUI___lead___eula___preview: true,

}