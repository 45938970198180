import React, { Component } from 'react';

//	S T Y L E

import './wp-tooltips-wrapper.scss';

//	S T A T E

import { state___WpTooltipsWrapper, state___WpTooltipsWrapper___default } from './wp-tooltips-wrapper.state';

//	E X P O R T

export default function WpTooltipsWrapper(WrappedComponent: React.ComponentType) {
	return class extends Component<{}, state___WpTooltipsWrapper> {

//#region 																							C O N S T R U C T O R

		constructor(
			props: {}
		) {
			super(props);
			this.state = state___WpTooltipsWrapper___default
		}

//#endregion

//#region 																							H A N D L E R S 

		handle___onMouseOver = (___e: MouseEvent) => {
			let ___target: HTMLElement | null = ___e.target as HTMLElement | null;
			while (___target && !___target.getAttribute('data-title')) { ___target = ___target.parentElement; }
			const ___data_title: string | null = ___target?.getAttribute('data-title') ?? null;
			if (___data_title) {
				this.setState({
					isVisible: true,
					text: ___data_title,
					tooltip___position___x: ___e.clientX,
					tooltip___position___y: ___e.clientY
				});
			}
		};

		handle___onMouseMove = (___e: MouseEvent) => {
			if (this.state.isVisible) {
				this.setState({
					tooltip___position___x: ___e.clientX,
					tooltip___position___y: ___e.clientY
				});
			}
		};

		handle___onMouseOut = (___e: MouseEvent) => {
			let ___target: HTMLElement | null = ___e.target as HTMLElement | null;
			while (___target && !___target.getAttribute('data-title')) { ___target = ___target.parentElement; }
			if (___target && ___target.getAttribute('data-title')) {
				this.setState({
					isVisible: false
				});
			}
		};

//#endregion

//#region 																							L I F E C Y C L E

		componentDidMount() : void
		{
			document.addEventListener('mouseover', this.handle___onMouseOver);
			document.addEventListener('mousemove', this.handle___onMouseMove);
			document.addEventListener('mouseout', this.handle___onMouseOut);
		}

		componentWillUnmount () : void 
		{
			document.removeEventListener('mouseover', this.handle___onMouseOver);
			document.removeEventListener('mousemove', this.handle___onMouseMove);
			document.removeEventListener('mouseout', this.handle___onMouseOut);
		}

//#endregion

//#region 																							R E N D E R

		render() : React.ReactNode
		{

			const ___tooltip___style: React.CSSProperties = { top: this.state.tooltip___position___y, left: this.state.tooltip___position___x };

			return (
				<div>
					<WrappedComponent {...this.props} />
					{(this.state.isVisible === true) ? <>
						<div className="wp-tooltip-wrapper---element" style={ ___tooltip___style }>
							<>{this.state.text }</>
						</div>
					</> : <></>}
				</div>
			);

		}

//#endregion

	};
}