import { props___page___hasAlert } from "../../interfaces/interface.alerts";
import { props___page___hasToast } from "../../interfaces/interface.toasts";
import { type___lead___status } from "../../types/types.types";

//	P R O P S

export interface props___ComLeadComponent extends props___page___hasAlert, props___page___hasToast {
	
	lead_props: any,
	lead_layout?: 'horizontal' | 'vertical',
	
	visible_user: boolean, 
	visible_onChangeStatus: boolean,

	event___onChangeStatus?: (_: type___lead___status) => Promise<void>,
	event___onChangeVisibility?: (_: boolean) => Promise<void>,

}

//	S T A T E

export interface state___ComLeadComponent {
	
	lead_props_vehicle: any | null,
	lead_props_viewed: boolean,

	GUI___picture___is_loading: boolean,

}

//	S T A T E   D E F A U L T S


export const state___ComLeadComponent___default: state___ComLeadComponent = {
	
	lead_props_vehicle: null,
	lead_props_viewed: true,

	GUI___picture___is_loading: true,

}