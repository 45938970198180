import { props___page___hasToast } from "../../../interfaces/interface.toasts"

//	P R O P S

export interface props___SbSettingsDealerPage extends props___page___hasToast {

}

//	S T A T E

export interface state___SbSettingsDealerPage {
	
	selected___tab: 'overview',

	dealer___props: any | null,
	dealer___props___connector: any | null,
	dealer___props___apis: any | null,

	GUI___page___is_loading: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbSettingsDealerPage___defaults : state___SbSettingsDealerPage = {

	selected___tab : 'overview',

	dealer___props: null,
	dealer___props___connector: null,
	dealer___props___apis: null,

	GUI___page___is_loading: true,

}