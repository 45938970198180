import React from "react";

//	S T Y L E

import './com-partner.scss';

//	S T A T E

import { props___ComPartnerComponent } from "./com-partner.state";

//	C L A S S

export default class ComPartnerComponent extends React.Component<props___ComPartnerComponent, {}>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___ComPartnerComponent
	) {
		super(_PROPS);
	}

//#endregion

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return <>
			
			<div className="partner-single---card---container">
			
				{(this.props.partner___props['partner_type'] === 'b') ? <>
					<h5>{ this._PROPS.partner___props['partner_name_business'] }</h5>
				</> : <></>}
			
				<h3>{ this._PROPS.partner___props['partner_name'] + ' ' + this._PROPS.partner___props['partner_surname'] }</h3>
			
				{(this._PROPS.partner___props['partner_type'] === 'b') ? <>
					<h4>{ this._PROPS.partner___props['partner_address_business']['street'] ?? '' + ' ' + this._PROPS.partner___props['partner_address_business']['street_n'] ?? '' + ' - ' + this._PROPS.partner___props['partner_address_business']['city'] ?? '' }</h4>
				</> : <>
					<h4>{ this._PROPS.partner___props['partner_address']['street'] ?? '' + ' ' + this._PROPS.partner___props['partner_address']['street_n'] ?? '' + ' - ' + this._PROPS.partner___props['partner_address']['city'] ?? '' }</h4>
				</>}

				<h6><span>email</span> { this._PROPS.partner___props['partner_email'] }</h6>
				<h6><span>telefono</span> { this._PROPS.partner___props['partner_phone'] }</h6>

			</div>
		</>;
	}

//#endregion

}