import { type___dealer_imap___account_provider, type___user___role } from "../../../types/types.types"

//	S T A T E

export interface state___SbSettingsImapsPage {
	

	dealer_imaps___list: any[],

	field___account___id: string | 'new' | null,
	field___account___provider: type___dealer_imap___account_provider,
	field___account___name: string,
	field___account___mail: string,
	field___account___password: string,
	field___account___password_repeat: string,
	field___account___password_visible: boolean,

	GUI___page___is_loading___list: boolean,
	GUI___page___is_loading___account: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbSettingsImapsPage___defaults: state___SbSettingsImapsPage = {

	dealer_imaps___list: [],

	field___account___id: null,
	field___account___provider: 'generic', 
	field___account___name: '',
	field___account___mail: '',
	field___account___password: '',
	field___account___password_repeat: '',
	field___account___password_visible: false,

	GUI___page___is_loading___list: true,
	GUI___page___is_loading___account: false,

}