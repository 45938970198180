import React from "react";

//	S T Y L E

import "./sb-settings-contracts.scss";

//	S E R V I C E S

import { service_RestApiService } from "../../../services/service-api";

//	T Y P E S

import { type___alert___controller } from "../../../types/types.alerts";
import { type___api___response } from "../../../types/types.api-response";
import { type___input___textarea___edit } from "../../../types/types.types";
import { type___toast___controller } from "../../../types/types.toasts";

//	S T A T E S

import { props___SbSettingsContractsPage, state___SbSettingsContractsPage, state___SbSettingsContractsPage___defaults } from "./sb-settings-contracts.state";

//	C O M P O N E N T S

import ComSpinnerComponent from "../../../components/com-spinner/com-spinner";
import ComResultsEmptyComponent from "../../../components/com-results-empty/com-results-empty";

//	C L A S S

export default class SbSettingsContractsPage extends React.Component<props___SbSettingsContractsPage, state___SbSettingsContractsPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbSettingsContractsPage,
	)
	{
		super(props);
		this.state = state___SbSettingsContractsPage___defaults;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___contract = async () => {
		const ___contract_props: type___api___response = await this._API.contracts___read___single(this.state.contract___type);
		console.log('___contract_props', ___contract_props)
		if (___contract_props.response === 'success')
		{
			this.setState({
				contract___type: ___contract_props.data['contract_type'],
				contract___text: ___contract_props.data['contract_text'],
				GUI___page___is_loading___section: false
			});
		}
		else
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'danger',
				toast___text: <>Impossibile caricare il contratto...</>
			}
			this.props.toast___setContent(___toast_props);
			this.props.toast___setShowing(true);
		}
	}

//#endregion

//#region 																							H A N D L E R S

	handle___select___tab = async (___e: state___SbSettingsContractsPage['selected___contract___tab']) => {
		this.setState({
			GUI___page___is_loading___section: true,
			selected___contract___tab: ___e,
		}, async () => {
			if (___e !== '' && ___e !== 'help')
			{
				this.setState({
					GUI___page___is_loading___section: true,
					contract___text: '',
					contract___type: ___e,
				}, async () => {
					await this.read_props___contract();
				});
			}
		});
	}

	handle___textarea___text___select = () => {
		const ___textarea_element: HTMLTextAreaElement | null = document.getElementById('contract-editor---textarea') as HTMLTextAreaElement;
		if (___textarea_element) {
			this.setState({
				contract___text___selection_start: ___textarea_element.selectionStart,
				contract___text___selection_end: ___textarea_element.selectionEnd,
			});
		}
	};


	handle___textarea___text___edit = (___edit_type: type___input___textarea___edit) => {
		if (this.state.contract___text___selection_start !== null && this.state.contract___text___selection_end !== null)
		{
		
			const ___text: string = this.state.contract___text;
			const ___text___selected_index___start: number = this.state.contract___text___selection_start!;
			const ___text___selected_index___end: number = this.state.contract___text___selection_end!;

			if (___text___selected_index___start !== ___text___selected_index___end)
			{

				const ___text___before: string = ___text.substring(0, ___text___selected_index___start);
				const ___text___selected: string = ___text.substring(___text___selected_index___start, ___text___selected_index___end);
				const ___text___after: string = ___text.substring(___text___selected_index___end);

				let ___text___delimiters: [string, string] = ['', ''];
				switch (___edit_type)
				{
					case 'bold': ___text___delimiters = ['**','**']; break;
					case 'italic': ___text___delimiters = ['_','_'];break;
					case 'underline': ___text___delimiters = ['<u>','</u>']; break;
				}

				const ___text___modified: string = ___text___before + ___text___delimiters[0] + ___text___selected + ___text___delimiters[1] + ___text___after;

				this.setState({
					contract___text: ___text___modified,
					contract___text___selection_start: null,
					contract___text___selection_end: null,
				});

			}

		}
	}

	handle___submit = () => {
		const ___alert_props : type___alert___controller = {
			alert___title: "Attenzione",
			alert___message : <>Stai per salvare il contratto, sei sicuro?</>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this.props.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si',
					alert___button___action: async (___partner_id : string) => {
						const ___contract___is_submitted: type___api___response = await this._API.contracts___submit(this.state.contract___type, this.state.contract___text);
						if (___contract___is_submitted.response === 'success')
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Contratto salvato con successo</>
							}
							this.props.toast___setContent(___toast_props);
							this.props.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore nel salvataggio del contratto</>
							}
							this.props.toast___setContent(___toast_props);
							this.props.toast___setShowing(true);
						}
						await this.read_props___contract();
						this.props.alert___setShowing(false);
					}
				}
			]
		}
		this.props.alert___setAlert(___alert_props);
		this.props.alert___setShowing(true);
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="page---container is-full-width">
				<section>
					<div className="contracts-overview---flexy">

						<div>
							<div className="page---top-bar page---top-bar---is-sidebar">
								<section>
									<input type="radio" id="d-ts---csec---commitment" name="d-ts---cb" value="commitment" checked={ this.state.selected___contract___tab === 'commitment' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
									<label htmlFor="d-ts---csec---commitment" className="is-card">
										<img src="/assets/ui/icons/icon-overview.svg" />
										<span>Impegno all'acquisto</span>
									</label>
									<input type="radio" id="d-ts---csec---sell" name="d-ts---cb" value="sell" checked={ this.state.selected___contract___tab === 'sell' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
									<label htmlFor="d-ts---csec---sell" className="is-card">
										<img src="/assets/ui/icons/icon-overview.svg" />
										<span>Condizioni generali di Vendita</span>
									</label>
									<input type="radio" id="d-ts---csec---payments" name="d-ts---cb" value="payments" checked={ this.state.selected___contract___tab === 'payments' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
									<label htmlFor="d-ts---csec---payments" className="is-card">
										<img src="/assets/ui/icons/icon-overview.svg" />
										<span>Contratto di Pagamento</span>
									</label>
									<input type="radio" id="d-ts---csec---payments_leasing" name="d-ts---cb" value="payments_leasing" checked={ this.state.selected___contract___tab === 'payments_leasing' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
									<label htmlFor="d-ts---csec---payments_leasing" className="is-card">
										<img src="/assets/ui/icons/icon-overview.svg" />
										<span>Contratto di Leasing</span>
									</label>
									<input type="radio" id="d-ts---csec---payments_financing" name="d-ts---cb" value="payments_financing" checked={ this.state.selected___contract___tab === 'payments_financing' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
									<label htmlFor="d-ts---csec---payments_financing" className="is-card">
										<img src="/assets/ui/icons/icon-overview.svg" />
										<span>Contratto di Finanziamento</span>
									</label>
									<br />
									<input type="radio" id="d-ts---csec---help" name="d-ts---cb" value="help" checked={ this.state.selected___contract___tab === 'help' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
									<label htmlFor="d-ts---csec---help" className="is-card">
										<img src="/assets/ui/icons/icon-info.svg" />
										<span>Aiuto e sintassi</span>
									</label>
								</section>
							</div>
						</div>
						{(() => {
							switch (this.state.selected___contract___tab) {
								case '': return <>
									<div className="is-card">
										<ComResultsEmptyComponent text="Seleziona un contratto per iniziare"/>
									</div>
								</>; break;
								case 'help': return <>
									<div className="is-card contracts-overview---help">
										<h2>Comandi dell'editor</h2>
										<br />
										<br />
										<div>
											<i className="fas fa-bold"></i>
											<p>seleziona il testo e clicca questo pulsante per trasformare in <b>grassetto</b></p>
										</div>
										<div>
											<i className="fas fa-italic"></i>
											<p>seleziona il testo e clicca questo pulsante per trasformare in <b>corsivo</b></p>
										</div>
										<div>
											<i className="fas fa-underline"></i>
											<p>seleziona il testo e clicca questo pulsante per trasformare in <b>sottolineato</b></p>
										</div>
										<br />
										<br />
										<p>I contatti seguono la sintassi standard MarkDown Text, in alternativa possono essere scritti in testo semplice o HTML</p>
									</div>
								</>; break;
								default: return <>
									<div className="is-card contracts-overview---editor">
										{(this.state.GUI___page___is_loading___section === false) ? <>
											<h3>
												{(() => {
													switch (this.state.selected___contract___tab) {
														case 'commitment': return <>Impegno all'acquisto</>; break;
														case 'sell': return <>Condizioni generali di Vendita</>; break;
														case 'payments': return <>Contratto di Pagamento</>; break;
														case 'payments_leasing': return <>Contratto di Leasing</>; break;
														case 'payments_financing': return <>Contratto di Finanziamento</>; break;
													}
												})()}
											</h3>
											<div className="contracts-overview---editor---buttons">
												<section>
													{/*
														<button type="button" onClick={() => {  }}>
															<i className="fas fa-heading"></i>
														</button>
														<span></span>
													*/}
													<button type="button" onClick={() => { this.handle___textarea___text___edit('bold'); }} data-title="Grassetto">
														<i className="fas fa-bold"></i>
													</button>
													<button type="button" onClick={() => { this.handle___textarea___text___edit('italic'); }} data-title="Corsivo">
														<i className="fas fa-italic"></i>
													</button>
													<button type="button" onClick={() => { this.handle___textarea___text___edit('underline') }} data-title="Sottolineato">
														<i className="fas fa-underline"></i>
													</button>
												</section>
											</div>
											<textarea id="contract-editor---textarea" value={ this.state.contract___text } onSelect={ this.handle___textarea___text___select } onInput={(___e: any) => { this.setState({ contract___text: ___e.target.value })}}></textarea>
											<br />
											<div className="page-container---bottom-bar---buttons">
												<button type="button" className="is-card is-submit no-shadow" onClick={() => { this.handle___submit(); }}>
													<i className="fas fa-check"></i>
													<span>Salva Contratto</span>
												</button>
											</div>
										</> : <>
											<ComSpinnerComponent/>
										</>}
									</div>
								</>; break;
							}
						})()}
					</div>
				</section>
			</div>
		</>;
	}

//#endregion

}




