import React from "react";

//	S T Y L E

import "./com-lead-activity.scss";

//	T Y P E S

import { type___activity___status, type___activity___type, type___lead___status } from "../../types/types.types";

//	F U N C S

import { funcs_datetime } from "../../funcs/funcs.datetime";
import { funcs_text_extendend } from "../../funcs/funcs.text.extended";

//	S T A T E

import { props___ComLeadActivityComponent, state___ComLeadActivityComponent, state___ComLeadActivityComponent___default } from "./com-lead-activity.state";

//	C L A S S

export default class ComLeadActivityComponent extends React.Component<props___ComLeadActivityComponent, state___ComLeadActivityComponent>
{

//#region 																							R E N D E R

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___text_extendend: funcs_text_extendend = new funcs_text_extendend();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComLeadActivityComponent
	) {
		super(props);
		this.state = state___ComLeadActivityComponent___default;
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{

	}

//#endregion

//#region 																							R E N D E R
	
	render() : React.ReactNode
	{
		return <>
			<div className={ 'lead---activity---single ' + ((this.props.activity___props['activity_status'] === 'canceled' || this.props.activity___props['activity_status'] === 'no_show') ? ' is-undone ' : ' ') }>
				<div>
					{(() => {
						switch (this.props.activity___props['activity_type'] as type___activity___type | 'first_contact') {
							case 'first_contact': return <><i className="fas fa-envelope-open-text"></i></>; break;
							case 'call': return <><i className="fas fa-phone"></i></>; break;
							case 'appointment': return <><i className="fas fa-handshake"></i></>; break;
							case 'test_drive': return <><i className="fas fa-car"></i></>; break;
							case 'lead_status_change': return <><i className="fas fa-exchange-alt"></i></>; break;
							case 'sale': return <><i className="fas fa-gift"></i></>; break;
						}
					})()}
					<p>
						{(() => {
							switch (this.props.activity___props['activity_type'] as type___activity___type | 'first_contact') {
								case 'first_contact': return <><span>Primo contatto</span></>; break;
								case 'call': return <><span>Chiamata con il cliente</span></>; break;
								case 'appointment': return <><span>Appuntamento con il cliente</span></>; break;
								case 'test_drive': return <><span>Test Drive Prenotato</span></>; break;
								case 'lead_status_change': return <>
									<span>
										<>Aggiornamento Lead</>
										{(() => {
											switch (this.props.activity___props['activity_notes'] as type___lead___status) {
												case 'working': return <> - <span>In lavorazione</span></>; break;
												case 'ready': return <> - <span>In Consegna</span></>; break;
												case 'sold': return <> - <span>Trattativa Conclusa (Venduto)</span></>; break;
												case 'archived': return <> - <span>Archiviato</span></>; break;
											}
										})()}
									</span>
								</>; break;
								case 'sale': return <><span><>Vendita Effettuata!</></span></>; break;
							}
						})()}
						{(() => {
							switch (this.props.activity___props['activity_notes'] as type___activity___status) {
								case null: return <><span>...In Attesa</span></>; break;
								case 'done': return <><span>Fatto</span></>; break;
								case 'canceled': return <><span>Annullato</span></>; break;
								case 'no_show': return <><span>Cliente non presentato</span></>; break;
							}
						})()}
					</p>
				</div>
				<div>
					<section>
						<span>
							<i className="fas fa-calendar-day"></i>
							<span><span>il giorno </span>{ this.funcs___datetime.datetime___get___date(this.props.activity___props['activity_datetime_from']) }</span>
						</span>
						<span>
							<i className="fas fa-clock"></i>
							<span><span>alle ore </span>{ this.funcs___datetime.datetime___get___time(this.props.activity___props['activity_datetime_from']) }</span>
						</span>
					</section>
					<div>
						<i className="fas fa-align-justify"></i>
						{(() => {
							switch (this.props.activity___props['activity_type'] as type___activity___type) {
								case 'lead_status_change': return <>
									<p className="lead---activity---single---note">
										<>Il lead è stato modificato in </>
										{(() => {
											switch (this.props.activity___props['activity_notes'] as type___lead___status) {
												case 'working': return <> "<b>in lavorazione</b>" </>; break;
												case 'ready': return <> "<b>in Consegna</b>" </>; break;
												case 'sold': return <> "<b>trattativa conclusa (venduto)</b>" </>; break;
												case 'archived': return <> "<b>archiviato</b>" </>; break;
											}
										})()}
										<> dall'agente </>
										<b>{ this.props.activity___props['activity_user_name'] }</b>
									</p>
								</>; break;
								case 'sale': return <>
									<p className="lead---activity---single---note">
										<>Il Veicolo oggetto di questo lead è stato venduto dall'agente </>
										<b>{ this.props.activity___props['activity_user_name'] }</b>
									</p>
								</>; break;
								case 'appointment': case 'call': case 'test_drive': default: return <>
									<p className="lead---activity---single---note">
										{ this.funcs___text_extendend.text_extended___enrich(this.props.activity___props['activity_notes']) }
									</p>
								</>; break;
							}
						})()}
					</div>
				</div>
			</div>
		</>;
	}

//#endregion

}