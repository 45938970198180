import React from 'react';
import packageJson from '../../../package.json';
import crc from 'crc';

//	S T Y L E

import './com-bottombar.scss';

//	S T A T E S

import { props___ComBottomBarComponent, state___ComBottomBarComponent, state___ComBottomBarComponent___default } from './com-bottombar.state';

//	E X P O R T

export default class ComBottomBarComponent extends React.Component<props___ComBottomBarComponent, state___ComBottomBarComponent>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComBottomBarComponent,
	)
	{
		super(props);
		this.state = state___ComBottomBarComponent___default;
	}

//#endregion

//#region 																							V E R S I O N

	version___get___version()
	{
		const ___version: string = packageJson['version'];
		this.setState({ version___version: ___version });
	}

	version___get___build()
	{
		const ___package: string = packageJson['version'];
		const ___package___hash: string = crc.crc16(___package).toString();
		this.setState({ version___build: ___package___hash });
	}

//#endregion

//#region 																							N E T W O R K

	network___check_status = () => {
		const ___network___is_online: boolean = window.navigator.onLine as boolean;
		this.setState({
			is_online___status: ___network___is_online
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void
	{
		setTimeout(() => {
			this.version___get___version();
			this.version___get___build();
			this.network___check_status();
		}, 1000);
		document.addEventListener('online', this.network___check_status);
		document.addEventListener('offline', this.network___check_status);
	}

	componentWillUnmount() : void
	{
		window.removeEventListener('online', this.network___check_status);
		window.removeEventListener('offline', this.network___check_status);
	}

//#endregion

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return <>
			<div className="com-bottombar---container">
				<div className="com-bottombar---container---version---container">
					<p>
						<span>VERSION</span>
						<span>{ this.state.version___version }</span>
						<span>-</span>
						<span>BUILD</span>
						<span>{ this.state.version___build }</span>
					</p>
				</div>
				{(this.props.me___props && this.props.me___props['user_role'] === 'support') ? <>
					<div className="com-bottombar---container---support-mode-active">
						<p>Modalità Supporto e Manutenzione Attiva</p>
					</div>
				</> : <></>}
				{(() => {
					switch (this.state.is_online___status) {
						case true: return <>
							<div className="com-bottombar---container---network-status---container network-status---online">
								<i className="fas fa-broadcast-tower"></i>
								<span>Online</span>
							</div>
						</>; break;
						case false: return <>
							<div className="com-bottombar---container---network-status---container network-status---offline">
								<i className="fas fa-broadcast-tower"></i>
								<span>Offline</span>
							</div>
						</>; break;
						case null: return <>
							<div className="com-bottombar---container---network-status---container network-status---checking">
								<i className="fas fa-ellipsis-h"></i>
								<span>Ricerca Rete</span>
							</div>
						</>; break;
					}
				})()}
			</div>
		</>;
	}

//#endregion

}