import { type___licence } from "../../types/types.licence"

//	P R O P S

export interface props___ComNavComponent {

	notification___leads: number,

	dealer___licence: type___licence,

	me___props: any | null,
	me___props___dealer: any | null,

	event_performLogOut: any,

}
//	S T A T E

export interface state___ComNavComponent {

	url___active: string,

}

//	S T A T E   D E F A U L T S

export const state___ComNavComponent___default: state___ComNavComponent = {
	
	url___active: '',

}