import React from 'react';

//	S T Y L E

import './sb-settings-users.scss';

//	T Y P E S

import { type___alert___controller } from '../../../types/types.alerts';
import { type___api___response } from '../../../types/types.api-response';
import { type___toast___controller } from '../../../types/types.toasts';
import { type___user___address, type___user___role } from '../../../types/types.types';

//	S T A T E

import { props___SbSettingsUsersPage, state___SbSettingsUsersPage, state___SbSettingsUsersPage___defaults } from './sb-settings-users.state';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComResultsEmptyComponent from '../../../components/com-results-empty/com-results-empty';

//	C L A S S

export default class SbSettingsUsersPage extends React.Component<props___SbSettingsUsersPage, state___SbSettingsUsersPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService()

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbSettingsUsersPage
	) {
		super(_PROPS);
		this.state = state___SbSettingsUsersPage___defaults;
	}

//#endregion

//#region 																							R E A D   P R O P S
	
	read_props___dealer = async (___search_param: string = '') => {
		const ___users___list: type___api___response = await this._API.dealers___read___single();
		this.setState({
			dealer___props: ___users___list.data,
			GUI___page___is_loading___list: false
		});
	}

	read_props___users___list = async (___search_param: string = '') => {
		const ___users___list: type___api___response = await this._API.users___read___multi('search', ___search_param);
		this.setState({
			user___list: ___users___list.data,
			GUI___page___is_loading___list: false
		});
	}
	
	read_props___users___single = (___user_id: string | 'new' | null) => {
		this.setState({
			user___single___id: null,
			user___single___field___role: 'member',
			user___single___field___username: '',
			user___single___field___name: '',
			user___single___field___surname: '',
			user___single___field___registry___cf: '',
			user___single___field___contact___mail: '',
			user___single___field___contact___phone: '',
			user___single___field___address___street: '',
			user___single___field___address___street_n: '',
			user___single___field___address___city: '',
			user___single___field___address___prov: '',
			user___single___field___address___zip: '',
			user___single___field___address___country: '',
			GUI___page___is_loading___user: (___user_id === null) ? false : true
		}, async () => {
			if (___user_id !== 'new' && ___user_id !== null)
			{
				const ___users___single: type___api___response = await this._API.users___read___single('id', ___user_id);
				if (___users___single.response === 'success') {
					this.setState({
						user___single___id: ___user_id,
						user___single___is_me: ___users___single.data['user_is_me'],
						user___single___field___role: ___users___single.data['user_role'],
						user___single___field___username: ___users___single.data['user_username'],
						user___single___field___name: ___users___single.data['user_name'],
						user___single___field___surname: ___users___single.data['user_surname'],
						user___single___field___registry___cf: ___users___single.data['user_registry_cf'],
						user___single___field___contact___mail: ___users___single.data['user_contact_mail'],
						user___single___field___contact___phone: ___users___single.data['user_contact_phone'],
						user___single___field___address___street: ___users___single.data['user_address']['street'],
						user___single___field___address___street_n: ___users___single.data['user_address']['street_n'],
						user___single___field___address___city: ___users___single.data['user_address']['city'],
						user___single___field___address___prov: ___users___single.data['user_address']['prov'],
						user___single___field___address___zip: ___users___single.data['user_address']['zip'],
						user___single___field___address___country: ___users___single.data['user_address']['country'],
						GUI___page___is_loading___user: false
					});
				}
				else
				{
					this.setState({
						user___single___id: ___user_id,
						GUI___page___is_loading___user: false
					});
				}
			}
			else
			{
				this.setState({
					user___single___id: ___user_id,
					GUI___page___is_loading___user: false
				});
			}
		});
	}

//#endregion

//#region 																							S U B M I T
	
	submit___user___check_if_can = () => {

		const ___can_submit___user___username: boolean = (this.state.user___single___field___username.length > 0) ? true : false;
		const ___can_submit___user___name: boolean = (this.state.user___single___field___name.length > 0) ? true : false;
		const ___can_submit___user___surname: boolean = (this.state.user___single___field___surname.length > 0) ? true : false;
		const ___can_submit___user___registy_cf: boolean = (this.state.user___single___field___registry___cf.length > 0) ? true : false;
		const ___can_submit___user___contact_email: boolean = (this.state.user___single___field___contact___mail.length > 0) ? true : false;
		const ___can_submit___user___contact_phone: boolean = (this.state.user___single___field___contact___phone.length > 0) ? true : false;

		const ___can_submit: boolean = ___can_submit___user___username && ___can_submit___user___name &&___can_submit___user___surname && ___can_submit___user___registy_cf &&___can_submit___user___contact_email && ___can_submit___user___contact_phone;

		this.setState({
			user___single___can_submit: ___can_submit
		});

	}

	submit___user = async () => {

		if (this.state.user___single___can_submit === true)
		{

			const ___user___id: string | null = (this.state.user___single___id !== 'new') ? this.state.user___single___id : null;
			const ___user___address: type___user___address = {
				street: this.state.user___single___field___address___street,
				street_n: this.state.user___single___field___address___street_n,
				city: this.state.user___single___field___address___city,
				prov: this.state.user___single___field___address___prov,
				zip: this.state.user___single___field___address___zip,
				country: this.state.user___single___field___address___country,
			}
	
			const ___usrr___username: string = (___user___id === null) ? this.state.dealer___props['dealer_username'] + '@' + this.state.user___single___field___username : this.state.user___single___field___username;
			const ___user___is_created: type___api___response = await this._API.users___submit(___user___id, this.state.user___single___field___role, ___usrr___username, this.state.user___single___field___name, this.state.user___single___field___surname, this.state.user___single___field___registry___cf, this.state.user___single___field___contact___mail, this.state.user___single___field___contact___phone, ___user___address);
			if (___user___is_created['response'] === 'success')
			{
				const ___toast_props: type___toast___controller = {
					toast___color: 'success',
					toast___text: <>Utente salvato con successo</>
				}
				this._PROPS.toast___setContent(___toast_props);
				this._PROPS.toast___setShowing(true);
			}
			else
			{
				const ___toast_props: type___toast___controller = {
					toast___color: 'danger',
					toast___text: <>Errore nella creazione del nuovo utente</>
				}
				this._PROPS.toast___setContent(___toast_props);
				this._PROPS.toast___setShowing(true);
			}
			
			this.read_props___users___list();

		}

	}

	submit___user___delete = () => {
		const ___alert_props : type___alert___controller = {
			alert___title: "Conferma Eliminazione",
			alert___message : <>Stai per eliminare l'utente "<b>{ this.state.user___single___field___name + ' ' + this.state.user___single___field___surname }</b>" Sei sicuro?<br/><br/><b>AZIONE IRREVERSIBILE</b></>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Conferma',
					alert___button___action : async () => {
						const ___user___delete: type___api___response = await this._API.users___delete(this.state.user___single___id!);
						if (___user___delete['response'] === 'success')
						{
							this.read_props___users___single(null);
							this.read_props___users___list();
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Hai rimosso l'utente "<b>{ this.state.user___single___field___name + ' ' + this.state.user___single___field___surname }</b>"</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore generico nel rimuovere l'utente "<b>{ this.state.user___single___field___name + ' ' + this.state.user___single___field___surname }</b>"</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						this._PROPS.alert___setShowing(false);
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

//#endregion

//#region 																							H A N D L E R S

	handle___user___show = (___user_id: string | 'new' | null) => {
		this.setState({
			user___single___id: ___user_id,
		}, () => {
			this.read_props___users___single(___user_id);
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		this.setState({
			GUI___page___is_loading___list: true,
			GUI___page___is_loading___user: true
		}, async () => {
			await this.read_props___dealer();
			await this.read_props___users___list();
			this.handle___user___show(null);
		});
	}

//#endregion 

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return <>
			<div className="page---container is-full-width is-flexy">
				<section>
					<div className="is-card settings-user---list---container">
						<h3 className="is-title">Lista Utenti</h3>
						{(this.state.GUI___page___is_loading___list === false) ? <>
							<section>
								<div className={(this.state.user___single___id === 'new') ? ' user-is-selected ' : ''} onClick={() => { this.handle___user___show('new') }}>
									<span>
										<span>Aggiungi Nuovo Utente</span>
									</span>
									<i className="fas fa-plus"></i>
								</div>
							</section>
							<br />
							<section>
								{this.state.user___list.map((___u: any, ___u___idx: number) => <>
									{(___u['user_role'] !== 'support') ? <>
										<div key={ ___u___idx } className={(this.state.user___single___id === ___u['user_id']) ? ' user-is-selected ' : ''} onClick={() => { this.handle___user___show(___u['user_id']) }}>
											<span>
												{(() => {
													switch (___u['user_role'] as type___user___role) {
														case 'admin': return <><img src="/assets/ui/icons/icon-user.svg"/></>; break;
														case 'member': return <><img src="/assets/ui/icons/icon-user.svg"/></>; break;
														case 'support': return <><img src="/assets/ui/icons/icon-user-support.svg"/></>; break;
													}
												})()}
												<span>{ ___u['user_name'] + ' ' + ___u['user_surname'] }</span>
												{(___u['user_is_me'] === true) ? <><span>(tu)</span></> : <></>}
											</span>
											<i className="fas fa-chevron-right"></i>
										</div>
									</> : <></>}
								</>)}
							</section>
							<br />
							<section>
								{this.state.user___list.map((___u_s: any, ___u_s___idx: number) => <>
									{(___u_s['user_role'] === 'support') ? <>
										<div key={ ___u_s } style={{'cursor':'default'}} data-title="Questo è un account di servizio. Serve al team di assistenza di CarsHub e verrà utilizzato solo ed esclusivamente quando necessario e sotto richiesta del titolare dell'account concessionario. Se hai altre domande su questa tipologia di account puoi contattare l'assistenza.">
											<span>
												<img src="/assets/ui/icons/icon-user-support.svg"/>
												<span>{ ___u_s['user_name'] }</span>
												{(___u_s['user_is_me'] === true) ? <><span>(tu)</span></> : <></>}
											</span>
											<i className="fas fa-lock"></i>
										</div>
									</> : <></>}
								</>)}
							</section>
						</>: <>
							<ComSpinnerComponent/>
						</>}
					</div>
					<div>
						<div className="is-card settings-user---user---container">
							{(this.state.GUI___page___is_loading___user === false) ? <>
								{(this.state.user___single___id === null) ? <>
									<ComResultsEmptyComponent text="Nessun utente selezionato"/>
								</> : <>

									<h2 className="is-title">Ruolo</h2>

									<section className="input---nb-outlined---flexy">
										<select value={ this.state.user___single___field___role ?? 'member' } onChange={(___e: any) => { this.setState({ user___single___field___role: ___e.target.value })}}>
											<option value={ 'member' }>Utente standard</option>
											<option value={ 'admin' }>Amministratore</option>
										</select>
									</section>
									<br />

									<h2 className="is-title">Dati</h2>

									<span className="input---nb-label">Username</span>
									{(this.state.user___single___id === 'new') ? <>
										<section className="input---nb-outlined---flexy">
											<p>{ this.state.dealer___props['dealer_username'] }</p>
											<p>@</p>
											<input type="text" value={ this.state.user___single___field___username } placeholder="username" onInput={(___e: any) => { this.setState({ user___single___field___username: ___e.target.value }, () => { this.submit___user___check_if_can() })}}/>
										</section>
									</> : <>
										<section className="input---nb-outlined---flexy">
											<input type="text" value={ this.state.user___single___field___username } placeholder="dealer@username" onInput={(___e: any) => { this.setState({ user___single___field___username: ___e.target.value })}} disabled={ true } style={{cursor:'not-allowed',fontWeight:'bold'}}/>
											<i className="fas fa-lock"></i>
										</section>
									</>}

									<span className="input---nb-label">Nome e Cognome</span>
									<section className="input---nb-outlined---flexy">
										<input type="text" value={ this.state.user___single___field___name } placeholder="Nome" onInput={(___e: any) => { this.setState({ user___single___field___name: ___e.target.value }, () => { this.submit___user___check_if_can() })}}/>
										<input type="text" value={ this.state.user___single___field___surname } placeholder="Cognome" onInput={(___e: any) => { this.setState({ user___single___field___surname: ___e.target.value }, () => { this.submit___user___check_if_can() })}}/>
									</section>
									<span className="input---nb-label">codice fiscale</span>
									<section className="input---nb-outlined---flexy">
										<input type="text" value={ this.state.user___single___field___registry___cf } placeholder="XXXXXX00X00X000X" onInput={(___e: any) => { this.setState({ user___single___field___registry___cf: ___e.target.value }, () => { this.submit___user___check_if_can() })}}/>
									</section>
									<br />

									{(this.state.user___single___id === 'new') ? <>
										<h2 className="is-title">Credenziali</h2>
										<p>
											L'utente potrà loggare inserendo come username "<b>{ this.state.user___single___field___username }</b>" e come password
											il codice fiscale inserito (<b>{ this.state.user___single___field___registry___cf }</b>), la password sarà modificabile dopo il primo login
										</p>
										<br />
									</> : <></> }

									<h2 className="is-title">Contatti</h2>

									<span className="input---nb-label">Email</span>
									<section className="input---nb-outlined---flexy">
										<input type="text" value={ this.state.user___single___field___contact___mail } placeholder="example@email.com" onInput={(___e: any) => { this.setState({ user___single___field___contact___mail: ___e.target.value }, () => { this.submit___user___check_if_can() })}}/>
									</section>
									<span className="input---nb-label">Telefono</span>
									<section className="input---nb-outlined---flexy">
										<input type="text" value={ this.state.user___single___field___contact___phone } placeholder="+39 333 333 3334" onInput={(___e: any) => { this.setState({ user___single___field___contact___phone: ___e.target.value })}}/>
									</section>
									<br />

									<h2 className="is-title">Indirizzo</h2>

									<section className="input---nb-outlined---flexy">
										<input type="text" value={this.state.user___single___field___address___street} placeholder="via" onInput={(___e: any) => { this.setState({ user___single___field___address___street: ___e.target.value }); }} />
										<input type="text" value={this.state.user___single___field___address___street_n} placeholder="n°" onInput={(___e: any) => { this.setState({ user___single___field___address___street_n: ___e.target.value }); }} style={{width:'100pt'}}/>
									</section>
									<section className="input---nb-outlined---flexy" style={{paddingTop:'10pt'}}>
										<input type="text" value={this.state.user___single___field___address___city} placeholder="Città" onInput={(___e: any) => { this.setState({ user___single___field___address___city: ___e.target.value }); }} />
										<input type="text" value={this.state.user___single___field___address___prov} placeholder="Prov" onInput={(___e: any) => { this.setState({ user___single___field___address___prov: ___e.target.value }); }} style={{width:'80pt'}}/>
										<input type="text" value={this.state.user___single___field___address___zip} placeholder="Zip/CAP" onInput={(___e: any) => { this.setState({ user___single___field___address___zip: ___e.target.value }); }} />
										<input type="text" value={this.state.user___single___field___address___country} placeholder="Paese" onInput={(___e: any) => { this.setState({ user___single___field___address___country: ___e.target.value }); }} />
									</section>

									<br />
									<br />

									<div className="page-container---bottom-bar---buttons is-spaced">
										{(this.state.user___single___id !== 'new' && this.state.user___single___is_me === false) ? <>
											<button type="button" className="is-card is-cancel no-shadow" onClick={ this.submit___user___delete }>
												<i className="fas fa-times"></i>
												<span>Elimina Utente</span>
											</button>
										</>: <>
											<section></section>
										</>}
										<section>
											{(this.state.user___single___id !== 'new') ? <>
												<button type="button" className="is-card no-shadow" >
													<i className="fas fa-unlock-alt"></i>
													<span>Reset Password</span>
												</button>
											</>: <></>}
											{(this.state.user___single___can_submit === true) ? <>
												<button type="button" className="is-card is-submit no-shadow" onClick={ this.submit___user }>
													<i className="fas fa-check"></i>
													<span>Salva</span>
												</button>
											</> : <>
												<button type="button" className="is-card is-disabled no-shadow">
													<i className="fas fa-check"></i>
													<span>Compila correttamente tutti i campi</span>
												</button>
											</>}
										</section>
									</div>
								
								</>}
							</> : <>
								<ComSpinnerComponent/>
							</>}
						</div>
					</div>
				</section>
			</div>
		</>;
	}

//#endregion

}