import React from 'react';

// 	S T A T E

import { props___MdEulaView } from './md-eula-view.state';

//	S T Y L E S

import './md-eula-view.scss';

//	C O M P O N E N T S

import ComMarkdownComponent from '../../components/com-markdown/com-markdown';
import { Link } from 'react-router-dom';

//	C L A S S

export default class MdEulaViewModal extends React.Component<props___MdEulaView, {}>
{

//#region 																							H A N D L E R S

	handle___close = () => {
		this.props.event___onDismiss();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return <>
			<div className="modal---modal-eula---container"></div>
			<div className="modal---modal-eula---container is-card">
				<div className="modal---modal-eula---content">
					<section>
						<img src="/assets/logo.squared.svg"/>
						<ComMarkdownComponent file_path="/assets/docs/eula.it-it.md" />
					</section>
				</div>
				<br />
				<div className="page-container---bottom-bar---buttons is-centered">
					<Link type="button" className="is-card no-shadow is-danger" to={ 'https://www.youtube.com/watch?v=xvFZjo5PgG0' }>
						<i className="fas fa-times"></i>
						<span>Rifiuta ed esci</span>
					</Link>
					<button type="button" className="is-card no-shadow is-primary" onClick={ this.handle___close }>
						<i className="fas fa-check"></i>
						<span>Accetta</span>
					</button>
				</div>
			</div>
		</>;

	}

//#endregion

}