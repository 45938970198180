import { props___page___hasToast } from "../../../interfaces/interface.toasts"

//	P R O P S

export interface props___SbSettingsProfilePage extends props___page___hasToast {

}

//	S T A T E

export interface state___SbSettingsProfilePage {
	
	selected___tab: 'overview' | 'access',

	me___props: any | null,

	field___me___password___old: string,
	field___me___password___new: string,
	field___me___password___new_repeat: string,

	GUI___page___is_loading: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbSettingsProfilePage___defaults : state___SbSettingsProfilePage = {

	selected___tab : 'overview',

	me___props: null,

	field___me___password___old: '',
	field___me___password___new: '',
	field___me___password___new_repeat: '',

	GUI___page___is_loading: true,

}