import React from 'react'

//	S T Y L E

import './com-404.scss'

//	E X P O R T

export default class Com404Component extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return <>
			<div className="com-404---container">
				
				
				404


			</div>
		</>;
	}

//#endregion

}