import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

//	S T Y L E S

import './global.scss';

//	L I B S

import { lib_names } from './libs/lib.names';

//	S E R V I C E S

import { service_RestAuthService } from './services/service-auth';
import { service_RestApiService } from './services/service-api';
import { service_LeadsSync } from './services/service-leadsSync';

//	S T A T E S   -   P R O P S

import { props___WpNavigationWrapper___functions } from './wrappers/wp-navigation-wrapper/wp-navigation-wrapper.state';
import { props___SbLoginPage } from './pages/sb-login/sb-login.state';
import { state___App, state___App___default } from './App.state';

//	T Y P E S

import { type___api___response } from './types/types.api-response';
import { type___alert___controller } from "./types/types.alerts";
import { type___toast___controller } from "./types/types.toasts";

//	N A V I G A T I O N

import WpNavigationWrapper from './wrappers/wp-navigation-wrapper/wp-navigation-wrapper';

//	C O N T R O L L E R S 

import AlAlertController from './alerts/al-alert-controller';
import TsToastController from './toasts/ts-toast-controller';

//	C O M P O N E N T S

import ComBottomBarComponent from './components/com-bottombar/com-bottombar';
import ComNavComponent from './components/com-nav/com-nav';

//	P A G E S

import SbAnalyticsPage from './pages/sb-analytics/sb-analytics';

import SbCalendarPage from './pages/sb-calendar/sb-calendar';

import SbDashboardPage from './pages/sb-dashboard/sb-dashboard';

import SbInfoPage from './pages/sb-info/sb-info';
import SbInfoEulaPage from './pages/sb-info/sb-info-eula/sb-info-eula';
import SbInfoLicencePage from './pages/sb-info/sb-info-licence/sb-info-licence';

import SbLandingPage from './pages/sb-landing/sb-landing';

import SbLeadsPage from './pages/sb-leads/sb-leads';
import SbLeadsEditPage from './pages/sb-leads/sb-leads-edit/sb-leads-edit';
import SbLeadsListPage from './pages/sb-leads/sb-leads-list/sb-leads-list';
import SbLeadsViewPage from './pages/sb-leads/sb-leads-view/sb-leads-view';

import SbLoginPage from './pages/sb-login/sb-login';

import SbPartnersPage from './pages/sb-partners/sb-partners';
import SbPartnersEditPage from './pages/sb-partners/sb-partners-edit/sb-partners-edit';
import SbPartnersListPage from './pages/sb-partners/sb-partners-list/sb-partners-list';
import SbPartnersViewPage from './pages/sb-partners/sb-partners-view/sb-partners-view';

import SbSettingsPage from './pages/sb-settings/sb-settings';
import SbSettingsContractsPage from './pages/sb-settings/sb-settings-contracts/sb-settings-contracts';
import SbSettingsDealerPage from './pages/sb-settings/sb-settings-dealer/sb-settings-dealer';
import SbSettingsEulaPage from './pages/sb-info/sb-info-eula/sb-info-eula';
import SbSettingsImapsPage from './pages/sb-settings/sb-settings-imaps/sb-settings-imaps';
import SbSettingsLicencePage from './pages/sb-info/sb-info-licence/sb-info-licence';
import SbSettingsProfilePage from './pages/sb-settings/sb-settings-profile/sb-settings-profile';
import SbSettingsUsersPage from './pages/sb-settings/sb-settings-users/sb-settings-users';

import SbStandaloneDownloadPage from './pages/sb-standalone-download/sb-standalone-download';

import SbSyncPage from './pages/sb-sync/sb-sync';
import SbSyncCatalogPage from './pages/sb-sync/sb-sync-catalog/sb-sync-catalog';
import SbSyncConnectorPage from './pages/sb-sync/sb-sync-connector/sb-sync-connector';
import SbSyncVehiclesPage from './pages/sb-sync/sb-sync-vehicles/sb-sync-vehicles';

import SbVehiclesPage from './pages/sb-vehicles/sb-vehicles';
import SbVehiclesEditPage from './pages/sb-vehicles/sb-vehicles-edit/sb-vehicles-edit';
import SbVehiclesListPage from './pages/sb-vehicles/sb-vehicles-list/sb-vehicles-list';
import SbVehicleViewPage from './pages/sb-vehicles/sb-vehicles-view/sb-vehicles-view';
import SbInfoSupportPage from './pages/sb-info/sb-info-support/sb-info-support';
import SbSettingsGlobalPage from './pages/sb-settings/sb-settings-general/sb-settings-general';
import MdLicenceExpiredModal from './modals/md-licence-expired/md-licence-expired';

//	C L A S S

export default class App extends React.Component<{}, state___App>
{

//#region 																							D E C L A R A T I O N S

	private readonly _AUTH: service_RestAuthService = new service_RestAuthService();
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _LEADSSYNC: service_LeadsSync = new service_LeadsSync();

	private ___checkFor___intervalId: number | undefined = undefined;

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: {},
	) {
		super(_PROPS);
		this.state = state___App___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___me___is_logged = async () => {
		const ___stored___login_token: string | null = localStorage.getItem(lib_names.storage.user___logintoken);
		const ___is_logged: any | null = await this._AUTH.auth___checklogged((___stored___login_token ?? ''));
		if (___stored___login_token !== null || ___is_logged.is_logged === true)
		{
			this.setState({
				me___is_logged: true
			}, async() => {
				await this.read_props___me___datas();
			});
		}
		else { this.setState({ me___is_logged: false }); }
	}

	read_props___me___datas = async () => {
		const ___me_props___results: type___api___response = await this._API.users___read___single('me');
		if (___me_props___results.response === 'success')
		{
			this.setState({
				me___props: ___me_props___results.data
			}, async () => {
				await this.read_props___dealer___datas();
				await this.read_props___dealer___licence();
			});
		}
		else { this.setState({ me___props: null }); }
	}

	read_props___dealer___datas = async () => {
		const ___dealer___results: type___api___response = await this._API.dealers___read___single();
		if (___dealer___results.response === 'success') { this.setState({ dealer___props: ___dealer___results.data }); }
		else { this.setState({ dealer___props: null }); }
	}

	read_props___dealer___licence = async () => {
		const ___dealer___results: type___api___response = await this._API.dealers_licences___read___single();
		if (___dealer___results !== null)
		{
			this.setState({
				dealer___licence: ___dealer___results.data
			});
		}
		else
		{
			this.setState({
				dealer___licence: {
					licence_active: null,
					licence_active___analytics: null,
					licence_active___cloudDocs: null, 
					licence_active___contracts: null,
					licence_active___sync___catalog: null,
					licence_active___sync___leads: null,
					licence_active___sync___vehicles: null,
					licence_active___multiusers: null,
					licence_active___plugin___CarsHubConnector: null,
					licence_active___vehiclesDb___cars: null,
					licence_active___vehiclesDb___commercials: null,
					licence_active___vehiclesDb___motorcycles: null
				}
			});
		}
	}

//#endregion

//#region 																							H A N D L E R S

	handle___auth___set_logged = (___is_logged: boolean) => {
		this.setState({
			me___is_logged: ___is_logged
		});
	}
	
	handle___auth___set_props = (___me_props: any) => {
		this.setState({
			me___props: ___me_props
		});
	}
	
	handle___auth___logout = (___p: boolean) => {
		const ___alert_props : type___alert___controller = {
			alert___title: "Logout",
			alert___message : <>Stai per effettuare il logout, Continuare?</>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this.alert___event____setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-sign-out-alt',
					alert___button___text: 'Effettua il Logout',
					alert___button___action: async () => {
						this._AUTH.sign___logout();
						this.alert___event____setShowing(false);
					}
				}
			]
		}
		this.alert___event____setContent(___alert_props);
		this.alert___event____setShowing(true);
	}

//#endregion

//#region 																							A L E R T S

	alert___event____setContent = (___alert_props: type___alert___controller | null) => {
		if (___alert_props === null)
		{
			this.setState({
				GUI___alert___title: '',
				GUI___alert___message: '',
				GUI___alert___buttons: []
			});
		}
		else
		{
			this.setState({
				GUI___alert___title: ___alert_props.alert___title,
				GUI___alert___message: ___alert_props.alert___message,
				GUI___alert___buttons: ___alert_props.alert___buttons
			});
		}
	}

	alert___event____setShowing = (___show: boolean = true) => {
		const ___alert___will_show: boolean = ___show as boolean ?? false;
		this.setState({
			GUI___alert___is_showing: ___alert___will_show
		});
	}

//#endregion

//#region 																							T O A S T S

	toast___event___setContent = (___toast_props: type___toast___controller) => {
		this.setState({
			GUI___toast___inner: ___toast_props.toast___text,
			GUI___toast___color: ___toast_props.toast___color
		});
	}

	toast___event___setShowing = (___show: boolean = true) => {
		const ___toast___will_show: boolean = ___show as boolean ?? false;
		this.setState({
			GUI___toast___is_showing: ___toast___will_show
		});
	}

//#endregion

//#region 																							T O A S T S

	sync___leads_notifications = async () => {
		const ___sync_result___leads: number = await this._LEADSSYNC.syncService();
		this.setState({
			notifications___leads: ___sync_result___leads
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		await this.read_props___me___is_logged()
	}

	async componentDidUpdate(prevProps: {}, prevState: state___App) : Promise<void> {
		if (this.state.me___is_logged !== prevState.me___is_logged && this.state.me___is_logged === true)
		{
			await this.sync___leads_notifications();
			this.___checkFor___intervalId = window.setInterval(async () => {
				await this.sync___leads_notifications();
			}, lib_names.sync.interval);
		}
	}

	componentWillUnmount() : void {
		if (this.___checkFor___intervalId) {
			clearInterval(this.___checkFor___intervalId);
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		
		const ___n_wrapper___props: props___WpNavigationWrapper___functions = {
			alert___setAlert: this.alert___event____setContent,
			alert___setShowing: this.alert___event____setShowing,
			toast___setContent: this.toast___event___setContent,
			toast___setShowing: this.toast___event___setShowing,
			props___licence: this.state.dealer___licence,
		};
	
		const ___login___props: props___SbLoginPage = {
			event_setMeIsLogged: this.handle___auth___set_logged,
			event_setMeProps: this.handle___auth___set_props,
			toast___setContent: this.toast___event___setContent,
			toast___setShowing: this.toast___event___setShowing,
		}

		return <>
			<Router>
				{(this.state.dealer___licence.licence_active === false) ? <>
					<MdLicenceExpiredModal/>
				</> : <></>}
				{(this.state.GUI___toast___is_showing === true) ? <>
					<TsToastController event_onDismiss={() => { this.setState({ GUI___toast___is_showing: false, GUI___toast___inner: null }); }} toast___text={this.state.GUI___toast___inner} toast___color={this.state.GUI___toast___color} />
				</> : <></>}
				{(() => {
					switch (this.state.me___is_logged)
					{
						case true:
							if (this.state.me___props !== null && this.state.dealer___props !== null) { return (<>
								{(this.state.GUI___alert___is_showing === true) ? <><AlAlertController event___onDidDismiss={() => { this.setState({ GUI___alert___is_showing: false }); }} alert___title={this.state.GUI___alert___title} alert___message={this.state.GUI___alert___message} alert___buttons={this.state.GUI___alert___buttons} /></> : <></>}
								<ComNavComponent notification___leads={ this.state.notifications___leads } me___props___dealer={ this.state.dealer___props } me___props={ this.state.me___props } dealer___licence={ this.state.dealer___licence } event_performLogOut={ this.handle___auth___logout } />
								<Routes>

									{/*      d a s h b o a r d      */}

									<Route index element={<Navigate replace to="/dashboard" />} />
									<Route path="*" element={<Navigate replace to="/dashboard" />} />
									<Route path="dashboard" element={<SbDashboardPage {...___n_wrapper___props} />} />
									<Route path="calendar" element={<SbCalendarPage {...___n_wrapper___props} />} />
									{(this.state.dealer___licence['licence_active___analytics'] === true && (this.state.me___props['user_role'] === 'admin' || this.state.me___props['user_role'] === 'support')) ? <><Route path="analytics" element={<SbAnalyticsPage {...___n_wrapper___props} />} /></> : <></>}

									{/*      v e h i c l e s      */}

									<Route path="/vehicles" element={<SbVehiclesPage />}>
										<Route path="create" element={<WpNavigationWrapper {...___n_wrapper___props} component={ SbVehiclesEditPage } />} />
										<Route path="edit/:id" element={<WpNavigationWrapper {...___n_wrapper___props} component={ SbVehiclesEditPage } />} />
										<Route path="list" element={<SbVehiclesListPage {...___n_wrapper___props} />} />
										<Route path="view/:id" element={<WpNavigationWrapper {...___n_wrapper___props} component={ SbVehicleViewPage } />} />
									</Route>

									{/*      p a r t n e r s      */}

									<Route path="/partners" element={<SbPartnersPage />}>
										<Route path="create" element={<WpNavigationWrapper {...___n_wrapper___props} component={ SbPartnersEditPage } />} />
										<Route path="edit/:id" element={<WpNavigationWrapper {...___n_wrapper___props} component={ SbPartnersEditPage } />} />
										<Route path="list" element={<SbPartnersListPage {...___n_wrapper___props} />} />
										<Route path="view/:id" element={<WpNavigationWrapper {...___n_wrapper___props} component={ SbPartnersViewPage }/>} />
									</Route>

									{/*      l e a d s      */}

									<Route path="/leads" element={<SbLeadsPage />}>
										<Route path="create" element={<SbLeadsEditPage {...___n_wrapper___props}/>} />
										<Route path="edit/:id" element={<SbLeadsEditPage {...___n_wrapper___props}/>} />
										<Route path="view/:id" element={<WpNavigationWrapper {...___n_wrapper___props} component={ SbLeadsViewPage } />} />
										<Route path="list" element={<SbLeadsListPage {...___n_wrapper___props} />} />
									</Route>

									{/*      s y n c       */}

									{((this.state.me___props['user_role'] === 'admin' || this.state.me___props['user_role'] === 'support') && (this.state.dealer___licence['licence_active___sync___catalog'] === true || this.state.dealer___licence['licence_active___sync___leads'] === true || this.state.dealer___licence['licence_active___sync___vehicles'])) ? <>
										<Route path="/sync" element={<SbSyncPage />}>
											{(this.state.dealer___licence['licence_active___sync___catalog'] === true) ? <><Route path="catalog" element={<SbSyncCatalogPage {...___n_wrapper___props} />} /></> : <></>}
											{(this.state.dealer___licence['licence_active___sync___vehicles'] === true) ? <><Route path="vehicles" element={<SbSyncVehiclesPage {...___n_wrapper___props} />} /></> : <></>}
											{(this.state.dealer___licence['licence_active___plugin___CarsHubConnector'] === true) ? <><Route path="connector" element={<SbSyncConnectorPage {...___n_wrapper___props} />} /></> : <></>}
										</Route>
									</> : <></>}

									{/*      s e t t i n g s      */}

									<Route path="/settings" element={<SbSettingsPage />}>
										<Route path="dealer" element={<SbSettingsDealerPage {...___n_wrapper___props}/>} />
										<Route path="general" element={<SbSettingsGlobalPage {...___n_wrapper___props}/>} />
										<Route path="profile" element={<SbSettingsProfilePage {...___n_wrapper___props}/>} />
										{(this.state.dealer___licence['licence_active___sync___leads'] === true) ? <><Route path="imaps" element={<SbSettingsImapsPage {...___n_wrapper___props} />} /></> : <></>}
										{((this.state.me___props['user_role'] === 'admin' || this.state.me___props['user_role'] === 'support')) ? <>
											<Route path="licence" element={<SbSettingsLicencePage {...___n_wrapper___props}/>} />
											{(this.state.dealer___licence['licence_active___contracts'] === true) ? <><Route path="contracts" element={<SbSettingsContractsPage {...___n_wrapper___props} />} /></> : <></>}
											{(this.state.dealer___licence['licence_active___multiusers'] === true) ? <><Route path="users" element={<SbSettingsUsersPage {...___n_wrapper___props} />} /></> : <></>}
										</> : <></>}
										<Route path="eula" element={<SbSettingsEulaPage />} />
									</Route>

									{/*      i n f o      */}

									<Route path="/info" element={<SbInfoPage />}>
										<Route path="eula" element={<SbInfoEulaPage {...___n_wrapper___props}/>} />
										<Route path="licence" element={<SbInfoLicencePage {...___n_wrapper___props}/>} />
										<Route path="support" element={<SbInfoSupportPage {...___n_wrapper___props}/>} />
									</Route>

									{/*      s t a n d a l o n e   d o w n l o a d      */}

									<Route path="/standalone" element={<SbSettingsPage />}>
										<Route path="download" element={<SbStandaloneDownloadPage {...___n_wrapper___props}/>} />
									</Route>

								</Routes>
								<ComBottomBarComponent me___props={ this.state.me___props}/>
							</>)} else {
								return <>
									<SbLandingPage />
								</>;
							};
						case false: return (<>
							<SbLoginPage {...___login___props}/>
						</>);
						case null: return (<>
							<SbLandingPage />
						</>);
					}
				})()}
			</Router>
		</>;

	}

//#endregion

}