//	P R O P S

export interface props___ComBottomBarComponent {

	me___props: any | null,

}

//	S T A T E

export interface state___ComBottomBarComponent {

	is_online___status: boolean | null,

	version___version: string,
	version___build: string,

}

//	S T A T E   D E F A U L T

export const state___ComBottomBarComponent___default: state___ComBottomBarComponent = {

	is_online___status: null,

	version___version: '×.×.×',
	version___build: '×××××',

}