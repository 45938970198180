import React from "react";
import { Link } from "react-router-dom";

//	S T Y L E

import "./com-vehicle.scss";

//	T Y P E S

import { type___vehicle___price_vat_type } from "../../types/types.types";

//	L I B S

import { lib_names } from "../../libs/lib.names";
import { lib_vehicle_attributes } from "../../libs/lib.attributes";

//	F U N C S

import { funcs_numbers } from "../../funcs/funcs.numbers";
import { funcs_text } from "../../funcs/funcs.text";

//	S T A T E

import { props___ComVehicleComponent } from "./com-vehicle.state";

//	C L A S S

export default class ComVehicleComponent extends React.Component<props___ComVehicleComponent, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly lib___vehicle_attributes: lib_vehicle_attributes = new lib_vehicle_attributes();

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();
	private readonly funcs___text: funcs_text = new funcs_text();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComVehicleComponent
	) {
		super(props);
	}

//#endregion

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return <>
			<div className={ 'vehicle-card---container ' + ((this.props.option___card && this.props.option___card === true) ? ' is-card ' : ' is-card no-border')}>
				<section>
					<section>
						{(this.props.vehicle___props['vehicle_gallery_pictures'].length > 0) ? <>
							<img src={ lib_names.cdn.endpoint + this.props.vehicle___props['vehicle_gallery_pictures'][0] }/>
						</> : <>
							<img src={ lib_names.media.placeholders.vehicle.generic }/>
						</>}
					</section>
					<section>
						<h5>{ this.lib___vehicle_attributes.get___vehicle_attribute___name___property('vehicle_category', this.props.vehicle___props['vehicle_conditions___category']) }</h5>
						<h3>
							<span>{ this.props.vehicle___props['vehicle_name'] }</span> 
						{/*	<span>{ this.props.vehicle___props['vehicle_version'] }</span>	*/}
						</h3>
						{(this.props.option___description && this.props.option___description === true) ? <>
							<p>{ this.funcs___text.chars___limit(this.props.vehicle___props['vehicle_info___description'], 90) }</p>
						</> : <></>}
						<section>
							<p>{ this.funcs___numbers.format___price(this.props.vehicle___props['vehicle_accounting___price_sell']) }</p>
							<p>
								<>IVA { this.props.vehicle___props['vehicle_accounting___price_sell_vat'] }%</>
								{(() => {
									switch (this.props.vehicle___props['vehicle_accounting___price_sell_vat_exposed'] as type___vehicle___price_vat_type) {
										case 'exposed': return <>Esposta</>; break;
										case 'included': return <>Inclusa</>; break;
										case 'margin': return <>a Margine</>; break;
										case 'partial': return <>Parziale</>; break;
									}
								})()}
							</p>
						</section>
					</section>
				</section>
				<section>
					{(this.props.option___buttons && this.props.option___buttons.length > 0) ? <>
						{this.props.option___buttons.map((___b: any, ___b_idx: number) => { 
							return <>{ ___b }</>;
						})}
					</> : <></>}
					<Link to={ '/vehicles/view/' + this.props.vehicle___props['vehicle_id'] } className="vehicle-single---open" target={ (this.props.option___redirect && this.props.option___redirect === 'redirect') ? '_blank' : '_self' }>
						<span>Visualizza Veicolo</span>
						<i className="fas fa-angle-right"></i>
					</Link>
				</section>
			</div>
		</>;
	}

//#endregion

}