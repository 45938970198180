import React from "react";
import { Link } from "react-router-dom";

//	S T Y L E

import "./sb-sync-catalog.scss";

//	L I B S

import { lib_names } from "../../../libs/lib.names";

//	T Y P E S

import { type___api___response } from "../../../types/types.api-response";

//	S E R V I C E S

import { service_RestApiService } from "../../../services/service-api";

//	S T A T E S

import { props___SbSyncCatalogPage, state___SbSyncCatalogPage, state___SbSyncCatalogPage___defaults } from "./sb-sync-catalog.state";

//	C O M P O N E N T S

import ComButtonCopierComponent from "../../../components/com-button-copier/com-button-copier";
import ComResultsEmptyComponent from "../../../components/com-results-empty/com-results-empty";
import ComSpinnerComponent from "../../../components/com-spinner/com-spinner";

//	C L A S S

export default class SbSyncCatalogPage extends React.Component<props___SbSyncCatalogPage, state___SbSyncCatalogPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();
	
//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbSyncCatalogPage,
	)
	{
		super(_PROPS);
		this.state = state___SbSyncCatalogPage___defaults;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___dealer = async () => {
		const ___dealer___props___importers: type___api___response = await this._API.dealers___read___importer_credentials___vehicles();
		this.setState({
			dealer___props___importers: ___dealer___props___importers.data,
			catalog_url___meta: lib_names.endpoints.catalogs.meta + ___dealer___props___importers.data['dealer_sync___catalog_key'],
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___select___tab = async (___e: state___SbSyncCatalogPage['selected___catalog___tab']) => {
		this.setState({
			selected___catalog___tab: ___e,
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		await this.read_props___dealer();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="page---container is-full-width">
				<section>
					<div className="impoter---container---flexy">

						<div>
							<div className="page---top-bar page---top-bar---is-sidebar page---top-bar---is-sidebar---vehicles-import">
								<section>
									<input type="radio" id="d-ts---csec---subito_it" name="d-ts---cb" value="subito_it" checked={ this.state.selected___catalog___tab === 'meta' } onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }}/>
									<label htmlFor="d-ts---csec---subito_it" className="is-card">
										<img src="/assets/third/third.meta.png" />
									</label>
								</section>
							</div>
						</div>
						{(() => {
							switch (this.state.selected___catalog___tab) {
								case 'meta': return <>
									<div className="is-card sync-catalog---container">
										<div className="sync-catalog---container---logo">
											<img src="/assets/third/third.meta.png" />
										</div>
										<br />
										<h2 className="input---nb-title">
											Condivisione con i cataloghi Meta
										</h2>
										<span className="input---nb-label" style={{lineHeight:'14pt'}}>
											questo URL serve a <a target="_blank" href="https://www.facebook.com/business/help/125074381480892?id=725943027795860" style={{textDecoration:'underine'}}>Cataloghi Meta</a> per interfacciarsi con 
											il nostro elenco di dati. è un url che contiene l'elenco dei veicoli nel tuo catalogo. Il catalogo Meta sarà aggiornato in tempo reale
										</span>
										<br />
										<br />
										{(this.state.dealer___props___importers !== null) ? <>
											<div className="sync-catalog---container---url">
												<div>
													<section className="input---nb-outlined---flexy">
														<p>URL</p>
														<input type="text" value={ this.state.catalog_url___meta } disabled={true}/>
														<ComButtonCopierComponent to_copy={ this.state.catalog_url___meta } toast___setContent={ this.props.toast___setContent } toast___setShowing={ this.props.toast___setShowing }/>
													</section>
													<span></span>
												</div>
											</div>
											<br />
											<div className="page-container---bottom-bar---buttons is-centered">
												<section>
													<Link to={ this.state.catalog_url___meta } className="is-card is-primary no-shadow">
														<i className="fas fa-download"></i>
														<span>Scarica File di Test</span>
													</Link>
												</section>
											</div>
										</> : <>
											<ComSpinnerComponent></ComSpinnerComponent>
										</>}
										<br />
										<br />
										<br />
									</div>
								</>; break;
								case '':
								default: return <>
									<div className="is-card import-from---welcome---container">
										<ComResultsEmptyComponent text="Decidi cosa importare"/>
									</div>
								</>; break;
							}
						})()}
					</div>
				</section>
			</div>
		</>;
	}

//#endregion

}




