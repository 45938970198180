import { props___page___hasAlert } from "../../interfaces/interface.alerts"
import { props___page___hasLicence } from "../../interfaces/interface.licence"
import { props___page___hasToast } from "../../interfaces/interface.toasts"
import { type___lead___status } from "../../types/types.types"

//	P R O P S

export interface props___SbDashboardPage extends props___page___hasAlert, props___page___hasToast, props___page___hasLicence {

}

//	S T A T E

export interface state___SbDashboardPage {
	
	selected___tab: type___lead___status | 'today',

	dealer___props: any | null,
	dealer___props___is_importing: boolean,

	leads___count___new: number,
	leads___count___working: number,
	leads___count___ready: number,
	leads___count___sold: number,
	leads___count___archived: number,

	leads___list: any[],
	leads___list___is_loading: boolean,

	GUI___tabs___hidden_show: boolean,
	GUI___page___is_loading: boolean,
	GUI___page___is_loaded: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbDashboardPage___defaults : state___SbDashboardPage = {

	selected___tab : 'new',

	dealer___props: null,
	dealer___props___is_importing: false,

	leads___count___new: 0,
	leads___count___working: 0,
	leads___count___ready: 0,
	leads___count___sold: 0,
	leads___count___archived: 0,

	leads___list : [],
	leads___list___is_loading: false,

	GUI___tabs___hidden_show: false,
	GUI___page___is_loading: true,
	GUI___page___is_loaded: false,

}