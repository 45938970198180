import { type___modals___controller } from "../../types/types.modals"
import { type___document___type } from "../../types/types.types"

//	P R O P S

export interface props___MdDocumentUploaderModal extends type___modals___controller {
	
	target: type___document___type,
	target_param: string,

}

//	S T A T E

export interface state___MdDocumentUploaderModal {

	field___file___title: string
	field___file___name: string,
	field___file: string,
	field___file___size: number,

	GUI___modal___is_uploading: boolean,

}

//	S T A T E   D E F A U L T 

export const state___MdDocumentUploaderModal___default: state___MdDocumentUploaderModal = {

	field___file___title: '',
	field___file___name: '',
	field___file: '',
	field___file___size: 0,

	GUI___modal___is_uploading: false,

}