import { props___page___hasAlert } from "../../../interfaces/interface.alerts"
import { props___page___hasLicence } from "../../../interfaces/interface.licence"
import { props___page___hasToast } from "../../../interfaces/interface.toasts"
import { type___params__url } from "../../../types/types.params"
import { type___vehicle___type, type___vehicle___condition, type___vehicle___visibility, type___vehicle___warranty, type___vehicle___price_vat_type, type___vehicle___power } from "../../../types/types.types"

//	P R O P S

export interface props___SbVehiclesEditPage extends props___page___hasAlert, props___page___hasToast, props___page___hasLicence {
	
	params: type___params__url,

}

//	S T A T E

export interface state___SbVehiclesEditPage {

	search___partners___results: any[],

	loaded_props___partners: any[],
	loaded_props___vehicle___brands: any[],
	loaded_props___vehicle___models: any[],
	loaded_props___vehicle___versions: any[],
	loaded_props___vehicle___variants: any[],

	field___id: string | null,
	
	field___vehicle_category: type___vehicle___type | null,

	field___vehicle_brand: string,
	field___vehicle_model: string,
	field___vehicle_version: string,
	field___vehicle_variant: string,

	field___vehicle_name: string,

	field___gallery_pictures: string[],
	field___gallery_360: string | null,
	field___gallery_video: string | '',

	field___conditions___category: string,
	field___conditions___km: number | null,
	field___conditions___registration_month: string,
	field___conditions___registration_year: number | null,
	field___conditions___owners_number: number | null,

	field___specs___engine_fuel_type: string,
	field___specs___engine_name: string,
	field___specs___engine_cylinders_displacement: string,
	field___specs___engine_cylinders_number: number | null,
	field___specs___engine_capacity: number | null,
	field___specs___engine_power_unit: type___vehicle___power,
	field___specs___engine_power: number | null,
	field___specs___gearbox_type: string,
	field___specs___gearbox_gears: number | null,
	field___specs___transmission_drive: string,
	field___specs___brakes_front_type: string,
	field___specs___brakes_rear_type: string,
	field___specs___emission_class: string,

	field___config___body_type: string,
	field___config___body_doors_number: number | null,
	field___config___paint_type: string,
	field___config___paint_color: string,
	field___config___interior_material: string,
	field___config___interior_color: string,
	field___config___interior_details: string,
	field___config___wheel_material: string | '', 
	field___config___wheel_tyres: string,
	field___config___optionals: string[],

	field___efficiency___engine: type___vehicle___condition, 
	field___efficiency___power_supply: type___vehicle___condition, 
	field___efficiency___exhaust: type___vehicle___condition, 
	field___efficiency___exhaust_emissions: type___vehicle___condition, 
	field___efficiency___leaks: type___vehicle___condition, 
	field___efficiency___engine_cooling: type___vehicle___condition, 
	field___efficiency___gearbox: type___vehicle___condition, 
	field___efficiency___clutch: type___vehicle___condition, 
	field___efficiency___brakes: type___vehicle___condition, 
	field___efficiency___steering: type___vehicle___condition, 
	field___efficiency___suspensions: type___vehicle___condition, 
	field___efficiency___wheels: type___vehicle___condition, 
	field___efficiency___noise: type___vehicle___condition, 
	field___efficiency___battery: type___vehicle___condition, 
	field___efficiency___engine_starter: type___vehicle___condition, 
	field___efficiency___lights: type___vehicle___condition, 
	field___efficiency___windows: type___vehicle___condition, 
	field___efficiency___windows_wipers: type___vehicle___condition, 
	field___efficiency___warnings: type___vehicle___condition, 
	field___efficiency___air_conditioning: type___vehicle___condition, 
	field___efficiency___mirrors: type___vehicle___condition, 
	field___efficiency___seatbelts: type___vehicle___condition,
	field___efficiency___interior: type___vehicle___condition, 
	field___efficiency___interior_seats: type___vehicle___condition, 
	field___efficiency___dashboard: type___vehicle___condition, 
	field___efficiency___body___01: type___vehicle___condition, 
	field___efficiency___body___02: type___vehicle___condition, 
	field___efficiency___body___03: type___vehicle___condition, 
	field___efficiency___body___04: type___vehicle___condition, 
	field___efficiency___body___05: type___vehicle___condition, 
	field___efficiency___body___06: type___vehicle___condition, 
	field___efficiency___body___07: type___vehicle___condition, 
	field___efficiency___body___08: type___vehicle___condition, 
	field___efficiency___body___09: type___vehicle___condition, 
	field___efficiency___body___10: type___vehicle___condition, 
	field___efficiency___body___11: type___vehicle___condition, 
	field___efficiency___body___12: type___vehicle___condition, 
	field___efficiency___body___13: type___vehicle___condition, 
	field___efficiency___body___14: type___vehicle___condition, 
	field___efficiency___body___15: type___vehicle___condition, 
	field___efficiency___body___16: type___vehicle___condition, 
	field___efficiency___body___17: type___vehicle___condition, 
	field___efficiency___body___18: type___vehicle___condition, 
	field___efficiency___body___19: type___vehicle___condition, 
	field___efficiency___body___20: type___vehicle___condition, 
	field___efficiency___body___21: type___vehicle___condition, 

	field___info___description: string,

	field___registration___from_type: string,
	field___registration___from_user: string,
	field___registration___plate: string,
	field___registration___vin: string,

	field___accounting___price_bought: number | null,
	field___accounting___price_bought_vat: string,
	field___accounting___price_bought_vat_type: type___vehicle___price_vat_type,
	field___accounting___price_sell: number | null, 
	field___accounting___price_sell_vat: string,
	field___accounting___price_sell_vat_type: type___vehicle___price_vat_type,

	field___vehicle___visibility: type___vehicle___visibility,

	can_submit___section___info: boolean,
	can_submit___section___media: boolean,
	can_submit___section___conditions: boolean,
	can_submit___section___specs: boolean,
	can_submit___section___config: boolean,
	can_submit___section___efficiency: boolean,
	can_submit___section___description: boolean,
	can_submit___section___catalog: boolean,
	can_submit___section___sale: boolean,
	can_submit___section___cross_posting: boolean,

	vehicle___is_submitted: boolean,
	vehicle___is_submitted___result: boolean | null,

	GUI___section___total: number,
	GUI___section___selected: number,

	GUI___page___is_loading: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbVehiclesEditPage___default: state___SbVehiclesEditPage = {

	search___partners___results: [],

	loaded_props___partners: [],
	loaded_props___vehicle___brands: [],
	loaded_props___vehicle___models: [],
	loaded_props___vehicle___versions: [],
	loaded_props___vehicle___variants: [],

	field___id: null,

	field___vehicle_category: null,

	field___vehicle_brand: 'null',
	field___vehicle_model: 'null',
	field___vehicle_version: 'null',
	field___vehicle_variant: 'null',

	field___vehicle_name: '',

	field___gallery_pictures: [],
	field___gallery_360: null,
	field___gallery_video: '',

	field___conditions___category: '',
	field___conditions___km: null,
	field___conditions___registration_month: '',
	field___conditions___registration_year: null,
	field___conditions___owners_number: null,

	field___specs___engine_fuel_type: '',
	field___specs___engine_name: '',
	field___specs___engine_cylinders_displacement: '',
	field___specs___engine_cylinders_number: null,
	field___specs___engine_capacity: null,
	field___specs___engine_power_unit: 'hp',
	field___specs___engine_power: null,
	field___specs___emission_class: '',

	field___specs___gearbox_type: '',
	field___specs___gearbox_gears: null,

	field___specs___transmission_drive: '',

	field___specs___brakes_front_type: '',
	field___specs___brakes_rear_type: '',

	field___config___body_type: '',
	field___config___body_doors_number: null,
	field___config___paint_type: '',
	field___config___paint_color: '',
	field___config___interior_material: '',
	field___config___interior_color: '',
	field___config___interior_details: '',
	field___config___wheel_material: '', 
	field___config___wheel_tyres: '',
	field___config___optionals: [],

	field___efficiency___engine: 'normal', 
	field___efficiency___power_supply: 'normal', 
	field___efficiency___exhaust: 'normal', 
	field___efficiency___exhaust_emissions: 'normal', 
	field___efficiency___leaks: 'normal', 
	field___efficiency___engine_cooling: 'normal', 
	field___efficiency___gearbox: 'normal', 
	field___efficiency___clutch: 'normal', 
	field___efficiency___brakes: 'normal', 
	field___efficiency___steering: 'normal', 
	field___efficiency___suspensions: 'normal', 
	field___efficiency___wheels: 'normal', 
	field___efficiency___noise: 'normal', 
	field___efficiency___battery: 'normal', 
	field___efficiency___engine_starter: 'normal', 
	field___efficiency___lights: 'normal', 
	field___efficiency___windows: 'normal', 
	field___efficiency___windows_wipers: 'normal', 
	field___efficiency___warnings: 'normal', 
	field___efficiency___air_conditioning: 'normal', 
	field___efficiency___mirrors: 'normal', 
	field___efficiency___seatbelts: 'normal', 
	field___efficiency___interior: 'normal', 
	field___efficiency___interior_seats: 'normal', 
	field___efficiency___dashboard: 'normal', 
	field___efficiency___body___01: 'normal', 
	field___efficiency___body___02: 'normal', 
	field___efficiency___body___03: 'normal', 
	field___efficiency___body___04: 'normal', 
	field___efficiency___body___05: 'normal', 
	field___efficiency___body___06: 'normal', 
	field___efficiency___body___07: 'normal', 
	field___efficiency___body___08: 'normal', 
	field___efficiency___body___09: 'normal', 
	field___efficiency___body___10: 'normal', 
	field___efficiency___body___11: 'normal', 
	field___efficiency___body___12: 'normal', 
	field___efficiency___body___13: 'normal', 
	field___efficiency___body___14: 'normal', 
	field___efficiency___body___15: 'normal', 
	field___efficiency___body___16: 'normal', 
	field___efficiency___body___17: 'normal', 
	field___efficiency___body___18: 'normal', 
	field___efficiency___body___19: 'normal', 
	field___efficiency___body___20: 'normal', 
	field___efficiency___body___21: 'normal', 

	field___info___description: '',

	field___registration___from_type: '',
	field___registration___from_user: '',
	field___registration___plate: '',
	field___registration___vin: '',

	field___accounting___price_bought: null,
	field___accounting___price_bought_vat: '22',
	field___accounting___price_bought_vat_type: 'exposed',
	field___accounting___price_sell: null, 
	field___accounting___price_sell_vat: '22',
	field___accounting___price_sell_vat_type: 'exposed',

	field___vehicle___visibility: 'visible',

	can_submit___section___info: false,
	can_submit___section___media: false,
	can_submit___section___conditions: false,
	can_submit___section___specs: false,
	can_submit___section___config: false,
	can_submit___section___efficiency: true,
	can_submit___section___description: false,
	can_submit___section___catalog: false,
	can_submit___section___sale: false,
	can_submit___section___cross_posting: false,

	vehicle___is_submitted: false,
	vehicle___is_submitted___result: null,
	
	GUI___section___total: 9,
	GUI___section___selected: 0,

	GUI___page___is_loading: true,

}