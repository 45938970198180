import React from "react";
import { Link } from "react-router-dom";

//	S T Y L E

import "./sb-vehicles-list.scss"

//	L I B S

import { lib_names } from "../../../libs/lib.names";

//	F U N C S

import { funcs_datetime } from "../../../funcs/funcs.datetime";
import { funcs_numbers } from "../../../funcs/funcs.numbers";

//	T Y P E S

import { type___alert___controller } from "../../../types/types.alerts";
import { type___api___response } from "../../../types/types.api-response";
import { type___request___order, type___vehicle___visibility } from "../../../types/types.types";
import { type___toast___controller } from "../../../types/types.toasts";

//	S T A T E S

import { props___SbVehiclesListPage, state___SbVehiclesListPage, state___SbVehiclesListPage___default } from "./sb-vehicles-list.state";

//	S E R V I C E S

import { service_RestApiService } from "../../../services/service-api";

//	C O M P O N E N T S

import ComResultsMoreComponent from "../../../components/com-results-more/com-results-more";

//	C L A S S

export default class SbVehiclesListPage extends React.Component<props___SbVehiclesListPage, state___SbVehiclesListPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbVehiclesListPage
	) {
		super(_PROPS)
		this.state = state___SbVehiclesListPage___default
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___dealer = async () => {
		const ___dealer___found: type___api___response = await this._API.dealers___read___single();
		this.setState({
			vehicles_list___is_importing: (___dealer___found.data) ? ___dealer___found.data['dealer_state_importing_vehicles'] : false,
		});
	}

	read_props___vehicles_list = async (___is_reset: boolean = true) => {

		await new Promise<void>((resolve) => this.setState({
			vehicles_list: (___is_reset === true) ? [] : this.state.vehicles_list,
			vehicles_list___is_loading: true
		}, () => resolve()));

		const ___vehicles___founds: type___api___response = await this._API.vehicles___read___multi('dealer_search', this.state.search_param___vehicles, this.state.vehicles_list.length, this.state.vehicles_list___order);
		switch (___is_reset) {
			case true: this.setState({ vehicles_list: ___vehicles___founds.data }); break;
			case false: this.setState({ vehicles_list: [...this.state.vehicles_list, ...___vehicles___founds.data]}); break;
		}

		await new Promise<void>((resolve) => this.setState({
			vehicles_list___is_loading: false
		}, () => resolve()));

	}

//#endregion

//#region 																							H A N D L E R S

	handle___vehicles___sortBy = (___param: string) => {

		let ___new___direction: type___request___order['direction'];
		const ___old___param: string = this.state.vehicles_list___order['param'];
		if (___old___param === ___param) {
			___new___direction = (this.state.vehicles_list___order['direction'] === 1) ? -1 : 1;
		} else {
			___new___direction = 1;
		}

		this.setState({
			vehicles_list___order: {
				param: ___param,
				direction: ___new___direction
			}
		}, async () => {
			await this.read_props___vehicles_list(true);
		});

	}

	handle___vehicles___search = (___e: any) => {
		this.setState({
			search_param___vehicles: ___e.target.value
		}, async () => {
			await this.read_props___vehicles_list(true);
		});
	}

	handle___vehicles____select_visibility = async (___vehicle: any, ___e: any, ___i: number) => {
		const ___udpated_vehicle___new_state: type___vehicle___visibility = ___e.target.value as type___vehicle___visibility;
		const ___udpated_vehicle: type___api___response = await this._API.vehicles___update___visibility(___vehicle['vehicle_id'], ___udpated_vehicle___new_state);
		if (___udpated_vehicle['response'] === 'success')
		{
			let ___temp___vehicle_list: typeof state___SbVehiclesListPage___default['vehicles_list'] = [...this.state.vehicles_list];
			if (___temp___vehicle_list[___i]) { ___temp___vehicle_list[___i] = { ...___temp___vehicle_list[___i], vehicle_visibility: ___udpated_vehicle___new_state }; }
			this.setState({ vehicles_list: ___temp___vehicle_list });
			let ___udpated_vehicle___new_state____text: string = '';
			switch (___udpated_vehicle___new_state) {
				case 'visible': ___udpated_vehicle___new_state____text = 'Visibile pubblicamente'; break;
				case 'hidden': ___udpated_vehicle___new_state____text = 'Nascosto'; break;
			}
			const ___toast_props: type___toast___controller = {
				toast___color: 'success',
				toast___text: <>Hai modificato il veicolo "<b>{ ___vehicle['vehicle_name'] }</b>" con "<b>{ ___udpated_vehicle___new_state____text }</b>"</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}
		else
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'danger',
				toast___text: <>Errore generico nella modifica della visibilità del veicolo</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}
	}

	handle___vehicles___delete = (___vehicle: any, ___remove_index: number) => {
		const ___alert_props : type___alert___controller = {
			alert___title: "Conferma eliminazione",
			alert___message : <>Vuoi <b>ELIMINARE</b> il veicolo "<b>{ ___vehicle['vehicle_name'] }</b>"?<br/><br/><b style={{textDecoration:'underline'}}>AZIONE IRREVERSIBILE</b></>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si, elimina',
					alert___button___action: async () => {
						let ___removed_vehicle___local_list: any[] = this.state.vehicles_list;
							___removed_vehicle___local_list.splice(___remove_index, 1);
						this.setState({ vehicles_list: ___removed_vehicle___local_list });
						this._PROPS.alert___setShowing(false);
						const ___removed_vehicle: type___api___response = await this._API.vehicles___delete(___vehicle['vehicle_id']);
						if (___removed_vehicle['response'] === 'success')
						{
							await this.read_props___vehicles_list(true);
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Hai eliminato il veicolo "<b>{ ___vehicle['vehicle_name'] } - { ___vehicle['vehicle_version'] }</b>"</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore generico nell'eliminazione del veicolo</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

	handle___vehicles___delete___sold_vehicle = (___vehicle: any, ___remove_index: number) => {
		const ___alert_props : type___alert___controller = {
			alert___title: "Eliminazione di un veicolo venduto",
			alert___message : <>L'eliminazione di un veicolo venduto comporta l'esecuzione di alcune procedure sul database e potrebbe influire negativamente sulle statistiche attuali, per <b>continuare nell'eliminazione contatta l'assistenza</b>, lo faremo noi per te</>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Ok',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

	handle___vehicles_import___begin = () => {
		const ___alert_props : type___alert___controller = {
			alert___title: 'Conferma Importazione',
			alert___message : <>Vuoi importare i veicoli?<br/><br/>La procedura può durare da qualche secondo a qualche minuto e verrà eseguita in background, senza sovrascrivere i veicoli esistenti</>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si',
					alert___button___action: async () => {
						this.setState({
							GUI___page___is_importing: true,
						}, async () => {
							const ___vehicles___imported: type___api___response = await this._API.sync_vehicles___from___subito_it();
							if (___vehicles___imported['response'] === 'success')
							{
								const ___toast_props: type___toast___controller = {
									toast___color: 'success',
									toast___text: <>Hai importato correttamente i veicoli presenti sui tuoi servizi connessi</>
								}
								this._PROPS.toast___setContent(___toast_props);
								this._PROPS.toast___setShowing(true);
							}
							else
							{
								const ___toast_props: type___toast___controller = {
									toast___color: 'danger',
									toast___text: <>Non è stato possibile importare tutti i veicoli, riprova più tardi...</>
								}
								this._PROPS.toast___setContent(___toast_props);
								this._PROPS.toast___setShowing(true);
							}
						});
						this._PROPS.alert___setShowing(false);
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}


	handle___vehicles_import___reset = () => {
		const ___alert_props : type___alert___controller = {
			alert___title: 'Conferma Annullamento Importazione',
			alert___message : <>Vuoi annullare l'importazione dei veicoli?<br/><br/>La procedura annulla l'importazione in background, è possibile che alla successiva importazione si presentino veicoli dupilicati<br/><br/>Utilizza questa funzione nel caso l'importazione si blocchi o duri più del dovuto</>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Chiudi',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si, annulla',
					alert___button___action: async () => {
						const ___vehicles___imported: type___api___response = await this._API.sync_vehicles___reset();
						this.setState({
							GUI___page___is_importing: (___vehicles___imported['response'] === 'success') ? true : false,
						}, async () => {
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Hai annullato il processo di sincronizzazione dei veicoli</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
							this._PROPS.alert___setShowing(false);
						});
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount(): Promise<void>
	{
		await this.read_props___dealer();
		await this.read_props___vehicles_list(true);
	}

//#endregion

//#region 																							R E A D   P R O P S

	render() : React.ReactNode
	{
		return <>
			<div className="page---container is-full-width">
				<section>
					<div className="page---top-bar">
						<section>
							<Link to={ '/vehicles/create' } className="is-card">
								<i className="fas fa-plus"></i>
								<span>Nuovo veicolo</span>
							</Link>
							{(this.props.props___licence['licence_active___sync___vehicles'] === true && this.state.vehicles_list___is_importing !== null) ? <>
								{(this.state.vehicles_list___is_importing === true) ? <>
									<button className="is-card is-reset" onClick={ this.handle___vehicles_import___reset }>
										<i className="fas fa-spinner"></i>
										<span>Annulla Importazione in corso</span>
									</button>
								</> : <>
									<button className="is-card" onClick={ this.handle___vehicles_import___begin }>
										<i className="fas fa-download"></i>
										<span>Importa</span>
									</button>
								</>}
							</> : <></>}
							<button className="is-card">
								<img src="/assets/ui/icons/icon-spreadsheet.svg" />
								<span>Esporta .CSV</span>
							</button>
						</section>
						<section>
							<label className="page---top-bar---search-box is-card" style={{width:'250pt'}}>
								<i className="fas fa-search"></i>
								<input type="text" placeholder="Cerca veicolo..." onInput={ this.handle___vehicles___search } />
							</label>
						</section>
					</div>
					<div className="container---table container---table---vehicles is-card">
						<div>
							<div></div>
							<div>
								<i className="fas fa-image" style={{opacity:'1'}}></i>
								<span>•</span>
							</div>
							<div>
								<span>Marca, Modello, Versione</span>
								{(this.state.vehicles_list___order['param'] === 'vehicle_name') ? <>
									{(this.state.vehicles_list___order['direction'] === -1) ? <>
										<i className="fas fa-sort-up" onClick={() => { this.handle___vehicles___sortBy('vehicle_name'); }}></i>
									</> : <>
										<i className="fas fa-sort-down" onClick={() => { this.handle___vehicles___sortBy('vehicle_name'); }}></i>
									</>}
								</> : <>
									<i className="fas fa-sort" onClick={() => { this.handle___vehicles___sortBy('vehicle_name'); }}></i>
								</>}
							</div>
							<div>
								<span>Targa</span>
								<span>•</span>
							</div>
							<div>
								<span>Immatr.</span>
								<span>•</span>
							</div>
							<div>
								<span>Km</span>
								{(this.state.vehicles_list___order['param'] === 'vehicle_conditions___km') ? <>
									{(this.state.vehicles_list___order['direction'] === -1) ? <>
										<i className="fas fa-sort-up" onClick={() => { this.handle___vehicles___sortBy('vehicle_conditions___km'); }}></i>
									</> : <>
										<i className="fas fa-sort-down" onClick={() => { this.handle___vehicles___sortBy('vehicle_conditions___km'); }}></i>
									</>}
								</> : <>
									<i className="fas fa-sort" onClick={() => { this.handle___vehicles___sortBy('vehicle_conditions___km'); }}></i>
								</>}
							</div>
							<div>
								<span>Prezzo</span>
								{(this.state.vehicles_list___order['param'] === 'vehicle_accounting___price_sell') ? <>
									{(this.state.vehicles_list___order['direction'] === 1) ? <>
										<i className="fas fa-sort-up" onClick={() => { this.handle___vehicles___sortBy('vehicle_accounting___price_sell'); }}></i>
									</> : <>
										<i className="fas fa-sort-down" onClick={() => { this.handle___vehicles___sortBy('vehicle_accounting___price_sell'); }}></i>
									</>}
								</> : <>
									<i className="fas fa-sort" onClick={() => { this.handle___vehicles___sortBy('vehicle_accounting___price_sell'); }}></i>
								</>}
							</div>
							<div>
								<span>Creazione</span>
								{(this.state.vehicles_list___order['param'] === 'createdAt') ? <>
									{(this.state.vehicles_list___order['direction'] === 1) ? <>
										<i className="fas fa-sort-up" onClick={() => { this.handle___vehicles___sortBy('createdAt'); }}></i>
									</> : <>
										<i className="fas fa-sort-down" onClick={() => { this.handle___vehicles___sortBy('createdAt'); }}></i>
									</>}
								</> : <>
									<i className="fas fa-sort" onClick={() => { this.handle___vehicles___sortBy('createdAt'); }}></i>
								</>}
							</div>
							<div>
								<span>Visibilità/Stato</span>
								{(this.state.vehicles_list___order['param'] === 'vehicle_visibility') ? <>
									{(this.state.vehicles_list___order['direction'] === 1) ? <>
										<i className="fas fa-sort-up" onClick={() => { this.handle___vehicles___sortBy('vehicle_visibility'); }}></i>
									</> : <>
										<i className="fas fa-sort-down" onClick={() => { this.handle___vehicles___sortBy('vehicle_visibility'); }}></i>
									</>}
								</> : <>
									<i className="fas fa-sort" onClick={() => { this.handle___vehicles___sortBy('vehicle_visibility'); }}></i>
								</>}
							</div>
						</div>
						{this.state.vehicles_list.map((___v: any, ___i: number) => (
							<div key={ ___i }>
								<div>
									<Link to={ '/vehicles/view/' + ___v['vehicle_id'] } data-title="Visualizza">
										<i className="fas fa-eye"></i>
									</Link>
									{(___v['vehicle_status_sold'] !== true) ? <>
										<Link to={ '/vehicles/edit/' + ___v['vehicle_id'] } data-title="Modifica">
											<i className="fas fa-edit"></i>
										</Link>
										<i className="fas fa-trash" onClick={()=>{ this.handle___vehicles___delete(___v, ___i); }} data-title="Elimina"></i>
									</> : <>
										<i className="fas fa-trash" onClick={()=>{ this.handle___vehicles___delete___sold_vehicle(___v, ___i); }} data-title="Elimina"></i>
									</>}
								</div>
								<div>
									{(___v['vehicle_gallery_pictures'].length > 0) ? <>
										<img src={ lib_names.cdn.endpoint + ___v['vehicle_gallery_pictures'][0] }/>
									</> : <>
										<img src={ lib_names.media.placeholders.vehicle.generic }/>
									</>}
								</div>
								<div><b>{ ___v['vehicle_name'] }</b></div>
								<div>{ ___v['vehicle_registration___plate'] }</div>
								<div>
									{(___v['vehicle_conditions___registration_month'] &&  ___v['vehicle_conditions___registration_year']) ? <>
										<>{ ___v['vehicle_conditions___registration_month'] + '/' + ___v['vehicle_conditions___registration_year'] }</>
									</> : <>-</>}
								</div>
								<div>{ (___v['vehicle_conditions___km']) ? this.funcs___numbers.format___mileage(___v['vehicle_conditions___km']) : '-' } km</div>
								<div>{ this.funcs___numbers.format___price(___v['vehicle_accounting___price_sell']) }</div>
								<div>{ this.funcs___datetime.datetime___get___date(___v['createdAt']) }</div>
								<div>
									{(___v['vehicle_status_sold'] === true) ? <>
										<span className="vehicle---sold" data-title={ 'Venduto il ' + this.funcs___datetime.datetime___get___date(___v['vehicle_status_sold_datetime']) }>
											<>Venduto</>
										</span>
									</> : <>
										<select value={ ___v['vehicle_visibility'] as type___vehicle___visibility ?? 'hidden' } className="input---nb-outlined" onChange={(___e: any) => { this.handle___vehicles____select_visibility(___v, ___e, ___i); }} data-title="Modifica Stato del veicolo">
											<option value={ 'visible' }>Visibile pubblicamente</option>
											<option value={ 'hidden' }>Nascosto</option>
										</select>
									</>}
								</div>
							</div>
						))}
						<ComResultsMoreComponent is_loading={ this.state.vehicles_list___is_loading } event___onClick={() => { this.read_props___vehicles_list(false); }}/>
					</div>
				</section>
			</div>
		</>;
	}

//#endregion

}