import { type___vehicle___power } from "../types/types.types";

//	E X P O R T   L I B

export class funcs_numbers
{

//#region 																							C O N V E R T   P O W E R

	public convert___power(___value: string | number, ___convert_to: type___vehicle___power) : string
	{
		let ___parsed_power: number = (typeof ___value === 'string') ? parseFloat(___value) : ___value;
		if (isNaN(___parsed_power) === true) { return '-'; }
		switch (___convert_to)
		{
			case 'kw': ___parsed_power = ___parsed_power * 0.73549875; break;
			case 'hp': ___parsed_power = ___parsed_power * 1.35962162; break;
			default: return '-'; break;
		}
		const ___parsed_power___formatted: string = this.format___number(___parsed_power, 0);
		return ___parsed_power___formatted as string;
	}

//#endregion

//#region 																							C O N V E R T   P O W E R   N U M B E R

	public convert___power___to_number(___value: number, ___convert_to: type___vehicle___power) : number
	{
		let ___parsed_power: number = ___value;
		switch (___convert_to)
		{
			case 'kw': ___parsed_power = ___parsed_power * 0.73549875; break;
			case 'hp': ___parsed_power = ___parsed_power * 1.35962162; break;
		}
		return ___parsed_power as number;
	}

//#endregion

//#region 																							F O R M A T   N  U M B E R

	private format___number(___value: number, ___decimal: number) : string
	{
		const ___value___formatter: Intl.NumberFormat = new Intl.NumberFormat('de-DE', {
			minimumFractionDigits: ___decimal,
			maximumFractionDigits: ___decimal,
		});
		return ___value___formatter.format(___value) as string;
	}

//#endregion

//#region 																							F O R M A T   P R I C E

	public format___price(___value: string | number, ___symbol: string | null = '€') : string
	{
		const ___parsed_price: number = (typeof ___value === 'string') ? parseFloat(___value) : ___value;
		if (isNaN(___parsed_price) === true) { return '0'; }
		const ___parsed_price___formatted: string = this.format___number(___parsed_price, 2);
		return ___parsed_price___formatted as string + ' ' + ((___symbol !== null) ? ___symbol : '');
	}

//#endregion

//#region 																							F O R M A T   M I L E A G E

	public format___mileage(___value: string | number) : string
	{
		const ___parsed_mileage: number = (typeof ___value === 'string') ? parseFloat(___value) : ___value;
		if (isNaN(___parsed_mileage) === true) { return '0'; }
		const ___parsed_mileage___formatted: string = this.format___number(___parsed_mileage, 0);
		return ___parsed_mileage___formatted as string;
	}

//#endregion

//#region 																							F O R M A T   B Y T E S I Z E

	public format___bytesize(___bytes: number, ___decimals: number = 2) : string
	{
		if (___bytes === 0) return '0 Bytes';

		const ___k: number = 1024;
		const ___sizes: string[] = ['Bytes', 'KB', 'MB', 'GB'];
		const ___i: number = Math.min(Math.floor(Math.log(___bytes) / Math.log(___k)), ___sizes.length - 1);
	
		if (___i > 3) return parseFloat((___bytes / Math.pow(___k, ___i)).toFixed(___decimals)) + ' ' + ___sizes[3];
		return parseFloat((___bytes / Math.pow(___k, ___i)).toFixed(___decimals)) + ' ' + ___sizes[___i];
	}

//#endregion

//#region 																							G E T

	public get_value___percentage(___value: number, ___total: number) : number
	{
		const ___percentage: number = (___value / ___total) * 100;
		return ___percentage;
	}

//#endregion

//#region 																							P A D

	pad___twoDigits(___value: number) : string
	{
		const ___value___to_return: string =  ___value.toString().padStart(2, '0');
		return ___value___to_return;
	}

//#endregion


return___numeric_value(value: number | string): number {
    if (typeof value === "number") {
        return value;
    } else if (typeof value === "string" && !isNaN(parseFloat(value)) && isFinite(+value)) {
        return +value; // The unary + operator converts the string to a number
    } else {
        return 0;
    }
}





}