import React from 'react';

//	S T Y L E

import './sb-landing.scss';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../components/com-spinner/com-spinner';

//	E X P O R T

export default class SbLandingPage extends React.Component<{},{}>
{

//#region 																							H A N D L E R S

	private handle___clear_storage = () => {
		localStorage.clear();
		window.location.href = window.location.href;
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="carshub---landing-page---container">
				<ComSpinnerComponent has_margin={ false }/>
				<div className="carshub---landing-page---problems">
					<p>
						<span>Stai riscontrando problemi nel caricamento?</span>
						<span onClick={ this.handle___clear_storage }>Elimina i Cookies</span>
					</p>
				</div>
			</div>
		</>
	}

//#endregion

}