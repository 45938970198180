import React from "react";
import { Link } from "react-router-dom";
import { AutorotatePlugin, ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';

//	S T Y L E S

import './sb-vehicles-edit.scss'

//	T Y P E S

import { type___api___response } from "../../../types/types.api-response";
import { type___alert___controller } from "../../../types/types.alerts";
import { type___params__url } from "../../../types/types.params";

//	I N T E R F A C E S

import { props___SbVehiclesEditPage, state___SbVehiclesEditPage, state___SbVehiclesEditPage___default } from "./sb-vehicles-edit.state";

//	L I B S

import { lib_names } from "../../../libs/lib.names";
import { lib_selectables } from "../../../libs/lib.selectables";
import { lib_vehicle_attributes } from "../../../libs/lib.attributes";

//	 F U N C S

import { funcs_numbers } from "../../../funcs/funcs.numbers";

//	S E R V I C E S

import { service_RestApiService } from "../../../services/service-api";

//	C O M P O N E N T S

import ComSpinnerComponent from "../../../components/com-spinner/com-spinner";
import ComVehicleEditGalleryComponent from "../../../components/com-vehicle-gallery-edit/com-vehicle-gallery-edit";

//	E X P O R T

export default class SbVehiclesEditPage extends React.Component<props___SbVehiclesEditPage, state___SbVehiclesEditPage>
{

//#region 																							D E C L A R A T I O N S

	readonly _URL_PARAMS: type___params__url;

	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly lib___selectables: lib_selectables = new lib_selectables();
	private readonly lib___vehicle_attributes: lib_vehicle_attributes = new lib_vehicle_attributes();

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbVehiclesEditPage,
	)
	{
		super(_PROPS);
		this.state = state___SbVehiclesEditPage___default;
		this._URL_PARAMS = this.props.params;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___vehicle_brands = (___reset_fields: boolean = true) => {
		this.setState({
			loaded_props___vehicle___brands: (___reset_fields === true) ? [] : this.state.loaded_props___vehicle___brands,
			loaded_props___vehicle___models: (___reset_fields === true) ? [] : this.state.loaded_props___vehicle___models,
			loaded_props___vehicle___versions: (___reset_fields === true) ? [] : this.state.loaded_props___vehicle___versions,
			loaded_props___vehicle___variants: (___reset_fields === true) ? [] : this.state.loaded_props___vehicle___variants,
			field___vehicle_brand: (___reset_fields === true) ? 'null' : this.state.field___vehicle_brand,
			field___vehicle_model: (___reset_fields === true) ? 'null' : this.state.field___vehicle_model,
			field___vehicle_version: (___reset_fields === true) ? 'null' : this.state.field___vehicle_version,
			field___vehicle_variant: (___reset_fields === true) ? 'other' : this.state.field___vehicle_variant,
		}, async () => {
			const ___vehicles_brands___found: type___api___response = await this._API.vehicles_brands___read___multi(this.state.field___vehicle_category!, null);
			this.setState({
				loaded_props___vehicle___brands: ___vehicles_brands___found.data,
				field___vehicle_model: (___reset_fields === true) ? 'null' : this.state.field___vehicle_model,
				field___vehicle_version: (___reset_fields === true) ? 'null' : this.state.field___vehicle_version,
				field___vehicle_variant: (___reset_fields === true) ? 'null' : this.state.field___vehicle_variant,
			});
		});
	}

	read_props___vehicle_models = (___reset_fields: boolean = true) => {
		if ((this.state.field___vehicle_brand && this.state.field___vehicle_brand !== '' && this.state.field___vehicle_brand !== 'null' && this.state.field___vehicle_brand !== 'other') || this.state.loaded_props___vehicle___brands.length < 0)
		{
			this.setState({
				loaded_props___vehicle___models: (___reset_fields === true) ? [] : this.state.loaded_props___vehicle___models,
				loaded_props___vehicle___versions: (___reset_fields === true) ? [] : this.state.loaded_props___vehicle___versions,
				loaded_props___vehicle___variants: (___reset_fields === true) ? [] : this.state.loaded_props___vehicle___variants,
				field___vehicle_model: (___reset_fields === true) ? 'null' : this.state.field___vehicle_model,
				field___vehicle_version: (___reset_fields === true) ? 'null' : this.state.field___vehicle_version,
				field___vehicle_variant: (___reset_fields === true) ? 'null' : this.state.field___vehicle_variant,
			}, async () => { 
				const ___vehicles_models___found: type___api___response = await this._API.vehicles_models__read___multi(this.state.field___vehicle_category!, this.state.field___vehicle_brand!);
				this.setState({
					loaded_props___vehicle___models: ___vehicles_models___found.data,
					field___vehicle_version: (___reset_fields === true) ? 'null' : this.state.field___vehicle_version,
				});
			});
		}
	}

/*

	read_props___vehicle_versions = (___reset_fields: boolean = true) => {
		if (this.state.field___vehicle_brand !== 'null' || this.state.loaded_props___vehicle___brands.length <= 0)
		{
			this.setState({
				loaded_props___vehicle___versions: (___reset_fields === true) ? [] : this.state.loaded_props___vehicle___versions,
				loaded_props___vehicle___variants: (___reset_fields === true) ? [] : this.state.loaded_props___vehicle___variants,
				field___vehicle_version: (___reset_fields === true) ? 'null' : this.state.field___vehicle_version,
				field___vehicle_variant: (___reset_fields === true) ? 'null' : this.state.field___vehicle_variant,
			}, async () => { 
				const ___vehicles_versions___found: any[] = await this._API.vehicles_models__read___multi___versions(this.state.field___vehicle_category!, this.state.field___vehicle_brand!, this.state.field___vehicle_model!);
				this.setState({
					loaded_props___vehicle___versions: ___vehicles_versions___found,
					field___vehicle_variant: (___reset_fields === true) ? 'null' : this.state.field___vehicle_variant,
				});
			});
		}
	}

	read_props___vehicle_variant = (___reset_fields: boolean = true) => {
		if (this.state.field___vehicle_brand !== 'null' || this.state.loaded_props___vehicle___brands.length <= 0)
		{
			this.setState({
				loaded_props___vehicle___variants: (___reset_fields === true) ? [] : this.state.loaded_props___vehicle___variants,
				field___vehicle_variant: (___reset_fields === true) ? 'null' : this.state.field___vehicle_variant,
			}, async () => { 
				const ___vehicles_series___found: any[] = await this._API.vehicles_models__read___multi___variants(this.state.field___vehicle_category!, this.state.field___vehicle_brand!, this.state.field___vehicle_model!, this.state.field___vehicle_version!);
				this.setState({
					loaded_props___vehicle___variants: ___vehicles_series___found
				});
			});
		}
	}

	read_props___vehicle_variant___single = async () => { 
		if (this.state.field___vehicle_brand !== 'other' && this.state.field___vehicle_brand !== 'null' || this.state.loaded_props___vehicle___brands.length <= 0)
		{
			const ___vehicles_series_single___found: any = await this._API.vehicles_models__read___single___variants(this.state.field___vehicle_variant!);	
			if (___vehicles_series_single___found !== null)
			{
				this.setState({
		
					field___specs___engine_fuel_type: (___vehicles_series_single___found['engine_fuel_type']) ? ___vehicles_series_single___found['engine_fuel_type'] : this.state.field___specs___engine_fuel_type,
					field___specs___engine_name: (___vehicles_series_single___found['engine_name']) ? ___vehicles_series_single___found['engine_name'] : this.state.field___specs___engine_name,
					field___specs___engine_cylinders_displacement: (___vehicles_series_single___found['engine_cylinder_displacement']) ? ___vehicles_series_single___found['engine_cylinder_displacement'] : this.state.field___specs___engine_cylinders_displacement,
					field___specs___engine_cylinders_number: (___vehicles_series_single___found['engine_cylinder_number']) ? ___vehicles_series_single___found['engine_cylinder_number'] : this.state.field___specs___engine_cylinders_number,
					field___specs___engine_capacity: (___vehicles_series_single___found['engine_capacity']) ? ___vehicles_series_single___found['engine_capacity'] : this.state.field___specs___engine_capacity,
					field___specs___engine_power_kw: (___vehicles_series_single___found['engine_power_kw']) ? ___vehicles_series_single___found['engine_power_kw'] : this.state.field___specs___engine_power_kw,
	
					field___specs___gearbox_type: (___vehicles_series_single___found['gearbox_type']) ? ___vehicles_series_single___found['gearbox_type'] : this.state.field___specs___gearbox_type,
					field___specs___gearbox_gears: (___vehicles_series_single___found['gearbox_gears']) ? ___vehicles_series_single___found['gearbox_gears'] : this.state.field___specs___gearbox_gears,
	
					field___specs___transmission_drive: (___vehicles_series_single___found['transmission_drive']) ? ___vehicles_series_single___found['transmission_drive'] : this.state.field___specs___transmission_drive,
	
					field___specs___brakes_front_type: (___vehicles_series_single___found['brakes_front_type']) ? ___vehicles_series_single___found['brakes_front_type'] : this.state.field___specs___brakes_front_type,
					field___specs___brakes_rear_type: (___vehicles_series_single___found['brakes_rear_type']) ? ___vehicles_series_single___found['brakes_rear_type'] : this.state.field___specs___brakes_rear_type,
	
					field___config___body_type: (___vehicles_series_single___found['body_type']) ? ___vehicles_series_single___found['body_type'] : this.state.field___config___body_type,
					field___config___body_doors_number: (___vehicles_series_single___found['body_doors_number']) ? ___vehicles_series_single___found['body_doors_number'] : this.state.field___config___body_doors_number,
	
					field___config___wheel_material: '',
					field___config___wheel_tyres: (___vehicles_series_single___found['wheel_tyres']) ? ___vehicles_series_single___found['wheel_tyres'] : this.state.field___config___wheel_tyres,

				});
			}
		}
	}

*/

	read_props___vehicle = async (___vehicle_id: string) => { 
		const ___vehicle_single___found: type___api___response = await this._API.vehicles___read___single(___vehicle_id);

		console.log('___vehicle_single___found',___vehicle_single___found)

		if (___vehicle_single___found.response === 'success')
		{

			const ___field___gallery_pictures_processed: string[] = [];
			const ___field___gallery_pictures: string[] = (___vehicle_single___found.data['vehicle_gallery_pictures']) ? ___vehicle_single___found.data['vehicle_gallery_pictures'] : this.state.field___gallery_pictures;
			for (let ___gallery___idx = 0; ___gallery___idx < ___field___gallery_pictures.length; ___gallery___idx++)
			{
				const ___picture_single: string = lib_names.cdn.endpoint + ___field___gallery_pictures[___gallery___idx];
				___field___gallery_pictures_processed.push(___picture_single);
			}

			this.setState({

				field___id: (___vehicle_single___found.data['vehicle_id']) ? ___vehicle_single___found.data['vehicle_id'] : this.state.field___id,

				field___vehicle_category: (___vehicle_single___found.data['vehicle_category']) ? ___vehicle_single___found.data['vehicle_category'] : this.state.field___vehicle_category,

				field___vehicle_brand: (___vehicle_single___found.data['vehicle_brand']) ? ___vehicle_single___found.data['vehicle_brand'] : this.state.field___vehicle_brand,
				field___vehicle_model: (___vehicle_single___found.data['vehicle_model']) ? ___vehicle_single___found.data['vehicle_model'] : this.state.field___vehicle_model,
				field___vehicle_version: (___vehicle_single___found.data['vehicle_version']) ? ___vehicle_single___found.data['vehicle_version'] : this.state.field___vehicle_version,
				field___vehicle_variant: (___vehicle_single___found.data['vehicle_variant']) ? ___vehicle_single___found.data['vehicle_variant'] : this.state.field___vehicle_variant,

				field___vehicle_name: (___vehicle_single___found.data['vehicle_name']) ? ___vehicle_single___found.data['vehicle_name'] : this.state.field___vehicle_name,

				field___gallery_pictures: ___field___gallery_pictures_processed,
			//	field___gallery_360
				field___gallery_video: (___vehicle_single___found.data['vehicle_gallery_video']) ? ___vehicle_single___found.data['vehicle_gallery_video'] : this.state.field___gallery_video,

				field___conditions___category: (___vehicle_single___found.data['vehicle_conditions___category']) ? ___vehicle_single___found.data['vehicle_conditions___category'] : this.state.field___conditions___category,
				field___conditions___km: (___vehicle_single___found.data['vehicle_conditions___km']) ? ___vehicle_single___found.data['vehicle_conditions___km'] : this.state.field___conditions___km,
				field___conditions___registration_month: (___vehicle_single___found.data['vehicle_conditions___registration_month']) ? ___vehicle_single___found.data['vehicle_conditions___registration_month'] : this.state.field___conditions___registration_month,
				field___conditions___registration_year: (___vehicle_single___found.data['vehicle_conditions___registration_year']) ? ___vehicle_single___found.data['vehicle_conditions___registration_year'] : this.state.field___conditions___registration_year,
				field___conditions___owners_number: (___vehicle_single___found.data['vehicle_conditions___owners_number']) ? ___vehicle_single___found.data['vehicle_conditions___owners_number'] : this.state.field___conditions___owners_number,

				field___specs___engine_fuel_type: (___vehicle_single___found.data['vehicle_specs___engine_fuel_type']) ? ___vehicle_single___found.data['vehicle_specs___engine_fuel_type'] : this.state.field___specs___engine_fuel_type,
				field___specs___engine_name: (___vehicle_single___found.data['vehicle_specs___engine_name']) ? ___vehicle_single___found.data['vehicle_specs___engine_name'] : this.state.field___specs___engine_name,
				field___specs___engine_cylinders_displacement: (___vehicle_single___found.data['vehicle_specs___engine_cylinders_displacement']) ? ___vehicle_single___found.data['vehicle_specs___engine_cylinders_displacement'] : this.state.field___specs___engine_cylinders_displacement,
				field___specs___engine_cylinders_number: (___vehicle_single___found.data['vehicle_specs___engine_cylinders_number']) ? ___vehicle_single___found.data['vehicle_specs___engine_cylinders_number'] : this.state.field___specs___engine_cylinders_number,
				field___specs___engine_capacity: (___vehicle_single___found.data['vehicle_specs___engine_capacity']) ? ___vehicle_single___found.data['vehicle_specs___engine_capacity'] : this.state.field___specs___engine_capacity,
				field___specs___engine_power_unit: 'kw',
				field___specs___engine_power: (___vehicle_single___found.data['vehicle_specs___engine_power_kw']) ? ___vehicle_single___found.data['vehicle_specs___engine_power_kw'] : this.state.field___specs___engine_power,
				field___specs___gearbox_type: (___vehicle_single___found.data['vehicle_specs___gearbox_type']) ? ___vehicle_single___found.data['vehicle_specs___gearbox_type'] : this.state.field___specs___gearbox_type,
				field___specs___gearbox_gears: (___vehicle_single___found.data['vehicle_specs___gearbox_gears']) ? ___vehicle_single___found.data['vehicle_specs___gearbox_gears'] : this.state.field___specs___gearbox_gears,
				field___specs___transmission_drive: (___vehicle_single___found.data['vehicle_specs___transmission_drive']) ? ___vehicle_single___found.data['vehicle_specs___transmission_drive'] : this.state.field___specs___transmission_drive,
				field___specs___brakes_front_type: (___vehicle_single___found.data['vehicle_specs___brakes_front_type']) ? ___vehicle_single___found.data['vehicle_specs___brakes_front_type'] : this.state.field___specs___brakes_front_type,
				field___specs___brakes_rear_type: (___vehicle_single___found.data['vehicle_specs___brakes_rear_type']) ? ___vehicle_single___found.data['vehicle_specs___brakes_rear_type'] : this.state.field___specs___brakes_rear_type,

				field___config___body_type: (___vehicle_single___found.data['vehicle_config___body_type']) ? ___vehicle_single___found.data['vehicle_config___body_type'] : this.state.field___config___body_type,
				field___config___body_doors_number: (___vehicle_single___found.data['vehicle_config___body_doors_number']) ? ___vehicle_single___found.data['vehicle_config___body_doors_number'] : this.state.field___config___body_doors_number,
				field___config___paint_type: (___vehicle_single___found.data['vehicle_config___paint_type']) ? ___vehicle_single___found.data['vehicle_config___paint_type'] : this.state.field___config___paint_type,
				field___config___paint_color: (___vehicle_single___found.data['vehicle_config___paint_color']) ? ___vehicle_single___found.data['vehicle_config___paint_color'] : this.state.field___config___paint_color,
				field___config___interior_material: (___vehicle_single___found.data['vehicle_config___interior_material']) ? ___vehicle_single___found.data['vehicle_config___interior_material'] : this.state.field___config___interior_material,
				field___config___interior_color: (___vehicle_single___found.data['vehicle_config___interior_color']) ? ___vehicle_single___found.data['vehicle_config___interior_color'] : this.state.field___config___interior_color,
				field___config___interior_details: (___vehicle_single___found.data['vehicle_config___interior_details']) ? ___vehicle_single___found.data['vehicle_config___interior_details'] : this.state.field___config___interior_details,
				field___config___wheel_material: (___vehicle_single___found.data['vehicle_config___wheel_material']) ? ___vehicle_single___found.data['vehicle_config___wheel_material'] : this.state.field___config___wheel_material,
				field___config___wheel_tyres: (___vehicle_single___found.data['vehicle_config___wheel_tyres']) ? ___vehicle_single___found.data['vehicle_config___wheel_tyres'] : this.state.field___config___wheel_tyres,
			
				//			O P T I O N A L S

				field___efficiency___engine: (___vehicle_single___found.data['vehicle_efficiency___engine']) ? ___vehicle_single___found.data['vehicle_efficiency___engine'] : this.state.field___efficiency___engine,
				field___efficiency___power_supply: (___vehicle_single___found.data['vehicle_efficiency___power_supply']) ? ___vehicle_single___found.data['vehicle_efficiency___power_supply'] : this.state.field___efficiency___power_supply,
				field___efficiency___exhaust: (___vehicle_single___found.data['vehicle_efficiency___exhaust']) ? ___vehicle_single___found.data['vehicle_efficiency___exhaust'] : this.state.field___efficiency___exhaust,
				field___efficiency___exhaust_emissions: (___vehicle_single___found.data['vehicle_efficiency___exhaust_emissions']) ? ___vehicle_single___found.data['vehicle_efficiency___exhaust_emissions'] : this.state.field___efficiency___exhaust_emissions,
				field___efficiency___leaks: (___vehicle_single___found.data['vehicle_efficiency___leaks']) ? ___vehicle_single___found.data['vehicle_efficiency___leaks'] : this.state.field___efficiency___leaks,
				field___efficiency___engine_cooling: (___vehicle_single___found.data['vehicle_efficiency___engine_cooling']) ? ___vehicle_single___found.data['vehicle_efficiency___engine_cooling'] : this.state.field___efficiency___engine_cooling,
				field___efficiency___gearbox: (___vehicle_single___found.data['vehicle_efficiency___gearbox']) ? ___vehicle_single___found.data['vehicle_efficiency___gearbox'] : this.state.field___efficiency___gearbox,
				field___efficiency___clutch: (___vehicle_single___found.data['vehicle_efficiency___clutch']) ? ___vehicle_single___found.data['vehicle_efficiency___clutch'] : this.state.field___efficiency___clutch,
				field___efficiency___brakes: (___vehicle_single___found.data['vehicle_efficiency___brakes']) ? ___vehicle_single___found.data['vehicle_efficiency___brakes'] : this.state.field___efficiency___brakes,
				field___efficiency___steering: (___vehicle_single___found.data['vehicle_efficiency___steering']) ? ___vehicle_single___found.data['vehicle_efficiency___steering'] : this.state.field___efficiency___steering,
				field___efficiency___suspensions: (___vehicle_single___found.data['vehicle_efficiency___suspensions']) ? ___vehicle_single___found.data['vehicle_efficiency___suspensions'] : this.state.field___efficiency___suspensions,
				field___efficiency___wheels: (___vehicle_single___found.data['vehicle_efficiency___wheels']) ? ___vehicle_single___found.data['vehicle_efficiency___wheels'] : this.state.field___efficiency___wheels,
				field___efficiency___noise: (___vehicle_single___found.data['vehicle_efficiency___noise']) ? ___vehicle_single___found.data['vehicle_efficiency___noise'] : this.state.field___efficiency___noise,
				field___efficiency___battery: (___vehicle_single___found.data['vehicle_efficiency___battery']) ? ___vehicle_single___found.data['vehicle_efficiency___battery'] : this.state.field___efficiency___battery,
				field___efficiency___engine_starter: (___vehicle_single___found.data['vehicle_efficiency___engine_starter']) ? ___vehicle_single___found.data['vehicle_efficiency___engine_starter'] : this.state.field___efficiency___engine_starter,
				field___efficiency___lights: (___vehicle_single___found.data['vehicle_efficiency___lights']) ? ___vehicle_single___found.data['vehicle_efficiency___lights'] : this.state.field___efficiency___lights,
				field___efficiency___windows: (___vehicle_single___found.data['vehicle_efficiency___windows']) ? ___vehicle_single___found.data['vehicle_efficiency___windows'] : this.state.field___efficiency___windows,
				field___efficiency___windows_wipers: (___vehicle_single___found.data['vehicle_efficiency___windows_wipers']) ? ___vehicle_single___found.data['vehicle_efficiency___windows_wipers'] : this.state.field___efficiency___windows_wipers,
				field___efficiency___warnings: (___vehicle_single___found.data['vehicle_efficiency___warnings']) ? ___vehicle_single___found.data['vehicle_efficiency___warnings'] : this.state.field___efficiency___warnings,
				field___efficiency___air_conditioning: (___vehicle_single___found.data['vehicle_efficiency___air_conditioning']) ? ___vehicle_single___found.data['vehicle_efficiency___air_conditioning'] : this.state.field___efficiency___air_conditioning,
				field___efficiency___mirrors: (___vehicle_single___found.data['vehicle_efficiency___mirrors']) ? ___vehicle_single___found.data['vehicle_efficiency___mirrors'] : this.state.field___efficiency___mirrors,
				field___efficiency___seatbelts: (___vehicle_single___found.data['vehicle_efficiency___seatbelts']) ? ___vehicle_single___found.data['vehicle_efficiency___seatbelts'] : this.state.field___efficiency___seatbelts,
				field___efficiency___interior: (___vehicle_single___found.data['vehicle_efficiency___interior']) ? ___vehicle_single___found.data['vehicle_efficiency___interior'] : this.state.field___efficiency___interior,
				field___efficiency___interior_seats: (___vehicle_single___found.data['vehicle_efficiency___interior_seats']) ? ___vehicle_single___found.data['vehicle_efficiency___interior_seats'] : this.state.field___efficiency___interior_seats,
				field___efficiency___dashboard: (___vehicle_single___found.data['vehicle_efficiency___dashboard']) ? ___vehicle_single___found.data['vehicle_efficiency___dashboard'] : this.state.field___efficiency___dashboard,
				field___efficiency___body___01: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][0]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][0] : this.state.field___efficiency___body___01,
				field___efficiency___body___02: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][1]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][1] : this.state.field___efficiency___body___02,
				field___efficiency___body___03: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][2]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][2] : this.state.field___efficiency___body___03,
				field___efficiency___body___04: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][3]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][3] : this.state.field___efficiency___body___04,
				field___efficiency___body___05: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][4]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][4] : this.state.field___efficiency___body___05,
				field___efficiency___body___06: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][5]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][5] : this.state.field___efficiency___body___06,
				field___efficiency___body___07: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][6]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][6] : this.state.field___efficiency___body___07,
				field___efficiency___body___08: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][7]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][7] : this.state.field___efficiency___body___08,
				field___efficiency___body___09: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][8]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][8] : this.state.field___efficiency___body___09,
				field___efficiency___body___10: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][9]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][9] : this.state.field___efficiency___body___10,
				field___efficiency___body___11: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][10]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][10] : this.state.field___efficiency___body___11,
				field___efficiency___body___12: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][11]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][11] : this.state.field___efficiency___body___12,
				field___efficiency___body___13: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][12]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][12] : this.state.field___efficiency___body___13,
				field___efficiency___body___14: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][13]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][13] : this.state.field___efficiency___body___14,
				field___efficiency___body___15: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][14]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][14] : this.state.field___efficiency___body___15,
				field___efficiency___body___16: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][15]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][15] : this.state.field___efficiency___body___16,
				field___efficiency___body___17: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][16]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][16] : this.state.field___efficiency___body___17,
				field___efficiency___body___18: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][17]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][17] : this.state.field___efficiency___body___18,
				field___efficiency___body___19: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][18]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][18] : this.state.field___efficiency___body___19,
				field___efficiency___body___20: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][19]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][19] : this.state.field___efficiency___body___20,
				field___efficiency___body___21: (___vehicle_single___found.data['vehicle_efficiency___body'] && ___vehicle_single___found.data['vehicle_efficiency___body'][20]) ? ___vehicle_single___found.data['vehicle_efficiency___body'][20] : this.state.field___efficiency___body___21,

				field___info___description: (___vehicle_single___found.data['vehicle_info___description']) ? ___vehicle_single___found.data['vehicle_info___description'] : this.state.field___info___description,

				field___registration___plate: (___vehicle_single___found.data['vehicle_registration___plate']) ? ___vehicle_single___found.data['vehicle_registration___plate'] : this.state.field___registration___plate,
				field___registration___vin: (___vehicle_single___found.data['vehicle_registration___vin']) ? ___vehicle_single___found.data['vehicle_registration___vin'] : this.state.field___registration___vin,

				field___accounting___price_bought: (___vehicle_single___found.data['vehicle_accounting___price_bought']) ? ___vehicle_single___found.data['vehicle_accounting___price_bought'] : this.state.field___accounting___price_bought,
				field___accounting___price_bought_vat: (___vehicle_single___found.data['vehicle_accounting___price_bought_vat']) ? ___vehicle_single___found.data['vehicle_accounting___price_bought_vat'] : this.state.field___accounting___price_bought_vat,
				field___accounting___price_bought_vat_type: (___vehicle_single___found.data['vehicle_accounting___price_bought_vat_type']) ? ___vehicle_single___found.data['vehicle_accounting___price_bought_vat_type'] : this.state.field___accounting___price_bought_vat_type,
				field___accounting___price_sell: (___vehicle_single___found.data['vehicle_accounting___price_sell']) ? ___vehicle_single___found.data['vehicle_accounting___price_sell'] : this.state.field___accounting___price_sell,
				field___accounting___price_sell_vat: (___vehicle_single___found.data['vehicle_accounting___price_sell_vat']) ? ___vehicle_single___found.data['vehicle_accounting___price_sell_vat'] : this.state.field___registration___plate,
				field___accounting___price_sell_vat_type: (___vehicle_single___found.data['vehicle_accounting___price_sell_vat_type']) ? ___vehicle_single___found.data['vehicle_accounting___price_sell_vat_type'] : this.state.field___accounting___price_sell_vat_type,
	
			}, () => {

				this.read_props___vehicle_brands(false);
				this.read_props___vehicle_models(false);
		//		this.read_props___vehicle_versions(false);
		//		this.read_props___vehicle_variant(false);

				setTimeout(() => {
					this.setState({
						GUI___page___is_loading: false,
					})
				}, 250);

			});
		}
		else
		{
			const ___alert_props : type___alert___controller = {
				alert___title: "Errore",
				alert___message : <>Impossibile caricare questo Veicolo...</>,
				alert___buttons : [
					{
						alert___button___icon: 'fa-chevron-left',
						alert___button___text: 'Torna Indietro',
						alert___button___action : () => {
							this._PROPS.alert___setShowing(false);
							if (window.history.length > 1) { window.history.back(); }
							else { window.location.href = '/vehicles/list'; }
						}
					}
				]
			}
			this._PROPS.alert___setAlert(___alert_props);
			this._PROPS.alert___setShowing(true);
		}
	}

//#endregion

//#region 																							S U B M I T

	submit___vehicle___check_if_can = () : boolean => {

		let ___error_toast___should_display: boolean = false;
		let ___error_toast___text: string = 'Impossibile salvare, mancano i seguenti campi: ';

		if (
			this.state.field___vehicle_brand !== null && this.state.field___vehicle_brand !== '' && this.state.field___vehicle_brand !== 'null' &&
			this.state.field___vehicle_model !== null && this.state.field___vehicle_model !== '' && this.state.field___vehicle_model !== 'null'
		) { this.setState({can_submit___section___info: true}); }

//		can_submit___section___media: boolean,

		if (
			this.state.field___conditions___category !== '' &&
			this.state.field___conditions___registration_month !== '' &&
			this.state.field___conditions___registration_year !== null && this.state.field___conditions___registration_year >= this.lib___vehicle_attributes.lib_vehicle_attributes___years_range.min && this.state.field___conditions___registration_year <= this.lib___vehicle_attributes.lib_vehicle_attributes___years_range.max
		) { this.setState({can_submit___section___conditions: true}); }

		if (
			this.state.field___specs___engine_fuel_type !== '' &&
			this.state.field___specs___engine_capacity !== null && this.state.field___specs___engine_capacity > 0 &&
			this.state.field___specs___engine_power !== null && this.state.field___specs___engine_power > 0 &&
			this.state.field___specs___gearbox_type !== '' &&
			this.state.field___specs___transmission_drive !== ''
		) { this.setState({can_submit___section___specs: true}); }


//		&&
//		this.state.field___config___body_type !== null &&
//		this.state.field___config___body_doors_number !== null

//		can_submit___section___config: boolean,
//		can_submit___section___catalog: boolean,
//		can_submit___section___sale: boolean,
//		can_submit___section___cross_posting: boolean,













//		const ___error_toast___properties: type___toast_controller = { toast_inner: ___error_toast___text, toast_color: 'danger' };

//		this._PROPS.event_toastSetup(___error_toast___properties);
//		this._PROPS.event_toastShow(___error_toast___should_display);

		return !(___error_toast___should_display);

	}

	submit___vehicle___try = async (___has_alert: boolean) => {
		switch (___has_alert)
		{
			case true:
				const ___alert_props : type___alert___controller = {
					alert___title: "Attenzione",
					alert___message : <>Vuoi salvare questo veicolo?</>,
					alert___buttons : [
						{
							alert___button___icon: 'fa-times',
							alert___button___text: 'Annulla',
							alert___button___action : () => {
								this._PROPS.alert___setShowing(false);
							}
						},
						{
							alert___button___icon: 'fa-check',
							alert___button___text: 'Si',
							alert___button___action: async () => {
								await this.submit___vehicle();
								this._PROPS.alert___setShowing(false);
							}
						}
					]
				}
				this._PROPS.alert___setAlert(___alert_props);
				this._PROPS.alert___setShowing(true);
				break;
			case false:
					await this.submit___vehicle();
				break;
		}
	}

	submit___vehicle = async () => {

		const ___vehicle_to_submit: any = {

			field___vehicle_brand: (this.state.field___vehicle_brand !== '' && this.state.field___vehicle_brand !== 'null' && this.state.field___vehicle_brand !== 'other') ? this.state.field___vehicle_brand : null,
			field___vehicle_model: (this.state.field___vehicle_model !== '' && this.state.field___vehicle_model !== 'null' && this.state.field___vehicle_model !== 'other') ? this.state.field___vehicle_model : null,
			field___vehicle_version: (this.state.field___vehicle_version !== '' && this.state.field___vehicle_version !== 'null' && this.state.field___vehicle_version !== 'other') ? this.state.field___vehicle_version : null,
			field___vehicle_variant: (this.state.field___vehicle_variant !== '' && this.state.field___vehicle_variant !== 'null' && this.state.field___vehicle_variant !== 'other') ? this.state.field___vehicle_variant : null,
			field___vehicle_name: this.state.field___vehicle_name,

			field___conditions___category: this.state.field___conditions___category,
			field___conditions___km: this.state.field___conditions___km,
			field___conditions___registration_month: this.state.field___conditions___registration_month,
			field___conditions___registration_year: this.state.field___conditions___registration_year,
			field___conditions___owners_number: this.state.field___conditions___owners_number,

			field___specs___engine_fuel_type: this.state.field___specs___engine_fuel_type,
			field___specs___engine_name: this.state.field___specs___engine_name,
			field___specs___engine_cylinders_displacement: this.state.field___specs___engine_cylinders_displacement,
			field___specs___engine_cylinders_number: this.state.field___specs___engine_cylinders_number,
			field___specs___engine_capacity: this.state.field___specs___engine_capacity,
			field___specs___engine_power_kw: (this.state.field___specs___engine_power_unit === 'hp') ? this.funcs___numbers.convert___power___to_number(this.state.field___specs___engine_power ?? 0, 'kw') : this.state.field___specs___engine_power,
			field___specs___emission_class: this.state.field___specs___emission_class,

			field___specs___gearbox_type: this.state.field___specs___gearbox_type,
			field___specs___gearbox_gears: this.state.field___specs___gearbox_gears,
		
			field___specs___transmission_drive: this.state.field___specs___transmission_drive,
		
			field___specs___brakes_front_type: this.state.field___specs___brakes_front_type,
			field___specs___brakes_rear_type: this.state.field___specs___brakes_rear_type,
		
			field___config___body_type: this.state.field___config___body_type,
			field___config___body_doors_number: this.state.field___config___body_doors_number,
			field___config___paint_type: this.state.field___config___paint_type,
			field___config___paint_color: this.state.field___config___paint_color,
			field___config___interior_material: this.state.field___config___interior_material,
			field___config___interior_color: this.state.field___config___interior_color,
			field___config___interior_details: this.state.field___config___interior_details,
			field___config___wheel_size: this.state.field___config___wheel_material, 
			field___config___wheel_tyres: this.state.field___config___wheel_tyres,
			field___config___optionals: this.state.field___config___optionals,

			field___efficiency___engine: this.state.field___efficiency___engine, 
			field___efficiency___power_supply: this.state.field___efficiency___power_supply, 
			field___efficiency___exhaust: this.state.field___efficiency___exhaust, 
			field___efficiency___exhaust_emissions: this.state.field___efficiency___exhaust_emissions, 
			field___efficiency___leaks: this.state.field___efficiency___leaks, 
			field___efficiency___engine_cooling: this.state.field___efficiency___engine_cooling, 
			field___efficiency___gearbox: this.state.field___efficiency___gearbox, 
			field___efficiency___clutch: this.state.field___efficiency___clutch, 
			field___efficiency___brakes: this.state.field___efficiency___brakes, 
			field___efficiency___steering: this.state.field___efficiency___steering, 
			field___efficiency___suspensions: this.state.field___efficiency___suspensions, 
			field___efficiency___wheels: this.state.field___efficiency___wheels, 
			field___efficiency___noise: this.state.field___efficiency___noise, 
			field___efficiency___battery: this.state.field___efficiency___battery, 
			field___efficiency___engine_starter: this.state.field___efficiency___engine_starter, 
			field___efficiency___lights: this.state.field___efficiency___lights, 
			field___efficiency___windows: this.state.field___efficiency___windows, 
			field___efficiency___windows_wipers: this.state.field___efficiency___windows_wipers, 
			field___efficiency___warnings: this.state.field___efficiency___warnings, 
			field___efficiency___air_conditioning: this.state.field___efficiency___air_conditioning, 
			field___efficiency___mirrors: this.state.field___efficiency___mirrors, 
			field___efficiency___seatbelts: this.state.field___efficiency___seatbelts,
			field___efficiency___interior: this.state.field___efficiency___interior, 
			field___efficiency___interior_seats: this.state.field___efficiency___interior_seats, 
			field___efficiency___dashboard: this.state.field___efficiency___dashboard, 
			field___efficiency___body: [
				this.state.field___efficiency___body___01,
				this.state.field___efficiency___body___02,
				this.state.field___efficiency___body___03,
				this.state.field___efficiency___body___04,
				this.state.field___efficiency___body___05,
				this.state.field___efficiency___body___06,
				this.state.field___efficiency___body___07,
				this.state.field___efficiency___body___08,
				this.state.field___efficiency___body___09,
				this.state.field___efficiency___body___10,
				this.state.field___efficiency___body___11,
				this.state.field___efficiency___body___12,
				this.state.field___efficiency___body___13,
				this.state.field___efficiency___body___14,
				this.state.field___efficiency___body___15,
				this.state.field___efficiency___body___16,
				this.state.field___efficiency___body___17,
				this.state.field___efficiency___body___18,
				this.state.field___efficiency___body___19,
				this.state.field___efficiency___body___20,
				this.state.field___efficiency___body___21
			],

			field___info___description: this.state.field___info___description,

			field___registration___plate: this.state.field___registration___plate,
			field___registration___vin: this.state.field___registration___vin,
		
			field___accounting___price_bought: this.state.field___accounting___price_bought,
			field___accounting___price_bought_vat: this.state.field___accounting___price_bought_vat,
			field___accounting___price_bought_vat_type: this.state.field___accounting___price_bought_vat_type,
			field___accounting___price_sell: this.state.field___accounting___price_sell, 
			field___accounting___price_sell_vat: this.state.field___accounting___price_sell_vat,
			field___accounting___price_sell_vat_type: this.state.field___accounting___price_sell_vat_type,

		};

		this.setState({
			vehicle___is_submitted: true,
		}, async () => {
			const ___is_submitted: type___api___response = await this._API.vehicles___submit(this.state.field___id, this.state.field___vehicle_category!, this.state.field___gallery_pictures, this.state.field___gallery_360, this.state.field___gallery_video, ___vehicle_to_submit, this.state.field___vehicle___visibility);
			this.setState({
				vehicle___is_submitted: true,
				vehicle___is_submitted___result: (___is_submitted['response'] === 'success') ? true : false,
			});
		});

	}

//#endregion

//#region 																							H A N D L E R S

	handle___GUI___select_section = (___index: 'prev' | 'next' | number) => {
		switch (___index) {
			case 'prev': const ___index___prev: number = (this.state.GUI___section___selected === 0) ? this.state.GUI___section___total : this.state.GUI___section___selected - 1; this.setState({ GUI___section___selected: ___index___prev }); break;
			case 'next': const ___index___next: number = (this.state.GUI___section___selected === this.state.GUI___section___total) ? 0 : this.state.GUI___section___selected + 1; this.setState({ GUI___section___selected: ___index___next }); break;
			default: this.setState({ GUI___section___selected: ___index }); break;
		}
	}

	handle___select___vehicle_category = (___e: any) => {
		this.setState({
			field___vehicle_category: ___e.target.value
		}, async () => {
			this.read_props___vehicle_brands();
			this.submit___vehicle___check_if_can();
		});
	}

	handle___select___vehicle_brand = (___e: any) => {
		this.setState({
			field___vehicle_brand: ___e.target.value
		}, async () => {
			this.read_props___vehicle_models();
			this.submit___vehicle___check_if_can();
			this.handle___field___generate_name();
		});
	}

	handle___select___vehicle_model = (___e: any) => {
		this.setState({
			field___vehicle_model: ___e.target.value
		}, async () => {
		//	await this.read_props___vehicle_versions();
			this.submit___vehicle___check_if_can();
			this.handle___field___generate_name();
		});
	}

	handle___select___vehicle_version = (___e: any) => {
		this.setState({
			field___vehicle_version: ___e.target.value
		}, async () => {
		//	await this.read_props___vehicle_variant();
			this.submit___vehicle___check_if_can();
			this.handle___field___generate_name();
		});
	}

	handle___select___vehicle_variant = (___e: any) => {
		this.setState({
			field___vehicle_variant: ___e.target.value
		}, async () => {
		//	await this.read_props___vehicle_variant___single();
			this.submit___vehicle___check_if_can();
			this.handle___field___generate_name();
		});
	}

	handle___field___generate_name = () => {
		if (this.state.field___id === null)
		{
			const ___f___brand: string = (this.state.field___vehicle_brand !== null && this.state.field___vehicle_brand !== '' && this.state.field___vehicle_brand !== 'other' && this.state.loaded_props___vehicle___brands.length > 0) ? this.state.loaded_props___vehicle___brands.find((___e: any) => ___e['vehicle_brand_id'] === this.state.field___vehicle_brand)['vehicle_brand_name'] : '';
			const ___f___model: string = (this.state.field___vehicle_model !== null && this.state.field___vehicle_model !== '' && this.state.field___vehicle_model !== 'other' && this.state.loaded_props___vehicle___models.length > 0) ? this.state.loaded_props___vehicle___models.find((___e: any) => ___e['vehicle_model_id'] === this.state.field___vehicle_model)['vehicle_model_name'] : '';
		//	const ___f___version: string = (this.state.field___vehicle_version !== null && this.state.field___vehicle_version !== '') ? this.state.loaded_props___vehicle___versions.find((___e: any) => ___e['vehicle_brand_id'] === this.state.field___vehicle_version) : '';
		//	const ___f___variant: string = (this.state.field___vehicle_variant !== null && this.state.field___vehicle_variant !== '') ? this.state.loaded_props___vehicle___versions.find((___e: any) => ___e['vehicle_brand_id'] === this.state.field___vehicle_variant) : '';
			this.setState({
				field___vehicle_name: ___f___brand + ' ' + ___f___model + ' '
			});
		}
	}

	handle___gallery___file___upload = (___e: React.ChangeEvent<HTMLInputElement>) => {
		let ___file___size_error: boolean = false;
		if (___e.target.files)
		{
			const ___files_array: File[] = Array.from(___e.target.files);
			for (let ___f_i = 0; ___f_i < ___files_array.length; ___f_i++)
			{
				const ___f: File = ___files_array[___f_i];
				if (___f.size <= 1.6 * 1024 * 1024)
				{
					const ___f___reader: any = new FileReader();
					___f___reader.onloadend = () => {
						this.setState((___prevState) => ({
							field___gallery_pictures: [...___prevState.field___gallery_pictures, ___f___reader.result as string]
						}));
					};
					___f___reader.readAsDataURL(___f);
				}
				else
				{
					___file___size_error = true;
					console.error('File is too large. Maximum size is 1.6MB.');
				}
			}
			if (___file___size_error === true)
			{
				console.error('ERROR EXCEDING FILE SIZE');
			}
		}
	};

	handle___select___vehicle_visibility = (___e: any) => {
		this.setState({
			field___vehicle___visibility: ___e.target.value
		});
	}

//#region

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		if (this._URL_PARAMS.id)
		{
			this.setState({
				field___id: this._URL_PARAMS.id
			}, async () => {
				await this.read_props___vehicle(this._URL_PARAMS.id);
			});
		}
		else
		{
			this.setState({
				GUI___page___is_loading: false
			})
		}
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode 
	{

		return <>
		
			{(this.state.GUI___page___is_loading === false) ? <>
				<div className="page---container is-full-width">
					<section>

						{(this.state.vehicle___is_submitted === false) ? <>

							<div className="vehicle-editor---container---flexy">

								<div className="page---top-bar vehicle-editor---top-bar page---top-bar---is-sidebar">
									<section></section>
									<section>
										<div className={ 'is-card ' + ((this.state.GUI___section___selected === 0) ? ' is-selected-item' : ' ') } onClick={() => { this.handle___GUI___select_section(0) }}>
											<div><img src="/assets/ui/icons/icon-vehicle-info.svg"/></div>
											<span>Info</span>
										</div>
						{/*
										{(this.state.field___vehicle_category !== null && this.state.field___vehicle_brand !== null && this.state.field___vehicle_brand !== 'null' && this.state.field___vehicle_model !== null && this.state.field___vehicle_model !== 'null') ? <>
						*/}

										{(true) ? <>
											<div className={ 'is-card ' + ((this.state.GUI___section___selected === 1) ? ' is-selected-item' : ' ') } onClick={() => { this.handle___GUI___select_section(1) }}>
												<div><img src="/assets/ui/icons/icon-vehicle-camera.svg"/></div>
												<span>Foto</span>
											</div>
											<div className={ 'is-card ' + ((this.state.GUI___section___selected === 2) ? ' is-selected-item' : ' ') } onClick={() => { this.handle___GUI___select_section(2) }}>
												<div><img src="/assets/ui/icons/icon-conditions.svg"/></div>
												<span>Condizioni</span>
											</div>
											<div className={ 'is-card ' + ((this.state.GUI___section___selected === 3) ? ' is-selected-item' : ' ') } onClick={() => { this.handle___GUI___select_section(3) }}>
												<div><img src="/assets/ui/icons/icon-specs.svg"/></div>
												<span>Specifiche</span>
											</div>
											<div className={ 'is-card ' + ((this.state.GUI___section___selected === 4) ? ' is-selected-item' : ' ') } onClick={() => { this.handle___GUI___select_section(4) }}>
												<div><img src="/assets/ui/icons/icon-vehicle-config.svg"/></div>
												<span>Configurazione</span>
											</div>
											<div className={ 'is-card ' + ((this.state.GUI___section___selected === 5) ? ' is-selected-item' : ' ') } onClick={() => { this.handle___GUI___select_section(5) }}>
												<div><img src="/assets/ui/icons/icon-vehicle-conditions.svg"/></div>
												<span>Efficienza</span>
											</div>
											<div className={ 'is-card ' + ((this.state.GUI___section___selected === 6) ? ' is-selected-item' : ' ') } onClick={() => { this.handle___GUI___select_section(6) }}>
												<div><img src="/assets/ui/icons/icon-writed.svg"/></div>
												<span>Descrizione</span>
											</div>
											<div className={ 'is-card ' + ((this.state.GUI___section___selected === 7) ? ' is-selected-item' : ' ') } onClick={() => { this.handle___GUI___select_section(7) }}>
												<div><img src="/assets/ui/icons/icon-documents.svg"/></div>
												<span>Catalogo</span>
											</div>
											<div className={ 'is-card ' + ((this.state.GUI___section___selected === 8) ? ' is-selected-item' : ' ') } onClick={() => { this.handle___GUI___select_section(8) }}>
												<div><img src="/assets/ui/icons/icon-sale.svg"/></div>
												<span>Vendita</span>
											</div>
											<div className={ 'is-card ' + ((this.state.GUI___section___selected === 9) ? ' is-selected-item' : ' ') } onClick={() => { this.handle___GUI___select_section(9) }}>
												<div><img src="/assets/ui/icons/icon-overview.svg"/></div>
												<span>Pubblicazione</span>
											</div>
										</> : <>
											<div className="is-card" style={{opacity:'0.5'}}>
												<div><img src="/assets/ui/icons/icon-vehicle-camera.svg"/></div>
												<span>Foto</span>
											</div>
											<div className="is-card" style={{opacity:'0.5'}}>
												<div><img src="/assets/ui/icons/icon-conditions.svg"/></div>
												<span>Condizioni</span>
											</div>
											<div className="is-card" style={{opacity:'0.5'}}>
												<div><img src="/assets/ui/icons/icon-specs.svg"/></div>
												<span>Specifiche</span>
											</div>
											<div className="is-card" style={{opacity:'0.5'}}>
												<div><img src="/assets/ui/icons/icon-vehicle-config.svg"/></div>
												<span>Configurazione</span>
											</div>
											<div className="is-card" style={{opacity:'0.5'}}>
												<div><img src="/assets/ui/icons/icon-vehicle-conditions.svg"/></div>
												<span>Efficienza</span>
											</div>
											<div className="is-card" style={{opacity:'0.5'}}>
												<div><img src="/assets/ui/icons/icon-writed.svg"/></div>
												<span>Descrizione</span>
											</div>
											<div className="is-card" style={{opacity:'0.5'}}>
												<div><img src="/assets/ui/icons/icon-documents.svg"/></div>
												<span>Catalogo</span>
											</div>
											<div className="is-card" style={{opacity:'0.5'}}>
												<div><img src="/assets/ui/icons/icon-sale.svg"/></div>
												<span>Vendita</span>
											</div>
											<div className="is-card" style={{opacity:'0.5'}}>
												<div><img src="/assets/ui/icons/icon-overview.svg"/></div>
												<span>Pubblicazione</span>
											</div>
										</>}
									</section>
									<section></section>
								</div>

								<div className="vehicle-editor---container" style={{top:'-' + (this.state.GUI___section___selected * 100).toString() + 'vh'}}>

{/*******************************    I N F O    *******************************/}
								
									<div id="info" className="is-card vehicle---modifier---container">
										<div className="vehicle-editor---title">
											{(this.state.field___vehicle_category === 'motorcycle') ? <>
												<img src="/assets/ui/icons/icon-vehicle-motorcycle.svg"/>
											</> : <>
												<img src="/assets/ui/icons/icon-vehicle-car.svg"/>
											</>}
											<span>Informazioni Veicolo</span>
										</div>
										<div className="vehicle-editor---section">
											<span className="input---nb-label is-required">Categoria veicolo</span>
											<select className={'input---nb-outlined ' + ((this.state.field___vehicle_category !== null) ? ' input---good ' : ' ')} value={ this.state.field___vehicle_category ?? '' } onChange={ this.handle___select___vehicle_category }>
												<option value={''} disabled={true} selected={true}>Seleziona Categoria Veicolo</option>
												{(this.props.props___licence['licence_active___vehiclesDb___cars'] === true) ? <><option value={ 'car' }>Automobile</option></> : <></>}
												{(this.props.props___licence['licence_active___vehiclesDb___commercials'] === true) ? <><option value={ 'commercial' }>Veicolo Commerciale</option></> : <></>}
												{(this.props.props___licence['licence_active___vehiclesDb___motorcycles'] === true) ? <><option value={ 'motorcycle' }>Motocicletta</option></> : <></>}
											</select>
											<br />
											<span className="input---nb-label is-required">Marchio/Produttore</span>
											<select className={'input---nb-outlined ' + ((this.state.field___vehicle_brand != null) ? ' input---good ' : ' ')} onChange={ this.handle___select___vehicle_brand } value={ this.state.field___vehicle_brand ?? 'other' } disabled={ this.state.loaded_props___vehicle___brands.length === 0 }>
												<option value={'null'} disabled={true} selected={true}>Seleziona Marchio/Produttore</option>
												<option value={''} disabled={true}></option>
												{ this.state.loaded_props___vehicle___brands.map((___c_b: any) => <option value={ ___c_b['vehicle_brand_id'] }>{ ___c_b['vehicle_brand_name'] }</option> )};
												<option value={''} disabled={true}></option>
												<option value={ 'other' }>Altro Marchio/Produttore</option>
											</select>
											<br />
											<span className="input---nb-label is-required">Modello</span>
											<select className={'input---nb-outlined ' + ((this.state.field___vehicle_model != null) ? ' input---good ' : ' ')} onChange={ this.handle___select___vehicle_model } value={ this.state.field___vehicle_model ?? 'other' } disabled={ !(this.state.loaded_props___vehicle___brands.length !== 0 && this.state.field___vehicle_brand !== null) }>
												<option value={'null'} disabled={true} selected={true}>Seleziona Modello</option>
												<option value={''} disabled={true}></option>
												{ this.state.loaded_props___vehicle___models.map((___c_m: any) => <option value={ ___c_m['vehicle_model_id'] }>{ ___c_m['vehicle_model_name'] }</option> )};
												<option value={''} disabled={true}></option>
												<option value={ 'other' }>Altro Modello</option>
											</select>
											<br />








										{/*

											<span className="input---nb-label">Versione (necessaria per l'autocompilazione)</span>
											<select className={'input---nb-outlined ' + ((this.state.field___vehicle_version != '') ? ' input---good ' : ' ')} onChange={ this.handle___select___vehicle_version } value={ this.state.field___vehicle_version ?? '' } disabled={ this.state.loaded_props___vehicle___versions.length === 0 && this.state.field___vehicle_model !== 'other' }>
												<option value={''} disabled={true} selected={true}>Seleziona Versione</option>
												<option value={''} disabled={true}></option>
												{ this.state.loaded_props___vehicle___versions.map((___c_ve: any) => <option value={ ___c_ve['vehicle_version'] }>{ ___c_ve['vehicle_version'] + ' - ' + this.lib___vehicle_attributes.get___vehicle_attribute___name___property('engine_engine_fuel_type', ___c_ve['engine_engine_fuel_type'])}</option> )};
												<option value={''} disabled={true}></option>
												<option value={ 'other' }>Altra Versione</option>
											</select>
											<br />
											<span className="input---nb-label">Variante Versione (necessaria per l'autocompilazione)</span>
											<select className={'input---nb-outlined ' + ((this.state.field___vehicle_variant != '') ? ' input---good ' : ' ')} onChange={ this.handle___select___vehicle_variant } value={ this.state.field___vehicle_variant ?? '' } disabled={ this.state.loaded_props___vehicle___variants.length === 0 && this.state.field___vehicle_version !== 'other' }>
												<option value={''} disabled={true} selected={true}>Seleziona Variante Versione</option>
												<option value={''} disabled={true}></option>
												{ this.state.loaded_props___vehicle___variants.map((___c_va: any) => <option value={ ___c_va['_id'] }>{ ___c_va['vehicle_variant'] }</option> )};
												<option value={''} disabled={true}></option>
												<option value={ 'other' }>Altro Variante Versione</option>
												</select>
											<br />

										*/}
										

											<span className="input---nb-label">Titolo Annuncio</span>
											<section className="input---nb-outlined---flexy">
												<input type="text" value={ this.state.field___vehicle_name } placeholder="Marca, Modello, Versione, Variante" onInput={(___e: any) => { this.setState({ field___vehicle_name: ___e.target.value })}}/>
											</section>
											<br />

										</div>
									</div>

{/*******************************    F O T O   E   V I D E O    *******************************/}

									<div id="media" className="is-card vehicle---modifier---container">
										<div className="vehicle-editor---title">
											<img src="/assets/ui/icons/icon-vehicle-camera.svg"/>
											<span>Foto e Video</span>
										</div>
										<div className="vehicle-editor---section">
											<br />
											<span className="input---nb-label">
												<span>Gallery Media</span>
												{(!(this.state.field___gallery_pictures.length > 0)) ? <>
													<span> - le immagini devono essere di massimo 1.6MB</span>
												</> : <></>}
											</span>
											{(this.state.field___gallery_pictures.length > 0) ? <>
												<ComVehicleEditGalleryComponent gallery_pictures={ this.state.field___gallery_pictures } event___onUpdate={(___e: string[]) => { this.setState({ field___gallery_pictures: ___e }, () => { console.log('___e',___e) }) }}/>
											</> : <></>}
											<section className="input---nb-outlined---file" style={{marginTop:'10pt'}}>
												<label htmlFor="input-file---gallery">
													<i className="fas fa-upload"></i>
													<span>carica file</span>
													<input type="file" id="input-file---gallery" onChange={ this.handle___gallery___file___upload } accept=".jpg, .jpeg" multiple/>
													<span></span>
												</label>
											</section>
											<br />
											<br />
											<span className="input---nb-label">
												<span>Immagine sferica a 360°</span>
												{(!(this.state.field___gallery_360 !== null)) ? <>
													<span> - l'immagine deve essere 360-FLAT e di massimo 2.5MB</span>
												</> : <></>}
											</span>
											{(this.state.field___gallery_360 !== null) ? <>
												<div className="vehicle-editor---360pic">
													<ReactPhotoSphereViewer src="/_test/360.jpg" height={'100%'} width={'100%'} navbar={false} plugins={[ [AutorotatePlugin, { AutorotatePlugin: { autostartOnIdle: true }}] ]}></ReactPhotoSphereViewer>
												</div>
											</> : <></>}
											<section className="input---nb-outlined---file" style={{marginTop:'10pt'}}>
												<label htmlFor="input-file---360">
													<i className="fas fa-upload"></i>
													<span>carica file</span>
													<input type="file" id="input-file---360"/>
													<span></span>
												</label>
											</section>
											<br />
											<br />
											<span className="input---nb-label">link al video</span>
											<section className="input---nb-outlined---flexy">
												<input type="text" value={ this.state.field___gallery_video } placeholder="https://www.linkalvideo.com/asdfg" min={ 0 } onInput={(___e: any) => { this.setState({ field___gallery_video: ___e.target.value })}}/>
											</section>
											<br />
										</div>
									</div>

{/*******************************    C O N D I Z I O N I    *******************************/}

									<div id="conditions" className="is-card vehicle---modifier---container">
										<div className="vehicle-editor---title">
											<img src="/assets/ui/icons/icon-conditions.svg"/>
											<span>Condizioni</span>
										</div>
										<div className="vehicle-editor---section">
											<br />
											<span className="input---nb-label is-required">Categoria utilizzo</span>
											<section className="input---nb-outlined---flexy">
												<select value={ this.state.field___conditions___category ?? '' } className={'input---nb-outlined ' + ((this.state.field___conditions___category !== '') ? ' input---good ' : ' ')} onChange={(___e: any)=>{this.setState({field___conditions___category:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} required>
													<option value={ '' } disabled={ true }>Seleziona categoria utilizzo</option>
													{this.lib___vehicle_attributes.get___vehicle_attribute___name('vehicle_category').map((___c_c: any) => <option value={ ___c_c['key'] }>{ ___c_c['value'] }</option> )}
												</select>
												<section>
													<input type="number" value={ this.state.field___conditions___km ?? undefined } className={'input---nb-outlined ' + ((this.state.field___conditions___km !== null) ? ' input---good ' : ' ')} placeholder="chilometraggio" min={ 0 } onInput={ (___e: any)=>{this.setState({field___conditions___km: ___e.target.value });this.submit___vehicle___check_if_can();} }/>
													<p>km</p>
												</section>
											</section>
											<br />
											<span className="input---nb-label">Data di immatricolazione</span>
											<section className="input---nb-outlined---flexy">
												<select value={ this.state.field___conditions___registration_month ?? '' } className={'input---nb-outlined ' + ((this.state.field___conditions___registration_month !== '') ? ' input---good ' : ' ')} onChange={(___e: any)=>{this.setState({field___conditions___registration_month:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} required>
													<option value={ '' } disabled={ true }>Mese</option>
													<option value={ '01' }>01 - Gennaio</option>
													<option value={ '02' }>02 - Febbraio</option>
													<option value={ '03' }>03 - Marzo</option>
													<option value={ '04' }>04 - Aprile</option>
													<option value={ '05' }>05 - Maggio</option>
													<option value={ '06' }>06 - Giugno</option>
													<option value={ '07' }>07 - Luglio</option>
													<option value={ '08' }>08 - Agosto</option>
													<option value={ '09' }>09 - Settembre</option>
													<option value={ '10' }>10 - Ottobre</option>
													<option value={ '11' }>11 - Novembre</option>
													<option value={ '12' }>12 - Dicembre</option>
												</select>
												<input type="number" value={ this.state.field___conditions___registration_year ?? undefined } className={'input---nb-outlined ' + ((this.state.field___conditions___registration_year) ? ' input---good ' : ' ')} placeholder="Anno" onChange={(___e: any)=>{this.setState({field___conditions___registration_year:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} min={ this.lib___vehicle_attributes.lib_vehicle_attributes___years_range.min } max={ this.lib___vehicle_attributes.lib_vehicle_attributes___years_range.max }/>
											</section>
											<section className="input---nb-outlined---flexy" style={{marginTop:'10pt'}}>
												<input type="number" value={ this.state.field___conditions___owners_number ?? undefined } className={'input---nb-outlined ' + ((this.state.field___conditions___owners_number !== null) ? ' input---good ' : ' ')} placeholder="Numero" onChange={(___e: any)=>{this.setState({field___conditions___owners_number:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} min={ 1900 }/>
												<p>ex</p><p>proprietari</p>
											</section>
											<br />
										</div>
									</div>

{/*******************************    S P E C I F I C H E   T E C N I C H E    *******************************/}

									<div id="specs" className="is-card vehicle---modifier---container">
										<div className="vehicle-editor---title">
											<img src="/assets/ui/icons/icon-specs.svg"/>
											<span>Specifiche tecniche</span>
										</div>
										<div className="vehicle-editor---section">
											<br />
											<span className="input---nb-label">carburante</span>
											<section className="input---nb-outlined---flexy">
												<select value={ this.state.field___specs___engine_fuel_type } className={'input---nb-outlined ' + ((this.state.field___specs___engine_fuel_type !== '') ? ' input---good ' : ' ')} required>
													<option value={ '' } disabled={ true }>Seleziona Carburante</option>
													{this.lib___vehicle_attributes.get___vehicle_attribute___name('engine_fuel_type').map((___f_t: any) => <option value={ ___f_t['key'] }>{ ___f_t['value'] }</option> )}
												</select>
											</section>
											<br />
											<span className="input---nb-label">Motore</span>
											<section className="input---nb-outlined---flexy">
												<input type="text" value={ this.state.field___specs___engine_name } className={'input---nb-outlined ' + ((this.state.field___specs___engine_name) ? ' input---good ' : ' ')} placeholder="Motorizzazione" onInput={(___e: any)=>{this.setState({field___specs___engine_name:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}}/>
											</section>
											<section className="input---nb-outlined---flexy" style={{marginTop:'10pt'}}>
												<select value={ this.state.field___specs___engine_cylinders_displacement ?? '' } className={'input---nb-outlined ' + ((this.state.field___specs___engine_cylinders_displacement !== '') ? ' input---good ' : ' ')} onChange={(___e: any)=>{this.setState({field___specs___engine_cylinders_displacement:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} required>
													<option value={ '' } disabled={ true }>Seleziona Displacement del Motore</option>
													{this.lib___vehicle_attributes.get___vehicle_attribute___name('engine_cylinder_displacement').map((___e_d: any) => <option value={ ___e_d['key'] }>{ ___e_d['value'] }</option> )}
												</select>
												<section>
													<input type="number" value={ this.state.field___specs___engine_cylinders_number ?? undefined } className={'input---nb-outlined ' + ((this.state.field___specs___engine_cylinders_number) ? ' input---good ' : ' ')} placeholder="n°" onInput={(___e: any)=>{this.setState({field___specs___engine_cylinders_number:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} min={ 0 }/>
													<p>cilindri</p>
												</section>
											</section>
											<section className="input---nb-outlined---flexy" style={{marginTop:'10pt'}}>
												<section>
													<input type="number" value={ this.state.field___specs___engine_capacity ?? undefined } className={'input---nb-outlined ' + ((this.state.field___specs___engine_capacity) ? ' input---good ' : ' ')} placeholder="Cilindrata" onInput={(___e: any)=>{this.setState({field___specs___engine_capacity:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} min={ 0 }/>
													<p>cc</p>
												</section>
												<section>
													<input type="number" value={ this.state.field___specs___engine_power ?? undefined } className={'input---nb-outlined ' + ((this.state.field___specs___engine_power) ? ' input---good ' : ' ')} placeholder="Potenza" onInput={(___e: any)=>{this.setState({field___specs___engine_power:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} min={ 0 }/>
													<select value={ this.state.field___specs___engine_power_unit ?? 'hp' } onChange={(___e: any)=>{this.setState({field___specs___engine_power_unit:___e.target.value})}} style={{width:'50pt'}}>
														<option value={ 'hp' }>cv</option>
														<option value={ 'kw' }>kw</option>
													</select>
												</section>
											</section>
											<br />
											<span className="input---nb-label">Cambio</span>
											<section className="input---nb-outlined---flexy">
												<section>
													<select value={ this.state.field___specs___gearbox_type ?? '' } onChange={(___e: any)=>{this.setState({field___specs___gearbox_type:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___specs___engine_fuel_type !== '') ? ' input---good ' : ' ')}>
														<option value={ '' } disabled={ true }>Seleziona Tipologia Cambio</option>
														{this.lib___vehicle_attributes.get___vehicle_attribute___name('gearbox_type').map((___e_g_t: any) => <option value={ ___e_g_t['key'] }>{ ___e_g_t['value'] }</option> )}
													</select>
												</section>
												<section>
													<input type="number" value={ this.state.field___specs___gearbox_gears ?? undefined } className={'input---nb-outlined ' + ((this.state.field___specs___gearbox_gears) ? ' input---good ' : ' ')} placeholder="n°" min={ 0 }/>
													<p>rapporti</p>
												</section>
											</section>
											<br />
											<span className="input---nb-label">Trazione</span>
											<section className="input---nb-outlined---flexy">
												<select value={ this.state.field___specs___transmission_drive ?? '' } onChange={(___e: any)=>{this.setState({field___specs___transmission_drive:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___specs___transmission_drive !== '') ? ' input---good ' : ' ')}>
													<option value={ '' } disabled={ true }>Seleziona Trazione</option>
													{this.lib___vehicle_attributes.get___vehicle_attribute___name('transmission_drive').map((___e_t_d: any) => <option value={ ___e_t_d['key'] }>{ ___e_t_d['value'] }</option> )}
												</select>
											</section>
											<br />
											<span className="input---nb-label">Freni</span>
											<section className="input---nb-outlined---flexy">
												<select value={ this.state.field___specs___brakes_front_type ?? '' } onChange={(___e: any)=>{this.setState({field___specs___brakes_front_type:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___specs___brakes_front_type !== '') ? ' input---good ' : ' ')}>
													<option value={ '' } disabled={ true }>Freni Anteriori</option>
													{this.lib___vehicle_attributes.get___vehicle_attribute___name('brakes_type').map((___b_f_t: any) => <option value={ ___b_f_t['key'] }>{ ___b_f_t['value'] }</option> )}
												</select>
												<select value={ this.state.field___specs___brakes_rear_type ?? '' } onChange={(___e: any)=>{this.setState({field___specs___brakes_rear_type:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___specs___brakes_rear_type !== '') ? ' input---good ' : ' ')}>
													<option value={ '' } disabled={ true }>Freni Posteriori</option>
													{this.lib___vehicle_attributes.get___vehicle_attribute___name('brakes_type').map((___b_r_t: any) => <option value={ ___b_r_t['key'] }>{ ___b_r_t['value'] }</option> )}
												</select>
											</section>
											<br />
											<span className="input---nb-label">Normativa Euro</span>
											<section className="input---nb-outlined---flexy">
												<select value={ this.state.field___specs___emission_class ?? '' } onChange={(___e: any)=>{this.setState({field___specs___emission_class:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___specs___transmission_drive !== '') ? ' input---good ' : ' ')}>
													<option value={ '' } disabled={ true }>Seleziona classe Euro</option>
													{this.lib___vehicle_attributes.get___vehicle_attribute___name('emission_class').map((___e_t_d: any) => <option value={ ___e_t_d['key'] }>{ ___e_t_d['value'] }</option> )}
												</select>
											</section>
											<br />
										</div>
									</div>

{/*******************************    C O N F I G U R A Z I O N E    *******************************/}

									<div id="config" className="is-card vehicle---modifier---container">
										<div className="vehicle-editor---title">
											<img src="/assets/ui/icons/icon-vehicle-config.svg"/>
											<span>Configurazione</span>
										</div>
										<div className="vehicle-editor---section">
											<br />
											{(this.state.field___vehicle_category !== null) ? <>
												{(() => {
													switch (this.state.field___vehicle_category) {
														case 'car': return <>
															<span className="input---nb-label">carrozzeria</span>
															<section className="input---nb-outlined---flexy">
																<select value={ this.state.field___config___body_type } onChange={(___e: any)=>{this.setState({field___config___body_type:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___config___body_type !== '') ? ' input---good ' : ' ')}>
																	<option value={ '' } disabled={ true }>Seleziona Carrozzeria</option>
																	{this.lib___vehicle_attributes.get___vehicle_attribute___name('body_type_car').map((___b_t: any) => <option value={ ___b_t['key'] }>{ ___b_t['value'] }</option> )}
																</select>
																<input type="number" value={ this.state.field___config___body_doors_number ?? undefined } className={'input---nb-outlined ' + ((this.state.field___config___body_doors_number !== null) ? ' input---good ' : ' ')} onInput={(___e: any)=>{this.setState({field___config___body_doors_number:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} placeholder="Numero porte"/>
																<p>porte</p>
															</section>
														</>; break;
														case 'motorcycle': return <>
															<span className="input---nb-label">tipo telaio</span>
															<section className="input---nb-outlined---flexy">
																<select value={ this.state.field___config___body_type } onChange={(___e: any)=>{this.setState({field___config___body_type:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___config___body_type !== '') ? ' input---good ' : ' ')}>
																	<option value={ '' } disabled={ true }>Seleziona Carrozzeria</option>
																	{this.lib___vehicle_attributes.get___vehicle_attribute___name('body_type_bike').map((___b_t: any) => <option value={ ___b_t['key'] }>{ ___b_t['value'] }</option> )}
																</select>
															</section>
														</>; break;
														case null: return <></>; break;
													}
												})()}
												<br />
											</> : <></>}
											<span className="input---nb-label">Vernice</span>
											<section className="input---nb-outlined---flexy">
												<select value={ this.state.field___config___paint_type } onChange={(___e: any)=>{this.setState({field___config___paint_type:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___config___paint_type !== '') ? ' input---good ' : ' ')}>
													<option value={ '' } disabled={ true }>Seleziona Tipologia Vernice</option>
													{this.lib___vehicle_attributes.get___vehicle_attribute___name('body_paint_type').map((___b_p_t: any) => <option value={ ___b_p_t['key'] }>{ ___b_p_t['value'] }</option> )}
												</select>
												<select value={ this.state.field___config___paint_color } onChange={(___e: any)=>{this.setState({field___config___paint_color:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___config___paint_color !== '') ? ' input---good ' : ' ')}>
													<option value={ '' } disabled={ true }>Seleziona Colore</option>
													{this.lib___vehicle_attributes.get___vehicle_attribute___name('body_paint_color').map((___b_p_c: any) => <option value={ ___b_p_c['key'] }>{ ___b_p_c['value'] }</option> )}
												</select>
											</section>
											<br />
											{(this.state.field___vehicle_category !== null) ? <>
												{(() => {
													switch (this.state.field___vehicle_category) {
														case 'car': return <>
															<span className="input---nb-label">Interni</span>
															<section className="input---nb-outlined---flexy">
																<select value={ this.state.field___config___interior_material } onChange={(___e: any)=>{this.setState({field___config___interior_material:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___config___interior_material !== '') ? ' input---good ' : ' ')}>
																	<option value={ '' } disabled={ true }>Seleziona Materiale</option>
																	{this.lib___vehicle_attributes.get___vehicle_attribute___name('interior_material').map((___i_m: any) => <option value={ ___i_m['key'] }>{ ___i_m['value'] }</option> )}
																</select>
																<select value={ this.state.field___config___interior_color } onChange={(___e: any)=>{this.setState({field___config___interior_color:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___config___interior_color !== '') ? ' input---good ' : ' ')}>
																	<option value={ '' } disabled={ true }>Seleziona Colore</option>
																	{this.lib___vehicle_attributes.get___vehicle_attribute___name('interior_colors').map((___i_c: any) => <option value={ ___i_c['key'] }>{ ___i_c['value'] }</option> )}
																</select>
																<select value={ this.state.field___config___interior_details } onChange={(___e: any)=>{this.setState({field___config___interior_details:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___config___interior_details !== '') ? ' input---good ' : ' ')}>
																	<option value={ '' } disabled={ true }>Seleziona Modanature</option>
																	{this.lib___vehicle_attributes.get___vehicle_attribute___name('interior_details').map((___i_d: any) => <option value={ ___i_d['key'] }>{ ___i_d['value'] }</option> )}
																</select>
															</section>
															</>; break;
														case 'motorcycle': return <></>; break;
														case null: return <></>; break;
													}
												})()}
												<br />
											</> : <></>}
											<br />
											<span className="input---nb-label">Ruote, cerchi e gomme</span>
											<section className="input---nb-outlined---flexy">
												<section>
													<p>cerchi</p>
													<select value={ this.state.field___config___wheel_material } onChange={(___e: any)=>{this.setState({field___config___wheel_material:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___config___wheel_material !== '') ? ' input---good ' : ' ')}>
														<option value={ '' } disabled={ true }>Seleziona Materiale</option>
														{this.lib___vehicle_attributes.get___vehicle_attribute___name('wheel_material').map((___i_w: any) => <option value={ ___i_w['key'] }>{ ___i_w['value'] }</option> )}
													</select>
												</section>
												<section>
													<p>gomme</p>
													<input type="text" value={ this.state.field___config___wheel_tyres ?? undefined } onInput={(___e: any)=>{this.setState({field___config___wheel_tyres:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___config___wheel_tyres !== '') ? ' input---good ' : ' ')} placeholder="000 / 00 - R00"/>
												</section>
											</section>
											<br />
											{(this.state.field___vehicle_category !== null) ? <>
												{(() => {
													switch (this.state.field___vehicle_category) {
														case 'car': return <>
															<span className="input---nb-label">optionals</span>
															<section className="input---nb-outlined---cb">
																{this.lib___vehicle_attributes.get___vehicle_attribute___name('optional').map((___o: any) => 
																	<label htmlFor={ 'optional---' + ___o['key'] }>
																		<input type="checkbox" id={ 'optional---' + ___o['key'] } value={ ___o['key'] }/>
																		<p>{ ___o['value'] }</p>
																	</label>
																)}
															</section>
															</>; break;
														case 'motorcycle': return <></>; break;
														case null: return <></>; break;
													}
												})()}
												<br />
											</> : <></>}
										</div>
									</div>

{/*******************************    E F F I C I E N Z A    *******************************/}

									<div id="efficiency" className="is-card vehicle---modifier---container">
										<div className="vehicle-editor---title">
											<img src="/assets/ui/icons/icon-vehicle-conditions.svg"/>
											<span>Efficienza dei sistemi</span>
										</div>
										<div className="vehicle-editor---section">
											<br />
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Motore</p>
												<select value={ this.state.field___efficiency___engine ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___engine:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Sistema di alimentazione</p>
												<select value={ this.state.field___efficiency___power_supply ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___power_supply:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Scarico</p>
												<select value={ this.state.field___efficiency___exhaust ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___exhaust:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Emissioni</p>
												<select value={ this.state.field___efficiency___exhaust_emissions ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___exhaust_emissions:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Perdite di liquidi</p>
												<select value={ this.state.field___efficiency___leaks ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___leaks:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Raffreddamento Motore</p>
												<select value={ this.state.field___efficiency___engine_cooling ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___engine_cooling:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Cambio</p>
												<select value={ this.state.field___efficiency___gearbox ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___gearbox:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Frizione</p>
												<select value={ this.state.field___efficiency___clutch ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___clutch:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Freni</p>
												<select value={ this.state.field___efficiency___brakes ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___brakes:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Sterzo</p>
												<select value={ this.state.field___efficiency___steering ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___steering:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Sospensioni</p>
												<select value={ this.state.field___efficiency___suspensions ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___suspensions:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Ruote e Gomme</p>
												<select value={ this.state.field___efficiency___wheels ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___wheels:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Rumorosità</p>
												<select value={ this.state.field___efficiency___noise ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___noise:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Batteria</p>
												<select value={ this.state.field___efficiency___battery ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___battery:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Avviamento</p>
												<select value={ this.state.field___efficiency___engine_starter ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___engine_starter:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Luci</p>
												<select value={ this.state.field___efficiency___lights ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___lights:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Vetri</p>
												<select value={ this.state.field___efficiency___windows ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___windows:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Tergicristalli</p>
												<select value={ this.state.field___efficiency___windows_wipers ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___windows_wipers:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Errori e Spie Avaria</p>
												<select value={ this.state.field___efficiency___warnings ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___warnings:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Aria condizionata</p>
												<select value={ this.state.field___efficiency___air_conditioning ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___air_conditioning:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Specchietti</p>
												<select value={ this.state.field___efficiency___mirrors ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___mirrors:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Cinture di Sicurezza</p>
												<select value={ this.state.field___efficiency___seatbelts ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___seatbelts:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Interni (Tappezzeria)</p>
												<select value={ this.state.field___efficiency___interior ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___interior:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Sedili</p>
												<select value={ this.state.field___efficiency___interior_seats ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___interior_seats:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
												<p>Cruscotto</p>
												<select value={ this.state.field___efficiency___dashboard ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___dashboard:___e.target.value})}}>
													{ this.lib___selectables.selectable_options___vehicle_conditions() }
												</select>
											</section>
											<br />
											<br />
											<br />
											<section className="efficiency---car-body">
												<div>
													<img src="/assets/ui/images/car-body-conditions.png"/>
												</div>
												<div>
													<section className="input---nb-outlined---flexy is-selectable">
														<p>01</p>
														<select value={ this.state.field___efficiency___body___01 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___01:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>02</p>
														<select value={ this.state.field___efficiency___body___02 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___02:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>03</p>
														<select value={ this.state.field___efficiency___body___03 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___03:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>04</p>
														<select value={ this.state.field___efficiency___body___04 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___04:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>05</p>
														<select value={ this.state.field___efficiency___body___05 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___05:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>06</p>
														<select value={ this.state.field___efficiency___body___06 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___06:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>07</p>
														<select value={ this.state.field___efficiency___body___07 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___07:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>08</p>
														<select value={ this.state.field___efficiency___body___08 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___08:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>09</p>
														<select value={ this.state.field___efficiency___body___09 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___09:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>10</p>
														<select value={ this.state.field___efficiency___body___10 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___10:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>11</p>
														<select value={ this.state.field___efficiency___body___11 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___11:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>12</p>
														<select value={ this.state.field___efficiency___body___12 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___12:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>13</p>
														<select value={ this.state.field___efficiency___body___13 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___13:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>14</p>
														<select value={ this.state.field___efficiency___body___14 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___14:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>15</p>
														<select value={ this.state.field___efficiency___body___15 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___15:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>16</p>
														<select value={ this.state.field___efficiency___body___16 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___16:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>17</p>
														<select value={ this.state.field___efficiency___body___17 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___17:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>18</p>
														<select value={ this.state.field___efficiency___body___18 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___18:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>19</p>
														<select value={ this.state.field___efficiency___body___19 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___19:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>20</p>
														<select value={ this.state.field___efficiency___body___20 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___20:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
													<section className="input---nb-outlined---flexy is-selectable" style={{marginTop:'10pt'}}>
														<p>21</p>
														<select value={ this.state.field___efficiency___body___21 ?? '' }  className="input---nb-outlined" onChange={(___e: any)=>{this.setState({field___efficiency___body___21:___e.target.value})}}>
															{ this.lib___selectables.selectable_options___vehicle_conditions() }
														</select>
													</section>
												</div>
											</section>
											<br />
										</div>
									</div>

{/*******************************    D E S C R I Z I O N E   E   T A G S    *******************************/}

									<div id="description" className="is-card vehicle---modifier---container">
										<div className="vehicle-editor---title">
											<img src="/assets/ui/icons/icon-writed.svg"/>
											<span>Descrizione e Tags</span>
										</div>
										<div className="vehicle-editor---section">
											<br />
											<span className="input---nb-label">Descrizione</span>
											<textarea value={ this.state.field___info___description ?? undefined } onInput={(___e: any)=>{this.setState({field___info___description:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined is-textarea ' + ((this.state.field___info___description !== '') ? ' input---good ' : ' ')} placeholder="Informazioni aggiuntive sul veicolo"></textarea>
											<br />
										</div>
									</div>

{/*******************************    C A T A L O G O   I N T E R N O    *******************************/}

									<div id="catalog" className="is-card vehicle---modifier---container">
										<div className="vehicle-editor---title">
											<img src="/assets/ui/icons/icon-documents.svg"/>
											<span>Catalogo Interno</span>
										</div>
										<div className="vehicle-editor---section">
											<br />
								{/*			<span className="input---nb-label">Provenienza</span>
											<section className="input---nb-outlined---flexy">
												<select value={ this.state.field___registration___from_type } onInput={(___e: any)=>{this.setState({field___registration___from_type:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___registration___from_type !== null) ? ' input---good ' : ' ')}>
													<option value={''} selected={ true } disabled={ true }>Seleziona provenienza</option>
													<option value={'partner_business'}>Acquistata da Azienda</option>
													<option value={'partner_private'}>Ritiro da Privato</option>
												</select>
											</section>

											<br />

											{(this.state.field___registration___from_type !== '') ? <>
											
											









												field___registration___from_user: '',







											</>	: <></>}

































											*/}

											<span className="input---nb-label">Targa e Telaio</span>
											<section className="input---nb-outlined---flexy">
												<section>
													<input type="text" value={ this.state.field___registration___plate } onInput={(___e: any)=>{this.setState({field___registration___plate:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___registration___plate !== '') ? ' input---good ' : ' ')} placeholder="XX 000 XX" min={ 0 }/>
												</section>
												<section>
													<input type="text" value={ this.state.field___registration___vin } onInput={(___e: any)=>{this.setState({field___registration___vin:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___registration___vin !== '') ? ' input---good ' : ' ')} placeholder="VIN Number"/>
												</section>
											</section>

											<br />

											<span className="input---nb-label">Prezzo di acquisto</span>
											<section className="input---nb-outlined---flexy">
												<section>
													<p>€</p>
													<input type="number" value={ this.state.field___accounting___price_bought ?? undefined } onInput={(___e: any)=>{this.setState({field___accounting___price_bought:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___accounting___price_bought !== null) ? ' input---good ' : ' ')} placeholder="0,00" min={ 0 }/>
													<p>iva</p>
													<select value={ this.state.field___accounting___price_bought_vat } onChange={(___e: any)=>{this.setState({field___accounting___price_bought_vat:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___accounting___price_bought_vat !== null) ? ' input---good ' : ' ')} style={{width:'100pt'}}>{ this.lib___selectables.selectable_options___vat_quotes() }</select>
													<select value={ this.state.field___accounting___price_bought_vat_type } onChange={(___e: any)=>{this.setState({field___accounting___price_bought_vat_type:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___accounting___price_bought_vat_type !== null) ? ' input---good ' : ' ')}>{ this.lib___selectables.selectable_options___vat_types() }</select>
												</section>
											</section>

											<br />

										</div>
									</div>

{/*******************************    V E N D I T A    *******************************/}

									<div id="sale" className="is-card vehicle---modifier---container">
										<div className="vehicle-editor---title">
											<img src="/assets/ui/icons/icon-sale.svg"/>
											<span>Vendita</span>
										</div>
										<div className="vehicle-editor---section">
											<br />
											<span className="input---nb-label">Prezzo di vendita (visibile pubblicamente)</span>
											<section className="input---nb-outlined---flexy">
												<section>
													<p>€</p>
													<input type="number" value={ this.state.field___accounting___price_sell ?? undefined } onInput={(___e: any)=>{this.setState({field___accounting___price_sell:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___accounting___price_sell !== null) ? ' input---good ' : ' ')} placeholder="0,00" min={ 0 }/>
													<p>iva</p>
													<select value={ this.state.field___accounting___price_sell_vat } onChange={(___e: any)=>{this.setState({field___accounting___price_sell_vat:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___accounting___price_sell_vat !== null) ? ' input---good ' : ' ')} style={{width:'100pt'}}>{ this.lib___selectables.selectable_options___vat_quotes() }</select>
													<select value={ this.state.field___accounting___price_sell_vat_type } onChange={(___e: any)=>{this.setState({field___accounting___price_sell_vat_type:___e.target.value},()=>{this.submit___vehicle___check_if_can()})}} className={'input---nb-outlined ' + ((this.state.field___accounting___price_sell_vat_type !== null) ? ' input---good ' : ' ')}>{ this.lib___selectables.selectable_options___vat_types() }</select>
												</section>
											</section>
											<br />
										</div>
									</div>

{/*******************************    P U B B L I C A Z I O N E    *******************************/}

									<div id="finish" className="is-card vehicle---modifier---container vehicle-edit---try-to-submit---container">
										<div className="vehicle-editor---title">
											<img src="/assets/ui/icons/icon-overview.svg"/>
											<span>Pubblicazione</span>
										</div>
										<div className="vehicle-editor---section">

											<br />
											<br />
											<br />

											<p>Il tuo articolo è pronto per la pubblicazione, vuoi pubblicarlo ora?</p>

											<br />

											<span className="input---nb-label">Pubblica veicolo come</span>
											<select value={ this.state.field___vehicle___visibility ?? 'visible' } className="input---nb-outlined" onChange={ this.handle___select___vehicle_visibility }>
												<option value={ 'visible' }>Veicolo visibile pubblicamente</option>
												<option value={ 'hidden' }>Veicolo nascosto</option>
											</select>

											<br />
											<br />
											<br />
											<br />

											<div className="page-container---bottom-bar---buttons is-centered">
												<button type="button" className="is-card is-submit" onClick={() => { this.submit___vehicle___try(true); }}>
													<i className="fas fa-check"></i>
													<span>Salva Veicolo</span>
												</button>
											</div>

										</div>
									</div>

								</div>
								
							</div>

						</> : <>

{/*******************************    I S   S U B M I T T I N G    *******************************/}

							<div  className="is-card vehicle-edit---is-submitted---container">
								<section>
									{(() => {
										switch (this.state.vehicle___is_submitted___result) {
											case null: return <>
												<ComSpinnerComponent has_margin={ false }/>
											</>; break;
											case true: return <>
												<img src="/assets/ui/icons/icon-check.svg"/>
												{(this.state.field___id === null) ? <>
													<h3>Hai inserito questo veicolo nel Database</h3>
												</> : <>
													<h3>Hai aggiornato questo veicolo</h3>
												</>}
												<div className="page-container---bottom-bar---buttons is-centered">
													<Link to={ '/vehicles/list' } className="is-submit no-shadow">
														<i className="fas fa-list"></i>
														<span className="is-full-width">Vai alla lista veicoli</span>
													</Link>
												</div>
											</>; break;
											case false: return <>
												<img src="/assets/ui/icons/icon-error.svg"/>
												{(this.state.field___id === null) ? <>
													<h3>Impossibile inserire questo veicolo nel Database</h3>
												</> : <>
													<h3>Impossibile aggiornare questo veicolo</h3>
												</>}
												<div className="page-container---bottom-bar---buttons is-centered">
													<button type="button" className="no-shadow" onClick={() => { this.submit___vehicle___try(false); }}>
														<i className="fas fa-sync-alt"></i>
														<span className="is-full-width">Riprova</span>
													</button>
												</div>
											</>; break;
										}
									})()}
								</section>
							</div>

						</>}
				</section>
			</div>

			</> : <>

				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<ComSpinnerComponent/>

			</>}

		</>;

	}

//#endregion

}