import React, { createRef } from 'react';
import { Chart as ChartJS } from 'chart.js/auto'

//	S T Y L E

import './sb-analytics.scss';

//	T Y P E S

import { type___lead___status } from '../../types/types.types';

//	S T A T E

import { props___SbAnalyticsPage, state___SbAnalyticsPage, state___SbAnalyticsPage___defaults } from './sb-analytics.state';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComResultsEmptyComponent from '../../components/com-results-empty/com-results-empty';
import ComLeadComponent from '../../components/com-lead/com-lead';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';

//	C L A S S

export default class SbAnalyticsPage extends React.Component<props___SbAnalyticsPage, state___SbAnalyticsPage>
{

//#region 																							D E C L A R A T I O N S

	private ___htmlRef___chart___accounting = createRef<HTMLCanvasElement>();
	private ___htmlRef___chart___accounting___instance?: ChartJS<'bar'>;

	private ___htmlRef___chart___leads = createRef<HTMLCanvasElement>();
	private ___htmlRef___chart___leads___instance?: ChartJS<'line'>;
	
	private ___htmlRef___chart___leads_source = createRef<HTMLCanvasElement>();
	private ___htmlRef___chart___leads_source___instance?: ChartJS<'pie'>;








	private readonly _API: service_RestApiService = new service_RestApiService()

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbAnalyticsPage
	) {
		super(props);
		this.state = state___SbAnalyticsPage___defaults;
	}

//#endregion

//#region																							R E A D   P R O P S

	rear_props___chart___year = async () => {
		const ___a_year___result: any = await this._API.analytics___year(2024);
		
		if (___a_year___result && ___a_year___result['sales'])
		{
			const ___htmlRef___chart___accounting___element = this.___htmlRef___chart___accounting.current?.getContext('2d');
			if (___htmlRef___chart___accounting___element) {
				this.___htmlRef___chart___accounting___instance = new ChartJS(___htmlRef___chart___accounting___element, {
					type: 'bar',
					data: {...___a_year___result['sales']}, 
					options: {
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							 y: { beginAtZero: true, },
						},
					},
				});
			}
		}
		
		if (___a_year___result && ___a_year___result['leads'])
		{
			const ___htmlRef___chart___leads___element = this.___htmlRef___chart___leads.current?.getContext('2d');
			if (___htmlRef___chart___leads___element) {
				this.___htmlRef___chart___leads___instance = new ChartJS(___htmlRef___chart___leads___element, {
					type: 'line',
					data: {...___a_year___result['leads']},
					options: {
						responsive: true,
						maintainAspectRatio: false,
						scales: {
							y: { beginAtZero: true, },
						},
					},
				});
			}
		}

		if (___a_year___result && ___a_year___result['leads'])
		{
			const ___htmlRef___chart___leads_source___element = this.___htmlRef___chart___leads_source.current?.getContext('2d');
			if (___htmlRef___chart___leads_source___element) {
				this.___htmlRef___chart___leads_source___instance = new ChartJS(___htmlRef___chart___leads_source___element, {
					type: 'pie',
					data: {...___a_year___result['leads_source']}, 
					options: {
						responsive: true,
						maintainAspectRatio: false,
						plugins: {
							legend: {
								position: 'right',
							},
						}
					},
				});
			}
		}

		this.setState({
			GUI___page___is_loading: false,
		});

	}

//#endregion

//#region 																							H A N D L E R S

	handle___select___tab = (___e: typeof state___SbAnalyticsPage___defaults['selected___tab']) => {
		this.setState({ 
			selected___tab: ___e,
			GUI___page___is_loading: true,
		}, async () => { 
			switch (this.state.selected___tab) {
				case 'overview':
					await this.rear_props___chart___year();
					break;
			}
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		this.setState({
			GUI___page___is_loading: true,
		}, async () => {
			await this.rear_props___chart___year();
		});
	}

//#endregion 

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="page---container is-full-width">
				<section>
					<div className="page---top-bar">
						<section>
							<input type="radio" id="d-ts---cb---overview" name="d-ts---cb" value="overview" checked={ this.state.selected___tab === 'overview' } onChange={(___e: any) => { this.handle___select___tab(___e['target']['value']); }} />
							<label htmlFor="d-ts---cb---overview" className="is-card">
								<img src="/assets/ui/icons/icon-analytics-overview.svg" />
								<span>Panoramica</span>
							</label>
							










						</section>
						<section>
							<label className="page---top-bar---search-box is-card" style={{width:'150pt'}}>
								<i className="fas fa-calendar"></i>
								<select >
									<option value="2024">2024</option>
								</select>
							</label>







						</section>
					</div>

					{(this.state.GUI___page___is_loading === true) ? <>
						<ComSpinnerComponent/>
					</> : <>
						{(() => {
							switch (this.state.selected___tab) {
								case 'overview': return <>
									<div className="is-card analytics-overview---container">
										<section>
											<h4>Entrate/Uscite</h4>
											<section>
												<canvas ref={ this.___htmlRef___chart___accounting } />
											</section>
											<h4>Leads</h4>
											<section>
												<canvas ref={ this.___htmlRef___chart___leads } />
											</section>
											<h4>Sorgente dei Leads</h4>
											<section>
												<canvas ref={ this.___htmlRef___chart___leads_source } />
											</section>
											<br />
											<br />
											<br />
										</section>
									</div>


								
								
								
								
								
								










								
								</>; break;











							}
						})()}
						
						










						
					</>}

				</section>
			</div>
		</>;
	}

//#endregion

}