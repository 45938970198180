import React from "react";
import { Outlet } from "react-router-dom";

//	E X P O R T

export default class SbSettingsPage extends React.Component
{

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<Outlet />
		</>;
	}

//#endregion

}