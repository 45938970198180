import React from "react";
import { Link } from "react-router-dom";

//	S T Y L E

import "./com-lead.scss";

//	T Y P E S

import { type___alert___controller } from "../../types/types.alerts";
import { type___api___response } from "../../types/types.api-response";
import { type___lead___interest___to, type___lead___status } from "../../types/types.types";
import { type___toast___controller } from "../../types/types.toasts";

//	L I B S

import { lib_properties } from "../../libs/lib.properties";
import { lib_names } from "../../libs/lib.names";

//	F U N C S

import { funcs_datetime } from "../../funcs/funcs.datetime";

//	S T A T E

import { props___ComLeadComponent, state___ComLeadComponent, state___ComLeadComponent___default } from "./com-lead.state";

//	S E R V I C E S

import { service_RestApiService } from "../../services/service-api";

//	C O M P O N E N T S

import ComSpinnerComponent from "../com-spinner/com-spinner";

//	C L A S S

export default class ComLeadComponent extends React.Component<props___ComLeadComponent, state___ComLeadComponent>
{

//#region 																							R E N D E R

	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly lib___properties: lib_properties = new lib_properties();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComLeadComponent
	) {
		super(props);
		this.state = state___ComLeadComponent___default;
	}

//#endregion

//#region 																							H A N D L E R S

	readprops___lead_vehicle = async () => { 
		if (this.props.lead_props['lead_vehicle'] !== null)
		{
			const ___vehicle_single___found: type___api___response = await this._API.vehicles___read___single(this.props.lead_props['lead_vehicle']);	
			this.setState({
				lead_props_vehicle: ___vehicle_single___found.data,
				GUI___picture___is_loading: false
			});
		}
		else
		{
			this.setState({
				GUI___picture___is_loading: false
			});
		}
	}

//#endregion

//#region 																							H A N D L E R S

	handle___select___lead_state = async (___new_state: type___lead___status) => {
		let ___new_status___text: string = '';
		switch (___new_state)
		{
			case 'new': ___new_status___text = 'Nuovo'; break;
			case 'working': ___new_status___text = 'In lavorazione'; break;
			case 'ready': ___new_status___text = 'In Consegna'; break;
			case 'archived': ___new_status___text = 'Archiviato'; break;
		}
		const ___alertprops : type___alert___controller = {
			alert___title: "Attenzione",
			alert___message : <>Stai modificando il lead con "<b>{ ___new_status___text }</b>", sei sicuro?</>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this.props.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si',
					alert___button___action: async (___partner_id : string) => {
						const ___new_lead_status_updated: type___api___response = await this._API.leads___update___status(this.props.lead_props['lead_id'], ___new_state, null);
						if (___new_lead_status_updated['response'] === 'success') {
							if (this.props.event___onChangeStatus) { await this.props.event___onChangeStatus(___new_state); }
							this.props.alert___setShowing(false);
							const ___toastprops: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Hai modificato lo stato di questo lead in "<b>{ ___new_status___text }</b>"</>
							}
							this.props.toast___setContent(___toastprops);
							this.props.toast___setShowing(true);
						}
					}
				}
			]
		}
		this.props.alert___setAlert(___alertprops);
		this.props.alert___setShowing(true);
	}

	handle___select___lead_is_viewed = async (___new_status: boolean = true) => {
		const ___lead___is_viewed: type___api___response = await this._API.leads___update___status_viewed(this.props.lead_props['lead_id'], ___new_status);
		if (___lead___is_viewed['response'] === 'success') {
			this.setState({
				lead_props_viewed: ___new_status
			}, () => {
				const ___toastprops: type___toast___controller = {
					toast___color: 'success',
					toast___text: <>Hai segnato questo Lead come "<b>{ (___new_status === true) ? 'Visualizzato' : 'Da vedere' }</b>"</>
				}
				this.props.toast___setContent(___toastprops);
				this.props.toast___setShowing(true);
			});
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void
	{
		this.setState({
			lead_props_viewed: this.props.lead_props['lead_status_viewed']
		}, async () => {
			await this.readprops___lead_vehicle();
		});
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-lead---container is-card">
				<section>
					{(this.props.visible_user === true) ? <>
						{(this.props.lead_props['lead_user']) ? <>
							<p className="com-lead---upperstatus">
								<span>assegnato a </span>{ this.props.lead_props['lead_user']['user_name'] + ' ' + this.props.lead_props['lead_user']['user_surname'] }
							</p>
						</> : <>
							<p className="com-lead---upperstatus">
								<span>non ancora assegnato</span>
							</p>
						</> }
					</> : <></>}
					{(this.props.visible_onChangeStatus === true && this.props.lead_props['lead_status'] !== 'sold' && this.props.lead_props['lead_status'] !== 'archived') ? <>
						<div className="com-lead---options---dropdown">
							<div>
								<span>Opzioni Lead</span>
								<i className="fas fa-angle-down"></i>
							</div>
							<div className="is-card">
								{(() => {
									switch (this.props.lead_props['lead_status'] as type___lead___status) {
										case 'new': return <>
											{(this.state.lead_props_vehicle !== null && this.state.lead_props_vehicle['vehicle_status_sold'] === false) ? <>
												<span onClick={async () => { await this.handle___select___lead_state('working'); }}>assegna - <span>In lavorazione</span></span>
												<span onClick={async () => { await this.handle___select___lead_state('ready'); }} >pronto - <span>In Consegna</span></span>
											</> : <></>}
											<span onClick={async () => { await this.handle___select___lead_state('archived'); }} style={{color:'var(--color-danger'}}><span>Archivia</span></span>
										</>; break;
										case 'working': return <>
											{(this.state.lead_props_vehicle !== null && this.state.lead_props_vehicle['vehicle_status_sold'] === false) ? <>
												<span onClick={async () => { await this.handle___select___lead_state('ready'); }} >pronto - <span>In Consegna</span></span>
											</> : <></>}
											<span onClick={async () => { await this.handle___select___lead_state('archived'); }} style={{color:'var(--color-danger'}}><span>Archivia</span></span>
										</>; break;
										case 'ready': return <>
											{(this.state.lead_props_vehicle !== null && this.state.lead_props_vehicle['vehicle_status_sold'] === false) ? <>
												<span onClick={async () => { await this.handle___select___lead_state('working'); }}>assegna - <span>In lavorazione</span></span>
											</> : <></>}
											<span onClick={async () => { await this.handle___select___lead_state('archived'); }} style={{color:'var(--color-danger'}}><span>Archivia</span></span>
										</>; break;
										case 'archived': return <>
											<span onClick={async () => { await this.handle___select___lead_state('working'); }}>assegna - <span>In lavorazione</span></span>
										</>; break;
									}
								})()}
							</div>
						</div>
					</> : <>
						{(() => {
							switch (this.props.lead_props['lead_status'] as type___lead___status) {
								case 'new': return <><p className="com-lead---upperstatus lead---is-new">Nuovo</p></>; break;
								case 'sold': return <><p className="com-lead---upperstatus lead---is-sold">Vendita Conlusa</p></>; break;
								case 'working': return <><p className="com-lead---upperstatus">In Lavorazione</p></>; break;
								case 'archived': return <><p className="com-lead---upperstatus lead---is-archived">Archiviato</p></>; break;
								case 'ready': return <><p className="com-lead---upperstatus">Pronto</p></>; break;
							}
						})()}
					</>}
					<section className="com-lead---image">
						{(this.state.GUI___picture___is_loading === true) ? <>
							<ComSpinnerComponent has_margin={ false }/>
						</> : <>
							{(this.props.lead_props['lead_vehicle'] !== null && this.state.lead_props_vehicle !== null) ? <>
								{(this.state.lead_props_vehicle['vehicle_gallery_pictures'].length > 0) ? <>
									<img src={ lib_names.cdn.endpoint + this.state.lead_props_vehicle['vehicle_gallery_pictures'][0] } className={(this.props.lead_props['lead_status'] !== 'sold' && this.state.lead_props_vehicle['vehicle_status_sold'] === true) ? ' img-car---is-not-available ' : ' '}/>
								</> : <>
									<img src={ lib_names.media.placeholders.vehicle.generic }/>
								</>}
								{(this.props.lead_props['lead_status'] !== 'sold' && this.state.lead_props_vehicle['vehicle_status_sold'] === true) ? <>
									<span>Veicolo non disponibile</span>
								</> : <></>}
							</> : <>
								{(this.props.lead_props['lead_vehicles_brands'].length > 0) ? <>
									<section>
										{this.props.lead_props['lead_vehicles_brands'].map((___v_b: any, ___v_b_i: number) => <>
											<img key={ ___v_b_i } src={ '/assets/brands/' + ___v_b['vehicle_brand_logo'] }/>
										</> )}
									</section>
								</> : <>
									<img src={ lib_names.media.placeholders.vehicle.generic }/>
								</>}
							</>}
						</>}
					</section>
					<h3>
						{(this.props.lead_props['lead_partner']['partner_type'] === 'p') ? <>
							<span>utente privato</span>
						</> : <>
							<span>utente azienda</span>
						</> }
					</h3>
					<h2>
						{(this.props.lead_props['lead_partner']['partner_type'] === 'p') ? <>
							<span>{ this.props.lead_props['lead_partner']['partner_name'] + ' ' + this.props.lead_props['lead_partner']['partner_surname'] }</span>
						</> : <>
							<span>{ this.props.lead_props['lead_partner']['partner_name_business'] }</span>
						</>}
					</h2>
					<h4>
						{(this.props.lead_props['lead_vehicle'] !== null && this.state.lead_props_vehicle !== null) ? <>
							<Link to={ '/vehicles/view/' + this.props.lead_props['lead_vehicle'] } data-title="Visualizza veicolo">
								<span>
									{(this.props.lead_props['lead_vehicle'] !== null && this.state.lead_props_vehicle) ? <>
										{ this.state.lead_props_vehicle['vehicle_name'] }
									</> : <>
										{ this.props.lead_props['lead_vehicle_name'] }
									</>}
								</span>
								<i className="fas fa-external-link-alt"></i>
							</Link>
						</> : <>
							{(this.props.lead_props['lead_vehicle'] !== null && this.state.lead_props_vehicle === null) ? <>
								<span className="lead-vehicle-chosen-component---com-warning">Veicolo non disponibile</span>
							</> : <>
								{(() => {
									switch (this.props.lead_props['lead_partner_interest'] as type___lead___interest___to) {
										case 'brands': return <>
											{(this.props.lead_props['lead_vehicles_brands'].length > 0) ? <>
												{ this.props.lead_props['lead_vehicles_brands'].map((___b: any, ___b_i: number) => { return <>
													<span key={ ___b_i }>{ ___b['vehicle_brand_name'] }</span>
												</>; })}
											</> : <></> }
										</>; break;
								//		case 'models': return <>
								//			{(this.props.lead_props['lead_vehicles_models'].length > 0) ? <>
								//				{ this.props.lead_props['lead_vehicles_models'].map((___m: any, ___m_i: number) => { return <>
								//					<span key={ ___m_i }>{ ___m['car_brand'] + ' ' + ___m['car_model'] }</span>
								//				</>; })}
								//			</> : <></> }
								//		</>; break;
										case 'stock_vehicle': return <>
											{(this.props.lead_props['lead_vehicle'] !== null && this.state.lead_props_vehicle !== null) ? <>
												<Link target="_blank" to={ '/vehicles/view/' + this.props.lead_props['lead_vehicle'] } data-title="Visualizza veicolo">
													{(this.props.lead_props['lead_vehicle'] !== null && this.state.lead_props_vehicle) ? <>
														{ this.state.lead_props_vehicle['vehicle_name'] }
													</> : <>
														{ this.props.lead_props['lead_vehicle_name'] }
													</>}
													<i className="fas fa-external-link-alt"></i>
												</Link>
											</> : <></>}
										</>; break;
										case 'custom': return <>
											{(this.props.lead_props['lead_vehicle_requested'] && this.props.lead_props['lead_vehicle_requested'].length > 0) ? <>
												<span>{ this.props.lead_props['lead_vehicle_requested'] }</span>
											</> : <>
												<span>{ this.props.lead_props['lead_notes'].toString().split('http')[0] }</span>
											</>}
										</>; break;
									}
								})()}
							</>}
						</>}
					</h4>
				</section>
				<section>
					<h5>
						<span>
							<span>creato il </span>
							<span>{ this.funcs___datetime.datetime___get___date(this.props.lead_props['lead_created_datetime']) }</span>
							<span> alle </span>
							<span>{ this.funcs___datetime.datetime___get___time(this.props.lead_props['lead_created_datetime']) }</span>
						</span>
						<br />
						<span>
							<span>provenienza</span>
							<span>{ this.lib___properties.get___property('lead_marketing_source', this.props.lead_props['lead_marketing_source']) }</span>
						</span>
					</h5>
					<section className="com-lead---action-btn">
						{(this.state.lead_props_viewed === false) ? <>
							<button type="button" onClick={() => { this.handle___select___lead_is_viewed(true); }} className="com-lead---action-btn---unviewed" data-title="Non mettere in evidenza">
								<i className="fas fa-bookmark"></i>
							</button>
						</> : <>
							<button type="button" onClick={() => { this.handle___select___lead_is_viewed(false); }} data-title="Metti in evidenza">
								<i className="fas fa-circle-notch"></i>
							</button>
						</>}
						<Link to={ '/leads/view/' + this.props.lead_props['lead_id'] }>
							<span>Visualizza Lead</span>
							<i className="fas fa-angle-right"></i>
						</Link>
					</section>
				</section>
			</div>
		</>;
	}

//#endregion

}