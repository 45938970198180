import { NavigateFunction, Params, useNavigate, useParams } from "react-router-dom";

//	S T A T E

import { props___WpNavigationWrapper } from "./wp-navigation-wrapper.state";

//	W R A P P E R

const WpNavigationWrapper : React.FC<props___WpNavigationWrapper<any>> = ({
	component: WrappedComponent, 
	alert___setAlert, 
	alert___setShowing, 
	toast___setContent, 
	toast___setShowing, 
	props___licence: licence
}) => {
	const params: Readonly<Params<string>> = useParams();
	const navigate: NavigateFunction = useNavigate();
	return <WrappedComponent 
		params={ params } 
		navigate={ navigate } 
		alert___setAlert={ alert___setAlert } 
		alert___setShowing={ alert___setShowing } 
		toast___setContent={ toast___setContent } 
		toast___setShowing={ toast___setShowing } 
		props___licence={ licence }
	/>;
};

//	W R A P P E R   E X P O R T

export default WpNavigationWrapper