import { props___page___hasAlert } from "../../../interfaces/interface.alerts";
import { props___page___hasToast } from "../../../interfaces/interface.toasts";
import { type___lead___status, type___request___order } from "../../../types/types.types";

//	P R O P S

export interface props___SbLeadsListPage extends props___page___hasAlert, props___page___hasToast {

}

//	S T A T E

export interface state___SbLeadsListPage {

	search_param___filter_by: 'all' | type___lead___status,
	
	leads___list: any[],
	leads___list___order: type___request___order,
	leads___list___is_loading: boolean,

	GUI___page___is_loading: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbLeadsListPage___default: state___SbLeadsListPage = {

	search_param___filter_by: 'all',
	
	leads___list: [],
	leads___list___order: { param: 'createdAt', direction: -1 },
	leads___list___is_loading: true,

	GUI___page___is_loading: false,

}