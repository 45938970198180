import { props___page___hasAlert } from "../../../interfaces/interface.alerts";
import { props___page___hasToast } from "../../../interfaces/interface.toasts";
import { type___partner___einvoice___type, type___partner___type } from "../../../types/types.types";

//	P R O P S

export interface props___SbPartnersEditPage extends props___page___hasAlert, props___page___hasToast {
	params: any,
	navigate : any,
}

//	S T A T E

export interface state___SbPartnersEditPage {	

	suggested_partners___list: any[],

	field___partner___id : string | null,

	field___partner___type: type___partner___type | '',

	field___partner___is___customer: boolean,
	field___partner___is___supplier: boolean,

	field___partner___name: string,
	field___partner___name___business: string,
	field___partner___surname: string,

	field___partner___email: string,
	field___partner___email_pec: string,
	field___partner___phone: string,
	field___partner___phone_mobile: string,
	field___partner___phone_fax: string,

	field___partner___born_date: string | null,
	field___partner___born_city: string,
	field___partner___born_country: string,

	field___partner___registry___cf: string,
	field___partner___registry___vat: string,

	field___partner___e_invoice___type: type___partner___einvoice___type,
	field___partner___e_invoice___sdi: string,

	field___partner___address___street: string,
	field___partner___address___street___n: string,
	field___partner___address___city: string,
	field___partner___address___prov: string,
	field___partner___address___zip: string,
	field___partner___address___country: string,

	field___partner___address_business___street: string,
	field___partner___address_business___street___n: string,
	field___partner___address_business___city: string,
	field___partner___address_business___prov: string,
	field___partner___address_business___zip: string,
	field___partner___address_business___country: string,

	field___partner___additional___notes: string,
	field___partner___additional___website: string,

	GUI___page___is_loading: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbPartnersEditPage___defaults: state___SbPartnersEditPage = {

	suggested_partners___list: [],

	field___partner___id : null,

	field___partner___type: '',

	field___partner___is___customer: false,
	field___partner___is___supplier: false,

	field___partner___name: '',
	field___partner___name___business: '',
	field___partner___surname: '',

	field___partner___email: '',
	field___partner___email_pec: '',
	field___partner___phone: '',
	field___partner___phone_mobile: '',
	field___partner___phone_fax: '',

	field___partner___born_date: null,
	field___partner___born_city: '',
	field___partner___born_country: '',

	field___partner___registry___cf: '',
	field___partner___registry___vat: '',

	field___partner___e_invoice___type: '',
	field___partner___e_invoice___sdi: '',

	field___partner___address___street: '',
	field___partner___address___street___n: '',
	field___partner___address___city: '',
	field___partner___address___prov: '',
	field___partner___address___zip: '',
	field___partner___address___country: '',

	field___partner___address_business___street: '',
	field___partner___address_business___street___n: '',
	field___partner___address_business___city: '',
	field___partner___address_business___prov: '',
	field___partner___address_business___zip: '',
	field___partner___address_business___country: '',

	field___partner___additional___notes: '',
	field___partner___additional___website: '',

	GUI___page___is_loading: true,

}