import { funcs_numbers } from "./funcs.numbers";

//	E X P O R T   L I B

export class funcs_text
{

//#region 																							D E C L A R A T I O N S

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

	public readonly text___empty_field: string = '×××××';

//#endregion

//#region 																							T E X T   R E T U R N   E M P T Y

	public text___return_empty(___input: string | number | null | undefined, ___string_to_append: string | null, ___format_as: 'price' | 'mileage' | null = null) : string
	{

		let ___string___to_return: string = this.text___empty_field;

		if (___input !== null && ___input !== undefined && ___input.toString().length > 0)
		{
			let ___input___to_string___formatted: string = ___input.toString().trim();
			if (___input___to_string___formatted.length > 0)
			{
				switch (___format_as) {
					case 'mileage': ___input___to_string___formatted = this.funcs___numbers.format___mileage(___input) ; break;
					case 'price': ___input___to_string___formatted = this.funcs___numbers.format___price(___input, null) ; break;
				}
				const ___string_to_append___sanitized: string = (___string_to_append !== null) ? ___string_to_append : '';
				___string___to_return = ___input___to_string___formatted + '' + ___string_to_append___sanitized;
			}
		}

		return ___string___to_return as string;

	}

//#endregion

//#region 																							C H A R S   L I M I T

	public chars___limit(___string: string, ___limit: number) : string
	{
		const ___comparable___string: string = ___string ?? '';
		if (___comparable___string.length <= ___limit) { return ___comparable___string as string; }
		else { return ___comparable___string.substring(0, ___limit) + '...' as string; }
	}

//#endregion
















}