//	E X P O R T   L I B

export class lib_properties {

//#region 																							D E C L A R A T I O N S

	private readonly lib_properties_list: Record<string, Record<string, Record<string, any>>> = {

		lead_marketing_source: {
			partner_already: { name_it_it: 'Già Cliente' },
			partner_wordofmouth: { name_it_it: 'Passa Parola' },
			external_marketplace_autoscout24: { name_it_it: 'AutoScout24' },
			external_marketplace_subitoit: { name_it_it: 'Subito.it' },
			external_paper: { name_it_it: 'Volantini o simili' },
			external_social_facebook: { name_it_it: 'Facebook' },
			external_social_instagram: { name_it_it: 'Instagram' },
			external_website: { name_it_it: 'Sito Web' },
			other: { name_it_it: 'Altro' },
		},

		lead_status: {
			new: { name_it_it: 'Nuovo' },
			sold: { name_it_it: 'Vendita' },
			working: { name_it_it: 'Lavorazione' },
			archived: { name_it_it: 'Archiviato' },
			ready: { name_it_it: 'Consegna' },
		},

		vehicle_vat_type: {
			included: { name_it_it: 'Inclusa' },
			exposed: { name_it_it: 'Esposta' },
			margin: { name_it_it: 'A Margine' },
			partial: { name_it_it: 'Parziale' },
		},








	



	};

//#endregion

//#region 																							G E T

	public get___property = (___key: string, ___value: string): string => {

		let ___to_return: string = '';
		if (this.lib_properties_list) {
			if (this.lib_properties_list[___key]) {
				if (this.lib_properties_list[___key][___value]) {
					___to_return = (this.lib_properties_list[___key][___value]) ? this.lib_properties_list[___key][___value]['name_it_it'] : '???';
				}
			}
		}
		return ___to_return;
	}

//#endregion

}