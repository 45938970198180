//	E X P O R T   L I B

const lib_errors = {
	
	
	
	duplicated: {
		duplicated_id: 'duplicated_id',
		duplicated_username: 'duplicated_username',
		duplicated_email: 'duplicated_email',
		duplicated_phone: 'duplicated_phone',

	},





	invalid: {
		invalid_email: 'invalid_email',
		invalid_password: 'invalid_password',
		invalid_password_weak: 'invalid_password_weak',
		invalid_username: 'invalid_username',
		invalid_user: 'invalid_user',
		invalid_username_exists: 'invalid_username_exists'
	},



	missing: {
		missing_coords: 'missing_coords',
		missing_description: 'missing_description',
		missing_id: 'missing_id',
		missing_image: 'missing_image',

		
	}








}

export default lib_errors;