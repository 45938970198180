import React from "react";

//	S T Y L E

import "./com-button-copier.scss";

//	L I B S

import { funcs_clipboard } from "../../funcs/funcs.clipboard";

//	S T A T E

import { props___ComLeadComponent } from "./com-button-copier.state";
import { type___toast___controller } from "../../types/types.toasts";

//	C L A S S

export default class ComButtonCopierComponent extends React.Component<props___ComLeadComponent, {}>
{

//#region 																							R E N D E R

	private readonly funcs___clipboard: funcs_clipboard = new funcs_clipboard();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComLeadComponent
	) {
		super(props);
	}

//#endregion

//#region 																							H A N D L E R S

	handle___clipboard___copy = () => {
		const ___to_copy: string = this.props.to_copy.toString() as string;
		this.funcs___clipboard.clipboard___copy_text(___to_copy);
		const ___to_copy___toastprops: type___toast___controller = {
			toast___color: 'success',
			toast___text: <>Hai copiato "<b>{ ___to_copy }</b>", incollalo dove vuoi</>
		}
		this.props.toast___setContent(___to_copy___toastprops);
		this.props.toast___setShowing(true);
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void
	{

	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="com-button-copier---container">
				<i className="far fa-copy" onClick={ this.handle___clipboard___copy } data-title="Copia"></i>
			</div>
		</>;
	}

//#endregion

}