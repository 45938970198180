import React from 'react';

//	S T Y L E

import './sb-settings-dealer.scss';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';
import { type___toast___controller } from '../../../types/types.toasts';

//	L I B S

import { funcs_clipboard } from '../../../funcs/funcs.clipboard';
import { lib_names } from '../../../libs/lib.names';

//	S T A T E

import { props___SbSettingsDealerPage, state___SbSettingsDealerPage, state___SbSettingsDealerPage___defaults } from './sb-settings-dealer.state';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';

//	C L A S S

export default class SbSettingsDealerPage extends React.Component<props___SbSettingsDealerPage, state___SbSettingsDealerPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___clipboard: funcs_clipboard = new funcs_clipboard();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbSettingsDealerPage
	) {
		super(_PROPS);
		this.state = state___SbSettingsDealerPage___defaults;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___dealer = async () => {
		const ___dealer___props: type___api___response = await this._API.dealers___read___single();
		const ___dealer___props___connector: type___api___response =await this._API.dealers___read___connector_credentials();
		this.setState({
			dealer___props: ___dealer___props.data,
			dealer___props___connector: ___dealer___props___connector.data,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___select___tab = (___e: state___SbSettingsDealerPage['selected___tab']) => {
		this.setState({ 
			selected___tab: ___e
		});
	}

	handle___input___copy = async (___text: string) => {

		const ___is_copied: boolean = await this.funcs___clipboard.clipboard___copy_text(___text);

		if (___is_copied === true)
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'success',
				toast___text: <>Hai copiato "<b>{ ___text }</b>"</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}
		else
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'danger',
				toast___text: <>Errore generico nel copiare</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}

	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		this.setState({
			GUI___page___is_loading: true,
		}, async () => {
			await this.read_props___dealer();
		});
	}

//#endregion 

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return <>
			{(this.state.dealer___props !== null) ? <>

				<div className="page---container is-full-width is-single-section">
					<section>
					
						<div className="page---top-bar">
							<section>
								<input type="radio" id="d-s-p---cb---overview" name="d-s-p---cb" value="overview" checked={this.state.selected___tab === 'overview'} onChange={(___e: any) => { this.handle___select___tab(___e['target']['value'] as state___SbSettingsDealerPage['selected___tab']); }} />
								<label htmlFor="d-s-p---cb---overview" className="is-card">
									<img src="/assets/ui/icons/icon-overview.svg" />
									<span>Panoramica</span>
								</label>
							</section>
							<section></section>
						</div>

						<div>
							{(() => {
								switch (this.state.selected___tab as state___SbSettingsDealerPage['selected___tab']) {
									case 'overview': return <>
										<div className="is-card dealer---overview---container">
											<div>
												<section>
													<span className="input---nb-label">Logo nei Contratto</span>
													<img src={ lib_names.cdn.endpoint + this.state.dealer___props['dealer_logo'] }/>
												</section>
												<section>
													<span className="input---nb-label">Logo Orizzontale</span>
													<img src={ lib_names.cdn.endpoint + this.state.dealer___props['dealer_logo_horizontal'] }/>
												</section>
											</div>
											<div>
												<h2 className="input---nb-title">Dati</h2>
												<span className="input---nb-label">Nome visualizzato</span>
												<section className="input---nb-outlined---flexy">
													<input type="text" value={ this.state.dealer___props['dealer_name'] } disabled={true}/>
												</section>
												<span className="input---nb-label">Ragione sociale</span>
												<section className="input---nb-outlined---flexy">
													<input type="text" value={ this.state.dealer___props['dealer_registry_name'] } disabled={true}/>
												</section>
												<span className="input---nb-label">Partita iva</span>
												<section className="input---nb-outlined---flexy">
													<input type="text" value={ this.state.dealer___props['dealer_registry_piva'] } disabled={true}/>
												</section>
												<span className="input---nb-label">Codice Fiscale</span>
												<section className="input---nb-outlined---flexy">
													<input type="text" value={ this.state.dealer___props['dealer_registry_cf'] } disabled={true}/>
												</section>
												<br />
												<h2 className="input---nb-title">Sede</h2>
												<span className="input---nb-label">Sede Legale / Inditizzo</span>
												<section className="input---nb-outlined---flexy">
													<input type="text" value={ this.state.dealer___props['dealer_address']['street'] }/>
													<input type="text" value={ this.state.dealer___props['dealer_address']['street_n']}/>
												</section>
												<section className="input---nb-outlined---flexy" style={{paddingTop:'10pt'}}>
													<input type="text" value={ this.state.dealer___props['dealer_address']['city'] + ' (' + this.state.dealer___props['dealer_address']['prov'] + ')' }/>
													<input type="text" value={ this.state.dealer___props['dealer_address']['zip']}/>
													<input type="text" value={ this.state.dealer___props['dealer_address']['country']}/>
												</section>
												<br />
												<h2 className="input---nb-title">Contatti - Telefono</h2>
												<span className="input---nb-label">Telefono</span>
												<section className="input---nb-outlined---flexy">
													<input type="text" value={ this.state.dealer___props['dealer_phone'] } disabled={true}/>
												</section>
												<span className="input---nb-label">Fax</span>
												<section className="input---nb-outlined---flexy ">
													<input type="text" value={ this.state.dealer___props['dealer_phone_fax'] } disabled={true}/>
												</section>
												<br />
												<h2 className="input---nb-title">Contatti - Email</h2>
												<span className="input---nb-label">Email</span>
												<section className="input---nb-outlined---flexy">
													<input type="text" value={ this.state.dealer___props['dealer_email'] } disabled={true}/>
												</section>
												<span className="input---nb-label">Pec</span>
												<section className="input---nb-outlined---flexy">
													<input type="text" value={ this.state.dealer___props['dealer_email_pec'] } disabled={true}/>
												</section>
												<br />
												<h2 className="input---nb-title">Altro</h2>
												<span className="input---nb-label">sito web</span>
												<section className="input---nb-outlined---flexy">
													<input type="text" value={ this.state.dealer___props['dealer_website'] } disabled={true}/>
												</section>
												<br />
											</div>
										</div>
									</>; break;
								}
							})()}
						</div>

					</section>
				</div>
			</> : <><ComSpinnerComponent/></>}
		</>;
	}

//#endregion

}