import React from 'react';

//	S T Y L E

import './sb-info-support.scss'

//	C O M P O N E N T S

import ComMarkdownComponent from '../../../components/com-markdown/com-markdown';
import { Link } from 'react-router-dom';

//	E X P O R T

export default class SbInfoSupportPage extends React.Component<{}, {}>
{

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return <>
			<div className="page---container  is-full-width">
				<section className="support-all---container">

					<section>

						<h2>Contatta l'assistenza con questi metodi</h2>

						<Link className="is-card" target="_blank" to="https://wa.me/message/SUCVFMDMSC2QL1?src=qr">
							<i className="fab fa-whatsapp" style={{color:'#25D366'}}></i>
							<h4>Chat Whatsapp PRYSM</h4>
							<h6>Chat Whatsapp con cui parlare direttamente con un incaricato di PRYSM</h6>
						</Link>

						<Link className="is-card" target="_blank" to="https://t.me/+_R79hQ4CDc41ODc8">
							<i className="fab fa-telegram" style={{color:'#24A1DE'}}></i>
							<h4>CarsHub Updates</h4>
							<h6>Canale telegram con gli updates del software e dei suoi componenti</h6>
						</Link>

						<Link className="is-card" target="_blank" to="mailto:info@carshub.io">
							<i className="fas fa-envelope" style={{color:'#FFA31A'}}></i>
							<h4>Supporto Mail CarsHub</h4>
							<h6>Indirizzo email di supporto con risposta entro le 24 ore</h6>
						</Link>

					</section>

				</section>
			</div>
		</>;
	}

//#endregion

}