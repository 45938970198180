//	E X P O R T   L I B

export class lib_vehicle_attributes {

//#region 																							D E C L A R A T I O N S

	readonly lib_vehicle_attributes___years_range: Record<string, number> = { min: 1900, max: 2024 };

	private readonly lib_vehicle_attributes___attributes: Record<string, Record<string, Record<string, any>>> = {

		body_paint_color: {
			body_paint_color_blue: { name_it_it: 'Blu' },
			body_paint_color_brown: { name_it_it: 'Marrone' },
			body_paint_color_yellow: { name_it_it: 'Giallo' },
			body_paint_color_grey: { name_it_it: 'Grigio' },
			body_paint_color_green: { name_it_it: 'Verde' },
			body_paint_color_red: { name_it_it: 'Rosso' },
			body_paint_color_black: { name_it_it: 'Nero' },
			body_paint_color_purple: { name_it_it: 'Viola' },
			body_paint_color_white: { name_it_it: 'Bianco' },
			body_paint_color_orange: { name_it_it: 'Arancio' },
		},

		body_paint_type: {
			body_paint_type_matte: { name_it_it: 'Opaco' },
			body_paint_type_lucid: { name_it_it: 'Lucido' },
			body_paint_type_metal: { name_it_it: 'Metallizzato' },
		},

		body_type: {
			body_type_cabrio: { name_it_it: 'Cabrio' },
			body_type_citycar: { name_it_it: 'CityCar' },
			body_type_coupe: { name_it_it: 'Coupé' },
			body_type_other: { name_it_it: 'Altro Tipo' },
			body_type_pickup: { name_it_it: 'PickUp' },
			body_type_sedan: { name_it_it: 'Berlina' },
			body_type_suv: { name_it_it: 'SUV o Fuoristrada' },
			body_type_van: { name_it_it: 'Van o Furgoni' },
			body_type_wagon: { name_it_it: 'Station Wagon' },
			body_type_motorcycle_scooter: { name_it_it: 'Scooter' },
			body_type_motorcycle_enduro: { name_it_it: 'Enduro' },
			body_type_motorcycle_cruiser: { name_it_it: 'Chopper/Cruiser' },
			body_type_motorcycle_quad: { name_it_it: 'Quad' },
		},

		body_type_car: {
			body_type_cabrio: { name_it_it: 'Cabrio' },
			body_type_citycar: { name_it_it: 'CityCar' },
			body_type_coupe: { name_it_it: 'Coupé' },
			body_type_other: { name_it_it: 'Altro Tipo' },
			body_type_pickup: { name_it_it: 'PickUp' },
			body_type_sedan: { name_it_it: 'Berlina' },
			body_type_suv: { name_it_it: 'SUV o Fuoristrada' },
			body_type_van: { name_it_it: 'Van o Furgoni' },
			body_type_wagon: { name_it_it: 'Station Wagon' },
		},

		body_type_bike: {
			body_type_motorcycle_scooter: { name_it_it: 'Scooter' },
			body_type_motorcycle_enduro: { name_it_it: 'Enduro' },
			body_type_motorcycle_cruiser: { name_it_it: 'Chopper/Cruiser' },
			body_type_motorcycle_quad: { name_it_it: 'Quad' },
		},

		brakes_type: {
			brakes_type_disc: { name_it_it: 'Freni a disco' },
			brakes_type_composite: { name_it_it: 'Freni a disco Carbo-Ceramici' },
			brakes_type_drum: { name_it_it: 'Freni a Tamburo' },
		},

		vehicle_category: {
			vehicle_category_new: { name_it_it: 'Nuovo' },
			vehicle_category_used: { name_it_it: 'Usato' },
			vehicle_category_company: { name_it_it: 'Aziendale' },
			vehicle_category_km0: { name_it_it: 'Km 0' },
			vehicle_category_broken: { name_it_it: 'Incidentata' },
		},

		engine_cylinder_displacement: {
			engine_cylinder_displacement_single: { name_it_it: 'Mono Cilindrico' },
			engine_cylinder_displacement_flat: { name_it_it: 'Contrapposto (Boxer)' },
			engine_cylinder_displacement_inline: { name_it_it: 'In Linea' },
			engine_cylinder_displacement_rotary: { name_it_it: 'Rotativo (Wankel)' },
			engine_cylinder_displacement_v_60: { name_it_it: 'V a 60' },
			engine_cylinder_displacement_v_90: { name_it_it: 'V a 90°' },
			engine_cylinder_displacement_w: { name_it_it: 'W' },
		},

		engine_fuel_type: {
			engine_fuel_type_diesel: { name_it_it: 'Diesel' },
			engine_fuel_type_electric: { name_it_it: 'Elettrica' },
			engine_fuel_type_lpg: { name_it_it: 'GPL o Metano' },
			engine_fuel_type_hybrid: { name_it_it: 'Ibrida' },
			engine_fuel_type_hydrogen: { name_it_it: 'Idrogeno' },
			engine_fuel_type_petrol: { name_it_it: 'Benzina' },
			engine_fuel_type_petrol_lpg: { name_it_it: 'Bengina/GPL' }
		},

		gearbox_type: {
			gearbox_type_cvt: { name_it_it: 'CVT' },
			gearbox_type_automatic: { name_it_it: 'Automatico' },
			gearbox_type_manual: { name_it_it: 'Manuale' },
		},

		emission_class: {
			emission_class_none: { name_it_it: 'Nessuna' },
			emission_class_euro_1: { name_it_it: 'Euro 1' },
			emission_class_euro_2: { name_it_it: 'Euro 2' },
			emission_class_euro_3: { name_it_it: 'Euro 3' },
			emission_class_euro_4: { name_it_it: 'Euro 4' },
			emission_class_euro_5: { name_it_it: 'Euro 5' },
			emission_class_euro_6: { name_it_it: 'Euro 6' },
		},

		interior_material: {
			interior_material_fabric: { name_it_it: 'Tessuto' },
			interior_material_leather: { name_it_it: 'Pelle' },
			interior_material_leather_fabric: { name_it_it: 'Pelle + Tessuto' },
			interior_material_alcantara: { name_it_it: 'Alcantara' },
		},

		interior_colors: {
			interior_colors_blue: { name_it_it: 'Blu' },
			interior_colors_brown: { name_it_it: 'Marrone' },
			interior_colors_yellow: { name_it_it: 'Beige' },
			interior_colors_grey: { name_it_it: 'Grigio' },
			interior_colors_red: { name_it_it: 'Rosso' },
			interior_colors_black: { name_it_it: 'Nero' },
			interior_colors_white: { name_it_it: 'Bianco' },
		},

		interior_details: {
			interior_details_carbon: { name_it_it: 'carbonio' },
			interior_details_wood: { name_it_it: 'Legno' },
			interior_details_metal: { name_it_it: 'Metallo' },
			interior_details_plastic: { name_it_it: 'Plastica' },
			interior_details_leather: { name_it_it: 'Pelle' },
			interior_details_alcantara: { name_it_it: 'Alcantara' },
			interior_details_fabric: { name_it_it: 'Tessuto' },
			interior_details_paint: { name_it_it: 'Vernice' },
		},

		optional: {
			optional_360_degree_camera: { id_autoscout24: null, name_it_it: "360° camera" },
			optional_e10_enabled: { id_autoscout24: null, name_it_it: "Abilitata per E10" },
			optional_abs: { id_autoscout24: null, name_it_it: "ABS" },
			optional_adaptive_cruise_control: { id_autoscout24: null, name_it_it: "Adaptive Cruise Control" },
			optional_handicap_accessible: { id_autoscout24: null, name_it_it: "Adatto a portatori di handicap" },
			optional_driver_airbag: { id_autoscout24: null, name_it_it: "Airbag conducente" },
			optional_side_airbags: { id_autoscout24: null, name_it_it: "Airbag laterali" },
			optional_passenger_airbag: { id_autoscout24: null, name_it_it: "Airbag passeggero" },
			optional_rear_airbag: { id_autoscout24: null, name_it_it: "Airbag posteriore" },
			optional_head_airbag: { id_autoscout24: null, name_it_it: "Airbag testa" },
			optional_electric_windows: { id_autoscout24: null, name_it_it: "Alzacristalli elettrici" },
			optional_android_auto: { id_autoscout24: null, name_it_it: "Android Auto" },
			optional_anti_theft_system: { id_autoscout24: null, name_it_it: "Antifurto" },
			optional_apple_carplay: { id_autoscout24: null, name_it_it: "Apple carPlay" },
			optional_high_beam_assistant: { id_autoscout24: null, name_it_it: "Assistente abbaglianti" },
			optional_car_radio: { id_autoscout24: null, name_it_it: "Autoradio" },
			optional_digital_car_radio: { id_autoscout24: null, name_it_it: "Autoradio digitale" },
			optional_blind_spot_monitor: { id_autoscout24: null, name_it_it: "Blind spot monitor" },
			optional_bluetooth: { id_autoscout24: null, name_it_it: "Bluetooth" },
			optional_armrest: { id_autoscout24: null, name_it_it: "Bracciolo" },
			optional_inductive_smartphone_charging: { id_autoscout24: null, name_it_it: "carica per smartphone a induzione" },
			optional_cd_player: { id_autoscout24: null, name_it_it: "CD" },
			optional_alloy_wheels: { id_autoscout24: null, name_it_it: "Cerchi in lega" },
			optional_steel_wheels: { id_autoscout24: null, name_it_it: "Cerchioni in acciaio" },
			optional_central_locking: { id_autoscout24: null, name_it_it: "Chiusura centralizzata" },
			optional_keyless_central_locking: { id_autoscout24: null, name_it_it: "Chiusura centralizzata senza chiave" },
			optional_remote_central_locking: { id_autoscout24: null, name_it_it: "Chiusura centralizzata telecomandata" },
			optional_air_conditioning: { id_autoscout24: null, name_it_it: "Climatizzatore" },
			optional_automatic_air_conditioning: { id_autoscout24: null, name_it_it: "Climatizzatore automatico" },
			optional_automatic_air_conditioning_2_zones: { id_autoscout24: null, name_it_it: "Climatizzatore automatico, 2 zone" },
			optional_automatic_air_conditioning_3_zones: { id_autoscout24: null, name_it_it: "Climatizzatore automatico, 3 zone" },
			optional_automatic_air_conditioning_4_zones: { id_autoscout24: null, name_it_it: "Climatizzatore automatico, 4 zone" },
			optional_on_board_computer: { id_autoscout24: null, name_it_it: "Computer di bordo" },
			optional_automatic_traction_control: { id_autoscout24: null, name_it_it: "Controllo automatico trazione" },
			optional_lane_control: { id_autoscout24: null, name_it_it: "Controllo elettronico della corsia" },
			optional_voice_control: { id_autoscout24: null, name_it_it: "Controllo vocale" },
			optional_biodiesel_conversion: { id_autoscout24: null, name_it_it: "Conversione biodiesel" },
			optional_cruise_control: { id_autoscout24: null, name_it_it: "Cruise Control" },
			optional_deflectors: { id_autoscout24: null, name_it_it: "Deflettori" },
			optional_luggage_compartment_dividers: { id_autoscout24: null, name_it_it: "Divisori per bagagliaio" },
			optional_esp: { id_autoscout24: null, name_it_it: "ESP" },
			optional_laser_headlights: { id_autoscout24: null, name_it_it: "Fari al laser" },
			optional_bi_xenon_headlights: { id_autoscout24: null, name_it_it: "Fari bi-Xeno" },
			optional_anti_glare_high_beam: { id_autoscout24: null, name_it_it: "Fari di profondità antiabbagliamento" },
			optional_directional_headlights: { id_autoscout24: null, name_it_it: "Fari direzionali" },
			optional_full_led_headlights: { id_autoscout24: null, name_it_it: "Fari full-LED" },
			optional_led_headlights: { id_autoscout24: null, name_it_it: "Fari LED" },
			optional_xenon_headlights: { id_autoscout24: null, name_it_it: "Fari Xenon" },
			optional_fog_lights: { id_autoscout24: null, name_it_it: "Fendinebbia" },
			optional_emergency_brake_assist: { id_autoscout24: null, name_it_it: "Frenata d'emergenza assistita" },
			optional_electric_parking_brake: { id_autoscout24: null, name_it_it: "Freno di stazionamento elettrico" },
			optional_tv_function: { id_autoscout24: null, name_it_it: "Funzione TV" },
			optional_tow_hook: { id_autoscout24: null, name_it_it: "Gancio traino" },
			optional_right_hand_drive: { id_autoscout24: null, name_it_it: "Guida a destra" },
			optional_head_up_display: { id_autoscout24: null, name_it_it: "Head-up display" },
			optional_hill_holder: { id_autoscout24: null, name_it_it: "Hill Holder" },
			optional_wi_fi_hotspot: { id_autoscout24: null, name_it_it: "Hotspot Wi-Fi" },
			optional_electronic_immobilizer: { id_autoscout24: null, name_it_it: "Immobilizzatore elettronico" },
			optional_leather_interior: { id_autoscout24: null, name_it_it: "Interni in pelle" },
			optional_isofix: { id_autoscout24: null, name_it_it: "Isofix" },
			optional_breakdown_kit: { id_autoscout24: null, name_it_it: "Kit antipanne" },
			optional_smokers_kit: { id_autoscout24: null, name_it_it: "Kit fumatori" },
			optional_steering_wheel_paddles: { id_autoscout24: null, name_it_it: "Leve al volante" },
			optional_speed_limiter: { id_autoscout24: null, name_it_it: "Limitatore di velocità" },
			optional_ambient_lighting: { id_autoscout24: null, name_it_it: "Luce d'ambiente" },
			optional_daytime_running_lights: { id_autoscout24: null, name_it_it: "Luci diurne" },
			optional_led_daytime_running_lights: { id_autoscout24: null, name_it_it: "Luci diurne LED" },
			optional_catalytic_converter: { id_autoscout24: null, name_it_it: "Marmitta catalitica" },
			optional_mp3: { id_autoscout24: null, name_it_it: "MP3" },
			optional_winter_package: { id_autoscout24: null, name_it_it: "Pacchetto invernale" },
			optional_sports_package: { id_autoscout24: null, name_it_it: "Pacchetto sportivo" },
			optional_heated_windshield: { id_autoscout24: null, name_it_it: "Parabrezza riscaldato" },
			optional_park_distance_control: { id_autoscout24: null, name_it_it: "Park distance control" },
			optional_snow_tires: { id_autoscout24: null, name_it_it: "Pneumatici da neve" },
			optional_summer_tires: { id_autoscout24: null, name_it_it: "Pneumatici estivi" },
			optional_all_season_tires: { id_autoscout24: null, name_it_it: "Pneumatici quattro stagioni" },
			optional_sliding_door: { id_autoscout24: null, name_it_it: "Porta scorrevole" },
			optional_right_sliding_door: { id_autoscout24: null, name_it_it: "Porta scorrevole a destra" },
			optional_left_sliding_door: { id_autoscout24: null, name_it_it: "Porta scorrevole a sinistra" },
			optional_roof_rack: { id_autoscout24: null, name_it_it: "Portapacchi" },
			optional_electric_tailgate: { id_autoscout24: null, name_it_it: "Portellone posteriore elettrico" },
			optional_range_extender: { id_autoscout24: null, name_it_it: "Range extender" },
			optional_rear_seat_electric_adjustment: { id_autoscout24: null, name_it_it: "Regolazione elettrica del sedile posteriore" },
			optional_electric_seat_adjustment: { id_autoscout24: null, name_it_it: "Regolazione elettrica sedili" },
			optional_traffic_sign_recognition: { id_autoscout24: null, name_it_it: "Riconoscimento dei segnali stradali" },
			optional_auxiliary_heating: { id_autoscout24: null, name_it_it: "Riscaldamento ausiliario" },
			optional_spare_wheel: { id_autoscout24: null, name_it_it: "Ruota di riserva" },
			optional_spare_tire: { id_autoscout24: null, name_it_it: "Ruotino" },
			optional_fully_digital_multifunction_display: { id_autoscout24: null, name_it_it: "Schermo multifunzione interamente digitale" },
			optional_foldable_passenger_seat: { id_autoscout24: null, name_it_it: "Sedile passeggero ribaltabile" },
			optional_split_rear_seat: { id_autoscout24: null, name_it_it: "Sedile posteriore sdoppiato" },
			optional_massage_seats: { id_autoscout24: null, name_it_it: "Sedili massaggianti" },
			optional_heated_seats: { id_autoscout24: null, name_it_it: "Sedili riscaldati" },
			optional_sports_seats: { id_autoscout24: null, name_it_it: "Sedili sportivi" },
			optional_ventilated_seats: { id_autoscout24: null, name_it_it: "Sedili ventilati" },
			optional_light_sensor: { id_autoscout24: null, name_it_it: "Sensore di luminosità" },
			optional_rain_sensor: { id_autoscout24: null, name_it_it: "Sensore di pioggia" },
			optional_front_parking_sensors: { id_autoscout24: null, name_it_it: "Sensori di parcheggio assistito anteriori" },
			optional_rear_parking_sensors: { id_autoscout24: null, name_it_it: "Sensori di parcheggio assistito posteriori" },
			optional_power_steering: { id_autoscout24: null, name_it_it: "Servosterzo" },
			optional_distance_warning_system: { id_autoscout24: null, name_it_it: "Sistema di avviso di distanza" },
			optional_emergency_call_system: { id_autoscout24: null, name_it_it: "Sistema di chiamata d'emergenza" },
			optional_tire_pressure_monitoring: { id_autoscout24: null, name_it_it: "Sistema di controllo pressione pneumatici" },
			optional_navigation_system: { id_autoscout24: null, name_it_it: "Sistema di navigazione" },
			optional_automatic_parking_system: { id_autoscout24: null, name_it_it: "Sistema di parcheggio automatico" },
			optional_fatigue_detection_system: { id_autoscout24: null, name_it_it: "Sistema di riconoscimento della stanchezza" },
			optional_night_vision_system: { id_autoscout24: null, name_it_it: "Sistema di visione notturna" },
			optional_headlight_cleaning_system: { id_autoscout24: null, name_it_it: "Sistema lavafari" },
			optional_ski_bag: { id_autoscout24: null, name_it_it: "Ski bag" },
			optional_air_suspension: { id_autoscout24: null, name_it_it: "Sospensioni pneumatiche" },
			optional_sport_suspension: { id_autoscout24: null, name_it_it: "Sospensioni sportive" },
			optional_sound_system: { id_autoscout24: null, name_it_it: "Sound system" },
			optional_electric_side_mirrors: { id_autoscout24: null, name_it_it: "Specchietti laterali elettrici" },
			optional_anti_glare_rearview_mirror: { id_autoscout24: null, name_it_it: "Specchietto retrovisore con funzione antiabbagliamento" },
			optional_spoiler: { id_autoscout24: null, name_it_it: "Spoiler" },
			optional_automatic_start_stop: { id_autoscout24: null, name_it_it: "Start/Stop Automatico" },
			optional_integrated_streaming_music: { id_autoscout24: null, name_it_it: "Streaming musicale integrato" },
			optional_lumbar_support: { id_autoscout24: null, name_it_it: "Supporto lombare" },
			optional_taxi_or_rental_car: { id_autoscout24: null, name_it_it: "Taxi o auto a noleggio" },
			optional_parking_assist_camera: { id_autoscout24: null, name_it_it: "Telecamera per parcheggio assistito" },
			optional_awning: { id_autoscout24: null, name_it_it: "Tendalino" },
			optional_panoramic_roof: { id_autoscout24: null, name_it_it: "Tetto panoramico" },
			optional_sunroof: { id_autoscout24: null, name_it_it: "Tettuccio apribile" },
			optional_touch_screen: { id_autoscout24: null, name_it_it: "Touch screen" },
			optional_four_wheel_drive: { id_autoscout24: null, name_it_it: "Trazione integrale" },
			optional_usb: { id_autoscout24: null, name_it_it: "USB" },
			optional_customized_car: { id_autoscout24: null, name_it_it: "Veicolo elaborato" },
			optional_tinted_windows: { id_autoscout24: null, name_it_it: "Vetri oscurati" },
			optional_hands_free_equipment: { id_autoscout24: null, name_it_it: "Vivavoce" },
			optional_leather_steering_wheel: { id_autoscout24: null, name_it_it: "Volante in pelle" },
			optional_multifunction_steering_wheel: { id_autoscout24: null, name_it_it: "Volante multifunzione" },
			optional_heated_steering_wheel: { id_autoscout24: null, name_it_it: "Volante riscaldato" }
		},

		transmission_drive: {
			transmission_drive_4wd: { name_it_it: 'Trazione 4x4' },
			transmission_drive_awd: { name_it_it: 'Trazione Integrale' },
			transmission_drive_fwd: { name_it_it: 'Trazione Anteriore' },
			transmission_drive_rwd: { name_it_it: 'Trazione Posteriore' },
		},

		wheel_material: {
			wheel_material_steel: { name_it_it: 'Ferro' },
			wheel_material_alloy: { name_it_it: 'Lega' },
			wheel_material_carbon: { name_it_it: 'Carbonio' },
			wheel_material_magnesum: { name_it_it: 'Magnesio' },
		},

	};

//#endregion

//#region 																							G E T

	public get___vehicle_attribute___name = (___input: string): { key: string, value: string }[] => {
		const ___attributes___return: { key: string, value: string }[] = [];
		const ___attributes___category: Record<string, Record<string, Record<string, any>>> = this.lib_vehicle_attributes___attributes[___input];
		if (___attributes___category) {
			for (const ___k in ___attributes___category) {
				if (___attributes___category.hasOwnProperty(___k)) {
					___attributes___return.push({ key: ___k, value: ___attributes___category[___k]['name_it_it'].toString() });
				}
			}
		}
		return ___attributes___return;
	}

	public get___vehicle_attribute___name___property = (___key: string, ___value: string): string => {

		let ___to_return: string = '';
		if (this.lib_vehicle_attributes___attributes) {
			if (this.lib_vehicle_attributes___attributes[___key]) {
				if (this.lib_vehicle_attributes___attributes[___key][___value]) {
					___to_return = (this.lib_vehicle_attributes___attributes[___key][___value]) ? this.lib_vehicle_attributes___attributes[___key][___value]['name_it_it'] : '???';
				}
			}
		}
		return ___to_return;
	}

//#endregion

}