import { props___page___hasAlert } from "../../interfaces/interface.alerts"
import { props___page___hasToast } from "../../interfaces/interface.toasts"
import { type___lead___status } from "../../types/types.types"

//	P R O P S

export interface props___SbAnalyticsPage extends props___page___hasAlert, props___page___hasToast {

}

//	S T A T E

export interface state___SbAnalyticsPage {

	selected___tab : 'overview' | 'leads' | 'users',
	
	analytics_chart___year: any | null,



	




	GUI___page___is_loading: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbAnalyticsPage___defaults : state___SbAnalyticsPage = {

	selected___tab : 'overview',

	analytics_chart___year: null,






	GUI___page___is_loading: true,

}