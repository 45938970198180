import React from 'react';
import { Navigate } from 'react-router-dom';

//	S T Y L E

import './sb-login.scss';

//	T Y P E S

import { type___toast___controller } from '../../types/types.toasts';

//	I N T E R F A C E S

import { props___SbLoginPage, state___SbLoginPage, state___SbLoginPage___default } from './sb-login.state';

//	L I B S

import lib_errors from '../../libs/lib.errors';
import { lib_names } from '../../libs/lib.names';

//	S E R V I C E S

import { service_RestAuthService } from '../../services/service-auth';
import { service_RestApiService } from '../../services/service-api';

//	M O D A L S

import MdEulaViewModal from '../../modals/md-eula-view/md-eula-view';
import { type___api___response } from '../../types/types.api-response';

//	C L A S S

export default class SbLoginPage extends React.Component<props___SbLoginPage, state___SbLoginPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _AUTH: service_RestAuthService = new service_RestAuthService();
	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbLoginPage,
	) {
		super(_PROPS);
		this.state = state___SbLoginPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___me_datas = async () => {
		const ___me_props___result: type___api___response = await this._API.users___read___single('me');
		if (___me_props___result.response === 'success')
		{
			this._PROPS.event_setMeProps(___me_props___result.data);
			this._PROPS.event_setMeIsLogged(true);
		}
		else
		{
			this._PROPS.event_setMeProps(null);
			this._PROPS.event_setMeIsLogged(false);
		}
	} 

//#endregion

//#region 																							A U T H

	perform___login = async () => {
		if (this.state.field___username.length > 0 || this.state.field___password.length > 0)
		{
			const ___auth_response: any | string | null = await this._AUTH.auth___login(this.state.field___username, this.state.field___password);
			if (___auth_response && ___auth_response['token'])
			{
				localStorage.setItem(lib_names.storage.user___logintoken, ___auth_response['token']);
				await this.read_props___me_datas().then(() => { window.location.href = window.location.href; });
			}
			else
			{
				let ___toast___auth_invalid___props___inner: any = '';
				switch (___auth_response)
				{
					case lib_errors.invalid.invalid_username: ___toast___auth_invalid___props___inner = 'Username non riconosciuto'; break;
					case lib_errors.invalid.invalid_password: ___toast___auth_invalid___props___inner = 'Password sbagliata'; break;
					default: this._PROPS.toast___setContent('Errore generico'); break;
				}
				const ___toast___auth_invalid___props: type___toast___controller = { toast___color: 'danger', toast___text: ___toast___auth_invalid___props___inner }
				this._PROPS.toast___setContent(___toast___auth_invalid___props);
		
				console.log('___toast___auth_invalid___props',___toast___auth_invalid___props)
		
				this._PROPS.toast___setShowing(true);
			}
			return <Navigate to="/lead"/>
		}
	}

//#endregion

//#region 																							H A N D L E R S
	
	handler___eula___show = (___wanna_showing: boolean = !(this.state.GUI___lead___eula___preview)) => {
		this.setState({
			GUI___lead___eula___preview: ___wanna_showing as boolean
		});
	}

	handler___eula___dismiss_accepting = () => {
		this.setState({
			field___eula_accepted: true,
			GUI___lead___eula___preview: false
		});
	}

//#endregion

//#region 																							R E T U R N

	render()
	{
		return <>
			{(this.state.GUI___lead___eula___preview === true) ? <>
				<MdEulaViewModal event___onDismiss={ this.handler___eula___dismiss_accepting }/>
			</> : <></>}
			<div className="carshub-sign---container">
				<div>
					<img src="/assets/logo.squared.svg"></img>
					<br/>
					<input placeholder="username" type="text" className="input---nb-outlined"  value={ this.state.field___username } onInput={(___e: any) => this.setState({field___username:___e.target.value})}/>
					<input placeholder="password" type="password" className="input---nb-outlined"  value={ this.state.field___password } onInput={(___e: any) => this.setState({field___password:___e.target.value})}/>
					<br/>
					<p>
						<label htmlFor="cb---login---eula-accepted">
							<input type="checkbox" id="cb---login---eula-accepted" checked={ this.state.field___eula_accepted } onChange={(___e: any) => { this.setState({ field___eula_accepted: ___e.target.checked })}}/>
							{(this.state.field___eula_accepted === true) ? <>
								<i className="far fa-check-square"></i>
							</> : <>
								<i className="far fa-square"></i>
							</>}
						</label>
						<span>
							<>Effettuando il login nella piattaforma accetto esplicitamente ed integralmente tutti i </> 
							<span onClick={() => { this.handler___eula___show(true); }} data-title="Leggi i Termini e le Condizioni">Termini e Condizioni visionabili in questa pagina</span> 
						</span>
					</p>
					<br/>
					<br/>
					<div className="page-container---bottom-bar---buttons">
						{(!(this.state.field___username.length > 0 && this.state.field___password.length)) ? <>
							<button type="button" className="is-standard" style={{cursor:'default'}}>
								<i className="fas fa-times"></i>
								<span>
									{(!(this.state.field___username.length > 0) && !(this.state.field___password.length > 0)) ? <>
										<>Mancano username e password</>
									</> : <>
										{(!(this.state.field___username.length > 0)) ? <>Manca l'username</> : <></>}
										{(!(this.state.field___password.length > 0)) ? <>Manca la password</> : <></>}
									</>}
								</span>
							</button>
						</> : <>
							{(this.state.field___eula_accepted === true) ? <>
								<button type="button" className="is-primary" onClick={ this.perform___login }>
									<i className="fas fa-chevron-right"></i>
									<span>Login</span>
								</button>
							</> : <>
								<button type="button" className="is-standard" style={{cursor:'default'}}>
									<i className="fas fa-times"></i>
									<span>Devi accettare i Termini</span>
								</button>
							</>}
						</> }
					</div>
				</div>
			</div>
		</>;
	}

//#endregion

}