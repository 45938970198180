//	P R O P S

export interface props___ComCalendarEventComponent {

	element___props: any,

}

//	S T A T E

export interface state___ComCalendarEventComponent {
	
	elment_props___activity: any | null,
	elment_props___lead: any | null,
	elment_props___vehicle: any | null,

	GUI___element___is_loading: boolean,

}

//	S T A T E   D E F A U L T S


export const state___ComCalendarEventComponent___default: state___ComCalendarEventComponent = {
	
	elment_props___activity: null,
	elment_props___lead: null,
	elment_props___vehicle: null,

	GUI___element___is_loading: true

}