import React from "react";

//	S T Y L E

import "./com-vehicle-small.scss";

//	F U N C S

import { funcs_numbers } from "../../funcs/funcs.numbers";

//	S T A T E

import { props___ComVehicleSmallComponent } from "./com-vehicle-small.state";
import { lib_names } from "../../libs/lib.names";

//	C L A S S

export default class ComVehicleSmallComponent extends React.Component<props___ComVehicleSmallComponent, {}>
{

//#region 																							D E C L A R A T I O N S

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComVehicleSmallComponent
	) {
		super(props);
	}

//#endregion

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return <>
			<div className="vehicle-small---container">
				{(this.props.vehicle___props['vehicle_gallery_pictures'].length > 0) ? <>
					<img src={ lib_names.cdn.endpoint + this.props.vehicle___props['vehicle_gallery_pictures'][0] }/>
				</> : <>
					<img src={ lib_names.media.placeholders.vehicle.generic }/>
				</>}
				<h3>{ this.props.vehicle___props['vehicle_name'] }</h3>
			{/*	<h4>{ this.props.vehicle___props['vehicle_version'] }</h4>	*/}
				<h5>{ this.funcs___numbers.format___price(this.props.vehicle___props['vehicle_accounting___price_sell'], ' €') }</h5>
				<h6>IVA { this.props.vehicle___props['vehicle_accounting___price_sell_vat'] }% {(this.props.vehicle___props['vehicle_accounting___price_sell_vat_exposed'] === 'exposed') ? <>Esposta</> : <>a Margine</>} </h6>
			</div>
		</>;
	}

//#endregion

}