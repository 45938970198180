import { props___page___hasAlert } from "../../../interfaces/interface.alerts";
import { props___page___hasToast } from "../../../interfaces/interface.toasts";
import { type___licence } from "../../../types/types.licence";
import { type___status___cloud } from "../../../types/types.types";

//	P R O P S

export interface props___SbInfoLicencePage extends props___page___hasAlert, props___page___hasToast {

}

//	S T A T E

export interface state___SbInfoLicencePage {

	selected___tab: 'active' | 'payments' | 'cloudDocs',

	licence___props: type___licence | null,

	cloud_stats___props: type___status___cloud,








	GUI___page___is_loading: boolean,

}

//	S T A T E   D E F A U L T

export const state___SbInfoLicencePage___default: state___SbInfoLicencePage = {

	selected___tab: 'active',

	licence___props: null,

	cloud_stats___props: {
		cloud_documents_space___total: 0,
		cloud_documents_space___occupied___amount: 0,
		cloud_documents_space___occupied___size: 0
	},









	GUI___page___is_loading: false,

}