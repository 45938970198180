import React from 'react';
import { Link } from 'react-router-dom';

//	S T Y L E

import './sb-dashboard.scss';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___lead___status } from '../../types/types.types';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	S T A T E

import { props___SbDashboardPage, state___SbDashboardPage, state___SbDashboardPage___defaults } from './sb-dashboard.state';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComResultsEmptyComponent from '../../components/com-results-empty/com-results-empty';
import ComLeadComponent from '../../components/com-lead/com-lead';
import ComSpinnerComponent from '../../components/com-spinner/com-spinner';
import ComResultsMoreComponent from '../../components/com-results-more/com-results-more';

//	C L A S S

export default class SbDashboardPage extends React.Component<props___SbDashboardPage, state___SbDashboardPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService()

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbDashboardPage
	) {
		super(_PROPS);
		this.state = state___SbDashboardPage___defaults;
	}

//#endregion

//#region																							R E A D   P R O P S

	read_props___dealer = async () => {
		const ___dealer___props: type___api___response = await this._API.dealers___read___single();
		this.setState({
			dealer___props: ___dealer___props.data,
			dealer___props___is_importing: ___dealer___props.data['dealer_state_importing_leads_emails']
		}, async () => {
			if (this.props.props___licence['licence_active___sync___leads'] === true)
			{
				if (___dealer___props.data['dealer_state_importing_leads_emails'] === true) {
					setTimeout(async () => {
						await this.read_props___dealer();
					}, lib_names.sync.interval);
				}
			}
			else
			{
				await this.read_props___dealer();
			}
		});
	}

	read_props___leads_count = async () => {
		const ___leads___count: type___api___response = await this._API.leads___count();
		this.setState({
			leads___count___new: ___leads___count.data['leads_count_new'],
			leads___count___working: ___leads___count.data['leads_count_working'],
			leads___count___ready: ___leads___count.data['leads_count_ready'],
			leads___count___sold: ___leads___count.data['leads_count_sold'],
			leads___count___archived: ___leads___count.data['leads_count_archived'],
		}, () => {
			if (this.state.GUI___page___is_loaded === false)
			{
				if (this.state.leads___count___new > 0)
				{
					this.setState({
						selected___tab: 'new',
						GUI___page___is_loaded: true
					}, async () => {
						await this.read_props___leads_list(true);
					});
				}
				else
				{
					this.setState({
						selected___tab: 'working',
						GUI___page___is_loaded: true
					}, async () => {
						await this.read_props___leads_list(true);
					});
				}
			}
		});
	}

	read_props___leads_list = async (___is_reset: boolean = true) => {
		this.setState({
			GUI___page___is_loading: (___is_reset === true) ? true : this.state.GUI___page___is_loading, 
			leads___list: (___is_reset === true) ? [] : this.state.leads___list,
			leads___list___is_loading: true
		}, async () => { 
			let ___leads___founds: type___api___response = { response: 'no-data', data: [] };
			switch (this.state.selected___tab) {
				case 'today':  ___leads___founds = await this._API.leads___read___multi('today', null, this.state.leads___list.length, { param: 'createdAt', direction: -1 }); break;
				default: ___leads___founds = await this._API.leads___read___multi('status', this.state.selected___tab!, this.state.leads___list.length, { param: 'createdAt', direction: -1 }); break;
			}
			switch (___is_reset) {
				case true: this.setState({ leads___list: ___leads___founds.data }); break;
				case false: this.setState({ leads___list: [...this.state.leads___list, ...___leads___founds.data]}); break;
			}
			this.setState({
				leads___list___is_loading: false,
				GUI___page___is_loading: false
			});
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___select___tab = (___e: type___lead___status) => {
		this.setState({ 
			GUI___page___is_loading: true,
			leads___list: [],
			selected___tab: ___e
		}, async () => { 
			await this.read_props___leads_count();
			await this.read_props___leads_list(); 
		});
	}

	handle___select___tab___show_hiddens = () => {
		this.setState({ 
			GUI___tabs___hidden_show: !(this.state.GUI___tabs___hidden_show),
		});
	}

	handle___lead___change_status = async (___e: type___lead___status) => {
		if (___e !== 'sold') { this.handle___select___tab(___e); }
		await this.read_props___leads_count();
		await this.read_props___leads_list();
	}

	handle___lead___sync = () => {
		if (this.props.props___licence['licence_active___sync___leads'] === true)
		{
			this.setState({
				dealer___props___is_importing: false
			}, async () => {
				await this._API.sync_leads___sync();
				await this.read_props___dealer();
				await this.read_props___leads_count();
			});
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void
	{
		this.setState({
			GUI___page___is_loading: true,
			leads___list: [],
		}, async () => {
			await this.read_props___dealer();
			await this.read_props___leads_count();
		});
	}

//#endregion 

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="page---container is-full-width">
				<section style={{overflow:'scroll'}}>
					<div className="page---top-bar is-backgrounded">
						<section>
							<input type="radio" id="d-ts---cb---today" name="d-ts---cb" value="today" checked={this.state.selected___tab === 'today'} onChange={(___e: any) => { this.handle___select___tab(___e['target']['value'] as type___lead___status); }} />
							<label htmlFor="d-ts---cb---today" className="is-card">
								<img src="/assets/ui/icons/icon-calendar.svg" />
								<span>Per Oggi</span>
								{/*(this.state.leads___count___new > 0) ? <span className="is-note">{ this.state.leads___count___new }</span> : <></>*/}
							</label>
							<Link to="/leads/list" className="is-card">
								<i className="fas fa-list"></i>
								<span>Lista</span>
							</Link>
						</section>
						<section>
							<input type="radio" id="d-ts---cb---new" name="d-ts---cb" value="new" checked={this.state.selected___tab === 'new'} onChange={(___e: any) => { this.handle___select___tab(___e['target']['value'] as type___lead___status); }} />
							<label htmlFor="d-ts---cb---new" className="is-card">
								<img src="/assets/ui/icons/icon-lead-new.svg" />
								<span>Nuovi Leads</span>
								{(this.state.leads___count___new > 0) ? <span className="is-note">{ this.state.leads___count___new }</span> : <></>}
							</label>
							<input type="radio" id="d-ts---cb---assigned" name="d-ts---cb" value="working" checked={this.state.selected___tab === 'working'} onChange={(___e: any) => { this.handle___select___tab(___e['target']['value'] as type___lead___status); }} />
							<label htmlFor="d-ts---cb---assigned" className="is-card">
								<img src="/assets/ui/icons/icon-lead-working.svg" />
								<span>In Lavorazione</span>
								{(this.state.leads___count___working > 0) ? <span className="is-note">{ this.state.leads___count___working }</span> : <></>}
							</label>
							<input type="radio" id="d-ts---cb---ready" name="d-ts---cb" value="ready" checked={this.state.selected___tab === 'ready'} onChange={(___e: any) => { this.handle___select___tab(___e['target']['value'] as type___lead___status); }} />
							<label htmlFor="d-ts---cb---ready" className="is-card">
								<img src="/assets/ui/icons/icon-lead-complete.svg" />
								<span>In Consegna</span>
								{(this.state.leads___count___ready > 0) ? <span className="is-note">{ this.state.leads___count___ready }</span> : <></>}
							</label>
							<button type="button" className="is-card" onClick={ this.handle___select___tab___show_hiddens } data-title={ (this.state.GUI___tabs___hidden_show === false) ? 'Visualizza Vendite e Archiviati' :  'Nascondi Vendite e Archiviati' }>
								{(this.state.GUI___tabs___hidden_show === true) ? <><i className="fas fa-eye"></i></> : <><i className="fas fa-eye-slash"></i></>}
							</button>
							{(this.state.GUI___tabs___hidden_show === true) ? <>
								<input type="radio" id="d-ts---cb---sold" name="d-ts---cb" value="sold" checked={this.state.selected___tab === 'sold'} onChange={(___e: any) => { this.handle___select___tab(___e['target']['value'] as type___lead___status); }} />
								<label htmlFor="d-ts---cb---sold" className="is-card">
									<img src="/assets/ui/icons/icon-lead-done.svg" />
									<span>Vendite</span>
									{(this.state.leads___count___sold > 0) ? <span className="is-note">{ this.state.leads___count___sold }</span> : <></>}
								</label>
								<input type="radio" id="d-ts---cb---archived" name="d-ts---cb" value="archived" checked={this.state.selected___tab === 'archived'} onChange={(___e: any) => { this.handle___select___tab(___e['target']['value'] as type___lead___status); }} />
								<label htmlFor="d-ts---cb---archived" className="is-card">
									<img src="/assets/ui/icons/icon-spider-web.svg" />
									<span>Archiviati</span>
									{(this.state.leads___count___archived > 0) ? <span className="is-note">{ this.state.leads___count___archived }</span> : <></>}
								</label>
							</> : <></>}
						</section>
						<section>
							<Link to="/leads/create" className="is-card">
								<i className="fas fa-plus"></i>
								<span>Nuovo Lead</span>
							</Link>
							{(this.props.props___licence['licence_active___sync___leads'] === true) ? <>
								{(this.state.dealer___props___is_importing !== false) ? <>
									<button onClick={ this.handle___lead___sync } className="is-card">
										<i className="fas fa-sync-alt"></i>
										<span>Sincronizza</span>
									</button>
								</> : <>
									<button className="is-card is-disabled">
										<i className="is-rotating fas fa-sync-alt"></i>
										<span>Sincronizzazione</span>
									</button>
								</>}
							</> : <></>}
						</section>
					</div>

					{(this.state.GUI___page___is_loading === true) ? <>
						<ComSpinnerComponent/>
					</> : <>
						{(this.state.leads___list.length > 0) ? <>
							<div className="leads-list---container">
								<div>
									{this.state.leads___list.map((___l: any, ___i: number) =>
										<ComLeadComponent key={ ___i } lead_props={ ___l } visible_user={ false } visible_onChangeStatus={!( this.state.selected___tab === 'today' )} alert___setAlert={ this._PROPS.alert___setAlert } alert___setShowing={ this._PROPS.alert___setShowing } toast___setContent={ this._PROPS.toast___setContent } toast___setShowing={ this._PROPS.toast___setShowing }/>
									)}
								</div>
								{(this.state.leads___list___is_loading === true) ? <><br/><br/></> : <></>}
								<ComResultsMoreComponent is_loading={ this.state.leads___list___is_loading } event___onClick={() => { this.read_props___leads_list(false); }}/>
								{(this.state.leads___list___is_loading === true) ? <><br/><br/></> : <></>}
							</div>
						</> : <>
							<div style={{height:'90%',display:'flex',flexDirection:'column',justifyContent:'space-around'}}>
								<section></section>
								<ComResultsEmptyComponent text="Nessun lead presente in questo elenco"/>
								<section></section>
							</div>
						</>}
					</>}

				</section>
			</div>
		</>;
	}

//#endregion

}