import React from 'react';
import { marked } from 'marked';

//	S T Y L E S

import './md-contract-preview.scss';

//	T Y P E S

import { type___api___response } from '../../types/types.api-response';
import { type___vehicle___condition } from '../../types/types.types';

//	L I B S

import { lib_names } from '../../libs/lib.names';
import { lib_properties } from '../../libs/lib.properties';
import { lib_vehicle_attributes } from '../../libs/lib.attributes';

//	F U N C S

import { funcs_numbers } from '../../funcs/funcs.numbers';
import { funcs_text } from '../../funcs/funcs.text';
import { funcs_text_extendend } from '../../funcs/funcs.text.extended';

//	S T A T E S

import { props___MdContractPreviewModal, state___MdContractPreviewModal, state___MdContractPreviewModal___default } from './md-contract-preview.state';

//	S E R V I C E S

import { service_RestApiService } from '../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../components/com-spinner/com-spinner';
import { props___ComContractBodyComponent } from '../../components/com-contract-body/com-contract-body.state';
import ComContractBodyComponent from '../../components/com-contract-body/com-contract-body';

//	C L A S S

export default class MdContractPreviewModal extends React.Component<props___MdContractPreviewModal, state___MdContractPreviewModal>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly lib___properties: lib_properties = new lib_properties();
	private readonly lib___vehicle_attributes: lib_vehicle_attributes = new lib_vehicle_attributes();

	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();
	private readonly funcs___text: funcs_text = new funcs_text();
	private readonly funcs___text_extended: funcs_text_extendend = new funcs_text_extendend();


//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdContractPreviewModal,
	) {
		super(props);
		this.state = state___MdContractPreviewModal___default
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___dealer = async() => {
		const ___dealer___props: type___api___response = await this._API.dealers___read___single();
		this.setState({
			dealer___props: ___dealer___props.data
		}, () => {
			this.process___contract_datas();
		});
	}

	read_props___vehicle___brand_model = async() => {
		const ___vehicle___brand: type___api___response = await this._API.vehicles_brands___read___single(this.props.vehicle___props['vehicle_brand']);
		const ___vehicle___model: type___api___response = await this._API.vehicles_models___read___single(this.props.vehicle___props['vehicle_model']);
		this.setState({
			vehicle___brand: (___vehicle___brand.response === 'success') ? ___vehicle___brand.data : '',
			vehicle___model: (___vehicle___brand.response === 'success') ? ___vehicle___model.data : '',
		}, () => {
			this.process___contract_datas();
		});
	}

	read_props___contract = async() => {
	
		await fetch(this.state.style___contract___root___path)
			.then((___r: any) => ___r.text())
			.then((___s: any) => this.setState({
				style___contract___root: ___s.toString() as string
			}, () => {
				const ___style_contract_root___styleElement = document.createElement('style');
				___style_contract_root___styleElement.innerHTML = this.state.style___contract___root ?? '';
				document.head.appendChild(___style_contract_root___styleElement);
				this.process___contract_datas();
			}));

		await fetch(this.state.style___contract___general___path)
			.then((___r: any) => ___r.text())
			.then((___s: any) => this.setState({
				style___contract___general: ___s.toString() as string
			}, () => {
				const ___style_contract_general___styleElement = document.createElement('style');
				___style_contract_general___styleElement.innerHTML = this.state.style___contract___general ?? '';
				document.head.appendChild(___style_contract_general___styleElement);
				this.process___contract_datas();
			}));

		const ___contract___c_commitment: type___api___response = await this._API.contracts___read___single('commitment');
		const ___contract___c_commitment___text: string = ___contract___c_commitment.data['contract_text'] ?? ' ';
		const ___contract___c_commitment___text___sub = this.funcs___text_extended.text_extended___enrich___substitute(___contract___c_commitment___text, lib_names.text_editor.snippet.signature___mark, lib_names.text_editor.snippet.signature___element);
	
		const ___contract___c_sell: type___api___response = await this._API.contracts___read___single('sell');
		const ___contract___c_sell___text: string = ___contract___c_sell.data['contract_text'] ?? ' ';
		const ___contract___c_sell___text___substituted: string = this.funcs___text_extended.text_extended___enrich___substitute(___contract___c_sell___text, lib_names.text_editor.snippet.signature___mark, lib_names.text_editor.snippet.signature___element);
		
		const ___contract___c_payments: type___api___response = await this._API.contracts___read___single('payments');
		const ___contract___c_payments___text: string = ___contract___c_payments.data['contract_text'] ?? ' ';
		const ___contract___c_payments___text___substituted: string = this.funcs___text_extended.text_extended___enrich___substitute(___contract___c_payments___text, lib_names.text_editor.snippet.signature___mark, lib_names.text_editor.snippet.signature___element);
		
		const ___contract___c_payments_leasing: type___api___response = await this._API.contracts___read___single('payments_leasing');
		const ___contract___c_payments_leasing___text: string = ___contract___c_payments_leasing.data['payments_leasing'] ?? ' ';
		const ___contract___c_payments_leasing___text___substituted: string = this.funcs___text_extended.text_extended___enrich___substitute(___contract___c_payments_leasing___text, lib_names.text_editor.snippet.signature___mark, lib_names.text_editor.snippet.signature___element);
		
		const ___contract___c_payments_financing: type___api___response = await this._API.contracts___read___single('payments_financing');
		const ___contract___c_payments_financing___text: string = ___contract___c_payments_financing.data['contract_text'] ?? ' ';
		const ___contract___c_payments_financing___text___substituted: string = this.funcs___text_extended.text_extended___enrich___substitute(___contract___c_payments_financing___text, lib_names.text_editor.snippet.signature___mark, lib_names.text_editor.snippet.signature___element);

		this.setState({
			textfile___contract____c_commitment: marked(___contract___c_commitment___text___sub) as string,
			textfile___contract____c_sell: marked(___contract___c_sell___text___substituted) as string,
			textfile___contract____c_payments: marked(___contract___c_payments___text___substituted) as string,
			textfile___contract____c_payments_leasing: marked(___contract___c_payments_leasing___text___substituted) as string,
			textfile___contract____c_payments_financing: marked(___contract___c_payments_financing___text___substituted) as string,
		}, () => {
			this.process___contract_datas();
		});

	}

//#endregion

//#region 																							P R O C E S S O R S

	process___contract_datas = () : void => {

		if (
			this.state.dealer___props !== null &&
			this.state.vehicle___brand !== null &&
			this.state.vehicle___model !== null &&
			this.props.vehicle___props !== null
		) {
			this.setState({
				preview_contract___props: {

					contract___for_business: this.props.contract___for_business,
					contract___fields: this.state.contract___fields,
					
					dealer___props: this.state.dealer___props,
	
					vehicle___brand: this.state.vehicle___brand,
					vehicle___model: this.state.vehicle___model,
					vehicle___props: this.props.vehicle___props,

					textfile___contract____c_commitment: this.state.textfile___contract____c_commitment,
					textfile___contract____c_sell: this.state.textfile___contract____c_sell,
					textfile___contract____c_payments: this.state.textfile___contract____c_payments,
					textfile___contract____c_payments_leasing: this.state.textfile___contract____c_payments_leasing,
					textfile___contract____c_payments_financing: this.state.textfile___contract____c_payments_financing,
	
				}
			});
		}

	}

//#endregion

//#region 																							H A N D L E R S

	handle___print = () => {
		
		const ___print___preview___content: string = document.getElementById('md-contract-preview---print-preview')!.innerHTML;

		const ___print___window = window.open(' ', 'PRINT')!;
		
		___print___window.document.write('<html>')
		___print___window.document.write('	<head>');
		___print___window.document.write('		<title> </title>');
		___print___window.document.write('	</head>');
		___print___window.document.write('	<body>');
		___print___window.document.write('		<style>' + this.state.style___contract___root + '</style>');
		___print___window.document.write('		<style>' + this.state.style___contract___general + '</style>');
		___print___window.document.write('		<section class="print-text">in stampa...</section>');
		___print___window.document.write('		<section class="print---contract-preview---print-preview">' + ___print___preview___content + '</section>');
		___print___window.document.write('	</body>');
		___print___window.document.write('</html>');

		___print___window.document.close(); 		// 		necessary for IE >= 10
		___print___window.focus(); 					// 		necessary for IE >= 10*/

		___print___window.print();
		___print___window.close();

	}

	handle___close = () => {
		this.props.event___onDismiss();
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void
	{
		const ___temp___contract___fields: string[] = this.props.contract___fields.map((___field: string) => {
			const ___field___processed: string = (___field) ? ___field.toString() : '';
			return ___field___processed;
		});
		this.setState({
			contract___fields: ___temp___contract___fields
		}, async () => {
			await this.read_props___dealer();
			await this.read_props___vehicle___brand_model();
			this.read_props___contract();
		})
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{

		return (<>
			<div className="md-contract-preview---opacizer"></div>
			<div className="md-contract-preview---container is-card">
				{(this.state.dealer___props !== null) ? <>

					<div id="md-contract-preview---print-preview" className="md-contract-preview---print-preview print---contract-preview---print-preview">
						{(this.state.preview_contract___props !== null) ? <>
							<ComContractBodyComponent { ...this.state.preview_contract___props }/>
						</> : <>
							<br />
							<br />
							<br />
							<ComSpinnerComponent/>
						</>}
					</div>

					<br />
					
					<div className="page-container---bottom-bar---buttons">
						<button type="button" className="is-card no-shadow" onClick={ this.handle___close }>
							<i className="fas fa-times"></i>
							<span>Chiudi</span>
						</button>
						<button type="button" className="is-card is-submit no-shadow" onClick={ this.handle___print }>
							<i className="fas fa-print"></i>
							<span>Stampa contratto</span>
						</button>
					</div>
						
				</> : <>
					<ComSpinnerComponent/>
				</>}

			</div>

		</>);
	}

//#endregion

}