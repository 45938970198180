import React from "react";

//	L I B S

import { type___vehicle___condition, type___vehicle___price_vat_quote, type___vehicle___price_vat_type } from "../types/types.types";

//	E X P O R T   L I B

export class lib_selectables
{

//#region 																							O P T I O N S   V A T   T Y P E S

	public selectable_options___vat_quotes() : React.ReactNode
	{
		return <>
			<option value={ '0' as type___vehicle___price_vat_quote }>0%</option>
			<option value={ '4' as type___vehicle___price_vat_quote }>4%</option>
			<option value={ '10' as type___vehicle___price_vat_quote }>10%</option>
			<option value={ '19' as type___vehicle___price_vat_quote }>19%</option>
			<option value={ '22' as type___vehicle___price_vat_quote }>22%</option>
		</>;
	}

//#endregion

//#region 																							O P T I O N S   V A T   T Y P E S

	public selectable_options___vat_types() : React.ReactNode
	{
		return <>
			<option value={ 'included' as type___vehicle___price_vat_type }>INCLUSA</option>
			<option value={ 'exposed' as type___vehicle___price_vat_type }>ESPOSTA</option>
			<option value={ 'margin' as type___vehicle___price_vat_type }>A MARGINE</option>
			<option value={ 'partial' as type___vehicle___price_vat_type }>PARZIALE</option>
		</>;
	}

//#endregion

//#region 																							O P T I O N S   V E H I C L E   C O N D I T I O N S

	public selectable_options___vehicle_conditions = () : React.ReactNode => {
		return 	<>
			<option value={ 'pristine' as type___vehicle___condition }>PERFETTO</option>
			<option value={ 'normal' as type___vehicle___condition }>NORMALE</option>
			<option value={ 'poor' as type___vehicle___condition }>MEDIOCRE</option>
			<option value={ 'none' as type___vehicle___condition }>NON EFFICIENTE</option>
		</>;
	}

//#endregion

















}