import React from 'react';

//	S T Y L E S

import './md-licence-expired.scss';

//	C L A S S

export default class MdLicenceExpiredModal extends React.Component<{}, {}>
{

//#region 																							H A N D L E R S

	private handle___clear_storage = () => {
		localStorage.clear();
		window.location.href = window.location.href;
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="modal---licence-expired---container is-card">
				<div className="modal---licence-expired---content">
					<section>
						<img src="/assets/logo.squared.svg"/>
						<h3>Sembra che la tua licenza sia scaduta</h3>
						<p>
							Se vuoi rinnovare, eliminare i tuoi dati e quelli della tua azienda, disdire l'abbonamento o pensi 
							ci sia stato un errore contatta l'assistenza all'indirizzo <a href="mailto:info@carshub.io">info@carshub.io </a> 
							oppure contatta il nostro rappresentante autorizzato 
						</p>
					</section>
					<div className="modal---licence-expired---problems">
						<p>
							<span>Vuoi effettuare il logout ed utilizzare un altro account?</span>
							<span onClick={ this.handle___clear_storage }>Chiudi la sessione</span>
						</p>
					</div>
				</div>
			</div>
		</>;
	}

//#endregion

}