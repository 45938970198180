import { type___alert___controller } from "./types/types.alerts";
import { type___licence } from "./types/types.licence";
import { type___toast___controller } from "./types/types.toasts";

//	P R O P S

export type props___AppRender = {

	event_alertSetup: any,
	event_alertShow: any,
	
	event_toastSetup: any,
	event_toastShow: any,

}

//	S T A T E

export interface state___App {

	me___is_logged: boolean | null,
	me___props: any | null,

	dealer___props: any | null,
	dealer___licence: type___licence,

	notifications___leads: number,

	GUI___alert___is_showing: boolean,
	GUI___alert___title: string,
	GUI___alert___message: string,
	GUI___alert___buttons: type___alert___controller['alert___buttons'],

	GUI___modal___is_showing: boolean,
	GUI___modal___title: string,
	GUI___modal___component: any | null,

	GUI___toast___is_showing: boolean,
	GUI___toast___inner: any | null, 
	GUI___toast___color: type___toast___controller['toast___color'],
	
}

//	S T A T E   D E F A U L T S

export const state___App___default: state___App = {

	me___is_logged : null,
	me___props : null,

	dealer___props: null,
	dealer___licence: {
		licence_active: null,
		licence_active___analytics: null,
		licence_active___cloudDocs: null, 
		licence_active___contracts: null,
		licence_active___sync___catalog: null,
		licence_active___sync___leads: null,
		licence_active___sync___vehicles: null,
		licence_active___multiusers: null,
		licence_active___plugin___CarsHubConnector: null,
		licence_active___vehiclesDb___cars: null,
		licence_active___vehiclesDb___commercials: null,
		licence_active___vehiclesDb___motorcycles: null,
	},

	notifications___leads: 0,

	GUI___alert___is_showing: false,
	GUI___alert___title: '',
	GUI___alert___message: '',
	GUI___alert___buttons: [],

	GUI___modal___is_showing: false,
	GUI___modal___title: '',
	GUI___modal___component: null,

	GUI___toast___is_showing: false,
	GUI___toast___inner: null, 
	GUI___toast___color: 'normal',
}