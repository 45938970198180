import { props___page___hasAlert } from "../../../interfaces/interface.alerts";
import { props___page___hasLicence } from "../../../interfaces/interface.licence";
import { props___page___hasToast } from "../../../interfaces/interface.toasts";
import { type___params__url } from "../../../types/types.params";
import { type___activity___type } from "../../../types/types.types";

//	P R O P S

export interface props___SbLeadsViewPage extends props___page___hasAlert, props___page___hasToast, props___page___hasLicence
{
	params: type___params__url
}

//	S T A T E

export interface state___SbLeadsViewPage {

	me___props: any | null,

	selected___lead_view___tab: 'overview' | 'matches' | 'contract' | 'sell',

	lead___id: string, 

	lead___props: any | null,
	lead___activities: any[],
	lead___matches_vehicles: any[],
	lead___matches_vehicles___search_param: string,

	partner___props: any | null,
	vehicle___props: any | null,

	field___new_activity___type: type___activity___type,
	field___new_activity___datetime___from: string,
	field___new_activity___datetime___to: string,
	field___new_activity___note: string,

	contract___generated: boolean,
	contract___fields: string[]
	contract___fields___seller: string[],

	GUI___lead___contract___preview: boolean,
	GUI___lead___create___new_activity: boolean,

	GUI___page___is_loading: boolean,
	GUI___page___is_loading___section: boolean,

}


//	S T A T E   D E F A U L T S

export const state___SbLeadsViewPage___defaults: state___SbLeadsViewPage = {

	me___props: null,

	selected___lead_view___tab: 'overview',

	lead___id: '', 
	
	lead___props: null,
	lead___activities: [],
	lead___matches_vehicles: [],
	lead___matches_vehicles___search_param: '',

	partner___props: null,
	vehicle___props: null,

	field___new_activity___type: 'appointment',
	field___new_activity___datetime___from: '',
	field___new_activity___datetime___to: '',
	field___new_activity___note: '',

	contract___generated: false,
	contract___fields: [],
	contract___fields___seller: [],

	GUI___lead___contract___preview: false,
	GUI___lead___create___new_activity: false,

	GUI___page___is_loading: true,
	GUI___page___is_loading___section: true,

}