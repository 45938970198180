import React from "react";
import { Link } from "react-router-dom";

//	S T Y L E

import "./sb-leads-list.scss"

//	T Y P E S

import { type___alert___controller } from "../../../types/types.alerts";
import { type___api___response } from "../../../types/types.api-response";
import { type___toast___controller } from "../../../types/types.toasts";
import { type___lead___interest___to, type___lead___status, type___request___order } from "../../../types/types.types";

//	L I B S

import { lib_names } from "../../../libs/lib.names";
import { lib_properties } from "../../../libs/lib.properties";

//	F U N C S

import { funcs_datetime } from "../../../funcs/funcs.datetime";

//	S T A T E S

import { props___SbLeadsListPage, state___SbLeadsListPage, state___SbLeadsListPage___default } from "./sb-leads-list.state";

//	S E R V I C E S

import { service_RestApiService } from "../../../services/service-api";

//	C O M P O N E N T S

import ComResultsMoreComponent from "../../../components/com-results-more/com-results-more";

//	C L A S S

export default class SbLeadsListPage extends React.Component<props___SbLeadsListPage, state___SbLeadsListPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly lib___properties: lib_properties = new lib_properties();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbLeadsListPage
	) {
		super(_PROPS)
		this.state = state___SbLeadsListPage___default
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___leads___list = async (___is_reset: boolean = true) => {

		await new Promise<void>((resolve) => this.setState({
			leads___list: (___is_reset === true) ? [] : this.state.leads___list,
			leads___list___is_loading: true
		}, () => resolve()));

		const ___leads___founds: type___api___response = await this._API.leads___read___multi('all', this.state.search_param___filter_by, this.state.leads___list.length, this.state.leads___list___order);
		switch (___is_reset) {
			case true: this.setState({ leads___list: ___leads___founds.data }); break;
			case false: this.setState({ leads___list: [...this.state.leads___list, ...___leads___founds.data]}); break;
		}

		await new Promise<void>((resolve) => this.setState({
			leads___list___is_loading: false
		}, () => resolve()));

	}

//#endregion

//#region 																							H A N D L E R S

	handle___leads___sortBy = (___param: string) => {

		let ___new___direction: type___request___order['direction'];
		const ___old___param: string = this.state.leads___list___order['param'];
		if (___old___param === ___param) {
			___new___direction = (this.state.leads___list___order['direction'] === 1) ? -1 : 1;
		} else {
			___new___direction = 1;
		}

		this.setState({
			leads___list___order: {
				param: ___param,
				direction: ___new___direction
			}
		}, async () => {
			await this.read_props___leads___list(true);
		});

	}

	handle___leads___filter_by = (___e: any) => {
		this.setState({
			search_param___filter_by: ___e.target.value
		}, async () => {
			await this.read_props___leads___list(true);
		});
	}

	handle___leads___delete = (___lead: any, ___i: number) => {
		const ___alert_props : type___alert___controller = {
			alert___title: "Conferma eliminazione",
			alert___message : <><>Vuoi davvero <b>ELIMINARE</b> questo Lead?</><br/><br/><b style={{textDecoration:'underline'}}>AZIONE IRREVERSIBILE</b></>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si, elimina',
					alert___button___action: async () => {
						const ___lead___delete: type___api___response = await this._API.leads___delete(___lead['lead_id']);
						if (___lead___delete['response'] === 'success')
						{
							const ___lead___deleted___url: string = '/leads/list';
							window.location.href = ___lead___deleted___url;
							this._PROPS.alert___setShowing(false);
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Hai rimosso il Lead in questione</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore generico nel rimuovere il Lead</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

	handle___leads___reload = () => {
		this.setState({
			search_param___filter_by: state___SbLeadsListPage___default['search_param___filter_by']
		}, async () => {
			await this.read_props___leads___list(true);
		});
	}

	handle___select___lead_is_viewed = async (___index: number, ___lead_id: string, ___new_status: boolean = true) => {
		const ___lead___is_viewed: type___api___response = await this._API.leads___update___status_viewed(___lead_id, ___new_status);
		if (___lead___is_viewed['response'] === 'success') {
			let ___leads___list___processed: any[] = [...this.state.leads___list];
				___leads___list___processed[___index]['lead_status_viewed'] = ___new_status;
			this.setState({
				leads___list: ___leads___list___processed
			}, () => {
				const ___toastprops: type___toast___controller = {
					toast___color: 'success',
					toast___text: <>Hai segnato questo Lead come "<b>{ (___new_status === true) ? 'Visualizzato' : 'Da vedere' }</b>"</>
				}
				this.props.toast___setContent(___toastprops);
				this.props.toast___setShowing(true);
			});
		}
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount(): Promise<void>
	{
		await this.read_props___leads___list(true);
	}

//#endregion

//#region 																							R E A D   P R O P S

	render() : React.ReactNode
	{
		return <>
			<div className="page---container is-full-width">
				<section>
					<div className="page---top-bar">
						<section>
							<Link to={ '/leads/create' } className="is-card">
								<i className="fas fa-plus"></i>
								<span>Nuovo Lead</span>
							</Link>
							<Link to={ '/dashboard' } className="is-card">
								<i className="fas fa-th-large"></i>
								<span>Dashboard</span>
							</Link>
							<button className="is-card">
								<img src="/assets/ui/icons/icon-spreadsheet.svg" />
								<span>Esporta .CSV</span>
							</button>
						</section>
						<section>
							<label className="page---top-bar---select-box is-card" htmlFor="search---select-filter---leads">
								<i className="fas fa-filter"></i>
								<select id="search---select-filter---leads" value={ this.state.search_param___filter_by } onChange={ this.handle___leads___filter_by }>
									<option value="all">Visualizza Tutto</option>
									<option value="all" disabled>-</option>
									<option value="new">Nuovi</option>
									<option value="sold">Venduti</option>
									<option value="working">In Lavorazione</option>
									<option value="archived">Archiviati</option>
									<option value="ready">In Consegna</option>
								</select>
							</label>
							<button className="is-card" onClick={() => { this.handle___leads___reload(); }}>
								<i className="fas fa-sync-alt"></i>
							</button>
						</section>
					</div>
					<div className="container---table container---table---leads-list is-card">
						<div>
							<div></div>
							<div></div>
							<div>
								<i className="fas fa-image" style={{opacity:'1'}}></i>
								<span>•</span>
							</div>
							<div>
								<span>Data</span>
								{(this.state.leads___list___order['param'] === 'createdAt') ? <>
									{(this.state.leads___list___order['direction'] === 1) ? <>
										<i className="fas fa-sort-up" onClick={() => { this.handle___leads___sortBy('createdAt'); }}></i>
									</> : <>
										<i className="fas fa-sort-down" onClick={() => { this.handle___leads___sortBy('createdAt'); }}></i>
									</>}
								</> : <>
									<i className="fas fa-sort" onClick={() => { this.handle___leads___sortBy('createdAt'); }}></i>
								</>}
							</div>
							<div>
								<span>Interesse</span>
								<span>•</span>
							</div>
							<div>
								<span>Cliente</span>
								<span>•</span>
							</div>
							<div>
								<span>Provenienza</span>
								<span>•</span>
							</div>
							<div>
								<span>Stato</span>
								<span>•</span>
							</div>
						</div>
						{this.state.leads___list.map((___l: any, ___i: number) => (
							<div key={ ___i }>
								<div>
									<Link to={ '/leads/view/' + ___l['lead_id'] } data-title="Visualizza">
										<i className="fas fa-eye"></i>
									</Link>
									{(___l['lead_status'] !== 'sold') ? <>
										<i className="fas fa-trash" onClick={()=>{ this.handle___leads___delete(___l, ___i); }} data-title="Elimina"></i>
									</> : <>
										<span></span>
									</>}
								</div>
								<div>
									{(___l['lead_status_viewed'] !== true) ? <>
										<span className="is---view-status is---view-status---unviewed" onClick={() => { this.handle___select___lead_is_viewed(___i, ___l['lead_id'], true); }} data-title="Non mettere in evidenza">
											<i className="fas fa-bookmark"></i>
										</span>
									</> : <>
										<span className="is---view-status is---view-status---viewed" onClick={() => { this.handle___select___lead_is_viewed(___i, ___l['lead_id'], false); }} data-title="Metti in evidenza">
											<i className="fas fa-circle-notch"></i>
										</span>
									</>}
								</div>
								<div>
									{(___l['lead_vehicle'] && ___l['lead_vehicle_gallery_pictures'] && ___l['lead_vehicle_gallery_pictures'].length > 0) ? <>
										<img src={ lib_names.cdn.endpoint + ___l['lead_vehicle_gallery_pictures'][0] }/>
									</> : <>
										<img src={ lib_names.media.placeholders.vehicle.generic }/>
									</>}
								</div>
								<div>{ this.funcs___datetime.datetime___get___date(___l['lead_creation_datetime']) }</div>
								<div>
									{(() => {
										switch (___l['lead_partner_interest'] as type___lead___interest___to) {
											case 'brands': return <>
												<span>Interessato a (brand): </span>
												{(___l['lead_vehicles_brands'].length > 0) ? <>
													{ ___l['lead_vehicles_brands'].map((___b: any, ___b_i: number) => {
														const ___b___separator: string = ((___b_i + 1) < ___l['lead_vehicles_brands'].length) ? ', ': ' ';
														return <><span>{ ___b['brand_name'] }</span>{ ___b___separator }</>;
													})}
												</> : <></> }
											</>; break;
											case 'models': return <>
												<span>Interessato a (modelli): </span>
												{(___l['lead_vehicles_models'].length > 0) ? <>
													{ ___l['lead_vehicles_models'].map((___m: any, ___m_i: number) => {
														const ___m___separator: string = ((___m_i + 1) < ___l['lead_vehicles_models'].length) ? ', ': ' ';
														return <><span>{ ___m['car_brand'] + ' ' + ___m['car_model'] }</span>{ ___m___separator }</>;
													})}
												</> : <></> }
											</>; break;
											case 'stock_vehicle': return <>
												<span>Abbinato a: </span>
												{(___l['lead_vehicle'] !== null) ? <>
													<Link target="_blank" to={ '/vehicles/view/' + ___l['lead_vehicle'] } data-title="Visualizza veicolo">
														<>{ ___l['lead_vehicle_name'] }</>
													</Link>
												</> : <>...</>}
											</>; break;
											case 'custom': return <>
												<span>Interessato a: </span>
												{(___l['lead_vehicle_requested']) ? <>
													<span>{ ___l['lead_vehicle_requested'] }</span>
												</> : <></> }
											</>; break;
										}
									})()}
								</div>
								<div>
									<Link target="_blank" to={ '/partners/view/' + ___l['lead_partner']['_id'] } data-title="Visualizza Cliente">
										{(___l['lead_partner']['partner_type'] === 'p') ? <>
											<span>{ ___l['lead_partner']['partner_name'] + ' ' + ___l['lead_partner']['partner_surname'] }</span>
										</> : <>
											<span>{ ___l['lead_partner']['partner_name_business'] }</span> 
											<span style={{opacity:'0.5'}}> - { ___l['lead_partner']['partner_name'] + ' ' + ___l['lead_partner']['partner_surname'] }</span>
										</>}
									</Link>
								</div>
								<div>
									{(___l['lead_marketing_source']) ? <>
										{ this.lib___properties.get___property('lead_marketing_source', ___l['lead_marketing_source']) }
									</> : <>-</>}
								</div>
								<div>
									{(() => {
										switch (___l['lead_status'] as type___lead___status) {
											case 'new': return <><span className="is---leadtable-status---new">Nuovo</span></>; break;
											case 'sold': return <><span className="is---leadtable-status---sold">Vendita</span></>; break;
											case 'working': return <><span className="is---leadtable-status---working">Lavorazione</span></>; break;
											case 'archived': return <><span className="is---leadtable-status---archived">Archiviato</span></>; break;
											case 'ready': return <><span>Consegna</span></>; break;
										}
									})()}
								</div>
							</div>
						))}
						<ComResultsMoreComponent is_loading={ this.state.leads___list___is_loading } event___onClick={() => { this.read_props___leads___list(false); }}/>
					</div>
				</section>
			</div>
		</>;
	}

//#endregion

}