//	E X P O R T   F U N C S

export class funcs_clipboard
{

//#region 																							C O P Y

	public clipboard___copy_text = async (___text: string) : Promise<boolean> => {

		if (!navigator.clipboard)
		{
			console.error('Clipboard API is not available');
			return false;
		}
	
		try
		{
			await navigator.clipboard.writeText(___text);
			return true;
		}
		catch (error)
		{
			console.error('Failed to copy text: ', error);
			return false;
		}

	}

//#endregion

}