import { props___ComContractBodyComponent } from "../../components/com-contract-body/com-contract-body.state"
import { type___modals___controller } from "../../types/types.modals"

//	P R O P S

export interface props___MdContractPreviewModal extends type___modals___controller {

	contract___for_business: boolean,
	contract___fields: string[]
	
	vehicle___props: any,

}

//	S T A T E

export interface state___MdContractPreviewModal {

	contract___fields: string[],

	dealer___props: any | null, 
	
	vehicle___brand: any | null,
	vehicle___model: any | null,

	style___contract___root___path: '/assets/style/style.contract.root.css',
	style___contract___root: string | null,
	style___contract___general___path: '/assets/style/style.contract.general.css',
	style___contract___general: string | null,

	textfile___contract____c_commitment: any | null,
	textfile___contract____c_sell: any | null,
	textfile___contract____c_payments: any | null,
	textfile___contract____c_payments_leasing: any | null,
	textfile___contract____c_payments_financing: any | null,
	
	preview_contract___props: props___ComContractBodyComponent | null,






}

//	S T A T E   D E F A U L T 

export const state___MdContractPreviewModal___default : state___MdContractPreviewModal = {

	contract___fields: [],

	dealer___props: null, 

	vehicle___brand: null,
	vehicle___model: null,

	style___contract___root___path: '/assets/style/style.contract.root.css',
	style___contract___root: null,
	style___contract___general___path: '/assets/style/style.contract.general.css',
	style___contract___general: null,

	textfile___contract____c_commitment: null,
	textfile___contract____c_sell: null,
	textfile___contract____c_payments: null,
	textfile___contract____c_payments_leasing: null,
	textfile___contract____c_payments_financing: null,

	preview_contract___props: null,








}