import React from "react";

//	S T Y L E

import "./sb-info-licence.scss"

//	T Y P E S

import { type___api___response } from "../../../types/types.api-response";

//	F U N C S

import { funcs_datetime } from "../../../funcs/funcs.datetime";
import { funcs_numbers } from "../../../funcs/funcs.numbers";

//	S T A T E S

import { props___SbInfoLicencePage, state___SbInfoLicencePage, state___SbInfoLicencePage___default } from "./sb-info-licence.state";

//	S E R V I C E S

import { service_RestApiService } from "../../../services/service-api";

//	C O M P O N E N T S

import ComSpinnerComponent from "../../../components/com-spinner/com-spinner";

//	C L A S S

export default class SbInfoLicencePage extends React.Component<props___SbInfoLicencePage, state___SbInfoLicencePage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbInfoLicencePage
	) {
		super(_PROPS)
		this.state = state___SbInfoLicencePage___default
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___licence = async () => {
		const ___licence___props: type___api___response = await this._API.dealers_licences___read___single();
		this.setState({
			licence___props: ___licence___props.data
		});
	}

	read_props___cloud_status = async () => {
		const ___cloud_stats___props: type___api___response = await this._API.cloud___read___status();
		this.setState({
			cloud_stats___props: ___cloud_stats___props.data
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___select___tab = (___e: state___SbInfoLicencePage['selected___tab']) => {
		this.setState({ 
			GUI___page___is_loading: true,
			selected___tab: ___e
		}, async () => { 
	//		await this.read_props___leads_count();
	//		await this.read_props___leads_list(); 
		});
	}







	














//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount(): Promise<void>
	{
		await this.read_props___licence();
		await this.read_props___cloud_status();
	}

//#endregion

//#region 																							R E A D   P R O P S

	render() : React.ReactNode
	{
		return <>
			<div className="page---container is-full-width">
				<section>
					<div className="page---top-bar">
						<section>
							<input type="radio" id="d-ts---cb---active" name="d-ts---cb" value="active" checked={this.state.selected___tab === 'active'} onChange={(___e: any) => { this.handle___select___tab(___e['target']['value'] as state___SbInfoLicencePage['selected___tab']); }} />
							<label htmlFor="d-ts---cb---active" className="is-card">
								<img src="/assets/ui/icons/icon-licence.svg" />
								<span>Licenza Attiva</span>
							</label>
							{(this.state.licence___props !== null && this.state.licence___props['licence_active___cloudDocs'] === true) ? <>
								<input type="radio" id="d-ts---cb---cloudDocs" name="d-ts---cb" value="cloudDocs" checked={this.state.selected___tab === 'cloudDocs'} onChange={(___e: any) => { this.handle___select___tab(___e['target']['value'] as state___SbInfoLicencePage['selected___tab']); }} />
								<label htmlFor="d-ts---cb---cloudDocs" className="is-card">
									<img src="/assets/ui/icons/icon-cloud.svg" />
									<span>Spazio Cloud Docs Disponibile</span>
								</label>
							</> : <></>}
						{/*
							<input type="radio" id="d-ts---cb---payments" name="d-ts---cb" value="payments" checked={this.state.selected___tab === 'payments'} onChange={(___e: any) => { this.handle___select___tab(___e['target']['value'] as state___SbInfoLicencePage['selected___tab']); }} />
							<label htmlFor="d-ts---cb---payments" className="is-card">
								<img src="/assets/ui/icons/icon-licence-payment.svg" />
								<span>Elenco Pagamenti</span>
							</label>
						*/}
						</section>
						<section>
							
						</section>
					</div>
					{(() => {
						switch (this.state.selected___tab as state___SbInfoLicencePage['selected___tab']) {
							case 'active': return <>
								<div className="is-card licence-active---overview---container">
									{(this.state.licence___props !== null) ? <>
										<div>
											<div>
												<img src="/assets/ui/icons/icon-licence.svg" />
												<h2>
													<span>Licenza</span>
													<span>Attiva</span>
												</h2>
												<div className="container---table">
													<div style={{display:'none'}}></div>
													<div>
														<div>-</div>
														<div>Contratto</div>
														<div>{ this.state.licence___props['licence_contract_id'] }</div>
													</div>
													<div>
														<div>-</div>
														<div>Fatturazione</div>
														<div>
															{(() => {
																switch (this.state.licence___props['licence_datetime_duration']) {
																	case 'year': return <>ANNUALE</>; break;
																	case 'month': return <>MENSILE</>; break;
																}
															})()}
														</div>
													</div>
													<div>
														<div>-</div>
														<div>Attivazione</div>
														{(this.state.licence___props['licence_datetime_begin']) ? <>
															<div>{ this.funcs___datetime.datetime___get___date(this.state.licence___props['licence_datetime_begin']!.toString()) }</div>
														</> : <>
															<div>---</div>
														</>}
													</div>
													<div>
														<div>-</div>
														<div>Scadenza</div>
														{(this.state.licence___props['licence_datetime_expire']) ? <>
															<div>{ this.funcs___datetime.datetime___get___date(this.state.licence___props['licence_datetime_expire']!.toString()) }</div>
														</> : <>
															<div>---</div>
														</>}
													</div>
												</div>
											</div>
										</div>
										<div>
											<div className="container---table">
												<div style={{display:'none'}}></div>
												<div>
													<div></div>
													<div>
														<p>Analytics</p>
														<p>Statistiche avanzate sull'operato del tuo concessionario, degli agenti e dei partner</p>
													</div>
													<div>
														{(this.state.licence___props['licence_active___analytics'] === true) ? <>
															<span className="is-licence-field-active">ATTIVO</span>
														</> : <>
															<span className="is-licence-field-unavaliable">NON DISPONIBILE</span>
														</>}
													</div>
												</div>
												<div>
													<div></div>
													<div>
														<p>Cloud Docs</p>
														<p>Spazio Cloud scalabile, categorizzato per ogni partner e per ogni veicolo in cui caricare documenti</p>
													</div>
													<div>
														{(this.state.licence___props['licence_active___cloudDocs'] === true) ? <>
															<span className="is-licence-field-active">ATTIVO</span>
														</> : <>
															<span className="is-licence-field-available">ACQUISTABILE</span>
														</>}
													</div>
												</div>
												<div>
													<div></div>
													<div>
														<p>Contrattualistica</p>
														<p>Generatore automatico di contratti</p>
													</div>
													<div>
														{(this.state.licence___props['licence_active___contracts'] === true) ? <>
															<span className="is-licence-field-active">ATTIVO</span>
														</> : <>
															<span className="is-licence-field-available">ACQUISTABILE</span>
														</>}
													</div>
												</div>
												<div>
													<div></div>
													<div>
														<p>Importer Leads</p>
														<p>Importatore automatico di Leads dalle email</p>
													</div>
													<div>
														{(this.state.licence___props['licence_active___sync___leads'] === true) ? <>
															<span className="is-licence-field-active">ATTIVO</span>
														</> : <>
															<span className="is-licence-field-available">ACQUISTABILE</span>
														</>}
													</div>
												</div>
												<div>
													<div></div>
													<div>
														<p>Importer Veicoli</p>
														<p>Importatore dei veicoli dalle piattaforme Autoscout24 e Subito.it</p>
													</div>
													<div>
														{(this.state.licence___props['licence_active___sync___vehicles'] === true) ? <>
															<span className="is-licence-field-active">ATTIVO</span>
														</> : <>
															<span className="is-licence-field-available">ACQUISTABILE</span>
														</>}
													</div>
												</div>
												<div>
													<div></div>
													<div>
														<p>Database Veicoli - Automobili</p>
														<p>Possibilità di accedere al nostro Database Veicoli relativo a tutte le automobili con marca, modello e variante costantemente aggiornato</p>
													</div>
													<div>
														{(this.state.licence___props['licence_active___vehiclesDb___cars'] === true) ? <>
															<span className="is-licence-field-active">ATTIVO</span>
														</> : <>
															<span className="is-licence-field-available">ACQUISTABILE</span>
														</>}
													</div>
												</div>
												<div>
													<div></div>
													<div>
														<p>Database Veicoli - Veicoli Commerciali</p>
														<p>Possibilità di accedere al nostro Database Veicoli relativo a tutte i veicoli commerciali con marca, modello e variante costantemente aggiornato</p>
													</div>
													<div>
														{(this.state.licence___props['licence_active___vehiclesDb___commercials'] === true) ? <>
															<span className="is-licence-field-active">ATTIVO</span>
														</> : <>
															<span className="is-licence-field-available">ACQUISTABILE</span>
														</>}
													</div>
												</div>
												<div>
													<div></div>
													<div>
														<p>Database Veicoli - Motociclette e Ciclomotori</p>
														<p>Possibilità di accedere al nostro Database Veicoli relativo a tutti i veicoli a due ruote con marca, modello e variante costantemente aggiornato</p>
													</div>
													<div>
														{(this.state.licence___props['licence_active___vehiclesDb___motorcycles'] === true) ? <>
															<span className="is-licence-field-active">ATTIVO</span>
														</> : <>
															<span className="is-licence-field-available">ACQUISTABILE</span>
														</>}
													</div>
												</div>
												<div>
													<div></div>
													<div>
														<p>Multiutenza</p>
														<p>Possibilità più utenti con diversi ruoli (utente ed amministratore)</p>
													</div>
													<div>
														{(this.state.licence___props['licence_active___multiusers'] === true) ? <>
															<span className="is-licence-field-active">ATTIVO</span>
														</> : <>
															<span className="is-licence-field-available">ACQUISTABILE</span>
														</>}
													</div>
												</div>
												<div>
													<div></div>
													<div>
														<p>Plugin - CarsHub Connector™</p>
														<p>Plugin per WordPress che consente la visualizzazione dell'archivio veicoli e la ricezione di nuovi leads</p>
													</div>
													<div>
														{(this.state.licence___props['licence_active___plugin___CarsHubConnector'] === true) ? <>
															<span className="is-licence-field-active">ATTIVO</span>
														</> : <>
															<span className="is-licence-field-available">ACQUISTABILE</span>
														</>}
													</div>
												</div>
											</div>
										</div>
									</> : <>
										<ComSpinnerComponent/>
									</>}
								</div>
							</>; break;
							case 'cloudDocs': return <>
								<div className="is-card cloudDocs-licence-space---container">
								{(this.state.licence___props !== null) ? <>
									<div>
										<img src="/assets/ui/icons/icon-cloud.svg" />
										<br />
										<h2 className="input---nb-title">Spazio cloud disponibile</h2>
										<br />
										<br />
										<div className="cloudDocs-status---container">
											<div>
												<div style={{left:this.funcs___numbers.get_value___percentage(this.state.cloud_stats___props['cloud_documents_space___occupied___size'],this.state.cloud_stats___props['cloud_documents_space___total'])}}>
													<p>In Uso: <b>{ this.funcs___numbers.format___bytesize(this.state.cloud_stats___props['cloud_documents_space___occupied___size']) }</b></p>
												</div>
											</div>
											<div>
												<div style={{width:this.funcs___numbers.get_value___percentage(this.state.cloud_stats___props['cloud_documents_space___occupied___size'],this.state.cloud_stats___props['cloud_documents_space___total'])}}></div>
											</div>
											<div>
												<p>0 B</p>
												<p>{ this.funcs___numbers.format___bytesize(this.state.cloud_stats___props['cloud_documents_space___total']) }</p>
											</div>
										</div>
										<br />
										<br />
										<br />
									</div>
									</> : <>
										<ComSpinnerComponent/>
									</>}
								</div>
							</>; break;
							case 'payments': return <>
								<div className="container---table container---table---leads-list is-card">
						
						









								</div>
							</>; break;
						}
					})()}
				</section>
			</div>
		</>;
	}

//#endregion

}