//	S T A T E

export interface state___WpTooltipsWrapper {
	isVisible: boolean,
	text: string,
	tooltip___position___x: number,
	tooltip___position___y: number,
}

//	S T A T E   D E F A U L T 

export const state___WpTooltipsWrapper___default: state___WpTooltipsWrapper = {
	isVisible: false,
	text: '',
	tooltip___position___x: 0,
	tooltip___position___y: 0,
}