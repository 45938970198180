import React from "react";

//	L I B S

import { lib_names } from "../libs/lib.names";

//	E X P O R T   L I B

export class funcs_text_extendend
{

//#region 																							T E X T   W R A P   R E A C T N O D E

	public text_extendend___wrap___as___ReactNode(___input: string | React.ReactNode | any) : React.ReactNode
	{
		const ___wrapped: React.ReactNode = (typeof ___input === 'function') ? ___input() : <>{ ___input }</>;
		return ___wrapped;
	}

//#endregion

//#region 																							T E X T   E N R I C H

	private text_extended___enrich___is_url(___input_string: string) : boolean
	{
		const urlPattern = new RegExp(lib_names.regex.text_url, 'i');
		return !!urlPattern.test(___input_string);
	}

	public text_extended___enrich(___input_text: string, ___url_target: '_blank' | null = null) : React.ReactNode
	{

		const ___input_text___parsed: React.ReactNode = ___input_text.split('\n').map((___l, ___l_i, ___l_a) => {
		
			const ___w___array = ___l.split(' ').map((___w, ___w_i) => {
				let ___w___trimmed = ___w.trim();
				
				if (this.text_extended___enrich___is_url(___w___trimmed))
				{
					return <a href={ ___w___trimmed } target="_blank" rel="noopener noreferrer">{ ___w___trimmed }</a>;
				}

				if (___w___trimmed.startsWith('**') && ___w___trimmed.endsWith('**'))
				{
					___w___trimmed = ___w___trimmed.substring(2, ___w___trimmed.length - 2);
					return <strong>{ ___w___trimmed }</strong>;
				}
				else
				{
					const parts = ___w___trimmed.split('**');
					const processedParts = parts.map((part, index) => {
					if (index % 2 === 1) return <strong>{part}</strong>; return part; });
					return <>{processedParts}{ ___w_i < ___l.split(' ').length - 1 ? ' ' : ''}</>;
				}
			
				return <>{ ___w___trimmed }{ ___w_i < ___l.split(' ').length - 1 ? ' ' : ''}</>;
			
			});
		
			return <>{ ___w___array }{ ___l_i < ___l_a.length - 1 ? <br /> : null}</>;
		
		});
		
		return <>{ ___input_text___parsed }</>;
	}

//#endregion

//#region 																							S U B S T I T U T E

	text_extended___enrich___substitute(___target: string, ___mark: string, ___element: string) : string
	{
		
		return ___target;
	
	
	//	const ___regex___search: RegExp = new RegExp(___mark.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g');
	//	return ___target.replace(___regex___search, ___element);
	}

//#endregion

}