import React from 'react';

//	S T Y L E

import './com-results-empty.scss';

//	T Y P E S

import { props___ComResultsEmptyComponent } from './com-results-empty.state';

//	C L A S S

export default class ComResultsEmptyComponent extends React.Component<props___ComResultsEmptyComponent, {}>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComResultsEmptyComponent,
	) {
		super(props);
	}

//#endregion

//#region 																							R E T U R N

	render(): React.ReactNode
	{
		return <>
			<div className="no-results---container">
				<h6>{ this.props.text }</h6>
			</div>
		</>;
	}

//#endregion

}