import { props___page___hasAlert } from "../../../interfaces/interface.alerts"
import { props___page___hasLicence } from "../../../interfaces/interface.licence"
import { props___page___hasToast } from "../../../interfaces/interface.toasts"
import { type___params__url } from "../../../types/types.params"
import { type___request___order, type___vehicle___visibility } from "../../../types/types.types"

//	P R O P S

export interface props___SbSettingsGlobalPage extends props___page___hasAlert, props___page___hasToast, props___page___hasLicence
{

}

//	S T A T E

export interface state___SbSettingsGlobalPage {

	selected___settings_general___tab: 'sales',

	dealer_setting___vehicles___visible_after_sale: type___vehicle___visibility,
	dealer_setting___vehicles___visible_after_sale___duration: number,
	dealer_setting___vehicles___visible_after_sale___price: type___vehicle___visibility,

	dealer_setting___vehicle_sync___default_visible: type___vehicle___visibility,





	GUI___page___is_loading___section: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbSettingsGlobalPage___defaults: state___SbSettingsGlobalPage = {

	selected___settings_general___tab: 'sales',

	dealer_setting___vehicles___visible_after_sale: 'hidden',
	dealer_setting___vehicles___visible_after_sale___duration: 15,
	dealer_setting___vehicles___visible_after_sale___price: 'hidden',

	dealer_setting___vehicle_sync___default_visible: 'hidden',

	





	GUI___page___is_loading___section: true,

}