import { props___page___hasAlert } from "../../../interfaces/interface.alerts";
import { props___page___hasToast } from "../../../interfaces/interface.toasts";
import { type___partner___type, type___lead___exchange, type___lead___interest___to, type___lead___source } from "../../../types/types.types";

//	P R O P S

export interface props___SbLeadsEditPage extends props___page___hasAlert, props___page___hasToast
{


}

//	S T A T E

export interface state___SbLeadsEditPage {

	search___vehicles___param: string,
	search___vehicles_brands___results: any[],
	search___vehicles_models___results: any[],
	search___vehicles___results: any[],
	search___partners___results: any[],

	field___partner___search_param: string,
	field___partner___binding_id: string | null,
	field___partner___binding_props: any | null,

	field___new_partner___type: type___partner___type,
	field___new_partner___name_business: string,
	field___new_partner___name: string,
	field___new_partner___surname: string,
	field___new_partner___contact_phone: string,
	field___new_partner___contact_email: string,

	field___lead___interest___to: type___lead___interest___to, 
	field___lead___interest___to___custom: string,
	field___lead___interest___to___custom___vehicle_props: any | null,
	field___lead___interest___year_from: number | null,
	field___lead___interest___year_to: number | null,
	field___lead___interest___price_from: number | null,
	field___lead___interest___price_to: number | null,
	field___lead___interest___brands: any[],
	field___lead___interest___models: any[],
	field___lead___interest___notes: string,

	field___lead___exchange: type___lead___exchange,
	field___lead___exchange___item: string,
	field___lead___exchange___price: number | null,

	field___lead___marketing___source: type___lead___source,
	field___lead___marketing___source___additional: string,
	field___lead___marketing___source___notes: string,

	lead___can_submit: boolean,

	GUI___user___is_creating: boolean,
	GUI___page___is_loading: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbLeadsEditPage___default: state___SbLeadsEditPage = {

	search___vehicles___param: '',
	search___vehicles_brands___results: [],
	search___vehicles_models___results: [],
	search___vehicles___results: [],
	search___partners___results: [],

	field___partner___search_param: '',
	field___partner___binding_id: null,
	field___partner___binding_props: null,

	field___new_partner___type: 'p',
	field___new_partner___name_business: '',
	field___new_partner___name: '',
	field___new_partner___surname: '',
	field___new_partner___contact_phone: '',
	field___new_partner___contact_email: '',

	field___lead___interest___to: 'stock_vehicle', 
	field___lead___interest___to___custom: '',
	field___lead___interest___to___custom___vehicle_props: null,
	field___lead___interest___year_from: null,
	field___lead___interest___year_to: null,
	field___lead___interest___price_from: null,
	field___lead___interest___price_to: null,
	field___lead___interest___brands: [],
	field___lead___interest___models: [],
	field___lead___interest___notes: '',

	field___lead___exchange: '',
	field___lead___exchange___item: '',
	field___lead___exchange___price: null,

	field___lead___marketing___source: '',
	field___lead___marketing___source___additional: '',
	field___lead___marketing___source___notes: '',

	lead___can_submit: false,

	GUI___user___is_creating: false,
	GUI___page___is_loading: true,

}