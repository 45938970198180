//	L I B S

import { lib_names } from '../libs/lib.names';
import { type___request___exporter_mime } from '../types/types.types';

//	E X P O R T

export class service_RestExporterService
{

//#region 																							D E C L A R A T I O N S

	private _HEADERS: any = { 'Content-Type': 'application/json' };

//#endregion

//#region 																							W H O A M I

	private load___whoami()
	{
		const ___whoami_token: string | null = <string>localStorage.getItem(lib_names.storage.user___logintoken) ?? null;
		if (___whoami_token) { this._HEADERS['Authorization'] = 'Bearer ' + ___whoami_token as string; }
	}

//#endregion

//#region 																							D O W N L O A D

	async download(___url: string, ___filename: string, ___mime: type___request___exporter_mime = 'csv') : Promise<boolean>
	{
		this.load___whoami();
		const ___s_route: string = ___url + ___mime;
		try {
			const ___s_response: Response = await fetch(___s_route, { method: 'GET', headers: this._HEADERS });
			const ___s_response___blob: Blob = await ___s_response.blob();
			const ___s_response___downloadUrl: string | MediaSource = window.URL.createObjectURL(___s_response___blob);
			const ___s_response___a___fileName: string = ___filename + '.' + ''
			const ___s_response___a: any = document.createElement('a');
				  ___s_response___a.href = ___s_response___downloadUrl;
				  ___s_response___a.setAttribute('download', ___s_response___a___fileName);
			document.body.appendChild(___s_response___a);
			___s_response___a.click();
		//	link.parentNode.removeChild(link);
			return true;
		} catch (___e_main: any) {
			console.error('Error fetching server response:', ___e_main);
			return false;
		}
	}

//#endregion

}