import React from 'react';
import { Link } from 'react-router-dom';

//	S T Y L E

import './sb-standalone-download.scss';

//	S T A T E

import { props___SbStandaloneDownloadPage } from './sb-standalone-download.state';

//	L I B S

import { lib_names } from '../../libs/lib.names';

//	C L A S S

export default class SbStandaloneDownloadPage extends React.Component<props___SbStandaloneDownloadPage, {}>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbStandaloneDownloadPage
	) {
		super(_PROPS);
	}

//#endregion

//#region 																							L I F E C Y C L E

	componentDidMount() : void
	{
		
	}

//#endregion 

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<div className="page---container is-full-width">
				<section>
					<div className="standalone-download---container is-card">
						<div className="standalone-download---container---logo">
							<img src="/assets/logo.horizontal.svg" />
						</div>
						<br />
						<span className="input---nb-label" style={{lineHeight:'14pt'}}>
							Per utilizzare l'app di CarsHub™, è necessario avere installato <a target="_blank" href="https://www.google.it/intl/it/chrome/">Google Chrome</a> ed 
							essere connessi ad internet
						</span>
						<br />
						<br />
						<div className="standalone-download---container---downloads">
							<Link target="_blank" to="">
								<img src="/assets/third/third.windows.svg" alt="Windows" />
								<br />
								<p>Scarica per Windows</p>
								<span>Windows 7 e successivi</span>
								<br />
								<i className="fas fa-download"></i>
							</Link>
							<Link target="_blank" to="/download/carshub_app_macOs.zip" download={ true }>
								<img src="/assets/third/third.macos.svg" alt="macOs" />
								<br />
								<p>Scarica per macOs</p>
								<span>macOs 10.0.1 e successivi</span>
								<br />
								<i className="fas fa-download"></i>
							</Link>
							<Link target="_blank" to="">
								<img src="/assets/third/third.ubuntu.svg" alt="macOs" />
								<br />
								<p>Scarica per Ubuntu</p>
								<span>Ubuntu 16.22 e successivi</span>
								<br />
								<i className="fas fa-download"></i>
							</Link>
						</div>
					</div>
				</section>
			</div>
			









		</>;
	}

//#endregion

}