import { props___page___hasAlert } from "../../../interfaces/interface.alerts"
import { props___page___hasToast } from "../../../interfaces/interface.toasts"
import { type___contract___type } from "../../../types/types.types"

//	P R O P S

export interface props___SbSettingsContractsPage extends props___page___hasAlert, props___page___hasToast
{

}

//	S T A T E

export interface state___SbSettingsContractsPage {

	selected___contract___tab: '' | type___contract___type | 'help',

	contract___type: type___contract___type,
	contract___text: string,
	contract___text___selection_start: number | null,
	contract___text___selection_end: number | null,

	GUI___page___is_loading___section: boolean,

}

//	S T A T E   D E F A U L T S

export const state___SbSettingsContractsPage___defaults: state___SbSettingsContractsPage = {

	selected___contract___tab: '',

	contract___type: 'sell',
	contract___text: '',
	contract___text___selection_start: null,
	contract___text___selection_end: null,

	GUI___page___is_loading___section: true,

}