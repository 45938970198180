import React from "react";
import { Link } from "react-router-dom";

//	S T Y L E

import "./sb-partners-view.scss";

//	L I B S

import { lib_names } from "../../../libs/lib.names";

//	F U N C S

import { funcs_datetime } from "../../../funcs/funcs.datetime";
import { funcs_numbers } from "../../../funcs/funcs.numbers";

//	T Y P E S

import { type___activity___type, type___lead___interest___to, type___lead___status, type___partner___einvoice___type, type___partner___type } from "../../../types/types.types";
import { type___alert___controller } from "../../../types/types.alerts";
import { type___api___response } from "../../../types/types.api-response";
import { type___toast___controller } from "../../../types/types.toasts";

//	S E R V I C E S

import { service_RestApiService } from "../../../services/service-api";

//	S T A T E S

import { props___SbPartnersViewPage, state___SbPartnersViewPage, state___SbPartnersViewPage___defaults } from "./sb-partners-view.state";

//	C O M P O N E N T S

import ComLicenceInactiveComponent from "../../../components/com-licence-inactive/com-licence-inactive";
import ComResultsEmptyComponent from "../../../components/com-results-empty/com-results-empty";
import ComSpinnerComponent from "../../../components/com-spinner/com-spinner";

//	M O D A L S

import MdDocumentUploaderModal from "../../../modals/md-document-upload/md-document-uploader";

//	C L A S S

export default class SbPartnersViewPage extends React.Component<props___SbPartnersViewPage, state___SbPartnersViewPage>
{

//#region 																							D E C L A R A T I O N S
	
	readonly params: any;

	private readonly _API: service_RestApiService = new service_RestApiService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbPartnersViewPage,
	)
	{
		super(_PROPS);
		this.state = state___SbPartnersViewPage___defaults;
		this.params = this.props.params;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___partner = async () => {
		this.setState({
			GUI___page___is_loading: true,
		}, async () => { 
			const ___partner___found: type___api___response = await this._API.partners___read___single(this.params.id);
			if (___partner___found.response === 'success')
			{
				this.setState({
					partner___props: ___partner___found.data,
					GUI___page___is_loading: false
				});
			}
			else
			{
				const ___alert_props : type___alert___controller = {
					alert___title: "Errore",
					alert___message : <>Impossibile caricare questo cliente/fornitore...</>,
					alert___buttons : [
						{
							alert___button___icon: 'fa-chevron-left',
							alert___button___text: 'Torna Indietro',
							alert___button___action : () => {
								this._PROPS.alert___setShowing(false);
								if (window.history.length > 1) { window.history.back(); }
								else { window.location.href = '/partners/list'; }
							}
						}
					]
				}
				this._PROPS.alert___setAlert(___alert_props);
				this._PROPS.alert___setShowing(true);
			}
		});
	}

	read_props___partner___leads_list = async (___is_update: boolean = true) => {
		this.setState({
			GUI___page___is_loading___section: (___is_update === true) ? true : this.state.GUI___page___is_loading___section, 
			partner___leads___list: (___is_update === true) ? [] : this.state.partner___leads___list
		}, async () => { 
			const ___leads___founds: type___api___response = await this._API.leads___read___multi('partner', this.state.partner___id, this.state.partner___leads___list.length, { param: 'createdAt', direction: 1 });
			this.setState({
				partner___leads___list: ___leads___founds.data,
				GUI___page___is_loading___section: false
			});
		});
	}

	read_props___partner___activities = async (___is_update: boolean = true) => {
		this.setState({
			GUI___page___is_loading___section: (___is_update === true) ? true : this.state.GUI___page___is_loading___section, 
			partner___activities___list: (___is_update === true) ? [] : this.state.partner___activities___list
		}, async () => { 
			const ___activities___founds: type___api___response = await this._API.activities___read___multi('partner', this.state.partner___id, this.state.partner___activities___list.length, 'standard');
			this.setState({
				partner___activities___list: ___activities___founds.data,
				GUI___page___is_loading___section: false
			});
		});
	}

	read_props___partner___sales = async (___is_update: boolean = true) => {
		this.setState({
			GUI___page___is_loading___section: (___is_update === true) ? true : this.state.GUI___page___is_loading___section, 
			partner___sales___list: (___is_update === true) ? [] : this.state.partner___sales___list
		}, async () => { 
			const ___sales___founds: type___api___response = await this._API.vehicles___read___multi('partner', this.state.partner___id, this.state.partner___sales___list.length, { param: 'createdAt', direction: -1 });
			this.setState({
				partner___sales___list: ___sales___founds.data,
				GUI___page___is_loading___section: false
			});
		});
	}

	read_props___partner___documents = async () => {
		const ___documents___founds: type___api___response = await this._API.documents___read___multi('partner', this.state.partner___id);
		this.setState({
			partner___documents: ___documents___founds.data
		});
	}

//#endregion

//#region 																							H A N D L E R S

	handle___select___tab = async (___e: state___SbPartnersViewPage['selected___partner_view___tab']) => {
		this.setState({
			selected___partner_view___tab: ___e,
			partner___leads___list: [],
		}, async () => {
			switch (___e)
			{
				case 'leads':
					this.setState({
						GUI___page___is_loading___section: true,
					}, async () => {
						await this.read_props___partner___leads_list();
					});
					break;
				case 'activities':
					this.setState({
						GUI___page___is_loading___section: true,
					}, async () => {
						await this.read_props___partner___activities();
					});
					break;
				case 'sales':
					this.setState({
						GUI___page___is_loading___section: true,
					}, async () => {
						await this.read_props___partner___sales();
					});
					break;
				case 'documents':
					this.setState({
						partner___documents: [],
					}, async () => {
						await this.read_props___partner___documents();
					})
					break;
			}
		});
	}

	handle___file_upload = async () => {
		this.setState({
			partner___documents___is_uploading: true
		});
	}

	handle___file_upload___done = (___is_done: boolean | null) => {
		switch (___is_done) {
			case true:
				const ___toast_props___true: type___toast___controller = {
					toast___color: 'success',
					toast___text: <>Documento caricato con successo</>
				}
				this._PROPS.toast___setContent(___toast_props___true);
				this._PROPS.toast___setShowing(true);
				break;
			case false:
				const ___toast_props___false: type___toast___controller = {
					toast___color: 'danger',
					toast___text: <>Errore generico nel caricamento di questo documento...</>
				}
				this._PROPS.toast___setContent(___toast_props___false);
				this._PROPS.toast___setShowing(true);
				break;
		}
		this.setState({
			partner___documents___is_uploading: false
		}, async () => {
			await this.read_props___partner___documents()
		});
	}

	handle___documents___delete = (___document: any, ___index: number) => {
		const ___alert_props : type___alert___controller = {
			alert___title: 'Conferma Eliminazione',		
			alert___message : <><>Vuoi davvero <b>ELIMINARE</b> il documento <b>{ ___document['document_name'] }</b>?</><br/><br/><b style={{textDecoration:'underline'}}>AZIONE IRREVERSIBILE</b></>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si, elimina',
					alert___button___action: async () => {
						this._PROPS.alert___setShowing(false);
						const ___removed_vehicle: type___api___response = await this._API.documents___delete(___document['document_id']);
						if (___removed_vehicle['response'] === 'success')
						{
							await this.read_props___partner___documents();
							this._PROPS.alert___setShowing(false);
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Documento eliminato</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore generico nell'eliminazione del documento</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}











	handle___partner___delete = () => {







	}












//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		if (this.params.id)
		{
			this.setState({
				partner___id: this.params.id
			}, async () => {
				await this.read_props___partner();
				await this.handle___select___tab(this.state.selected___partner_view___tab);
			});
		}
	}

//#endregion

//#region 																							R E A D   P R O P S

	render() : React.ReactNode
	{
		return <>
			{(this.state.partner___documents___is_uploading === true) ? <>
				<MdDocumentUploaderModal target="partner" target_param={ this.state.partner___id } event___onDismiss={ this.handle___file_upload___done }/>
			</> : <></>}
			{(this.state.partner___props !== null) ? <>
				<div className="page---container is-full-width">
					<section>
						<div className="is-card partner-view-single-container---top-bar">
							<span>
								{(this.state.partner___props['partner_is_customer'] === true) ? <>Cliente</> : <></>}
								{(this.state.partner___props['partner_is_customer'] === true && this.state.partner___props['partner_is_seller'] === true) ? <> / </> : <></>}
								{(this.state.partner___props['partner_is_seller'] === true) ? <>Fornitore</> : <></>}
							</span>
							<i className="fas fa-angle-right"></i>
							<span>
								{(() => {
									switch (this.state.partner___props['partner_type'] as type___partner___type) {
										case 'b': return <>
											<>{ this.state.partner___props['partner_name_business'] }</>
											<span>-</span>
											<span>{ this.state.partner___props['partner_name'] + ' ' + this.state.partner___props['partner_surname'] }</span>
										</>; break;
										case 'p': return <>
											{ this.state.partner___props['partner_name'] + ' ' + this.state.partner___props['partner_surname'] }
										</>; break;
									}
								})()}
							</span>
						</div>
						<div className="partner-view-single-container---flexy">
							<div>
								<div className="page---top-bar page---top-bar---is-sidebar">
									<section>
										<input type="radio" id="d-ts---csec---registry" name="d-ts---cb" value="registry" checked={this.state.selected___partner_view___tab === 'registry'} onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }} />
										<label htmlFor="d-ts---csec---registry" className="is-card">
											<img src="/assets/ui/icons/icon-documents.svg" />
											<span>Anagrafica Completa</span>
										</label>
										<input type="radio" id="d-ts---csec---leads" name="d-ts---cb" value="leads" checked={this.state.selected___partner_view___tab === 'leads'} onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }} />
										<label htmlFor="d-ts---csec---leads" className="is-card">
											<img src="/assets/ui/icons/icon-lead-new.svg" />
											<span>Archivio Leads</span>
										</label>
										<input type="radio" id="d-ts---csec---activities" name="d-ts---cb" value="activities" checked={this.state.selected___partner_view___tab === 'activities'} onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }} />
										<label htmlFor="d-ts---csec---activities" className="is-card">
											<img src="/assets/ui/icons/icon-activity.svg" />
											<span>Archivio attività</span>
										</label>
										<input type="radio" id="d-ts---csec---sales" name="d-ts---cb" value="sales" checked={this.state.selected___partner_view___tab === 'sales'} onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }} />
										<label htmlFor="d-ts---csec---sales" className="is-card">
											<img src="/assets/ui/icons/icon-car-key.svg" />
											<span>Archivio Acquisti</span>
										</label>
										<input type="radio" id="d-ts---csec---documents" name="d-ts---cb" value="documents" checked={this.state.selected___partner_view___tab === 'documents'} onChange={(___e: any) => { this.handle___select___tab(___e.target.value); }} disabled={ this.props.props___licence['licence_active___cloudDocs'] === false }/>
										<label htmlFor="d-ts---csec---documents" className="is-card">
											<img src="/assets/ui/icons/icon-cloud.svg" />
											<span>Cloud Docs</span>
										</label>
									</section>
									<section>
										<Link to={ '/partners/edit/' + this.state.partner___props['partner_id'] } className="is-card">
											<i className="fas fa-edit"></i>
											<span>Modifica Anagrafica</span>
										</Link>
										<button className="is-card" onClick={() => { this.handle___partner___delete(); }}>
											<i className="fas fa-trash"></i>
											<span>Elimina Partner</span>
										</button>
									</section>
								</div>
							</div>
							{(() => {
								switch (this.state.selected___partner_view___tab) {
									case 'registry': return <>
										<div className="container---table table-partners---registry is-card">
											<div className="container---table---spacer"></div>
											<div>
												<div></div>
												<div>Rapporto</div>
												<div>
													{(this.state.partner___props['partner_is_customer'] === true) ? <>Cliente</> : <></>}
													{(this.state.partner___props['partner_is_customer'] === true && this.state.partner___props['partner_is_seller'] === true) ? <> + </> : <></>}
													{(this.state.partner___props['partner_is_seller'] === true) ? <>Fornitore</> : <></>}
												</div>
											</div>
											<div className="container---table---spacer"></div>
											<div>
												<div></div>
												<div>Tipologia</div>
												<div>
													{(() => {
														switch (this.state.partner___props['partner_type'] as type___partner___type) {
															case 'b': return <>Azienda</>; break;
															case 'p': return <>Persona Fisica</>; break;
														}
													})()}
												</div>
											</div>
											{(this.state.partner___props['partner_type'] === 'b') ? <>
												<div className="container---table---spacer"></div>
												<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Dati Azienda</div><div></div></div>
												<div>
													<div></div>
													<div>Ragione Sociale</div>
													<div>{ this.state.partner___props['partner_name_business'] }</div>
												</div>
												<div>
													<div></div>
													<div>P. Iva</div>
													{(this.state.partner___props['partner_registry_piva']) ? <>
														<div>{ this.state.partner___props['partner_registry_piva'] }</div>
													</> : <>
														<div style={{'opacity':'0.5'}}>{ lib_names.text_editor.placeholders.x }</div>
													</>}
												</div>
												{(this.state.partner___props['partner_address_business']) ? <>
													<div className="container---table---spacer"></div>
													<div>
														<div></div>
														<div>Sede Legale</div>
														<div>{ this.state.partner___props['partner_address_business']['street'] + ' ' + this.state.partner___props['partner_address_business']['street_n'] + ' - ' + this.state.partner___props['partner_address_business']['city'] + ' (' + this.state.partner___props['partner_address_business']['prov'] + ') - ' + this.state.partner___props['partner_address_business']['zip'] + ' - ' + this.state.partner___props['partner_address_business']['country'] }</div>
													</div>
												</> : <></>}
												<div className="container---table---spacer"></div>
												<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Rapp. Legale</div><div></div></div>
												<div>
													<div></div>
													<div>Nome e Cognome (o rap. Legale)</div>
													<div>{ this.state.partner___props['partner_name'] + ' ' + this.state.partner___props['partner_surname'] }</div>
												</div>
												<div>
													<div></div>
													<div>Nascita - Data</div>
													{(this.state.partner___props['partner_born_date']) ? <>
														<div>{ this.funcs___datetime.datetime___get___date(this.state.partner___props['partner_born_date']) }</div>
													</> : <>
														<div style={{'opacity':'0.5'}}>{ lib_names.text_editor.placeholders.x }</div>
													</>}
												</div>
												<div>
													<div></div>
													<div>Nascita - Luogo</div>
													{(this.state.partner___props['partner_born_address'] && this.state.partner___props['partner_born_address']['city'] && this.state.partner___props['partner_born_address']['prov']) ? <>
														<div>{ this.state.partner___props['partner_born_address']['city'] + ' (' + this.state.partner___props['partner_born_address']['prov'] + ')' }</div>
													</> : <>
														<div style={{'opacity':'0.5'}}>{ lib_names.text_editor.placeholders.x }</div>
													</>}
												</div>
												<div className="container---table---spacer"></div>
												<div>
													<div></div>
													<div>Indirizzo Rapp. Legale</div>
													<div>{ this.state.partner___props['partner_address']['street'] + ' ' + this.state.partner___props['partner_address']['street_n'] + ' - ' + this.state.partner___props['partner_address']['city'] + ' (' + this.state.partner___props['partner_address']['prov'] + ') - ' + this.state.partner___props['partner_address']['zip'] + ' - ' + this.state.partner___props['partner_address']['country'] }</div>
												</div>
											</> : <>
												<div>
													<div></div>
													<div>Nome e Cognome (o rap. Legale)</div>
													<div>{ this.state.partner___props['partner_name'] + ' ' + this.state.partner___props['partner_surname'] }</div>
												</div>
												<div>
													<div></div>
													<div>Nascita - Data</div>
													<div>{ this.funcs___datetime.datetime___get___date(this.state.partner___props['partner_born_date']) }</div>
												</div>
												<div>
													<div></div>
													<div>Nascita - Luogo</div>
													<div>{ this.state.partner___props['partner_born_address']['city'] + ' (' + this.state.partner___props['partner_born_address']['prov'] + ')' }</div>
												</div>
												<div className="container---table---spacer"></div>
												<div>
													<div></div>
													<div>Indirizzo</div>
													<div>{ this.state.partner___props['partner_address']['street'] + ' ' + this.state.partner___props['partner_address']['street_n'] + ' - ' + this.state.partner___props['partner_address']['city'] + ' (' + this.state.partner___props['partner_address']['prov'] + ') - ' + this.state.partner___props['partner_address']['zip'] + ' - ' + this.state.partner___props['partner_address']['country'] }</div>
												</div>
											</>}
											<div className="container---table---spacer"></div>
											<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Contatti</div><div></div></div>
											<div>
												<div></div>
												<div>Email</div>
												<div>{ this.state.partner___props['partner_email'] }</div>
											</div>
											<div>
												<div></div>
												<div>Email - PEC</div>
												{(this.state.partner___props['partner_email_pec']) ? <>
													<div>{ this.state.partner___props['partner_email_pec'] }</div>
												</> : <>
													<div style={{'opacity':'0.5'}}>{ lib_names.text_editor.placeholders.x }</div>
												</>}
											</div>
											<div>
												<div></div>
												<div>Telefono</div>
												{(this.state.partner___props['partner_phone']) ? <>
													<div>{ this.state.partner___props['partner_phone'] }</div>
												</> : <>
													<div style={{'opacity':'0.5'}}>{ lib_names.text_editor.placeholders.x }</div>
												</>}
											</div>
											<div>
												<div></div>
												<div>Telefono - Mobile</div>
												{(this.state.partner___props['partner_phone_mobile']) ? <>
													<div>{ this.state.partner___props['partner_phone_mobile'] }</div>
												</> : <>
													<div style={{'opacity':'0.5'}}>{ lib_names.text_editor.placeholders.x }</div>
												</>}
											</div>
											<div>
												<div></div>
												<div>Telefono - FAX</div>
												{(this.state.partner___props['partner_phone_fax']) ? <>
													<div>{ this.state.partner___props['partner_phone_fax'] }</div>
												</> : <>
													<div style={{'opacity':'0.5'}}>{ lib_names.text_editor.placeholders.x }</div>
												</>}
											</div>
											<div className="container---table---spacer"></div>
											<div><div></div><div style={{fontWeight:'bold',textTransform:'uppercase',transform:'translateX(-25pt)'}}>Fatturazione</div><div></div></div>
											<div>
												<div></div>
												<div>Tipologia</div>
												{(this.state.partner___props['partner_einvoice_type']) ? <>
													<div>{ this.state.partner___props['partner_einvoice_type'] }</div>
												</> : <>
													<div>Nessuna</div>
												</>}
											</div>
											<div>
												<div></div>
												<div>Indirizzo Fatturazione</div>
												<div>
													{(() => {
														switch (this.state.partner___props['partner_einvoice_type'] as type___partner___einvoice___type) {
															case 'pec': return <>{ this.state.partner___props['partner_email_pec'] }</>; break;
															case 'sdi': return <>{ this.state.partner___props['partner_einvoice_sdi'] }</>; break;
															default: return <>Nessuno</>; break;
														}
													})()}
												</div>
											</div>
										</div>
									</>; break;
									case 'leads': return <>
										<div className="container---table table-partners---leads is-card">
											<div>
												<div></div>
												<div>
													<span>Data</span>
													<span>•</span>
												</div>
												<div>
													<span>Interesse</span>
													<span>•</span>
												</div>
												<div>
													<span>Stato</span>
													<span>•</span>
												</div>
											</div>
											{(this.state.partner___leads___list.length > 0) ? <>
												{(this.state.GUI___page___is_loading___section === true) ? <>
													<ComSpinnerComponent/>
												</> : <>
													{this.state.partner___leads___list.map((___l: any, ___id: number) => (
														<div>
															<div>
																<Link to={`/leads/view/${___l['lead_id']}`} data-title="Visualizza">
																	<i className="fas fa-eye"></i>
																</Link>
															</div>
															<div>{ this.funcs___datetime.datetime___get___date(___l['lead_created_datetime']) }</div>
															<div>
																{(() => {
																	switch (___l['lead_partner_interest'] as type___lead___interest___to) {
																		case 'brands': return <>
																			<span>Interessato a (brand): </span>
																			{(___l['lead_vehicles_brands'].length > 0) ? <>
																				{ ___l['lead_vehicles_brands'].map((___b: any, ___b_i: number) => {
																					const ___b___separator: string = ((___b_i + 1) < ___l['lead_vehicles_brands'].length) ? ', ': ' ';
																					return <><span>{ ___b['brand_name'] }</span>{ ___b___separator }</>;
																				})}
																			</> : <></> }
																		</>; break;
																		case 'models': return <>
																			<span>Interessato a (modelli): </span>
																			{(___l['lead_vehicles_models'].length > 0) ? <>
																				{ ___l['lead_vehicles_models'].map((___m: any, ___m_i: number) => {
																					const ___m___separator: string = ((___m_i + 1) < ___l['lead_vehicles_models'].length) ? ', ': ' ';
																					return <><span>{ ___m['car_brand'] + ' ' + ___m['car_model'] }</span>{ ___m___separator }</>;
																				})}
																			</> : <></> }
																		</>; break;
																		case 'stock_vehicle': return <>
																			<span>Abbinato a: </span>
																			{(___l['lead_vehicle'] !== null) ? <>
																				<Link to={ '/vehicles/view/' + ___l['lead_vehicle'] } data-title="Visualizza Veicolo">
																					{ ___l['lead_vehicle_name'] }
																				</Link>
																			</> : <>...</>}
																		</>; break;
																		case 'custom': return <>
																			<span>Interessato a: </span>
																			{(___l['lead_vehicle_requested']) ? <>
																				<span>{ ___l['lead_vehicle_requested'] }</span>
																			</> : <></> }
																		</>; break;
																	}
																})()}
															</div>
															<div>
																{(() => {
																	switch (___l['lead_status'] as type___lead___status) {
																		case 'new': return <><b>NUOVO</b></>; break;
																		case 'working': return <><b>IN LAVORAZIONE</b></>; break;
																		case 'ready': return <><b>PRONTO</b></>; break;
																		case 'sold': return <><b>VENDUTO</b></>; break;
																		case 'archived': return <><b>ARCHIVIATO</b></>; break;
																	}
																})()}
															</div>
														</div>
													))}
												</>}
											</> : <>
												<ComResultsEmptyComponent text="nessun Lead trovato"/>
											</>}
										</div>
									</>;break;
									case 'activities': return <>
										<div className="container---table table-partners---activities is-card">
											<div>
												<div></div>
												<div>
													<span>Data</span>
													<span>•</span>
												</div>
												<div>
													<span>Tipo</span>
													<span>•</span>
												</div>
												<div>
													<span>Descrizione</span>
													<span>•</span>
												</div>
											</div>
											{(this.state.partner___activities___list.length > 0) ? <>
												{(this.state.GUI___page___is_loading___section === true) ? <>
													<ComSpinnerComponent/>
												</> : <>
													{this.state.partner___activities___list.map((___a: any, ___id: number) => (
														<div>
															<div>
																<Link to={ '/leads/view/' + ___a['activity_lead'] } data-title="Visualizza">
																	<i className="fas fa-eye"></i>
																</Link>
															</div>
															<div>{ this.funcs___datetime.datetime___get___date(___a['activity_datetime_from']) }</div>
															<div>
																{(() => {
																	switch (___a['activity_type'] as type___activity___type) {
																		case 'call': return <><b>Chiamata</b></>; break;
																		case 'appointment': return <><b>Appuntamento</b></>; break;
																		case 'test_drive': return <><b>Test Drive</b></>; break;
																		case 'lead_status_change': return <><b>Aggiornamento</b></>; break;
																		case 'sale': return <><b>Vendita</b></>; break;
																	}
																})()}
															</div>
															<div>
																{(___a['activity_type'] === 'lead_status_change' || ___a['activity_type'] === 'sale') ? <>
																	<>
																		<Link to={ '/leads/view/' + ___a['activity_lead'] }>Il lead</Link>
																		<> lead è stato modificato in </>
																	</>
																	{(() => {
																		switch (___a['activity_notes'] as type___lead___status) {
																			case 'working': return <> "<b>IN LAVORAZIONE</b>" </>; break;
																			case 'ready': return <> "<b>PRONTO</b>" </>; break;
																			case 'sold': return <> "<b>TRATTATIVA CONCLUSA (VENDUTO)</b>" </>; break;
																			case 'archived': return <> "<b>ARCHIVIATO</b>" </>; break;
																		}
																	})()}
																</> : <>
																	<>{ ___a['activity_notes'] }</>
																</>}
															</div>
														</div>
													))}
												</>}
											</> : <>
												<ComResultsEmptyComponent text="nessun Attività effettuata con questo utente"/>
											</>}
										</div>
									</>;break;
									case 'sales': return <>
										<div className="container---table table-partners---sales is-card">
											<div>
												<div></div>
												<div>
													<i className="fas fa-image" style={{opacity:'1'}}></i>
													<span>•</span>
												</div>
												<div>
													<span>Veicolo</span>
													<span>•</span>
												</div>
												<div>
													<span>Data</span>
													<span>•</span>
												</div>
												<div>
													<span>Prezzo</span>
													<span>•</span>
												</div>
											</div>
											{(this.state.partner___sales___list.length > 0) ? <>
												{(this.state.GUI___page___is_loading___section === true) ? <>
													<ComSpinnerComponent/>
												</> : <>
													{this.state.partner___sales___list.map((___s: any, ___id: number) => (
														<div>
															<div>
																<Link to={ '/vehicles/view/' + ___s['vehicle_id'] } data-title="Visualizza">
																	<i className="fas fa-eye"></i>
																</Link>
																<Link to={ '/leads/view/' + ___s['vehicle_status_sold_lead'] } data-title="Visualizza Lead Abbinato">
																	<i className="fas fa-folder-open"></i>
																</Link>
															</div>
															<div>
																{(___s['vehicle_gallery_pictures'].length > 0) ? <>
																	<img src={ lib_names.cdn.endpoint + ___s['vehicle_gallery_pictures'][0] }/>
																</> : <>
																	<img src={ lib_names.media.placeholders.vehicle.generic }/>
																</>}
															</div>
															<div>{ ___s['vehicle_name'] }</div>
															<div>{ this.funcs___datetime.datetime___get___date(___s['vehicle_status_sold_datetime']) }</div>
															<div>{ this.funcs___numbers.format___price(___s['vehicle_accounting___price_sell'], ' €') }</div>
														</div>
													))}
												</>}
											</> : <>
												<ComResultsEmptyComponent text="ancora nessun acquisto da parte di questo utente"/>
											</>}
										</div>
									</>;break;
									case 'documents': return <>
										<div className="is-card">
											{(() => {
												switch (this.props.props___licence['licence_active___cloudDocs']) {
													case true: return <>
														<br />
														<div className="page-container---bottom-bar---buttons is-centered">
															<button type="button" className="is-card is-standard no-shadow" onClick={ this.handle___file_upload }>
																<i className="fas fa-upload"></i>
																<span className="is-full-width">Carica un documento nel cloud</span>
															</button>
														</div>
														<br />
														{(this.state.partner___documents.length > 0) ? <>
															<div className="container---table table-partners---documents">
																<div>
																	<div></div>
																	<div>
																		<span>Data e Ora</span>
																		<span>•</span>
																	</div>
																	<div>
																		<span>Titolo</span>
																		<span>•</span>
																	</div>
																	<div>
																		<span>Dimensione</span>
																		<span>•</span>
																	</div>
																	<div>
																		<span>Tipo</span>
																		<span>•</span>
																	</div>
																</div>
																{this.state.partner___documents.map((___d: any, ___d_i: number) => (
																	<div key={ ___d_i }>
																		<div>
																			<Link target="_blank" to={ lib_names.cdn.endpoint + ___d['document_reference'] } data-title="Visualizza">
																				<i className="fas fa-eye"></i>
																			</Link>
																			<i className="fas fa-trash" onClick={()=>{ this.handle___documents___delete(___d, ___d_i); }} data-title="Elimina"></i>
																		</div>
																		<div>{ this.funcs___datetime.datetime___get___date(___d['document_createdAt']) + ' alle ' + this.funcs___datetime.datetime___get___time(___d['document_createdAt']) }</div>
																		<div><b>{ ___d['document_name'] }</b></div>
																		<div>{ this.funcs___numbers.format___bytesize(___d['document_size']) } </div>
																		<div>File <span style={{textTransform:'uppercase',fontWeight:'bold'}}>{ ___d['document_mime'] }</span></div>
																	</div>
																))}
															</div>
														</> : <>
															<ComResultsEmptyComponent text="nessun documento per questo cliente/fornitore"/>
														</>}
													</>; break;
													case false: return <>
														<ComLicenceInactiveComponent licence_needed="cloud_docs"/>
													</>; break;
													case null: return <>
														<ComSpinnerComponent/>
													</>; break;
												}
											})()}
										</div>
									</>;break;
								}
							})()}
						</div>
					</section>
				</div>
			</> : <><br /><br /><br /><ComSpinnerComponent/></>
			}
		</>;
	}

//#endregion

}