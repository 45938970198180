import React from 'react';

//	S T Y L E

import './sb-settings-profile.scss';

//	T Y P E S

import { type___toast___controller } from '../../../types/types.toasts';

//	S T A T E

import { props___SbSettingsProfilePage, state___SbSettingsProfilePage, state___SbSettingsProfilePage___defaults } from './sb-settings-profile.state';

//	S E R V I C E S

import { service_RestApiService } from '../../../services/service-api';

//	C O M P O N E N T S

import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import { type___api___response } from '../../../types/types.api-response';

//	C L A S S

export default class SbSettingsProfilePage extends React.Component<props___SbSettingsProfilePage, state___SbSettingsProfilePage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbSettingsProfilePage
	) {
		super(_PROPS);
		this.state = state___SbSettingsProfilePage___defaults;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___me = async () => {
		const ___me___props: type___api___response = await this._API.users___read___single('me');
		this.setState({
			me___props: ___me___props.data,
		});
	}

//#endregion

//#region 																							S U B M I T

	submit___user___password_change = async () => {
		const ___password_change___result: type___api___response = await this._API.users___edit___password(this.state.field___me___password___old, this.state.field___me___password___new);
		if (___password_change___result['response'] === 'success')
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'success',
				toast___text: <>Password aggiornata</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
			this.handle___select___tab('overview');
		}
		else
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'danger',
				toast___text: <>Errore nell'aggiornamento della password...</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}
	}

//#endregion

//#region 																							H A N D L E R S

	handle___select___tab = (___e: state___SbSettingsProfilePage['selected___tab']) => {
		this.setState({ 
			selected___tab: ___e
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{
		this.setState({
			GUI___page___is_loading: true,
		}, async () => {
			await this.read_props___me();
		});
	}

//#endregion 

//#region 																							R E N D E R

	render(): React.ReactNode
	{
		return <>
			{(this.state.me___props !== null) ? <>

				<div className="page---container is-full-width is-single-section">
					<section>
					
						<div className="page---top-bar">
							<section>
								<input type="radio" id="d-s-p---cb---overview" name="d-s-p---cb" value="overview" checked={this.state.selected___tab === 'overview'} onChange={(___e: any) => { this.handle___select___tab(___e['target']['value'] as state___SbSettingsProfilePage['selected___tab']); }} />
								<label htmlFor="d-s-p---cb---overview" className="is-card">
									<img src="/assets/ui/icons/icon-overview.svg" />
									<span>Panoramica</span>
								</label>
								<input type="radio" id="d-s-p---cb---access" name="d-s-p---cb" value="access" checked={this.state.selected___tab === 'access'} onChange={(___e: any) => { this.handle___select___tab(___e['target']['value'] as state___SbSettingsProfilePage['selected___tab']); }} />
								<label htmlFor="d-s-p---cb---access" className="is-card">
									<img src="/assets/ui/icons/icon-lock.svg" />
									<span>Accesso</span>
								</label>
							</section>
							<section></section>
						</div>

						<div>
							{(() => {
								switch (this.state.selected___tab as state___SbSettingsProfilePage['selected___tab']) {
									case 'overview': return <>
										<div className="is-card user-profile---overview---container">
											<div>
												<section>
													<span className="input---nb-label">avatar</span>
													<img src={ 'https://ui-avatars.com/api/?name=' + this.state.me___props['user_name']  + '' + this.state.me___props['user_surname']}/>
												</section>
											</div>
											<div>
												<h2 className="input---nb-title">Dati</h2>
												<span className="input---nb-label">Username</span>
												<section className="input---nb-outlined---flexy">
													<input type="text" value={ this.state.me___props['user_username'] } disabled={true}/>
												</section>
												{(this.state.me___props['user_role'] !== 'support') ? <>
													<span className="input---nb-label">Nome e Cognome</span>
													<section className="input---nb-outlined---flexy">
														<input type="text" value={ this.state.me___props['user_name'] } disabled={true}/>
														<input type="text" value={ this.state.me___props['user_surname'] } disabled={true}/>
													</section>
												</> : <>
													<span className="input---nb-label">Nome Account</span>
													<section className="input---nb-outlined---flexy">
														<input type="text" value={ this.state.me___props['user_name'] } disabled={true}/>
													</section>
												</>}
												{(this.state.me___props['user_role'] !== 'support') ? <>
													<span className="input---nb-label">Codice Fiscale</span>
													<section className="input---nb-outlined---flexy">
														<input type="text" placeholder="XXX XXX 00X00 X000X" value={ this.state.me___props['dealer_registry_cf'] } disabled={true}/>
													</section>
													<br />
													<h2 className="input---nb-title">Indirizzo</h2>
													<section className="input---nb-outlined---flexy">
														<input type="text" placeholder="via" value={ this.state.me___props['user_address']['street'] }/>
														<input type="text" placeholder="n°" value={ this.state.me___props['user_address']['street_n']}/>
													</section>
													<section className="input---nb-outlined---flexy" style={{paddingTop:'10pt'}}>
														<input type="text" placeholder="città (prov)" value={ this.state.me___props['user_address']['city'] + ' (' + this.state.me___props['user_address']['prov'] + ')' }/>
														<input type="text" placeholder="cap/zip" value={ this.state.me___props['user_address']['zip']}/>
														<input type="text" placeholder="stato" value={ this.state.me___props['user_address']['country']}/>
													</section>
												</> : <></>}
												<br />
												<h2 className="input---nb-title">Contatti</h2>
												<span className="input---nb-label">Telefono</span>
												<section className="input---nb-outlined---flexy">
													<input type="text" placeholder="+39 333 333 3334" value={ this.state.me___props['user_contact_phone'] } disabled={true}/>
												</section>
												<span className="input---nb-label">Email</span>
												<section className="input---nb-outlined---flexy">
													<input type="text" placeholder="email@email.com" value={ this.state.me___props['user_contact_mail'] } disabled={true}/>
												</section>
												<br />
											</div>
										</div>
									</>; break;
									case 'access': return <>
										<div className="is-card user-profile---access---container">
											<section>
												<br />
												<br />
												<br />
												<h2 className="input---nb-title">Modifica password</h2>
												<span className="input---nb-label">Vecchia Password</span>
												<section className="input---nb-outlined---flexy">
													<input type="password" value={ this.state.field___me___password___old } placeholder="vecchia password" onInput={(___e: any) => { this.setState({ field___me___password___old: ___e.target.value })}}/>
												</section>
												<br />
												<span className="input---nb-label">Nuova Password</span>
												<section className="input---nb-outlined---flexy">
													<input type="password" value={ this.state.field___me___password___new } placeholder="nuova password" onInput={(___e: any) => { this.setState({ field___me___password___new: ___e.target.value })}}/>
												</section>
												<span className="input---nb-label">Ripeti Nuova Password</span>
												<section className="input---nb-outlined---flexy">
													<input type="password" value={ this.state.field___me___password___new_repeat } placeholder="nuova password" onInput={(___e: any) => { this.setState({ field___me___password___new_repeat: ___e.target.value })}}/>
												</section>
												<br />
												<br />
												<br />
												<div className="page-container---bottom-bar---buttons is-centered">
													{(this.state.field___me___password___old.length > 0 && this.state.field___me___password___new.length > 0) ? <>
														{(this.state.field___me___password___new === this.state.field___me___password___new_repeat) ? <>
															<button type="button" className="is-card is-submit" onClick={() => { this.submit___user___password_change(); }}>
																<i className="fas fa-check"></i>
																<span>Salva Password</span>
															</button>
														</> : <>
															<button type="button" className="is-card is-disabled">
																<i className="fas fa-check"></i>
																<span>Le password devono combaciare</span>
															</button>
														</>}
													</> : <>
														<button type="button" className="is-card is-disabled">
															<i className="fas fa-check"></i>
															<span>Inserisci vecchia e nuova password</span>
														</button>
													</>}
												</div>
												<br />
												<br />
												<br />
											</section>
										</div>
									</>; break;
								}
							})()}
						</div>

					</section>
				</div>
			</> : <><ComSpinnerComponent/></>}
		</>;
	}

//#endregion

}