import React from "react";

//	T Y P E S

import { type___alert___controller } from "../../../types/types.alerts";
import { type___api___response } from "../../../types/types.api-response";
import { type___toast___controller } from "../../../types/types.toasts";

//	S T Y L E

import "./sb-leads-edit.scss"

//	S E R V I C E S

import { service_RestApiService } from "../../../services/service-api";

//	S T A T E S

import { props___SbLeadsEditPage, state___SbLeadsEditPage, state___SbLeadsEditPage___default } from "./sb-leads-edit.state";

//	C O M P O N E N T S

import ComResultsEmptyComponent from "../../../components/com-results-empty/com-results-empty";
import ComPartnerComponent from "../../../components/com-partner/com-partner";
import ComVehicleComponent from "../../../components/com-vehicle/com-vehicle";
import ComVehicleSmallComponent from "../../../components/com-vehicle-small/com-vehicle-small";

//	C L A S S

export default class SbLeadsEditPage extends React.Component<props___SbLeadsEditPage, state___SbLeadsEditPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _API: service_RestApiService = new service_RestApiService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public _PROPS: props___SbLeadsEditPage,
	)
	{
		super(_PROPS);
		this.state = state___SbLeadsEditPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	read_props___partners = async (___search_param: string) => {
		if (___search_param.length > 0)
		{
			const ___partners___founds: type___api___response = await this._API.partners___read___multi('all', ___search_param, 0, { param: 'partner_name', direction: 1 });
			this.setState({
				search___partners___results: ___partners___founds.data
			});
		}
	}

	read_props___partners_single = async () => {
		if (this.state.field___partner___binding_id !== null)
		{
			const ___partner___single: type___api___response = await this._API.partners___read___single(this.state.field___partner___binding_id);
			this.setState({
				field___partner___binding_id: ___partner___single.data['partner_id'],
				field___partner___binding_props: ___partner___single.data,
			});
		}
	}

	read_props___vehicles_brands = async () => {
		this.setState({
			search___vehicles_brands___results: [],
		}, async () => { 
			if (this.state.search___vehicles___param.length > 0)
			{
				const ___vehicles_brands___found: type___api___response = await this._API.vehicles_brands___read___multi('all', this.state.search___vehicles___param);
				this.setState({
					search___vehicles_brands___results: ___vehicles_brands___found.data
				});
			}
		});
	}

	read_props___vehicles_models = async () => {
		this.setState({
			search___vehicles_models___results: [],
		}, async () => { 
			if (this.state.search___vehicles___param.length > 0)
			{
				const ___skip_trims: number[] | string[] = this.state.field___lead___interest___models.map((___m: any) => ___m['_id_trim']);
				const ___vehicles_models___found: any[] = await this._API.vehicles_models___read___multi___search(this.state.field___lead___interest___year_from ?? 0, this.state.field___lead___interest___year_to ?? 9999, this.state.search___vehicles___param, ___skip_trims);
				this.setState({
					search___vehicles_models___results: ___vehicles_models___found
				});
			}
		});
	}


























	

	read_props___vehicles = async () => {
		this.setState({
			search___vehicles___results: [],
		}, async () => { 
			if (this.state.search___vehicles___param.length > 0)
			{
				const ___vehicles___found: type___api___response = await this._API.vehicles___read___multi('lead_search', this.state.search___vehicles___param, 0, { param: 'createdAt', direction: -1 });
				this.setState({
					search___vehicles___results: ___vehicles___found.data
				});
			}
		});
	}

	read_props___partners_suggested = async (___search_target: 'name_business' | 'name' | 'surname' | 'email' | 'phone', ___search_param: string) => {
		if (this.state.field___partner___binding_id === null)
		{
			if (___search_param.length > 0)
			{
				const ___partner_suggestions___target: string = 'search:' + ___search_target as string;
				const ___partner_suggestions___results: type___api___response = await this._API.partners___read___multi(___partner_suggestions___target, ___search_param, 0, { param: 'partner_name', direction: 1 });
				this.setState({
					search___partners___results: ___partner_suggestions___results.data
				});
			}
			else
			{
				this.setState({
					search___partners___results: []
				});
			}
		}	
	}

//#endregion

//#region 																							H A N D L E R S

	handle___partner___create___select_type = (___e: any) => {
		this.setState({
			field___new_partner___type: ___e.target.value
		}, () => {
			this.submit___lead___check_if_can();
		});
	}

	handle___partner___create = (___create: boolean) => {
		this.setState({
			GUI___user___is_creating: ___create
		}, () => {
			this.submit___lead___check_if_can();
		});
	}

	handle___partner___search = (___e: any) => {
		this.setState({
			field___partner___search_param: ___e.target.value
		}, async () => {
			await this.read_props___partners(this.state.field___partner___search_param);
			this.submit___lead___check_if_can();
		});
	}

	handle___partner___select = (___e: any) => {
		this.handle___partner___create(false);
		this.setState({
			field___partner___binding_id: ___e
		}, async () => {
			await this.read_props___partners_single();
			this.submit___lead___check_if_can();
		});
	}

	handle___partner___reset = () => {
		this.setState({
			field___partner___binding_id: null,
			field___partner___binding_props: null,
		});
	}

	handle___partner___select_marketing_source = (___e: any) => {
		this.setState({
			field___lead___marketing___source: ___e.target.value
		}, () => {
			this.submit___lead___check_if_can();
		});
	}

	handle___lead___interest___select = (___e: any) => {
		this.setState({
			field___lead___interest___to: ___e.target.value,
			field___lead___interest___to___custom: '',
			search___vehicles___param: '',
			search___vehicles___results: [],
			search___vehicles_brands___results: [],
			search___vehicles_models___results: [],
			field___lead___interest___models: [],
			field___lead___interest___brands: [],
		}, () => {
			this.submit___lead___check_if_can();
		});
	}

	handle___lead___interest___search_objects = async () => {
		switch (this.state.field___lead___interest___to)
		{
			case 'brands': await this.read_props___vehicles_brands(); break;
			case 'models': await this.read_props___vehicles_models(); break;
			case 'stock_vehicle': await this.read_props___vehicles(); break;
		}
	}

	handle___lead___vehicle_brand___add = (___e: any) => {
		this.setState({ 
			field___lead___interest___brands: [...this.state.field___lead___interest___brands, ___e]
		}, async () => {
			await this.read_props___vehicles_brands();
			this.submit___lead___check_if_can();
		});
	}

	handle___lead___vehicle_brand___remove = (___e: string) => {
		this.setState(prevState => ({
			field___lead___interest___brands: prevState.field___lead___interest___brands.filter((___b: any) => ___b['vehicle_brand_id'] !== ___e )
		}), async () => {
			await this.read_props___vehicles_brands();
			this.submit___lead___check_if_can();
		});
	}

//	handle___lead___vehicle_model___add = (___e: any) => {
//		this.setState({
//			field___lead___interest___models: [...this.state.field___lead___interest___models, ___e]
//		}, async () => {
//			await this.read_props___vehicles_models();
//		});
//	}

//	handle___lead___vehicle_model___remove = (___e: any) => {
//		this.setState(prevState => ({
//			field___lead___interest___models: prevState.field___lead___interest___models.filter((___b: any) => ___b['vehicle_model'] !== ___e)
//		}), async () => {
//			await this.read_props___vehicles_models();
//		});
//	}
	
	handle___lead___vehicle___select = async (___to_match: string | null) => {
		if (___to_match === null)
		{
			this.setState({
				field___lead___interest___to___custom: '',
				field___lead___interest___to___custom___vehicle_props: null,
			}, () => {
				this.submit___lead___check_if_can();
			});
		}
		else
		{
			const ___vehicle_props___found: type___api___response = await this._API.vehicles___read___single(___to_match);
			if (___vehicle_props___found.response === 'success')
			{
				this.setState({
					field___lead___interest___to___custom: ___vehicle_props___found.data['vehicle_id'],
					field___lead___interest___to___custom___vehicle_props: ___vehicle_props___found.data,
				}, () => {
					this.submit___lead___check_if_can();
				});
			}
			else
			{
				const ___toast_props: type___toast___controller = {
					toast___color: 'danger',
					toast___text: <>Impossibile abbinare questo veicolo</>
				}
				this._PROPS.toast___setContent(___toast_props);
				this._PROPS.toast___setShowing(true);
			}
		}
		this.submit___lead___check_if_can();
	}

	handle___lead___exchange___select = (___e: any) => {
		this.setState({
			field___lead___exchange: ___e.target.value,
			field___lead___exchange___item: '',
			field___lead___exchange___price: null
		}, () => {
			this.submit___lead___check_if_can();
		});
	}

//#endregion

//#region 																							S U B M I T

	submit___partner___for_lead = async () => {
		const ___new_user___submitted: type___api___response = await this._API.leads___submit___new_partner(this.state.field___new_partner___type, this.state.field___new_partner___name_business, this.state.field___new_partner___name, this.state.field___new_partner___surname, this.state.field___new_partner___contact_phone, this.state.field___new_partner___contact_email);
		if (___new_user___submitted.response === 'success')
		{
			this.handle___partner___select(___new_user___submitted.data);
			const ___toast_props: type___toast___controller = {
				toast___color: 'success',
				toast___text: <>Cliente creato con successo e selezionato</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
			this.setState({
				GUI___user___is_creating: false
			})
		}
		else
		{
			const ___toast_props: type___toast___controller = {
				toast___color: 'success',
				toast___text: <>Impossibile creare il cliente....</>
			}
			this._PROPS.toast___setContent(___toast_props);
			this._PROPS.toast___setShowing(true);
		}
	}

	submit___lead___check_if_can = () : boolean => {

		const ___can_submit___binding_id: boolean = this.state.field___partner___binding_id !== null;
		const ___can_submit___interest___brands: boolean = (this.state.field___lead___interest___to === 'brands' && this.state.field___lead___interest___brands.length > 0);
		const ___can_submit___interest___models: boolean = (this.state.field___lead___interest___to === 'models' && this.state.field___lead___interest___models.length > 0);
		const ___can_submit___interest___custom: boolean = (this.state.field___lead___interest___to === 'custom' && this.state.field___lead___interest___to___custom.length > 0);
		const ___can_submit___interest___stock_vehicle: boolean = (this.state.field___lead___interest___to === 'stock_vehicle' && this.state.field___lead___interest___to___custom !== '');

		const ___new_state___lead___can_submit: boolean = ___can_submit___binding_id && (___can_submit___interest___brands || ___can_submit___interest___models || ___can_submit___interest___custom || ___can_submit___interest___stock_vehicle);
	
		this.setState({
			lead___can_submit: ___new_state___lead___can_submit
		});

		return ___new_state___lead___can_submit 

	}

	submit___lead = async () => {

		const ___lead___to_submit___years: [number|null, number|null] = [this.state.field___lead___interest___year_from ?? null, this.state.field___lead___interest___year_to ?? null];
		const ___lead___to_submit___prices: [number|null, number|null] = [this.state.field___lead___interest___price_from ?? null, this.state.field___lead___interest___price_to ?? null];
		const ___alert_props : type___alert___controller = {
			alert___title: "Attenzione",
			alert___message : <>Vuoi creare questo nuovo Lead?</>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si',
					alert___button___action: async () => {
						const ___lead___to_submit: type___api___response = await this._API.leads___submit(null, this.state.field___partner___binding_id!, this.state.field___lead___interest___to, ___lead___to_submit___years, ___lead___to_submit___prices, this.state.field___lead___interest___to___custom, this.state.field___lead___interest___brands, this.state.field___lead___interest___models, this.state.field___lead___interest___notes, this.state.field___lead___exchange, this.state.field___lead___exchange, this.state.field___lead___exchange___price, this.state.field___lead___marketing___source, this.state.field___lead___marketing___source___additional, this.state.field___lead___marketing___source___notes);
						if (___lead___to_submit['response'] === 'success')
						{
							const ___lead___to_submit___url: string = '/leads/view/' + ___lead___to_submit.data['id'];
							window.location.href = ___lead___to_submit___url;
							this._PROPS.alert___setShowing(false);
							const ___toast_props: type___toast___controller = {
								toast___color: 'success',
								toast___text: <>Hai salvato il lead</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
						else
						{
							const ___toast_props: type___toast___controller = {
								toast___color: 'danger',
								toast___text: <>Errore generico nel salvataggio del Lead</>
							}
							this._PROPS.toast___setContent(___toast_props);
							this._PROPS.toast___setShowing(true);
						}
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	
	}

	submit___lead___reset = () => {

		const ___alert_props : type___alert___controller = {
			alert___title: "Attenzione",
			alert___message : <>Vuoi resettare tutti i campi?</>,
			alert___buttons : [
				{
					alert___button___icon: 'fa-times',
					alert___button___text: 'Annulla',
					alert___button___action : () => {
						this._PROPS.alert___setShowing(false);
					}
				},
				{
					alert___button___icon: 'fa-check',
					alert___button___text: 'Si',
					alert___button___action: async () => {
						this._PROPS.alert___setShowing(false);
						this.setState(state___SbLeadsEditPage___default);
					}
				}
			]
		}
		this._PROPS.alert___setAlert(___alert_props);
		this._PROPS.alert___setShowing(true);
	}

//#endregion

//#region 																							L I F E C Y C L E

	async componentDidMount() : Promise<void>
	{

	}

//#endregion

//#region 																							R E A D   P R O P S

	render() : React.ReactNode
	{
		return <>
			<div className="page---container is-full-width is-flexy edit-leads---container">
				<section>

					<div className="is-card">
						{(this.state.GUI___user___is_creating === true) ? <>
							<h3 className="is-title has-icon"><span>Crea nuovo cliente</span></h3>
							<br />
							<span className="input---nb-label">tipologia cliente</span>
							<section className="input---nb-outlined---flexy">
								<select value={ this.state.field___new_partner___type } onChange={ this.handle___partner___create___select_type }>
									<option value={'p'}>Privato (Persona Fisica)</option>
									<option value={'b'}>Azienda</option>
								</select>
							</section>
							{(this.state.field___new_partner___type === 'b') ? <>
								<span className="input---nb-label">ragione sociale</span>
								<section className="input---nb-outlined---flexy">
									<input type="text" value={ this.state.field___new_partner___name_business } className={ 'input---nb-outlined' } placeholder="Ragione sociale" onInput={(___e: any) => { this.setState({ field___new_partner___name_business: ___e.target.value }, async () => { await this.read_props___partners_suggested('name_business', this.state.field___new_partner___name_business); });}}/>
								</section>
							</> : <></>}
							<span className="input---nb-label">nome e cognome</span>
							<section className="input---nb-outlined---flexy">
								<input type="text" value={ this.state.field___new_partner___name } className={ 'input---nb-outlined' } placeholder="nome" onInput={(___e: any) => { this.setState({ field___new_partner___name: ___e.target.value }, async () => { await this.read_props___partners_suggested('name', this.state.field___new_partner___name); });}}/>
							</section>
							<section className="input---nb-outlined---flexy" style={{paddingTop:'10pt'}}>
								<input type="text" value={ this.state.field___new_partner___surname } className={ 'input---nb-outlined' } placeholder="cognome" onInput={(___e: any) => { this.setState({ field___new_partner___surname: ___e.target.value }, async () => { await this.read_props___partners_suggested('surname', this.state.field___new_partner___surname); });}}/>
							</section>
							<span className="input---nb-label">telefono</span>
							<section className="input---nb-outlined---flexy">
								<input type="text" value={ this.state.field___new_partner___contact_phone } className={ 'input---nb-outlined' } placeholder="+39 333 333 3334" onInput={(___e: any) => { this.setState({ field___new_partner___contact_phone: ___e.target.value }, async () => { await this.read_props___partners_suggested('phone', this.state.field___new_partner___contact_phone); }); }}/>
							</section>
							<span className="input---nb-label">email</span>
							<section className="input---nb-outlined---flexy">
								<input type="text" value={ this.state.field___new_partner___contact_email } className={ 'input---nb-outlined' } placeholder="email@email.it" onInput={(___e: any) => { this.setState({ field___new_partner___contact_email: ___e.target.value }, async () => { await this.read_props___partners_suggested('email', this.state.field___new_partner___contact_email); });}}/>
							</section>
							<br />
							<br />
							<div className="page-container---bottom-bar---buttons">
								<button  className="is-card no-shadow is-cancel" onClick={() => { this.handle___partner___create(false); }}>
									<i className="fas fa-times"></i>
									<span>Annulla</span>
								</button>
								{(this.state.field___new_partner___name.length > 0 && this.state.field___new_partner___surname.length > 0) ? <>
									<button  className="is-card no-shadow is-submit" onClick={ this.submit___partner___for_lead }>
										<i className="fas fa-check"></i>
										<span>Crea</span>
									</button>
								</> : <>
									<button  className="is-card no-shadow is-disabled">
										<i className="fas fa-check"></i>
										<span>Compila i campi</span>
									</button>
								</>}
							</div>
							{(this.state.search___partners___results.length > 0) ? <>
								<h3 className="is-title has-icon">
									<span>Suggerimento Cliente</span>
									<i className="fas fa-info-circle"></i>
								</h3>
								<section className="is-content">
									{this.state.search___partners___results.map((___p: any, ___p_i: number) => { return <>
										<section onClick={() => { this.handle___partner___select(___p['partner_id']); }} style={{marginBottom:'15pt'}}>
											<ComPartnerComponent key={ ___p_i } partner___props={ ___p } />
										</section>
									</>})}
								</section>
							</> : <></>}
						</> : <>
							{(this.state.field___partner___binding_id !== null && this.state.field___partner___binding_props !== null) ? <>
								<h3 className="is-title has-icon">
									<span>Cliente Selezionato</span>
									<i className="fas fa-link"></i>
								</h3>
								<br />
								<section style={{pointerEvents:'none'}}>
									<ComPartnerComponent partner___props={ this.state.field___partner___binding_props }/>
								</section>
								<br />
								<div className="page-container---bottom-bar---buttons">
									<button type="button" className="is-card no-shadow" onClick={ this.handle___partner___reset }>
										<i className="fas fa-undo-alt"></i>
										<span>Reset</span>
									</button>
								</div>
								<br />
								<section className="is-content">
									<span className="input---nb-label">provenienza cliente</span>
									<section className="input---nb-outlined---flexy">
										<select value={ this.state.field___lead___marketing___source } onChange={ this.handle___partner___select_marketing_source }>
											<option value={''} disabled={true}>Seleziona provenienza del Lead</option>
											<option value={'partner_already'}>Già Cliente</option>
											<option value={'partner_wordofmouth'}>Passa Parola</option>
											<option value={'external_marketplace_autoscout24'}>AutoScout24</option>
											<option value={'external_marketplace_subitoit'}>Subito.it</option>
											<option value={'external_paper'}>Volantini o simili</option>
											<option value={'external_social_facebook'}>Facebook</option>
											<option value={'external_social_instagram'}>Instagram</option>
											<option value={'external_website'}>Sito Web</option>
											<option value={'other'}>Altro</option>
										</select>
									</section>
									<section className="input---nb-outlined---flexy" style={{paddingTop:'10pt'}}>
										<input type="text" value={ this.state.field___lead___marketing___source___additional } placeholder="informazioni provenienza" onInput={(___e: any) =>{ this.setState({ field___lead___marketing___source___additional:___e.target.value }); this.submit___lead___check_if_can(); }}/>
									</section>
									<br />
									<span className="input---nb-label">Note sull'arrivo del cliente</span>
									<textarea value={ this.state.field___lead___marketing___source___notes } onInput={(___e: any)=>{this.setState({field___lead___marketing___source___notes:___e.target.value},()=>{this.submit___lead___check_if_can()})}} className={'input---nb-outlined is-textarea'} style={{height:'100pt'}} placeholder="Informazioni aggiuntive o note sull'arrivo del cliente..."></textarea>
									<br />
									<br />
									<br />
								</section>
							</> : <>
								<h3 className="is-title">Ricerca cliente o aggiungi nuovo</h3>
								<section className="is-content">
									<section className="input---nb-outlined---flexy">
										<input type="text" value={ this.state.field___partner___search_param } placeholder="parametro di ricerca..." onInput={ this.handle___partner___search }/>
										<i className="fas fa-search"></i>
									</section>
								</section>
								<p className="input---nb-description">cerca un cliente per ragione sociale, nome, cognome, email, p.iva o codice fiscale</p>
								<br />
								<div className="page-container---bottom-bar---buttons">
									<button  className="is-card is-hovered no-shadow" style={{width:'100%',justifyContent:'center'}} onClick={() => { this.handle___partner___create(true); }}>
										<i className="fas fa-plus"></i>
										<span>crea nuovo cliente</span>
									</button>
								</div>
								<br />
								{(this.state.field___partner___search_param.length > 0) ? <>
									<h3 className="is-title">Risultati ricerca</h3>
									<section className="is-content">
										{(this.state.search___partners___results.length > 0) ? <> {
											this.state.search___partners___results.map((___p: any, ___p_i: number) => { return <>
												<section onClick={() => { this.handle___partner___select(___p['partner_id']); }} style={{marginBottom:'15pt'}}>
													<ComPartnerComponent key={ ___p_i } partner___props={ ___p } />
												</section>
											</>})}
										</> : <>
											<ComResultsEmptyComponent text="nessun risultato"/>
										</>}
									</section>
								</> : <></>}
							</>}
						</> }
					</div>

					<div>
						<div style={{display:'none'}}></div>
						<div className="is-card">
							<h3 className="is-title">Interessi</h3>
							<section className="is-content">
								<span className="input---nb-label">interesse dimostrato</span>
								<section className="input---nb-outlined---flexy">
									<select value={ this.state.field___lead___interest___to } onChange={ this.handle___lead___interest___select }>
										<option value={'custom'}>Richiesta Specifica</option>
										<option value={'brands'}>Verso Brand Specifici</option>
									{/*	<option value={'models'}>Verso Modelli Specifici</option>	*/}
										<option value={'stock_vehicle'}>Verso un veicolo presente in Stock</option>
									</select>
								</section>
								{(this.state.field___lead___interest___to !== 'custom' && !(this.state.field___lead___interest___to === 'stock_vehicle' && this.state.field___lead___interest___to___custom !== '')) ? <>
									<section className="input---nb-outlined---flexy" style={{paddingTop:'10pt'}}>
										<p>ricerca</p>
										<input type="text" value={ this.state.search___vehicles___param } placeholder="Ricerca brand di interesse..." onInput={(___e: any)=>{this.setState({ search___vehicles___param: ___e.target.value }, async() => { await this.handle___lead___interest___search_objects(); });}}/>
										<i className="fas fa-times" onClick={() => { this.setState({ search___vehicles___param: '' }, async () => { await this.handle___lead___interest___search_objects(); })}}></i>
									</section>
									<br />
								</> : <>
									<br />
								</>}
								{(() => {
									switch (this.state.field___lead___interest___to) {
										case 'brands': return <>
											{(this.state.search___vehicles_brands___results.length > 0) ? <>
												<section className="brand-model---results">
													{this.state.search___vehicles_brands___results.map((___r_b: any) => 
														<div onClick={() => { this.handle___lead___vehicle_brand___add({'vehicle_brand_name': ___r_b['vehicle_brand_name'], 'vehicle_brand_id': ___r_b['vehicle_brand_id']}); }}>
															<span>{ ___r_b['vehicle_brand_name'] }</span>
															<i className="fas fa-plus"></i>
														</div>
													)}
												</section>
											</> : <></>}
											{(this.state.field___lead___interest___brands.length > 0) ? <>
												<span className="input---nb-label">brand di interesse</span>
												<section className="brand-model---results" style={{marginBottom:'10pt',fontWeight:'bold'}}>
													{this.state.field___lead___interest___brands.map((___i_b: any) => 
														<div onClick={() => { this.handle___lead___vehicle_brand___remove(___i_b['vehicle_brand_id']); }}>
															<span>{ ___i_b['vehicle_brand_name'] }</span>
															<i className="fas fa-times"></i>
														</div>
													)}
												</section>
											</> : <></>}
											<br />
											</>; break;
									//	case 'models': return <>
									//		{(this.state.search___vehicles_models___results.length > 0) ? <>
									//			<section className="brand-model---results">
									//				{this.state.search___vehicles_models___results.map((___r_m: any) => 
									//					<div onClick={() => { this.handle___lead___vehicle_model___add({ 'vehicle_brand': ___r_m['vehicle_brand'], 'vehicle_model': ___r_m['vehicle_model']}); }}>
									//						<span><span>{ ___r_m['vehicle_brand'] }</span> { ___r_m['vehicle_model'] }</span>
									//						<i className="fas fa-plus"></i>
									//					</div>
									//				)}
									//			</section>
									//		</> : <></>}
									//		{(this.state.field___lead___interest___models.length > 0) ? <>
									//			<span className="input---nb-label">modelli di interesse</span>
									//			<section className="brand-model---results" style={{marginBottom:'10pt',fontWeight:'bold'}}>
									//				{this.state.field___lead___interest___models.map((___i_m: any) => 
									//					<div onClick={() => { this.handle___lead___vehicle_model___remove(___i_m['vehicle_model']); }}>
									//						<span><span>{ ___i_m['vehicle_brand'] }</span> { ___i_m['vehicle_model'] }</span>
									//						<i className="fas fa-times"></i>
									//					</div>
									//				)}
									//			</section>
									//		</> : <></>}
									//		<br />
									//		</>; break;
										case 'stock_vehicle': return <>
											{(this.state.field___lead___interest___to___custom !== '') ? <>
												<ComVehicleComponent vehicle___props={ this.state.field___lead___interest___to___custom___vehicle_props } option___description={ true } option___card={ false } option___buttons={[<>
													<div onClick={() => { this.handle___lead___vehicle___select(null); }}>
														<span>Rimuovi selezione</span>
														<i className="fas fa-unlink"></i>
													</div>
												</>]} option___redirect={ 'redirect' }/>
											</> : <>
												{(this.state.search___vehicles___results.length > 0) ? <>
													<section className="vehicles-all---results">
														{this.state.search___vehicles___results.map((___r_v: any) => 
															<div onClick={() => { this.handle___lead___vehicle___select(___r_v['vehicle_id']); }}>
																<ComVehicleSmallComponent vehicle___props={ ___r_v }/>
															</div>
														)}
													</section>
												</> : <></>}
											</>}
											</>; break;
										case 'custom': return <>
											<span className="input---nb-label">interessato a</span>
											<textarea value={ this.state.field___lead___interest___to___custom } onInput={(___e: any)=>{this.setState({field___lead___interest___to___custom:___e.target.value},()=>{this.submit___lead___check_if_can()})}} className={'input---nb-outlined is-textarea'} placeholder="Informazioni aggiuntive sull'interesse"></textarea>
											<br />
											</>; break;
								}})()}
								<br />

								{(this.state.field___lead___interest___to !== 'stock_vehicle') ? <>
									<span className="input---nb-label">caratteristiche di interesse</span>
									<div className="container---table table-lead-new---interests">
										<div style={{display:'none'}}></div>
										<div>
											<div>Anno di produzione</div>
											<div><input type="number" value={ this.state.field___lead___interest___year_from ?? undefined } placeholder="da anno" min={ 1900 } onInput={(___e: any)=>{this.setState({field___lead___interest___year_from: ___e.target.value });this.submit___lead___check_if_can();}}/></div>
											<div></div>
											<div><input type="number" value={ this.state.field___lead___interest___year_to ?? undefined } placeholder="ad anno" min={ 1900 } onInput={(___e: any)=>{this.setState({field___lead___interest___year_to: ___e.target.value });this.submit___lead___check_if_can();}}/></div>
										</div>
										<div>
											<div>Range di Prezzo</div>
											<div><input type="number" value={ this.state.field___lead___interest___price_from ?? undefined } placeholder="da euro" onInput={(___e: any)=>{this.setState({field___lead___interest___price_from:___e.target.value });this.submit___lead___check_if_can();}}/><p>€</p></div>
											<div></div>
											<div><input type="number" value={ this.state.field___lead___interest___price_to ?? undefined } placeholder="ad euro" onInput={(___e: any)=>{this.setState({field___lead___interest___price_to:___e.target.value });this.submit___lead___check_if_can();}}/><p>€</p></div>
										</div>
									</div>
									<br />
									<span className="input---nb-label">eventuale Permuta con altro veicolo</span>
									<section className="input---nb-outlined---flexy">
										<select value={ this.state.field___lead___exchange } onChange={ this.handle___lead___exchange___select }>
											<option value={''}>Nessuna permuta</option>
											<option value={'exchange'}>Disponibile alla permuta</option>
										</select>
									</section>
									{( this.state.field___lead___exchange === 'exchange') ? <>
										<section className="input---nb-outlined---flexy" style={{paddingTop:'10pt'}}>
											<p>con</p>
											<input type="text" value={ this.state.field___lead___exchange___item } className={ 'input---nb-outlined' } placeholder="oggetto della permuta" onInput={(___e: any)=>{this.setState({field___lead___exchange___item:___e.target.value });this.submit___lead___check_if_can();}}/>
										</section>
										<section className="input---nb-outlined---flexy" style={{paddingTop:'10pt'}}>
											<p>prezzo</p>
											<input type="number" value={ this.state.field___lead___exchange___price ?? undefined } className={ 'input---nb-outlined' } placeholder="prezzo (€)" onInput={(___e: any)=>{this.setState({field___lead___exchange___price:___e.target.value });this.submit___lead___check_if_can();}}/>
											<p>€</p>
										</section>
									</> : <></>}
									<br />
								</> : <></>}
								<span className="input---nb-label">Note</span>
								<textarea value={ this.state.field___lead___interest___notes } onInput={(___e: any)=>{this.setState({field___lead___interest___notes:___e.target.value},()=>{this.submit___lead___check_if_can()})}} className={'input---nb-outlined is-textarea'} placeholder="Informazioni aggiuntive o note"></textarea>
								<br />
							</section>

						</div>
						<div className="page-container---bottom-bar---buttons">
							<button type="button" className="is-card" onClick={() => { this.submit___lead___reset(); }}>
								<i className="fas fa-times"></i>
								<span>Reset</span>
							</button>
							{(this.state.lead___can_submit === true) ? <>
								<button type="button" className="is-card is-submit" onClick={() => { this.submit___lead(); }}>
									<i className="fas fa-check"></i>
									<span>Salva Nuovo Lead</span>
								</button>
							</> : <>
								<button type="button" className="is-card is-disabled">
									<i className="fas fa-check"></i>
									<span>Completa tutti i campi prima di salvare</span>
								</button>
							</>}
						</div>
					</div>

				</section>
			</div>
		</>;
	}

//#endregion

}