import React from 'react';

//	S T Y L E

import './com-licence-inactive.scss';

//	T Y P E S

import { props___ComLicenceInactiveComponent } from './com-licence-inactive.state';

//	C L A S S

export default class ComLicenceInactiveComponent extends React.Component<props___ComLicenceInactiveComponent, {}>
{

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___ComLicenceInactiveComponent,
	) {
		super(props);
	}

//#endregion

//#region 																							R E T U R N

	render(): React.ReactNode
	{
		return <>
			<div className="licence---needed">
				<h6>licenza { this.props.licence_needed }</h6>
			</div>
		</>;
	}

//#endregion

}