import { props___page___hasAlert } from "../../../interfaces/interface.alerts"
import { props___page___hasToast } from "../../../interfaces/interface.toasts"

//	P R O P S

export interface props___SbSyncVehiclesPage extends props___page___hasAlert, props___page___hasToast
{

}

//	S T A T E

export interface state___SbSyncVehiclesPage {

	selected___import___tab: '' | 'multigestionale_motori',

	dealer___props: any | null,
	dealer___props___importers: any | null,

}

//	S T A T E   D E F A U L T S

export const state___SbSyncVehiclesPage___defaults: state___SbSyncVehiclesPage = {

	selected___import___tab: 'multigestionale_motori',

	dealer___props: null,
	dealer___props___importers: null,

}