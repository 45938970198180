import { type___alert___controller } from "../types/types.alerts";

//	P R O P S

export interface props___AlAlertController {

	alert___title: string,
	alert___message: any,
	alert___buttons: type___alert___controller['alert___buttons'],
	
	event___onDidDismiss: any

}

//	S T A T E

export interface state___AlAlertController {

	wrapped___alert_message: string | any,

}

//	S T A T E   D E F A U L T S

export const state___AlAlertController___default: state___AlAlertController = {
	
	wrapped___alert_message: null

}